const BaseModel = require('cf-base-model')
const schemata = require('./schema')()
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

module.exports = BaseModel.extend({
  schemata: schemata,

  type: 'list',

  displayName: 'List',

  defaults() {
    return schemata.makeDefault({ type: this.type || 'list' })
  },

  validate: validateDelegate,

  initialize: function () {},
})
