module.exports = InstanceService
const CrudService = require('../../../../admin/source/js/lib/crud-service')
const parseUrl = require('url').parse

function InstanceService() {
  this.name = 'InstanceService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/instances'
}

InstanceService.prototype = Object.create(CrudService.prototype)

InstanceService.prototype.getUrl = function (instance, cb) {
  this.serviceLocator.accountService.read(
    instance.account,
    function (err, account) {
      if (err) return cb(err)
      cb(null, this.createUrl(instance, account))
    }.bind(this)
  )
}

InstanceService.prototype.createUrl = function (instance, account) {
  const parsed = parseUrl(this.serviceLocator.config.url)
  let portPostfix = ''
  if (parsed.port) portPostfix = ':' + parsed.port
  return 'http://' + this.createHost(instance, account) + portPostfix
}

InstanceService.prototype.createHost = function (instance, account) {
  return instance.subdomain + (account.domain ? '.' + account.domain : '')
}
