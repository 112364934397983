const CrudService = require('../../../../admin/source/js/lib/crud-service')

class MotmService extends CrudService {
  get name() {
    return 'motmService'
  }
  get urlRoot() {
    return '/motms'
  }
}

module.exports = MotmService
