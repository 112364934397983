const Model = require('merstone')
const ImageAreaModel = require('../../asset/models/image-area')
const createSchema = require('../../../service/motm/schema')

class MotmModel extends Model {
  constructor(serviceLocator, attributes) {
    super(serviceLocator, attributes)

    this.images = new ImageAreaModel(this.get('images'))

    this.images.on(
      'add remove change',
      function () {
        this.set('images', this.images.toJSON())
      }.bind(this)
    )

    this.schema = createSchema()
    this.attributes = this.schema.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })
  }

  validate(cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset(attrs) {
    attrs = this.schema.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }

  has(key) {
    return !!this.get(key)
  }
}

module.exports = MotmModel
