const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (checked, data, disabled, inputType) {
buf.push("<input" + (jade.attr("type", inputType, true, false)) + (jade.attr("name", data.name, true, false)) + (jade.attr("checked", checked, true, false)) + (jade.attr("disabled", disabled, true, false)) + (jade.cls(['js-image-contexts',data.allowNull ? 'context-optional' : 'context-required'], [null,true])) + "/><span>" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</span>");}.call(this,"checked" in locals_for_with?locals_for_with.checked:typeof checked!=="undefined"?checked:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"disabled" in locals_for_with?locals_for_with.disabled:typeof disabled!=="undefined"?disabled:undefined,"inputType" in locals_for_with?locals_for_with.inputType:typeof inputType!=="undefined"?inputType:undefined));;return buf.join("");
}

module.exports = window.Backbone.View.extend({
  tagName: 'label',

  initialize: function () {
    if (this.model.get('allowMultipleSelection')) {
      this.options.inputType = 'checkbox'
    } else {
      this.options.inputType = 'radio'
    }

    // Check if the properties dictate that this should be an automatically
    // checked context.
    if (this.model.get('autoChecked')) {
      this.options.checked = true
      this.options.disabled = true
    }

    this.render()
  },

  template: template,

  render: function () {
    this.$el.empty().append(
      this.template({
        data: this.model.toJSON(),
        inputType: this.options.inputType,
        checked: this.options.checked,
        disabled: this.options.disabled,
      })
    )

    return this
  },
})
