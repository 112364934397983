module.exports = CompetitionModel
const Model = require('merstone')
const createSchema = require('../../../service/competition/schema')

function CompetitionModel(serviceLocator, attributes) {
  Model.apply(this, arguments)
  this.schema = createSchema(serviceLocator)
  this.attributes = this.schema.cast(attributes || {})

  this.hook('preSet', (attrs, cb) => {
    cb(null, attrs)
  })
}

CompetitionModel.prototype = Object.create(Model.prototype)

CompetitionModel.prototype.validate = function (cb) {
  this.schema.validate(this.attributes, cb)
}

CompetitionModel.prototype.reset = function (attrs) {
  attrs = this.schema.cast(attrs || {})
  Model.prototype.reset.call(this, attrs)
}

CompetitionModel.prototype.isEditable = function () {
  return this.get('source') === 'cms'
}
