import editView from './views/form'
import model from './models/model'
import itemView from '../../../widget/views/item/edit-base'

const initWidget = () => ({ commentaryWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Commentary',
    description: 'Display match commentary',
  }

  serviceLocator.widgetFactories.get('match').register('commentary', widget)
  done()
}

module.exports = initWidget
