module.exports = createController
const ListView = require('../views/list')
const Collection = require('chale')
const SectionModel = require('../models/section')
const Model = require('merstone')
const async = require('async')
const pageSize = 150
const getPreviewUrlComponents = require('../lib/get-preview-url-component')

function createController(serviceLocator) {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0,
  })
  let currentPage = 1
  let currentParams = { keywords: '', filter: {}, sort: [ 'fullUrlPath', 'asc' ] }

  // Whenever a section is updated, reset the model with its new attributes
  serviceLocator.sectionService.on('update', function (id, attrs) {
    var model = collection.get(id)
    if (model) model.reset(attrs)
    serviceLocator.sectionService.cachedFind.clear()
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.sectionService.on('create', refresh)

  // Update when any instance changes
  serviceLocator.instanceService.on('create', refresh)
  serviceLocator.instanceService.on('update', refresh)

  // Update when any account changes
  serviceLocator.accountService.on('create', refresh)
  serviceLocator.accountService.on('update', refresh)

  serviceLocator.router.route('sections(/)', 'listSections', function () {
    if (!serviceLocator.allow('section', 'discover')) return false

    getSections(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      { page: currentPage, pageSize: pageSize }
    )

    async.parallel(
      { instances: getInstances, accounts: getAccounts },
      function (err, res) {
        if (err)
          return this.serviceLocator.logger.error(
            err,
            'Could not load instances/accounts'
          )

        var list = new ListView(
          serviceLocator,
          collection,
          paginationModel,
          res.instances,
          res.accounts
        ).render()

        list.displayFilterParams(currentParams)

        list.on('createNew', function () {
          if (!serviceLocator.allow('section', 'create')) return false
          serviceLocator.router.navigate('sections/form', { trigger: true })
        })

        list.on('edit', function (id) {
          if (!serviceLocator.allow('section', 'update')) return false
          serviceLocator.router.navigate('sections/' + id + '/form', {
            trigger: true,
          })
        })

        list.on('manageLayouts', function (id) {
          if (!serviceLocator.allow('section', 'manageLayouts')) return false
          serviceLocator.router.navigate('layouts/' + id + '/manager', {
            trigger: true,
          })
        })

        list.on('reorder', function () {
          var instance = currentParams.filter.instance
          if (!instance && res.instances && res.instances[0]) {
            instance = res.instances[0]._id
          }
          serviceLocator.router.navigate('sections/reorder/' + instance, {
            trigger: true,
          })
        })

        list.on('preview', function (section) {
          var w = window.open('')

          getPreviewUrlComponents(
            serviceLocator,
            section.get('instance') || res.instances[0]._id,
            function (err, obj) {
              if (err) {
                w.close()
                return serviceLocator.logger.error(
                  err,
                  'Could not preview section'
                )
              }
              w.location =
                serviceLocator.instanceService.createUrl(
                  obj.instance,
                  obj.account
                ) +
                section.get('fullUrlPath') +
                '?previewId=' +
                section.get('previewId')
            }
          )
        })

        list.on('datePreview', function (section) {
          if (!serviceLocator.allow('section', 'discover')) return false
          var w = window.open('')

          async.parallel(
            { url: getPreviewUrlComponents.bind(null, section) },
            function (err, obj) {
              if (err) {
                w.close()
                return serviceLocator.logger.error(
                  err,
                  'Could not preview section'
                )
              }
              w.location =
                serviceLocator.instanceService.createUrl(
                  obj.url.instance,
                  obj.url.account
                ) +
                '/date-preview' +
                section.get('fullUrlPath') +
                '?date=' +
                new Date().toISOString() +
                '&dateHash=' +
                obj.hash
            }
          )
        })

        list.on('delete', function (id) {
          if (!serviceLocator.allow('section', 'delete')) return false
          serviceLocator.sectionService.delete(id, function (err) {
            if (err) return alert(err.message)
            collection.remove(id)
          })
        })

        list.on('filter', function (params) {
          currentParams = params
          var pagination = { page: currentPage, pageSize: pageSize }
          currentPage = 1
          getSections(params.keywords, params.filter, params.sort, pagination)
        })

        list.on('loadMore', function () {
          currentPage += 1
          var pagination = { page: currentPage, pageSize: pageSize }
          appendSections(
            currentParams.keywords,
            currentParams.filter,
            currentParams.sort,
            pagination
          )
        })

        serviceLocator.router.render(list, 'Sections')
      }
    )
  })

  function getSections(keywords, filter, sort, pagination) {
    serviceLocator.sectionService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err)
          return serviceLocator.logger.error(err, 'Could not load sections')
        collection.reset(
          res.results.map(function (section) {
            return new SectionModel(serviceLocator, section)
          })
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  function appendSections(keywords, filter, sort, pagination) {
    serviceLocator.sectionService.find(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err) return alert(err.message)
        res.results.forEach(function (section) {
          collection.add(new SectionModel(serviceLocator, section))
        })
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  function getInstances(cb) {
    serviceLocator.instanceService.find(
      '',
      {},
      [ 'name' ],
      { pageSize: 1000 },
      function (err, res) {
        if (err) return cb(err)
        return cb(null, res.results)
      }
    )
  }

  function getAccounts(cb) {
    serviceLocator.accountService.find(
      '',
      {},
      [ 'name' ],
      { pageSize: 1000 },
      function (err, res) {
        if (err) return cb(err)
        return cb(null, res.results)
      }
    )
  }

  function refresh() {
    serviceLocator.sectionService.cachedFind.clear()
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    getSections(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  }
}
