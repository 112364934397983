const FormBuilderFormView = require('../../form-builder/views/form')
const QuestionFormView = require('./question')

class PollsFormView extends FormBuilderFormView {
  constructor(...args) {
    super(...args)

    this.title = 'Poll'
    this.QuestionFormView = QuestionFormView
    this.allowAdditionalQuestions = false
    this.allowQuestionDescription = false
    this.allowReorder = false

    if (this.isNew) {
      this.handleAddQuestion()
    }
  }
}

module.exports = PollsFormView
