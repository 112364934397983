module.exports = createController
const ListView = require('../views/list')
const AssetCollection = require('../collections/asset')

function createController(serviceLocator) {
  const router = serviceLocator.router
  const displayName = { singular: 'Asset', plural: 'Assets' }

  router.route('asset-manager(/)', 'listAssets', function () {
    if (!serviceLocator.allow('asset', 'discover')) return false

    var collection = new AssetCollection()

    router.render(
      new ListView({
        collection: collection,
        title: displayName.plural,
        serviceLocator: serviceLocator,
      }),
      displayName.plural
    )

    collection.load()
  })
}
