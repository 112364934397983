import FormView from '../list/views/form'
import ItemView from '../list/views/item'
import Model from './models/model'

const initWidget = () => ({
  maxiGridWidget: ['widget', 'listWidget', 'sectionAdmin', init],
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    name: 'Maxi Grid',
    itemView: ItemView,
    description: 'Displays content in a maxi-grid',
  }

  ;['article', 'section', 'tabsBody'].forEach((factory) =>
    serviceLocator.widgetFactories.get(factory).register('maxiGrid', widget)
  )

  done()
}

module.exports = initWidget
