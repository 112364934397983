import editView from './views/form'
import model from './models/model'
import itemView from './views/item'

const initWidget = () => ({ pollWidget: ['sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Poll',
    description: 'Display a poll',
  }

  serviceLocator.widgetFactories.get('section').register('poll', widget)
  serviceLocator.widgetFactories.get('article').register('poll', widget)
  serviceLocator.widgetFactories.get('articleBody').register('poll', widget)
  serviceLocator.widgetFactories.get('tabsBody').register('poll', widget)

  done()
}

module.exports = initWidget
