module.exports = initAdmin
const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/rugby-camp')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')

const RugbyCampService = require('./services/rugby-camp')

function initAdmin() {
  return { rugbyCampAdmin: [ init ] }
}

function init(serviceLocator, cb) {
  serviceLocator.rugbyCampService = new RugbyCampService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'rugby camp',
    plural: 'rugby camps',
    path: 'rugby-camps',
    service: serviceLocator.rugbyCampService,
    Model,
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}
