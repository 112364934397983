const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('@clocklimited/validity-required')

const schema = schemata({
  name: 'Advert',
  properties: {
    advert: {
      type: String,
      validators: [required],
    },
    size: {
      type: String,
      validators: [required],
    },
    demarcate: {
      type: Boolean,
      defaultValue: false,
    },
    topMargin: {
      type: Boolean,
      defaultValue: true,
    },
    bottomMargin: {
      type: Boolean,
      defaultValue: true,
    },
    ...baseSchema.getProperties(),
  },
})

module.exports = BaseModel.extend({
  schemata: schema,
  type: 'advert',
  defaults() {
    return schema.makeDefault({ type: this.type || 'advert' })
  },

  validate: validateDelegate,
})
