const compileJade = require('browjadify-compile')
const View = require('ventnor')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"panel panel-styled panel-fixed\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Season</span><div class=\"js-seasons form-field\"></div></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Competition</span><div class=\"js-competitions form-field\"></div></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Squad</span><div class=\"js-squads form-field\"></div></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Status</span><div class=\"js-statuses form-field\"></div></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Sort By</span><select name=\"sort\" class=\"control control--choice form-field\"><option value=\"-dateCreated\">Newest First</option><option value=\"dateCreated\">Oldest First</option><option value=\"-score\">Relevance</option></select></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");;return buf.join("");
}
const SquadSelector = require('../../squad/views/squad-selector')
const CompetitionSelect = require('../../competition/views/competition-select')
const SeasonSelector = require('../../season/views/season-selector')
const StatusSelector = require('./status-selector')

class ListFilterView extends View {
  constructor(...args) {
    super(...args)
    this.$el.addClass('list-filters')
    this.$el.on('submit', 'form', this.handleSubmit.bind(this))
    this.$el.on('click', 'input[type=reset]', this.handleClear.bind(this))
    this.squadSelectView = new SquadSelector(this.serviceLocator)

    this.competitionSelectView = new CompetitionSelect(this.serviceLocator)

    this.seasonSelectView = new SeasonSelector(this.serviceLocator)

    this.statusSelectView = new StatusSelector(this.serviceLocator)
  }

  trigger(...args) {
    return this.emit(...args)
  }

  updateDisplay(params) {
    if (params.filter.season) {
      this.$el
        .find('[name=season] option[value=' + params.filter.season + ']')
        .attr('selected', true)
    }

    if (params.filter.competition) {
      this.$el
        .find(
          '[name=competition] option[value=' + params.filter.competition + ']'
        )
        .attr('selected', true)
    }

    if (params.filter.squad) {
      this.$el
        .find('[name=squad] option[value=' + params.filter.squad + ']')
        .attr('selected', true)
    }

    if (params.filter.status) {
      this.$el
        .find('[name=status] option[value=' + params.filter.status + ']')
        .attr('selected', true)
    }

    const backMap = {
      'dateCreated,asc': 'dateCreated',
      'dateCreated,desc': '-dateCreated',
      'score,desc': '-score',
    }

    if (Array.isArray(params.sort)) {
      this.$el
        .find(
          '[name=sort] option[value=' + backMap[params.sort.join(',')] + ']'
        )
        .attr('selected', true)
    }
  }

  handleSubmit(e) {
    if (e) e.preventDefault()

    const params = { filter: {}, sort: [] }
    const map = {
      dateCreated: [ 'dateTime', 'asc' ],
      '-dateCreated': [ 'dateTime', 'desc' ],
      '-score': [ 'score', 'desc' ],
    }

    const season = this.$el.find('form [name=season]').val()
    const competition = this.$el.find('form [name=competition]').val()
    const squad = this.$el.find('form [name=squad]').val()
    const venue = this.$el.find('form [name=venue]').val()
    const status = this.$el.find('form [name=status]').val()

    params.sort = map[this.$el.find('form [name=sort]').val()]

    if (season) params.filter.season = season
    if (competition) params.filter.competition = competition
    if (squad) params.filter.squad = squad
    if (venue) params.filter.venue = venue
    if (status) params.filter.status = status

    this.emit('filter', params)
  }

  handleClear(e) {
    e.preventDefault()
    this.$el.find('form [name=sort]').val('-dateCreated')
    this.$el.find('form [name=season]').val('')
    this.$el.find('form [name=competition]').val('')
    this.$el.find('form [name=squad]').val('')
    this.$el.find('form [name=status]').val('')
    this.$el.find('form [name=venue]').val('')
    this.$el.find('form [name=source]').val('')
    this.handleSubmit()
  }

  render() {
    this.$el.empty().append(template({}))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    this.squadSelectView.render().$el.appendTo(this.$el.find('.js-squads'))
    this.statusSelectView.render().$el.appendTo(this.$el.find('.js-statuses'))
    this.competitionSelectView
      .render()
      .$el.appendTo(this.$el.find('.js-competitions'))
    this.seasonSelectView.render().$el.appendTo(this.$el.find('.js-seasons'))

    this.$el.find('form [name=season]').on('change', () => {
      this.competitionSelectView.populate(
        this.$el.find('form [name=season]').val()
      )
    })
    return this
  }
}

module.exports = ListFilterView
