const BaseModel = require('cf-base-model')
const createContextValidator = require('validity-cf-image-context-selection')
const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const url = require('validity-url')
const createImageUrlBuilder = require('cf-image-url-builder')
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()
const ImageAreaModel = require('../../../asset/models/image-area')
const config = window.config
const schema = schemata({
  name: 'Store item model schema',
  properties: {
    name: {
      type: String,
      validators: {
        all: [required],
      },
    },
    price: {
      type: String,
      validators: {
        all: [required],
      },
    },
    link: {
      type: String,
      validators: {
        all: [required, url],
      },
    },
    image: {
      type: Object,
      defaultValue: function () {
        return {}
      },
      validators: {
        all: [createContextValidator(['Main'])],
      },
    },
  },
})

module.exports = BaseModel.extend({
  schemata: schema,

  defaults() {
    return schema.makeDefault()
  },

  initialize: function () {
    this.images = new ImageAreaModel(this.get('image'))
  },

  getPreviewImageUrl: function () {
    const images = this.images.widgets.map(function (widget) {
      return widget.toJSON()
    })
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, images)
    const image = urlBuilder.getImage('Main')
    if (!image) return null
    return image.crop('Square').constrain(300).url()
  },

  validate: validateDelegate,
})
