const BaseModel = require('cf-base-model')

module.exports = BaseModel.extend({
  initialize: function (fromSection) {
    this.set('fromSection', fromSection)
    this.set('toSections', [])
    this.set('duplicateRecursively', false)
    this.set('duplicateSectionLayout', true)
    this.set('duplicateArticleLayout', false)
  },
  addSection: function (id) {
    if (this.get('toSections').indexOf(id) === -1) {
      var currentSections = this.get('toSections')
      currentSections.push(id)
      this.set('toSections', currentSections)
    }
  },
  removeSection: function (id) {
    if (this.get('toSections').indexOf(id) !== -1) {
      const arrayIndex = this.get('toSections').indexOf(id)
      const currentSections = this.get('toSections')
      this.set('toSections', currentSections.splice(arrayIndex, 1))
    }
  },
})
