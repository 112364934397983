let createAuthedRequester = require('../../../../admin/source/js/lib/authed-request')

class RevisionService {
  constructor(serviceLocator) {
    this.urlRoot = '/revisions'
    this.serviceLocator = serviceLocator
    this.authedRequest = createAuthedRequester(serviceLocator.config.apiUrl)
  }

  listRevisionsForEntity(entityId, type, cb) {
    this.authedRequest(
      'GET',
      this.urlRoot + '/' + type + '/' + entityId,
      null,
      function (err, res, body) {
        if (err) return cb(err)
        cb(null, body)
      }
    )
  }

  compareRevisions(entityId, type, oldId, newId, cb) {
    var url =
      this.urlRoot +
      '/' +
      type +
      '/' +
      entityId +
      '/compare/' +
      oldId +
      '/' +
      newId
    this.authedRequest('GET', url, null, function (err, res, body) {
      if (err) return cb(err)
      cb(null, body)
    })
  }

  restoreRevision(entityId, type, revisionId, index, cb) {
    const url = this.urlRoot + '/' + type + '/restore'
    const data = { revisionId: revisionId, revisionIndex: index }

    this.authedRequest('POST', url, data, function (err, res, body) {
      if (err) return cb(err)
      cb(null, body)
    })
  }
}

module.exports = RevisionService
