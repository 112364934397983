const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const tagSchema = require('../tag/schema')

module.exports = function () {
  return schemata({
    name: 'Instance schema',
    properties: {
      _id: { type: String },
      name: { type: String, validators: { all: [required] } },
      account: { type: String, validators: { all: [required] } },
      subdomain: { type: String, validators: { all: [required] } },
      showInstanceHeader: {
        type: Boolean,
        defaultValue: true,
        validators: { all: [required] },
      },
      enabled: {
        type: Boolean,
        defaultValue: true,
        validators: { all: [required] },
      },
      tags: {
        tags: { type: schemata.Array(tagSchema()) },
        defaultValue: function () {
          return []
        },
      },
      strapline: { type: String, validators: { all: [] } },
      accentBackground: {
        type: String,
        validators: { all: [required] },
        defaultValue: '45515c',
      },
      accentText: {
        type: String,
        validators: { all: [required] },
        defaultValue: 'ffffff',
      },
      logo: { type: String, validators: { all: [required] } },
      image: { type: String, validators: { all: [] } },
      addThisId: { type: String, validators: { all: [] } },
      facebookId: { type: String, validators: { all: [] } },
      twitterId: { type: String, validators: { all: [] } },
      googlePlusId: { type: String, validators: { all: [] } },
      pinterestId: { type: String, validators: { all: [] } },
      instagramId: { type: String, validators: { all: [] } },
      googleAnalyticsPropertyId: { type: String, validators: { all: [] } },
      footerHtml: { type: String, validators: { all: [] } },
      navigation: { type: Array },
      newKitModalEnabled: { type: Boolean, validators: { all: [] } },
      newKitModalCtaText: { type: String, validators: { all: [] } },
      newKitModalCtaLink: { type: String, validators: { all: [] } },
      navigationCallToActionText: { type: String },
      navigationCallToActionLink: { type: String },
      navigationSecondaryCallToActionText: { type: String },
      navigationSecondaryCallToActionLink: { type: String },
      navigationTertiaryCallToActionText: { type: String },
      navigationTertiaryCallToActionLink: { type: String },
      mobileAppNewsSources: { type: Array, validators: { all: [required] } },
      cookieConsentId: { type: String, validators: { all: [] } },

      dateCreated: {
        type: Date,
        defaultValue: function () {
          return new Date()
        },
      },
    },
  })
}
