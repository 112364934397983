const debug = require('../../../../admin/source/js/lib/debug')(
  'widget based layout'
)
const WidgetAreaModel = require('./widget-area')
const clone = require('lodash.clonedeep')
const max = require('lodash.max')
const extend = require('lodash.assign')

function Layout(layout, options) {
  debug('init', layout)
  this.layout = layout || {}
  this.layoutWidgets = {}
  this.options = options
  this.abstractWidgetFactory = options && options.abstractWidgetFactory
  Object.keys(this.layout).forEach(
    function (row) {
      this.layout[row].cols.forEach(
        function (column) {
          column.widgetArea = new WidgetAreaModel(
            { widgets: column.widgetArea.widgets },
            {
              relatedWidgets: this.layoutWidgets,
              abstractWidgetFactory: this.abstractWidgetFactory,
              serviceLocator: options.serviceLocator,
            }
          )

          this.propagateWidgetAreaChanges(column.widgetArea)
        }.bind(this)
      )
      this.trigger('addRow', row)
    }.bind(this)
  )
}

extend(Layout.prototype, window.Backbone.Events)

Layout.prototype.getType = function (type) {
  var widgets = []
  Object.keys(this.layout).forEach(
    function (row) {
      this.layout[row].cols.forEach(function (column) {
        widgets = widgets.concat(column.widgetArea.getType(type))
      })
    }.bind(this)
  )
  return widgets
}

Layout.prototype.findWidgetArea = function (id) {
  if (typeof id !== 'string') {
    return false
  }
  var areaId = id.split(':')

  if (areaId.length !== 2) {
    return false
  }

  if (
    this.layout[areaId[0]] &&
    this.layout[areaId[0]].cols[areaId[1]] &&
    this.layout[areaId[0]].cols[areaId[1]].id === id
  ) {
    return this.layout[areaId[0]].cols[areaId[1]].widgetArea
  }

  return false
}

function newWidgetArea() {
  return new WidgetAreaModel(
    {},
    {
      relatedWidgets: this.layoutWidgets,
      abstractWidgetFactory: this.abstractWidgetFactory,
    }
  )
}

Layout.prototype.addRow = function (type) {
  let id = max(Object.keys(this.layout), (keys) => parseInt(keys, 10)) || 0
  const layoutDefinitions = {
    '3W': {
      title: '3 Column Wide',
      attributes: ['wide'],
      cols: [
        {
          id: id + ':0',
          order: 1,
          width: 3,
          attributes: [],
          widgetArea: newWidgetArea.call(this),
        },
      ],
    },
    '3': {
      title: '3 Column',
      cols: [
        {
          id: id + ':0',
          order: 1,
          width: 3,
          attributes: [],
          widgetArea: newWidgetArea.call(this),
        },
      ],
    },
    '1:2': {
      title: '2 Column Right',
      attributes: ['gutterless'],
      cols: [
        {
          id: id + ':0',
          order: 1,
          width: 1,
          widgetArea: newWidgetArea.call(this),
        },
        {
          id: id + ':1',
          order: 2,
          width: 2,
          widgetArea: newWidgetArea.call(this),
        },
      ],
    },
    '2:1': {
      title: '2 Column Left',
      attributes: ['gutterless'],
      cols: [
        {
          id: id + ':0',
          order: 1,
          width: 2,
          widgetArea: newWidgetArea.call(this),
        },
        {
          id: id + ':1',
          order: 2,
          width: 1,
          widgetArea: newWidgetArea.call(this),
        },
      ],
    },
    '1:1': {
      title: '2 Column Equal',
      cols: [
        {
          id: id + ':0',
          order: 1,
          width: 1.5,
          widgetArea: newWidgetArea.call(this),
        },
        {
          id: id + ':1',
          order: 2,
          width: 1.5,
          widgetArea: newWidgetArea.call(this),
        },
      ],
    },
    '1:1:1': {
      title: '1 Column',
      cols: [
        {
          id: id + ':0',
          order: 1,
          width: 1,
          widgetArea: newWidgetArea.call(this),
        },
        {
          id: id + ':1',
          order: 2,
          width: 1,
          widgetArea: newWidgetArea.call(this),
        },
        {
          id: id + ':2',
          order: 3,
          width: 1,
          widgetArea: newWidgetArea.call(this),
        },
      ],
    },
  }

  id = id < 0 ? 0 : parseInt(id, 10) + 1
  const newLayout = layoutDefinitions[type]

  if (newLayout === undefined) {
    throw new Error("Unknown row type '" + type + "'")
  }

  const newRow = {
    id: id,
    title: newLayout.title,
    cols: newLayout.cols,
    attributes: newLayout.attributes,
  }

  this.layout[id] = newRow

  newLayout.cols.forEach(
    function (area) {
      this.propagateWidgetAreaChanges(area.widgetArea)
    }.bind(this)
  )

  this.trigger('addRow', newRow)
  this.trigger('change', this)
}

Layout.prototype.removeRow = function (index) {
  if (!this.layout[index]) {
    throw new RangeError("No such row '" + index + "'")
  }

  delete this.layout[index]
  this.trigger('removeRow', index)
}

Layout.prototype.toJSON = function () {
  var returnLayout = {}
  Object.keys(this.layout).forEach(
    function (id) {
      var row = this.layout[id]
      returnLayout[id] = clone(row)
      returnLayout[id].cols = []
      row.cols.forEach(function (column) {
        var col = clone(column)
        col.widgetArea = column.widgetArea.toJSON()
        returnLayout[id].cols.push(col)
      })
    }.bind(this)
  )
  return returnLayout
}

/*
 * Widget area 'add' 'remove' and 'change' events mean that the layout has changed
 * so emit 'change' events on the layout.
 */
Layout.prototype.propagateWidgetAreaChanges = function (widgetArea) {
  widgetArea.on(
    'add remove change',
    function () {
      this.trigger('change', this)
    }.bind(this)
  )
}

Layout.prototype.setRowOrder = function (ids) {
  var layout = {}

  ids.forEach(
    function (id, i) {
      layout[i] = this.layout[id]
      layout[i].id = i
    }.bind(this)
  )

  this.layout = layout
}

module.exports = Layout
