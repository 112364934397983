const inputTypes = [
  {
    name: 'Short text',
    input: 'text',
    requiresResponse: false,
  },
  {
    name: 'Email Address',
    input: 'email',
    requiresResponse: false,
  },
  {
    name: 'Paragraph',
    input: 'textarea',
    requiresResponse: false,
  },
  {
    name: 'Drop-down',
    input: 'select',
    requiresResponse: true,
  },
  {
    name: 'Multiple choice',
    input: 'radio',
    requiresResponse: true,
  },
  {
    name: 'Checkboxes',
    input: 'checkbox',
    requiresResponse: true,
  },
  {
    name: 'Date',
    input: 'datePicker',
    requiresResponse: false,
  },
]

module.exports.all = () => inputTypes
module.exports.requiresResponse = () =>
  inputTypes.filter((input) => input.requiresResponse)
