const schemata = require('@clocklimited/schemata')
const questionSchema = require('./question-schema')
const validity = require('validity')
const required = require('@clocklimited/validity-required')
const isNotEmpty = (value) => value.length > 0
const nonEmptyValidator =
  validity.createValidatorAllowingFailureMessageOverride(
    validity.booleanToCallback(isNotEmpty),
    'Must have at least one question'
  )

module.exports = () => {
  return schemata({
    name: 'Form builder schema',
    properties: {
      _id: {
        type: String,
      },
      title: {
        type: String,
        validators: [required],
      },
      email: {
        type: String,
      },
      ctaText: {
        type: String,
      },
      thanksMessage: {
        type: String,
      },
      description: {
        type: String,
      },
      questions: {
        defaultValue: () => [],
        type: schemata.Array(questionSchema()),
        validators: [nonEmptyValidator],
      },
      dateCreated: {
        type: Date,
        defaultValue: function () {
          return new Date()
        },
      },
      closeDate: {
        type: Date,
      },
      account: {
        type: String,
        validators: [required],
      },
      responseCount: {
        type: Number,
        defaultValue: 0,
      },
    },
  })
}
