const View = require('ventnor')
const uniqBy = require('lodash.uniqby')
class AdvertSelect extends View {
  constructor(serviceLocator, selected) {
    super(serviceLocator)
    this.$el = $('<select id="advert" />')
    this.$el.addClass('control control--choice control--multiline')
    this.el = this.$el[0]
    this.$el.attr('placeholder', 'Choose an advert')
    this.selected = selected || []
  }

  initializeSelectize() {
    this.serviceLocator.advertService.find(
      '',
      { name: this.selected },
      [],
      {},
      (err, res) => {
        if (err) return alert('Cannot find existing adverts')
        uniqBy(res.results, 'name').forEach((advert) => {
          // The item needs to be added to the list
          // of selectize options in order to be selected
          this.el.selectize.addOption({ value: advert.name, text: advert.name })
          // Select the added option
          this.el.selectize.addItem(advert.name)
        })
        this.el.selectize.on('change', this.updateSelection.bind(this))
      }
    )
  }

  updateSelection() {
    this.selected = this.el.selectize.getValue()
    this.emit('change', this.selected)
  }

  load(query, cb) {
    this.serviceLocator.advertService.find(query, {}, [], {}, (err, data) => {
      if (err) return alert('Cannot load existing adverts')
      cb(
        uniqBy(data.results, 'name').map((advert) => ({
          value: advert.name,
          text: advert.name,
        }))
      )
    })
  }

  render() {
    setTimeout(() => {
      this.$el.selectize({
        delimiter: ',',
        create: false,
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this),
        preload: true,
      })
    }, 0)
    return this
  }
}

module.exports = AdvertSelect
