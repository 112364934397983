const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const BaseModel = require('cf-base-model')
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()

const schema = schemata({
  name: 'Sponsor picker',
  properties: {
    sponsors: {
      type: Array,
      defaultValue: function () {
        return []
      },
    },
    ...baseSchema.getProperties(),
  },
})

module.exports = BaseModel.extend({
  schemata: schema,
  defaults() {
    return schema.makeDefault({ type: this.type || 'sponsorPicker' })
  },
  validate: validateDelegate,
})
