const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')

module.exports = () => {
  return schemata({
    name: 'Hospitality package schema',
    properties: {
      _id: {
        type: String,
      },
      account: {
        type: String,
        validators: [required],
      },
      type: {
        type: String,
        validators: [required],
      },
      available: {
        type: Boolean,
        defaultValue: true,
      },
      date: {
        type: Date,
        validators: [required],
        defaultValue: () => new Date(),
      },
      price: {
        type: String,
        validators: [required],
      },
      callToAction: {
        type: String,
        name: 'Call to Action',
        defaultValue: 'Book Online',
        validators: [required],
      },
      callToActionLink: {
        type: String,
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date(),
      },
    },
  })
}
