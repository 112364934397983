const config = window.config

const handleResponse = (postcode, response, callback) => {
  if (response && response.status === 'OK') {
    callback(response)
  } else if (response && response.status === 'OVER_QUERY_LIMIT') {
    setTimeout(
      () =>
        doGeocode(postcode, (response) => handleResponse(response, callback)),
      500
    )
  } else {
    callback()
  }
}

const doGeocode = (postcode, callback) => {
  Backbone.ajax({
    url: config.apiUrl + '/lat-long-cache/' + postcode,
    contentType: 'application/json',
    dataType: 'json',
    type: 'GET',
    success: callback,
    error: () => callback(),
  })
}

module.exports = (postcode, callback) => {
  doGeocode(postcode, (response) =>
    handleResponse(postcode, response, callback)
  )
}
