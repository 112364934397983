module.exports = RawView
const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (JSON, model) {
buf.push("<div class=\"grid\"><div class=\"grid__item desktop-one-half\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Old</h2></div><div class=\"panel-content\">");
if ( model.raw.old)
{
buf.push("<pre class=\"small\">" + (jade.escape(null == (jade_interp = JSON.stringify(model.raw.old, null, 2)) ? "" : jade_interp)) + "</pre>");
}
buf.push("</div></div></div><div class=\"grid__item desktop-one-half\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>New</h2></div><div class=\"panel-content\">");
if ( model.raw.new)
{
buf.push("<pre class=\"small\">" + (jade.escape(null == (jade_interp = JSON.stringify(model.raw.new, null, 2)) ? "" : jade_interp)) + "</pre>");
}
buf.push("</div></div></div></div>");}.call(this,"JSON" in locals_for_with?locals_for_with.JSON:typeof JSON!=="undefined"?JSON:undefined,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined));;return buf.join("");
}

function RawView(serviceLocator, model) {
  View.apply(this, arguments)
  this.serviceLocator = serviceLocator
  this.model = model
}

RawView.prototype = Object.create(View.prototype)

RawView.prototype.render = function () {
  this.$el.append(
    template({ model: this.model, format: this.serviceLocator.format })
  )
  return this
}
