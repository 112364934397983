module.exports = MatchService
const CrudService = require('../../../../admin/source/js/lib/crud-service')
const squads = require('../../../service/team/squads').playable
const pluck = require('lodash.pluck')

function MatchService() {
  this.name = 'MatchService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/matches'

  this.on('toggleLiveStatus', (matchId, matchIsLive, cb) => {
    CrudService.prototype.partialUpdate.call(
      this,
      matchId,
      { isLive: matchIsLive, lastModifiedDate: new Date() },
      (error) => {
        if (error) return cb(error)
        return cb(null)
      }
    )
  })

  this.on('toggleMatchStatus', (matchId, status, cb) => {
    CrudService.prototype.partialUpdate.call(
      this,
      matchId,
      { status: status, lastModifiedDate: new Date() },
      (error) => {
        if (error) return cb(error)
        return cb(null)
      }
    )
  })
}

MatchService.prototype = Object.create(CrudService.prototype)

MatchService.prototype.find = function (
  keywords,
  filter,
  sort,
  pagination,
  cb
) {
  const primaryClubIds = pluck(squads, 'teamId')
  const primaryClubId = {
    $or: [
      { homeTeam: { $in: primaryClubIds } },
      { awayTeam: { $in: primaryClubIds } },
      { source: 'cms' },
    ],
  }
  filter = Object.assign({}, filter, primaryClubId)
  CrudService.prototype.find.call(this, keywords, filter, sort, pagination, cb)
}
