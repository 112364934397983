const BaseModel = require('cf-base-model')
const schemata = require('schemata')
const required = require('@clocklimited/validity-required')

const schema = schemata({
  name: 'Frequently Asked Item',
  properties: {
    question: {
      type: String,
      validators: { all: [ required ] },
    },
    answer: {
      type: String,
      validators: { all: [ required ] },
    },
  },
})

module.exports = BaseModel.extend({
  schema,

  defaults() {
    return schema.makeDefault()
  },

  validate(cb) {
    this.schema.validate(this.attributes, (ignoreErr, errors) =>
      cb(Object.keys(errors).length > 0 ? errors : undefined)
    )
  },
})
