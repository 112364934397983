const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('@clocklimited/validity-required')

const schema = schemata({
  name: 'Match centre',
  properties: {
    competition: {
      type: String,
    },
    nextMatch: {
      type: String,
      validators: [required],
      defaultValue: () => 'home',
    },
    ...baseSchema.getProperties(),
  },
})

module.exports = BaseModel.extend({
  schemata: schema,
  type: 'matchCentre',
  defaults() {
    return schema.makeDefault({ type: this.type || 'matchCentre' })
  },

  validate: validateDelegate,
})
