const Model = require('merstone')
const createSchema = require('../../../service/linked-keyword/schema')
const Collection = require('chale')
const KeywordModel = require('./keyword')

class LinkedKeywordModel extends Model {
  constructor(serviceLocator, attributes) {
    super(serviceLocator, attributes)

    this.schema = createSchema()
    this.attributes = this.schema.cast(attributes || {})
    this.keywords = new Collection()

    this.keywords.on('add', () => {
      this.set('keywords', this.keywords.toJSON())
    })

    this.keywords.on('model:change', () => {
      this.set('keywords', this.keywords.toJSON())
    })

    this.keywords.on('remove', () => {
      this.set('keywords', this.keywords.toJSON())
    })

    if (!this.has('keywords')) this.set('keywords', [])

    this.get('keywords').forEach((response) => {
      this.keywords.add(new KeywordModel({}, response))
    })

    this.hook(
      'preSet',
      function (attrs, cb) {
        cb(null, this.schema.cast(attrs))
      }.bind(this)
    )
  }

  validate(cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset(attrs) {
    attrs = this.schema.cast(attrs || {})
    super.reset(attrs)
  }

  has(key) {
    return !!this.get(key)
  }
}

module.exports = LinkedKeywordModel
