const squads = [
  {
    text: 'Senior',
    value: 'senior',
    isPlayable: true,
    teamId: '1250',
  },
  {
    text: 'Women’s Squad',
    value: 'women',
    isPlayable: true,
    teamId: '30396',
  },
  {
    text: 'Development',
    value: 'development',
    isPlayable: true,
    teamId: '1250-development',
  },
  {
    text: 'Junior',
    value: 'junior',
    isPlayable: true,
    teamId: '1250-junior',
  },
  {
    text: 'Wheelchair',
    value: 'wheelchair',
    isPlayable: true,
    teamId: '1250-wheelchair',
  },
  {
    text: '7s',
    value: '7s',
    isPlayable: true,
    teamId: '1250-7s',
  },
  {
    text: 'Senior Squad Coaches',
    value: 'coaches',
    isPlayable: false,
  },
  {
    text: 'Senior Academy Squad Coaches',
    value: 'academy-management',
    isPlayable: false,
  },
  {
    text: 'Women’s Squad Coaches',
    value: 'womens-coaches',
    isPlayable: false,
  },
  {
    text: 'Wheelchair Rugby Coaches',
    value: 'wheelchair-coaches',
    isPlayable: false,
  },
  {
    text: 'Board',
    value: 'board',
    isPlayable: false,
  },
  {
    text: 'Past Players',
    value: 'past',
    isPlayable: false,
  },
  {
    text: 'Legendary',
    value: 'legendary',
    isPlayable: false,
  },
  {
    text: 'Hall of Fame',
    value: 'hall-of-hame',
    isPlayable: false,
  },
]

module.exports.all = squads
module.exports.playable = squads.filter((squad) => squad.isPlayable)

module.exports.getSquadNameById = (id) => {
  const squad = squads.find((squad) => squad.value === id)
  return squad ? squad.text : ''
}

module.exports.getSquadIdByName = (name) => {
  const squad = squads.find((squad) => squad.value === name)
  return squad ? squad.teamId : ''
}
