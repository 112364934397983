module.exports = initWidget

function initWidget() {
  return { fullStandingsWidget: [ 'widget', 'sectionAdmin', init ] }
}

function init(serviceLocator, done) {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Full Competition Table',
    itemView: require('./views/item'),
    description: 'Display all tables for a competition',
  }

  serviceLocator.widgetFactories
    .get('section')
    .register('fullStandings', widget)

  done()
}
