module.exports = createFileUploader
const AssetModel = require('../models/asset')
const debug = require('../../../../admin/source/js/lib/debug')('file uploader')
const config = window.config
const extend = require('lodash.assign')

require('../../../../admin/source/js/lib/vendor/iframe-transport-1.6.1')
require('../../../../admin/source/js/lib/vendor/blueimp-file-upload.5.21.1')

function createFileUploader($el, allowedFileType) {
  var uploader = extend({}, window.Backbone.Events)

  function destroy() {
    try {
      $el.fileupload('destroy')
    } catch (e) {
      debug(
        "Caught error trying to destroy fileupload. It probably didn't initialize yet",
        e
      )
    }
  }

  function addFileToModel(file, cb) {
    const type = determineFileType(file.type)
    let message
    let model

    // Only allow images for the time being.
    if (allowedFileType && type !== allowedFileType) {
      message =
        '"' + file.name + '" is not of the allowed type for this uploader.'
      message += ' Only files of type "' + allowedFileType + '" are allowed.'
      uploader.trigger('error', message)
      return
    }

    model = new AssetModel({
      size: file.size,
      name: file.name,
      mime: file.type,
    })

    model.checkSize(file.size, function (err) {
      if (err && err.level === 'error') {
        debug('Filesize check failed', err)
        uploader.trigger('error', err.message)
        return
      } else if (err && err.level === 'warning') {
        debug('Filesize check gave a warning, continuing…', err)
        uploader.trigger('warning', err.message)
      }

      uploader.trigger('add', model)
      cb(model)
    })
  }

  function addUpload(e, data) {
    debug('Upload starting', e, data)
    data.files.forEach(function (file) {
      addFileToModel(file, function (model) {
        data.model = model
        data.submit()
      })
    })
  }

  function getInfo(model, cb) {
    function onSuccess(data) {
      debug('Got response for image data', data)
      if (data.width && data.height) {
        cb(null, data)
      } else {
        cb(new Error('Could not retrieve image info'))
      }
    }

    $.ajax({
      dataType: 'json',
      url: model.buildInfoUrl(),
      success: onSuccess,
      error: function () {
        cb(new Error('Could not retrieve image info'))
      },
    })
  }

  $el
    .fileupload({
      url: config.darkroom.url + '/',
      singleFileUploads: true,
      dropZone: $('html'),
      pasteZone: $(),
      add: addUpload,
      limitConcurrentUploads: 5,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('x-darkroom-key', config.darkroom.key)
      },
    })
    .bind('fileuploadprogress', function (event, data) {
      data.model.trigger(
        'uploadProgress',
        Math.ceil((data.loaded / data.total) * 100),
        data.model
      )
    })
    .bind('fileuploadfail', function (event, data) {
      data.model.trigger(
        'uploadError',
        'The upload server is unavailable',
        data.model
      )
    })
    .bind('fileuploaddone', setData)

  function setData(e, data) {
    let fileType = 'image'
    let type

    if (typeof data.files !== 'undefined') {
      fileType = data.files[0].type
    }

    type = determineFileType(fileType)

    data.model.set('type', type)
    data.model.set('binaryUri', data.result.id)

    if (type === 'image') {
      getInfo(data.model, function (err, info) {
        if (err) {
          return uploader.trigger('error', err.message)
        }
        data.model.set({ height: info.height, width: info.width })
        data.model.trigger('uploadEnd', data.model)
      })
    } else {
      data.model.trigger('uploadEnd', data.model)
    }
  }

  function determineFileType(fileType) {
    let type = 'binary'
    const imageTypesTreatedAsBinary = [
      'image/vnd.adobe.photoshop',
      'image/tiff',
      'image/tiff-fx',
    ]

    if (fileType && fileType.indexOf('image') > -1) {
      type = 'image'
    }
    imageTypesTreatedAsBinary.forEach(function (imageType) {
      if (fileType === imageType) {
        type = 'binary'
      }
    })
    return type
  }

  uploader.addFileToModel = addFileToModel
  uploader.setData = setData

  uploader.on('destroy', destroy)

  return uploader
}
