import editView from './views/form'
import model from './models/model'
import itemView from '../../../widget/views/item/edit-base'

const initWidget = () => ({ menuAndScoreWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Menu and Score',
    description: 'Display a menu for navigating match page with the score',
  }

  serviceLocator.widgetFactories.get('match').register('menuAndScore', widget)
  done()
}

module.exports = initWidget
