const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (menu, undefined) {
// iterate menu
;(function(){
  var $$obj = menu;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var section = $$obj[$index];

buf.push("<div class=\"main-navigation__title\">" + (jade.escape(null == (jade_interp = section.title) ? "" : jade_interp)) + "</div><ul>");
// iterate section.items
;(function(){
  var $$obj = section.items;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var menuItem = $$obj[$index];

buf.push("<li><a" + (jade.attr("href", '/' + menuItem.route, true, false)) + (jade.attr("data-route", menuItem.route, true, false)) + ">" + (jade.escape(null == (jade_interp = menuItem.name) ? "" : jade_interp)) + "</a></li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var menuItem = $$obj[$index];

buf.push("<li><a" + (jade.attr("href", '/' + menuItem.route, true, false)) + (jade.attr("data-route", menuItem.route, true, false)) + ">" + (jade.escape(null == (jade_interp = menuItem.name) ? "" : jade_interp)) + "</a></li>");
    }

  }
}).call(this);

buf.push("</ul>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var section = $$obj[$index];

buf.push("<div class=\"main-navigation__title\">" + (jade.escape(null == (jade_interp = section.title) ? "" : jade_interp)) + "</div><ul>");
// iterate section.items
;(function(){
  var $$obj = section.items;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var menuItem = $$obj[$index];

buf.push("<li><a" + (jade.attr("href", '/' + menuItem.route, true, false)) + (jade.attr("data-route", menuItem.route, true, false)) + ">" + (jade.escape(null == (jade_interp = menuItem.name) ? "" : jade_interp)) + "</a></li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var menuItem = $$obj[$index];

buf.push("<li><a" + (jade.attr("href", '/' + menuItem.route, true, false)) + (jade.attr("data-route", menuItem.route, true, false)) + ">" + (jade.escape(null == (jade_interp = menuItem.name) ? "" : jade_interp)) + "</a></li>");
    }

  }
}).call(this);

buf.push("</ul>");
    }

  }
}).call(this);
}.call(this,"menu" in locals_for_with?locals_for_with.menu:typeof menu!=="undefined"?menu:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const config = window.config

module.exports = window.Backbone.View.extend({
  events: { 'click [data-route]': 'handleRoute' },
  handleRoute: function (e) {
    // Detect middle click or CMD click to allow <a> to open in new tab
    if (e.which === 2 || e.metaKey) return
    e.preventDefault()
    this.trigger('route', $(e.target).attr('data-route'))
  },
  render: function () {
    this.$el.append(
      template({
        menu: this.options.menu,
        config: config,
        allowed: this.options.serviceLocator.allowed,
      })
    )
  },
})
