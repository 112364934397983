const editView = require('./views/form')
const model = require('./models/model')
const itemView = require('../../../widget/views/item/edit-base')

const initWidget = () => ({ matchDetailsWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Match Details',
    description: 'Display details for a match, including key events',
  }

  serviceLocator.widgetFactories.get('match').register('matchDetails', widget)
  done()
}

module.exports = initWidget
