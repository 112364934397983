const SectionModel = require('../models/lite-section')
const BaseCollection = require('../../../../admin/source/js/lib/base-collection')
const config = window.config

module.exports = BaseCollection.extend({
  model: SectionModel,
  url: config.apiUrl + '/sections',
  pageSize: 500,
  sortProperty: 'fullUrlPath',
  sortDirection: 'asc',
})
