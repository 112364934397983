const createBaseSchema =
  require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')

module.exports = () =>
  schemata({
    name: 'Article search',
    properties: {
      resultsPath: {
        type: String,
      },
      tags: {
        type: String,
      },
      ...createBaseSchema.getProperties(),
    },
  })
