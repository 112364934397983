const CrudService = require('../../../../admin/source/js/lib/crud-service')

class AdvertService extends CrudService {
  get name() {
    return 'AdvertService'
  }
  get urlRoot() {
    return '/adverts'
  }
}

module.exports = AdvertService
