const Model = require('merstone')
const createSchema = require('../../../service/match-commentary/schema')
const ImageAreaModel = require('../../../admin/asset/models/image-area')

class CommentaryModel extends Model {
  constructor(serviceLocator, attributes) {
    super(serviceLocator, attributes)

    Model.apply(this, arguments)
    this.schema = createSchema()
    this.attributes = this.schema.cast(attributes || {})
    this.images = new ImageAreaModel(this.get('images'))

    this.images.on(
      'add remove change',
      function () {
        this.set('images', this.images.toJSON())
      }.bind(this)
    )

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })
  }
  validate(cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset(attrs) {
    attrs = this.schema.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }
}

module.exports = CommentaryModel
