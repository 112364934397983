module.exports = initWidget

function initWidget() {
  return { rugbyCampFinderWidget: [ 'sectionAdmin', init ] }
}

function init(serviceLocator, done) {
  var widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('../../widget/views/item/edit-base'),
    name: 'Rugby Camp Finder',
    description: 'Rugby Camp Finder',
  }

  serviceLocator.widgetFactories
    .get('section')
    .register('rugbyCampFinder', widget)

  done()
}
