const compileJade = require('browjadify-compile')
const View = require('ventnor')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"panel panel-styled panel-fixed js-fixed-sidebar\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Search <div class=\"form-label-text__right\"><span data-toggle=\"tooltip\" title=\"Use double quotes to search for a phrase and minus to negate a word, e.g.&lt;br /&gt;[ &quot;Fashion Week&quot; ] or [ Fashion -Week ]\" class=\"js-tooltip-trigger label\"><i class=\"icon icon--question\">Help</i></span></div></span><input type=\"text\" name=\"keywords\" placeholder=\"Keywords\" class=\"control control--text form-field\"/></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Unit Name<div class=\"form-label-text__right\"><span data-toggle=\"tooltip\" title=\"Exact name must be entered for an advert to be found.\" class=\"js-tooltip-trigger label\"><i class=\"icon icon--question\">Help</i></span></div></span><input type=\"text\" name=\"name\" placeholder=\"Unit Name\" class=\"control control--text form-field\"/></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Order</span><select name=\"sort\" class=\"control control--choice form-field\"><option value=\"-createdDate\">Newest First</option><option value=\"createdDate\">Oldest First</option><option value=\"-score\">Relevance</option></select></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Active</span><select name=\"active\" class=\"control control--choice form-field\"><option value=\"\">-- Select state --</option><option value=\"true\">Yes</option><option value=\"false\">No</option></select></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");;return buf.join("");
}

class ListFilterView extends View {
  constructor(...args) {
    super(...args)
    this.$el.addClass('list-filters')
    this.$el.on('submit', 'form', this.handleSubmit.bind(this))
    this.$el.on('click', 'input[type=reset]', this.handleClear.bind(this))
  }

  trigger(...args) {
    return this.emit(...args)
  }

  updateDisplay(params) {
    this.$el.find('form [name=keywords]').val(params.keywords)
    this.$el.find('form [name=name]').val(params.name)

    if (params.filter.active) {
      this.$el
        .find('[name=active] option[value=' + params.filter.active + ']')
        .attr('selected', true)
    }

    const backMap = {
      'createdDate,asc': 'createdDate',
      'createdDate,desc': '-createdDate',
      'score,desc': '-score',
    }

    if (Array.isArray(params.sort)) {
      this.$el
        .find(
          '[name=sort] option[value=' + backMap[params.sort.join(',')] + ']'
        )
        .attr('selected', true)
    }
  }

  handleSubmit(e) {
    if (e) e.preventDefault()

    const params = { filter: {}, sort: [] }
    const map = {
      createdDate: ['createdDate', 'asc'],
      '-createdDate': ['createdDate', 'desc'],
      '-score': ['score', 'desc'],
    }
    const keywords = this.$el.find('form [name=keywords]').val()
    const active = this.$el.find('form [name=active]').val()
    const name = this.$el.find('form [name=name]').val()

    params.sort = map[this.$el.find('form [name=sort]').val()]

    if (active) params.filter.active = active
    if (name) params.filter.name = name
    if (keywords.length) params.keywords = keywords

    this.emit('filter', params)
  }

  handleClear(e) {
    e.preventDefault()
    this.$el.find('form [name=keywords]').val('')
    this.$el.find('form [name=sort]').val('-createdDate')
    this.$el.find('form [name=name]').val('')
    this.$el.find('form [name=active]').val('')
    this.handleSubmit()
  }

  render() {
    this.$el.empty().append(template({}))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListFilterView
