const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const validateIfPropertyEquals = require('validity-validate-if-property-equals')

module.exports = function (serviceLocator) {
  return schemata({
    name: 'Competition schema',
    properties: {
      _id: {
        type: String,
        validators: [validateIfPropertyEquals('source', 'opta', required)],
      },
      optaId: {
        type: String,
        validators: [validateIfPropertyEquals('source', 'opta', required)],
      },
      season: {
        type: Number,
        validators: [required],
        defaultValue: () => serviceLocator.seasonService.getCurrentYear(),
      },
      name: { type: String, validators: [required] },
      slug: { type: String, validators: [required] },
      source: { type: String, defaultValue: () => 'cms' },
      squad: { type: String, validators: [required] },
      lastModifiedDate: { type: Date },
      createdDate: {
        type: Date,
        defaultValue: () => new Date(),
      },
    },
  })
}
