module.exports = createController
const FormView = require('../views/form')
const Model = require('../models/hospitality-package')
const notify = require('../../notification/foreground')

function createController(serviceLocator) {
  // Edit
  serviceLocator.router.route(
    'hospitality-packages/:id/form',
    'editpackage',
    function (id) {
      if (!serviceLocator.allow('hospitality-package', 'update')) return false

      serviceLocator.hospitalityPackageService.read(id, function (
        err,
        packageInstance
      ) {
        if (err) return serviceLocator.router.trigger('notFound', err.message)

        var form = new FormView(
          serviceLocator,
          new Model(serviceLocator, packageInstance),
          false
        ).render()
        serviceLocator.router.render(form, 'Edit package')

        form.on('back', back)

        form.on('save', function () {
          saveExisting(id, form, function (err) {
            if (err) return
            notify('Saved', 'save')
          })
        })

        form.on('saveAndClose', function () {
          saveExisting(id, form, function (err) {
            if (err) return
            notify('Saved', 'save')
            serviceLocator.router.navigate('hospitality-packages', {
              trigger: true,
            })
          })
        })
      })
    }
  )

  // Create
  serviceLocator.router.route(
    'hospitality-packages/form',
    'createpackage',
    function () {
      if (!serviceLocator.allow('hospitality-package', 'create')) return false

      const model = new Model(serviceLocator)
      let form

      model.set(model.schema.makeDefault())

      form = new FormView(serviceLocator, model, true).render()

      serviceLocator.router.render(form, 'New package')

      form.on('back', back)

      form.on('save', function () {
        saveNew(form, function (err, saved) {
          if (err) return
          notify('Saved', 'save')
          serviceLocator.router.navigate(
            'hospitality-packages/' + saved._id + '/form',
            { trigger: true }
          )
        })
      })

      form.on('saveAndClose', function () {
        saveNew(form, function (err) {
          if (err) return
          notify('Saved', 'save')
          serviceLocator.router.navigate('hospitality-packages', {
            trigger: true,
          })
        })
      })
    }
  )

  function saveExisting(id, form, cb) {
    serviceLocator.hospitalityPackageService.update(
      id,
      form.model.toJSON(),
      function (err, packageInstance) {
        if (err) {
          form.showErrors(err.errors)
          return cb(err)
        }
        form.clearUnsavedChanges()
        form.clearErrors()
        cb(null, packageInstance)
      }
    )
  }

  function saveNew(form, cb) {
    serviceLocator.hospitalityPackageService.create(
      form.model.toJSON(),
      function (err, packageInstance) {
        if (err) {
          form.showErrors(err.errors)
          return cb(err)
        }
        form.clearUnsavedChanges()
        cb(null, packageInstance)
      }
    )
  }

  function back() {
    serviceLocator.router.navigate('hospitality-packages', { trigger: true })
  }
}
