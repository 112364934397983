module.exports = function (message, icon) {
  var n = new ForegroundNotification({}, message, icon)
  n.render().$el.appendTo('body')
  setTimeout(function () {
    n.emit('afterAppend')
  }, 10)
  setTimeout(function () {
    n.remove()
  }, 2000)
}
const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (icon, message) {
buf.push("<span><i" + (jade.cls(['icon','icon--' + icon], [null,true])) + "></i></span><p>" + (jade.escape(null == (jade_interp = message) ? "" : jade_interp)) + "</p>");}.call(this,"icon" in locals_for_with?locals_for_with.icon:typeof icon!=="undefined"?icon:undefined,"message" in locals_for_with?locals_for_with.message:typeof message!=="undefined"?message:undefined));;return buf.join("");
}

function ForegroundNotification(serviceLocator, message, icon) {
  View.apply(this, arguments)
  this.message = message
  this.icon = icon
  this.$el.addClass(
    'js-foreground-notification foreground-notification is-hidden'
  )
}

ForegroundNotification.prototype = Object.create(View.prototype)

ForegroundNotification.prototype.render = function () {
  $('body').find('.js-foreground-notification').addClass('is-hidden')
  this.$el.append(template({ message: this.message, icon: this.icon }))
  this.on('afterAppend', this.$el.removeClass.bind(this.$el, 'is-hidden'))
  return this
}

ForegroundNotification.prototype.remove = function () {
  this.$el.addClass('is-hidden')
  setTimeout(
    function () {
      View.prototype.remove.call(this)
    }.bind(this),
    300
  )
}
