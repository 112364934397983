const CrudService = require('../../../../admin/source/js/lib/crud-service')

class RugbyCampService extends CrudService {
  get name() {
    return 'RugbyCampService'
  }
  get urlRoot() {
    return '/rugby-camps'
  }
}

module.exports = RugbyCampService
