const createController = require('./controllers/controller')

const initAdmin = () => ({ externalFeedAdmin: init })

const init = (serviceLocator, done) => {
  createController(serviceLocator)
  done()
}

module.exports = initAdmin
