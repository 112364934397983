const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Keyword Details</h2></div><div class=\"panel-content\"><div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"name\"" + (jade.attr("value", data.name, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--link\" data-field=\"link\" class=\"form-row\"><label><span class=\"form-label-text\">Link<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"link\"" + (jade.attr("value", data.link, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--active\" data-field=\"active\" class=\"form-row form-row-boolean\"><label><span class=\"form-label-text\">Active</span></label><div class=\"form-field\"><input type=\"checkbox\" name=\"active\"" + (jade.attr("checked", data.active, true, false)) + " class=\"control control--boolean\"/><span>Automatically link these keywords</span></div></div></div><div class=\"panel-header\"><h2>Keywords</h2></div><div class=\"panel-content\"><div class=\"js-add-keyword counter-answer-reset\"></div><div class=\"form-row form-row-actions\"><a class=\"btn btn--actions js-keyword-add\">Add Keyword</a></div></div></div></form></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const KeywordModel = require('../models/keyword')
const KeywordView = require('./keyword')
const formErrorTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (errors, undefined) {
jade_mixins["displayError"] = jade_interp = function(key, error){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if ( typeof error === 'object')
{
// iterate error
;(function(){
  var $$obj = error;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var err = $$obj[key];

jade_mixins["displayError"](key, err);
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var err = $$obj[key];

jade_mixins["displayError"](key, err);
    }

  }
}).call(this);

}
else
{
buf.push("<li><a" + (jade.attr("href", ('#field--' + key), true, false)) + ">" + (jade.escape(null == (jade_interp = error) ? "" : jade_interp)) + "</a></li>");
}
};
buf.push("<div class=\"form-copy form-error\"><strong>Oops! Something isn’t right.</strong><p>Please check and try again.</p><ul>");
// iterate errors
;(function(){
  var $$obj = errors;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var error = $$obj[key];

jade_mixins["displayError"](key, error);
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var error = $$obj[key];

jade_mixins["displayError"](key, error);
    }

  }
}).call(this);

buf.push("</ul></div>");}.call(this,"errors" in locals_for_with?locals_for_with.errors:typeof errors!=="undefined"?errors:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    this.$el.on('click', '.js-keyword-add', () => this.handleKeywordAdd())
    this.keywordViews = []
  }

  handleKeywordAdd(keywordModel) {
    const $keywordElement = this.$('.js-add-keyword')
    let model = keywordModel

    if (!keywordModel) {
      model = new KeywordModel()
      this.model.keywords.add(model)
    }

    const keywordView = new KeywordView(this.serviceLocator, model)

    keywordView.on('remove', (id) => {
      this.model.keywords.remove(id)
      const i = this.keywordViews.findIndex((view) => view.cid === id)
      this.keywordViews.splice(i, 1)
    })

    this.keywordViews.push({ cid: model.cid, view: keywordView })

    $($keywordElement).append(keywordView.render().$el)
  }

  showErrors(errors) {
    super.showErrors(errors)

    if (errors.keywords && typeof errors.keywords === 'object') {
      Object.keys(errors.keywords).forEach((key) => {
        this.keywordViews[key].view.showErrors(errors.keywords[key])
      })
    }

    if (errors.keyword && typeof errors.keyword === 'string') {
      this.$('[data-field=keyword] .js-error').append(
        formErrorTemplate({ error: errors.responses })
      )
    }
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New Keyword' : 'Edit Keyword',
        data: this.model.toJSON(),
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))
    if (this.isNew) {
      this.handleKeywordAdd()
    } else {
      this.model.keywords.models.forEach((keyword) => {
        this.handleKeywordAdd(keyword)
      })
    }

    return this
  }
}
module.exports = FormView
