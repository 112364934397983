const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const squads = require('../../../../service/team/squads').all

module.exports = () =>
  schemata({
    name: 'Squad',
    properties: {
      squad: {
        type: String,
        options: squads,
        validators: [required],
      },
    },
  })
