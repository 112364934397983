const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (_id, name) {
buf.push("<div" + (jade.attr("data-sponsorId", _id, true, false)) + " class=\"list-item list-item-minimal\"><div class=\"list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-remove\">Remove</a></li></ul></div></div><h4>" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</h4></div></div>");}.call(this,"_id" in locals_for_with?locals_for_with._id:typeof _id!=="undefined"?_id:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const BaseView = require('cf-base-view')

module.exports = BaseView.extend({
  events: { 'click .js-remove': 'handleRemove' },
  className: 'js-selected-sponsor',
  handleRemove: function () {
    this.$el.remove()
    this.trigger('removed', this.model)
  },
  render: function () {
    this.$el.empty().append(template(this.model))
    return this
  },
})
