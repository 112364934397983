const createListController = require('./controllers/list')
const createFormController = require('./controllers/form')
const PlayerService = require('./services/player')
const OptaPlayerService = require('./services/opta-player')
const createWidgetFactory = require('../widget/lib/base-factory')

function initAdmin() {
  return { playerAdmin: [ 'widget', init ] }
}

function init(serviceLocator, cb) {
  serviceLocator.playerService = new PlayerService(serviceLocator)
  serviceLocator.optaPlayerService = new OptaPlayerService(serviceLocator)
  const widgetFactory = createWidgetFactory()
  serviceLocator.widgetFactories.register('player', widgetFactory)

  // Entity services get all the widgets from sectionw widget factory
  const sectionWidgetFactory = serviceLocator.widgetFactories.get('section')
  serviceLocator.componentLoader.on('complete', () => {
    Object.keys(sectionWidgetFactory.list()).forEach((widget) =>
      widgetFactory.register(widget, sectionWidgetFactory(widget))
    )
  })

  createListController(serviceLocator)
  createFormController(serviceLocator)
  cb(null)
}

module.exports = initAdmin
