module.exports = PlayerModel
const Model = require('merstone')
const createSchema = require('../../../service/player/schema')
const ImageAreaModel = require('../../asset/models/image-area')

function PlayerModel(serviceLocator, attributes) {
  Model.apply(this, arguments)
  this.schema = createSchema()
  this.attributes = this.schema.cast(attributes || {})

  this.hook('preSet', (attrs, cb) => {
    cb(null, attrs)
  })

  this.images = new ImageAreaModel(this.get('images'))

  this.images.on(
    'add remove change',
    function () {
      this.set('images', this.images.toJSON())
    }.bind(this)
  )
}

PlayerModel.prototype = Object.create(Model.prototype)

PlayerModel.prototype.validate = function (cb) {
  this.schema.validate(this.attributes, cb)
}

PlayerModel.prototype.reset = function (attrs) {
  attrs = this.schema.cast(attrs || {})
  Model.prototype.reset.call(this, attrs)
}
