const displayButtons = [
  {
    value: 'buyTickets',
    option: 'Buy Tickets',
  },
  {
    value: 'hospitality',
    option: 'Hospitality',
  },
]

module.exports.all = displayButtons
module.exports.values = displayButtons.map((button) => button.value)
