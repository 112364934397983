module.exports = createController
const ListView = require('../views/list')
const Collection = require('chale')
const ArticleModel = require('../models/article')
const Model = require('merstone')
const async = require('async')
const pageSize = 50
const moment = require('moment')
const crypto = require('crypto')

function createController(serviceLocator) {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0,
  })
  let currentParams = {
    keywords: '',
    filter: {},
    sort: [ 'dateCreated', 'desc' ],
  }
  let currentPage = 1

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.articleService.on('create', function () {
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    getArticles(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  // Whenever an article is updated, reset the model with its new attributes
  serviceLocator.articleService.on('update', function (id, attrs) {
    var model = collection.get(id)
    if (model) model.reset(attrs)
  })

  serviceLocator.router.route('articles(/)', 'listArticles', function () {
    if (!serviceLocator.allow('article', 'discover')) return false

    // Pre-populate the list's collection with a set of articles
    const pagination = { page: currentPage, pageSize: pageSize }
    const list = new ListView(
      serviceLocator,
      collection,
      paginationModel
    ).render()

    getArticles(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )

    list.displayFilterParams(currentParams)

    list.on('createNew', function () {
      if (!serviceLocator.allow('article', 'create')) return false
      serviceLocator.router.navigate('articles/form', { trigger: true })
    })

    list.on('edit', function (id) {
      if (!serviceLocator.allow('article', 'update')) return false
      serviceLocator.router.navigate('articles/' + id + '/form', {
        trigger: true,
      })
    })

    function getPreviewUrlComponents(article, cb) {
      async.waterfall(
        [
          function (cb) {
            serviceLocator.sectionService.read(
              article.get('sections')[0],
              function (err, section) {
                if (err) return cb(err)
                return cb(null, { section: section })
              }
            )
          },
          function (obj, cb) {
            if (obj.section.instance) {
              serviceLocator.instanceService.read(
                obj.section.instance,
                function (err, instance) {
                  if (err) return cb(err)
                  obj.instance = instance
                  return cb(null, obj)
                }
              )
            } else {
              serviceLocator.instanceService.find(
                '',
                {},
                [],
                { pageSize: 1, page: 1 },
                function (err, res) {
                  if (err) return cb(err)
                  obj.instance = res.results[0]
                  return cb(null, obj)
                }
              )
            }
          },
          function (obj, cb) {
            serviceLocator.accountService.read(obj.instance.account, function (
              err,
              account
            ) {
              if (err) return cb(err)
              obj.account = account
              return cb(null, obj)
            })
          },
        ],
        cb
      )
    }

    list.on('preview', function (article) {
      var w = window.open('')

      getPreviewUrlComponents(article, function (err, obj) {
        if (err) {
          w.close()
          return serviceLocator.logger.error(err, 'Could not preview article')
        }

        w.location =
          serviceLocator.instanceService.createUrl(obj.instance, obj.account) +
          article.get('__fullUrlPath') +
          '?previewId=' +
          article.get('previewId')
      })
    })

    list.on('datePreview', function (article) {
      const w = window.open('')
      const now = moment().format(serviceLocator.config.formats.isoZ)

      async.parallel(
        { url: getPreviewUrlComponents.bind(null, article) },
        function (err, obj) {
          if (err) {
            w.close()
            return serviceLocator.logger.error(err, 'Could not preview article')
          }
          let url =
            '//' + obj.url.instance.subdomain + '.' + obj.url.account.domain
          const hash = crypto.createHash('sha1').update(now).digest('hex')

          if (
            [ 'development', 'testing' ].indexOf(serviceLocator.config.env) !== -1
          ) {
            url += ':' + serviceLocator.config.port
          }
          url +=
            '/date-preview' +
            article.get('__fullUrlPath') +
            '?date=' +
            now +
            '&dateHash=' +
            hash
          w.location = url
        }
      )
    })

    list.on('duplicate', function (model) {
      if (!serviceLocator.allow('article', 'duplicate')) return false
      serviceLocator.router.navigate('articles/' + model.id + '/duplicate', {
        trigger: true,
      })
    })

    list.on('delete', function (ids) {
      if (!serviceLocator.allow('article', 'delete')) return false
      async.each(ids, deleteOne, function (err) {
        if (err) return alert(err.message)
      })

      function deleteOne(id, cb) {
        serviceLocator.articleService.delete(id, function (err) {
          if (err) return cb(err)
          collection.remove(id)
        })
      }
    })

    list.on('filter', function (params) {
      currentParams = params
      var pagination = { page: currentPage, pageSize: pageSize }
      currentPage = 1
      getArticles(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', function () {
      currentPage += 1
      var pagination = { page: currentPage, pageSize: pageSize }
      appendArticles(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    list.on('showRevisions', function (model) {
      serviceLocator.router.navigate('articles/' + model.id + '/revisions', {
        trigger: true,
      })
    })

    serviceLocator.router.render(list, 'Articles')
  })

  function getArticles(keywords, filter, sort, pagination) {
    serviceLocator.articleService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err)
          return serviceLocator.logger.error(err, 'Could not load articles')
        collection.reset(
          res.results.map(function (article) {
            return new ArticleModel(serviceLocator, article)
          })
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  function appendArticles(keywords, filter, sort, pagination) {
    serviceLocator.articleService.find(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err) return alert(err.message)
        res.results.forEach(function (article) {
          collection.add(new ArticleModel(serviceLocator, article))
        })
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }
}
