const createRegistry = require('regg')
function initAdmin() {
  return { widget: init }
}

function init(serviceLocator, done) {
  serviceLocator.widgetFactories = createRegistry()
  done()
}

module.exports = initAdmin
