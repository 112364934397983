module.exports = MatchCommentaryService
const CrudService = require('../../../../admin/source/js/lib/crud-service')

function MatchCommentaryService() {
  this.name = 'MatchCommentaryService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/match-commentary'
}

MatchCommentaryService.prototype = Object.create(CrudService.prototype)
