module.exports = () => ({ enquiryWidget: ['sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('../../widget/views/item/edit-base'),
    name: 'Enquiry',
    description: 'Make an Enquiry',
  }

  ;['section', 'article', 'tabsBody'].forEach((factory) =>
    serviceLocator.widgetFactories.get(factory).register('enquiry', widget)
  )

  done()
}
