module.exports = EventService

const CrudService = require('../../../../admin/source/js/lib/crud-service')

function EventService() {
  this.name = 'EventService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/events'
}

EventService.prototype = Object.create(CrudService.prototype)
