const BaseModel = require('cf-base-model')
const schemata = require('./schema')()
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const config = window.config
const createImageUrlBuilder = require('cf-image-url-builder')
const ImageAreaModel = require('../../../asset/models/image-area')

module.exports = BaseModel.extend({
  schemata: schemata,
  type: 'fixturesAndResults',
  initialize() {
    this.groupDayImages = new ImageAreaModel(this.get('groupDayImages'))
    this.awayTravelImages = new ImageAreaModel(this.get('awayTravelImages'))
  },
  defaults() {
    return schemata.makeDefault({ type: this.type || 'fixturesAndResults' })
  },
  getPreviewImageUrl() {
    const images = this.images.widgets.map((widget) => widget.toJSON())
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, images)
    const image = urlBuilder.getImage('Main')
    if (!image) return null
    return image.crop('Landscape').constrain(300).url()
  },
  validate: validateDelegate,
})
