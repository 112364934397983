module.exports = createController
const MenuBarView = require('../views/menu-bar')
const menu = [
  {
    title: 'Content',
    items: [
      { name: 'Sections', route: 'sections', resource: 'section' },
      { name: 'Articles', route: 'articles', resource: 'article' },
      { name: 'Lists', route: 'lists', resource: 'list' },
      { name: 'Assets', route: 'asset-manager', resource: 'asset' },
      { name: 'Authors', route: 'authors', resource: 'author' },
      { name: 'Tags', route: 'tags', resource: 'tag' },
      { name: 'Redirects', route: 'redirects', resource: 'redirect' },
      { name: 'Events', route: 'events', resource: 'event' },
      { name: 'Sponsors', route: 'sponsors', resource: 'sponsor' },
      { name: 'Forms', route: 'form-builders', resource: 'formBuilder' },
      { name: 'Polls', route: 'polls', resource: 'poll' },
      { name: 'Gallery', route: 'gallery', resource: 'gallery' },
      { name: 'Keywords', route: 'linked-keywords', resource: 'linkedKeyword' },
    ],
  },
  {
    title: 'Commercial',
    items: [
      {
        name: 'Hospitality',
        route: 'hospitality-packages',
        resource: 'hospitality-packge',
      },
      { name: 'Rugby Camps', route: 'rugby-camps', resource: 'rugby-camp' },
      { name: 'Adverts', route: 'adverts', resource: 'adverts' },
    ],
  },
  {
    title: 'Competition Data',
    items: [
      { name: 'Competitions', route: 'competitions', resource: 'competition' },
      { name: 'Man of the Match', route: 'motms', resource: 'motm' },
      { name: 'Teams', route: 'teams', resource: 'team' },
      { name: 'Matches', route: 'matches', resource: 'match' },
      { name: 'Players', route: 'players', resource: 'player' },
      { name: 'Venues', route: 'venues', resource: 'venue' },
    ],
  },
  {
    title: 'User Management',
    items: [
      { name: 'Roles', route: 'roles', resource: 'role' },
      {
        name: 'Administrators',
        route: 'administrators',
        resource: 'administrator',
      },
    ],
  },
  {
    title: 'Settings',
    items: [
      { name: 'Accounts', route: 'accounts', resource: 'account' },
      { name: 'Instances', route: 'instances', resource: 'instance' },
    ],
  },
]

function createController(serviceLocator) {
  serviceLocator.hub.once('acl:load', function () {
    var menuBarView = new MenuBarView({
      el: $('.js-menu-bar'),
      menu: filterMenu(menu, serviceLocator),
      serviceLocator: serviceLocator,
    })

    menuBarView.render()

    menuBarView.on('route', function (route) {
      serviceLocator.router.navigate(route, { trigger: true })
    })
  })
}

function filterMenu(menu, serviceLocator) {
  return menu.filter(function (section) {
    section.items = section.items.filter(function (menuItem) {
      return (
        (menuItem && serviceLocator.allowed(menuItem.resource, 'discover')) ||
        !menuItem.resource
      )
    })
    return section.items.length > 0
  })
}
