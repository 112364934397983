const BaseModel = require('cf-base-model')
const schemata = require('./schema')()
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

module.exports = BaseModel.extend({
  schemata: schemata,
  type: 'squad',
  defaults() {
    return schemata.makeDefault({ type: this.type || 'squad' })
  },
  validate: validateDelegate,
})
