const Model = require('merstone')
const createSchema = require('../../../service/advert/schema')
const ImageAreaModel = require('../../asset/models/image-area')

class AdvertModel extends Model {
  constructor(serviceLocator, attributes) {
    super(serviceLocator, attributes)

    this.schema = createSchema()
    this.attributes = this.schema.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })

    this.images = new ImageAreaModel(this.get('images'))

    this.images.on('add remove change', () =>
      this.set('images', this.images.toJSON())
    )
  }

  validate(cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset(attrs) {
    attrs = this.schema.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }
}

module.exports = AdvertModel
