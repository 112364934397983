const Collection = require('chale')
const MerstoneModel = require('merstone')
const async = require('async')
const pageSize = 50
const defaultCurrentParams = {
  keywords: '',
  filter: {},
  sort: [ 'dateCreated', 'desc' ],
}

const baseListController = ({
  serviceLocator,
  service,
  plural,
  path,
  singular,
  Model,
  ListView,
  currentParams = defaultCurrentParams,
  customListViewInitiator,
}) => {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new MerstoneModel(serviceLocator, {
    totalItems: 0,
    showing: 0,
  })
  let currentPage = 1
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an entiry is updated, reset the model with its new attributes
  service.on('update', function (id, attributes) {
    var model = collection.get(id)
    if (model) model.reset({ ...model.attributes, ...attributes })
    service.cachedFind.clear()
  })

  service.on('partialUpdate', function (id, attributes) {
    var model = collection.get(id)
    if (model) model.reset({ ...model.attributes, ...attributes })
    service.cachedFind.clear()
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  service.on('create', function () {
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    service.cachedFind.clear()
    getEntities(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  serviceLocator.router.route(`${path}(/)`, `list${plural}`, function () {
    if (!serviceLocator.allow(singular, 'discover')) return false

    getEntities(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )

    var list = new ListView(
      serviceLocator,
      collection,
      paginationModel
    ).render()

    list.displayFilterParams(currentParams)

    list.on('createNew', function () {
      if (!serviceLocator.allow(singular, 'create')) return false
      serviceLocator.router.navigate(`${path}/form`, { trigger: true })
    })

    list.on('edit', function (id) {
      if (!serviceLocator.allow(singular, 'update')) return false
      serviceLocator.router.navigate(`${path}/${id}/form`, { trigger: true })
    })

    list.on('delete', function (ids) {
      if (!serviceLocator.allow(singular, 'delete')) return false
      async.each(ids, deleteOne, function (err) {
        if (err) return alert(err.message)
      })

      function deleteOne(id, cb) {
        service.delete(id, function (err) {
          if (err) return cb(err)
          collection.remove(id)
        })
      }
    })

    list.on('filter', function (params) {
      currentParams = params
      var pagination = { page: currentPage, pageSize: pageSize }
      currentPage = 1
      getEntities(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', function () {
      currentPage += 1
      var pagination = { page: currentPage, pageSize: pageSize }
      appendEntities(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    if (customListViewInitiator)
      customListViewInitiator(list, serviceLocator.router)

    serviceLocator.router.render(list, plural)
  })

  const getEntities = (keywords, filter, sort, pagination) => {
    service.cachedFind(keywords, filter, sort, pagination, (err, res) => {
      if (err)
        return serviceLocator.logger.error(err, `Could not load ${plural}`)
      collection.reset(
        res.results.map((entity) => new Model(serviceLocator, entity))
      )
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }

  const appendEntities = (keywords, filter, sort, pagination) => {
    service.find(keywords, filter, sort, pagination, (err, res) => {
      if (err) return alert(err.message)
      res.results.forEach((entity) =>
        collection.add(new Model(serviceLocator, entity))
      )
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }
}

module.exports = baseListController
