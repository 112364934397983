module.exports = initAdmin

function initAdmin() {
  return { standardArticle: [ 'articleAdmin', init ] }
}

function init(serviceLocator, done) {
  serviceLocator.articleTypeFactory.register('article', { name: 'Article' })
  done()
}
