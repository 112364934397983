module.exports = initWidget

function initWidget() {
  return { storeWidget: [ 'sectionAdmin', init ] }
}

function init(serviceLocator, done) {
  var widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('../../widget/views/item/edit-base'),
    name: 'Store',
    description: 'Store',
  }

  serviceLocator.widgetFactories.get('section').register('store', widget)

  done()
}
