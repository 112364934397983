module.exports = initAdmin
const createFormController = require('./controllers/form')
const createListController = require('./controllers/list')
const TagService = require('./services/tag')

function initAdmin() {
  return { tagAdmin: init }
}

function init(serviceLocator, done) {
  serviceLocator.tagTypes = [ 'General', 'System', 'Instance', 'Player' ]
  serviceLocator.tagService = new TagService(serviceLocator)
  createFormController(serviceLocator)
  createListController(serviceLocator)
  done()
}
