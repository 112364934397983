module.exports = TagSelect
const View = require('ventnor')
const Collection = require('chale')
const Model = require('merstone')
const find = require('lodash.find')

function TagSelect(serviceLocator, initialTags, tagType, isReadOnly) {
  if (!isReadOnly && !tagType) throw new Error('Tag type must be supplied')
  View.apply(this, arguments)
  this.tagType = tagType || null
  this.isReadOnly = !!isReadOnly
  this.$el = $('<select multiple/>')
  this.$el.addClass('control control--choice control--multiline')
  this.el = this.$el[0]
  this.$el.attr('placeholder', 'Choose some tags')
  initialTags = initialTags || []
  this.tags = new Collection(
    serviceLocator,
    initialTags
      .filter(function (tag) {
        return !tagType || tag.type === tagType
      })
      .map(function (tag) {
        return new Model(serviceLocator, tag)
      })
  )
  this.on(
    'remove',
    function () {
      this.el.selectize.destroy()
    }.bind(this)
  )
}

TagSelect.prototype = Object.create(View.prototype)

TagSelect.prototype.loadTags = function () {
  return this
}

TagSelect.prototype.create = function (input) {
  var value = JSON.stringify({ tag: input, type: this.tagType, meta: [] })
  return { value: value, text: input }
}

TagSelect.prototype.onAdd = function (value) {
  var tag = JSON.parse(value)
  this.tags.add(new Model(this.serviceLocator, tag))
  this.emit('change')
}

TagSelect.prototype.onRemove = function (value) {
  const tag = JSON.parse(value)
  const toDelete = find(this.tags.models, function (existing) {
    return existing.get('type') === tag.type && existing.get('tag') === tag.tag
  })
  if (toDelete) this.tags.remove(toDelete.cid)
  this.emit('change')
}

TagSelect.prototype.addSelectizeHandlers = function () {
  this.el.selectize.on('item_add', this.onAdd.bind(this))
  this.el.selectize.on('item_remove', this.onRemove.bind(this))
}

TagSelect.prototype.initializeSelectize = function () {
  this.tags.models.forEach(
    function (item) {
      var value = JSON.stringify({
        account: item.get('account'),
        tag: item.get('tag'),
        type: item.get('type'),
        meta: item.get('meta'),
      })
      // The item needs to be added to the list
      // of selectize options in order to be selected
      this.el.selectize.addOption({ value: value, text: item.get('tag') })
      // Select the added option
      this.el.selectize.addItem(value)
    }.bind(this)
  )
  this.addSelectizeHandlers()
}

TagSelect.prototype.load = function (query, cb) {
  const filter = this.tagType ? { type: this.tagType } : {}
  const order = [ 'tag', 'asc' ]
  const pagination = { page: 1, pageSize: 500 }

  this.serviceLocator.tagService.find(
    query,
    filter,
    order,
    pagination,
    function (err, res) {
      if (err)
        return this.serviceLocator.logger.error(err, 'Error loading tags')
      cb(
        res.results.map(function (tag) {
          return {
            value: JSON.stringify({
              account: tag.account,
              tag: tag.tag,
              type: tag.type,
              meta: tag.meta,
            }),
            text: tag.tag,
          }
        })
      )
    }.bind(this)
  )
}

TagSelect.prototype.render = function () {
  setTimeout(
    function () {
      this.$el.selectize({
        delimiter: ',',
        persist: false,
        create: this.isReadOnly ? false : this.create.bind(this),
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this),
      })
    }.bind(this),
    100
  )
  return this
}
