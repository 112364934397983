import View from 'ventnor'

class VenueSelect extends View {
  constructor(serviceLocator, selected) {
    super(serviceLocator)
    this.$el = $('<select id="venueId" />')
    this.$el.addClass('control control--choice control--multiline')
    this.el = this.$el[0]
    this.$el.attr('placeholder', 'Choose a venue')
    this.selected = selected || []
  }

  initializeSelectize() {
    const pagination = { page: 1, pageSize: 500 }
    this.serviceLocator.venueService.find(
      '',
      { _id: this.selected },
      [],
      pagination,
      (err, res) => {
        if (err) return alert('Cannot find existing venues')
        res.results.forEach((venue) => {
          // The item needs to be added to the list
          // of selectize options in order to be selected
          this.el.selectize.addOption({ value: venue._id, text: venue.name })
          // Select the added option
          this.el.selectize.addItem(venue._id)
        })
        this.el.selectize.on('change', this.updateSelection.bind(this))
      }
    )
  }

  updateSelection() {
    this.selected = this.el.selectize.getValue()
    this.emit('change', this.selected)
  }

  load(query, cb) {
    const pagination = { page: 1, pageSize: 500 }
    this.serviceLocator.venueService.find(
      query,
      {},
      [],
      pagination,
      (err, data) => {
        if (err) return alert('Cannot load existing venues')
        cb(
          data.results.map((venue) => ({ value: venue._id, text: venue.name }))
        )
      }
    )
  }

  render() {
    setTimeout(() => {
      this.$el.selectize({
        create: false,
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this),
        preload: true,
        selectOnTab: true,
      })
    }, 0)
    return this
  }
}

module.exports = VenueSelect
