const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const imageConfig = require('./image-config.json')
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')
const validateIfPropertySet = require('validity-validate-if-property-set')

module.exports = function () {
  const requiredContexts = imageConfig.contexts.map((context) => context.name)
  const requiredCrops = imageConfig.crops.map((crop) => crop.name)

  return schemata({
    name: 'Event schema',
    properties: {
      _id: {
        type: String,
      },
      account: {
        type: String,
        validators: [required],
      },
      title: {
        type: String,
        validators: [required],
      },
      venue: {
        type: String,
        validators: [required],
      },
      date: {
        type: Date,
        validators: [required],
      },
      ctaText: {
        type: String,
        name: 'Call to action text',
        validators: [validateIfPropertySet('ctaLink', required)],
      },
      ctaLink: {
        type: String,
        name: 'Call to action link',
        validators: [validateIfPropertySet('ctaText', required)],
      },
      priceRange: {
        type: String,
        validators: [required],
      },
      description: {
        type: String,
      },
      availabilityText: {
        type: String,
      },
      findOutMoreLink: {
        type: String,
      },
      available: {
        type: Boolean,
      },
      images: {
        type: Object,
        validators: [
          createContextValidator(requiredContexts),
          createCropValidator(requiredCrops),
        ],
      },
      createdDate: {
        type: Date,
        defaultValue: function () {
          return new Date()
        },
      },
    },
  })
}
