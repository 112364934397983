const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const validateInteger = require('validity-integer')
const validateNumber = require('validity-number')
const imageConfig = require('./image-config.json')
const squads = require('../team/squads').all
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')
const validateIfSet = require('validity-validate-if-set')
const validateLength = require('validity-length')
const seasonStatSchema = require('./season-stat-schema')

module.exports = function () {
  const requiredContexts = ['Main', 'Bio', 'Line Up']
  const requiredCrops = imageConfig.crops.map((crop) => crop.name)
  return schemata({
    name: 'Player schema',
    properties: {
      _id: {
        type: String,
      },
      account: {
        type: String,
        validators: [required],
      },
      firstName: {
        type: String,
        validators: [required],
      },
      lastName: {
        type: String,
        validators: [required],
      },
      order: {
        type: Number,
        validators: [validateIfSet(validateInteger)],
      },
      slug: {
        type: String,
        validators: [required],
      },
      shirtNumber: {
        type: Number,
        validators: [validateIfSet(validateInteger)],
      },
      dateOfBirth: {
        type: Date,
      },
      birthPlace: {
        type: String,
      },
      position: {
        type: String,
        validators: [required],
      },
      height: {
        type: Number,
        validators: [validateIfSet(validateNumber)],
      },
      weight: {
        type: Number,
        validators: [validateIfSet(validateNumber)],
      },
      appearances: {
        type: Number,
        validators: [validateIfSet(validateInteger)],
      },
      internationalCountry: {
        type: String,
        validators: [],
      },
      internationalCaps: {
        type: String,
        validators: [],
      },
      points: {
        type: Number,
        validators: [validateIfSet(validateInteger)],
      },
      sponsors: {
        type: Array,
      },
      squad: { type: Array, options: squads, validators: [validateLength(1)] },
      biography: {
        type: String,
      },
      hallOfFame: {
        type: Boolean,
        defaultValue: false,
      },
      optaId: {
        type: Number,
        validators: [validateIfSet(validateInteger)],
      },
      images: {
        type: Object,
        validators: [
          createContextValidator(requiredContexts),
          createCropValidator(requiredCrops),
        ],
      },
      seasonStats: {
        type: schemata.Array(seasonStatSchema()),
        defaultValue: () => [],
      },
      createdDate: {
        type: Date,
        defaultValue: () => {
          return new Date()
        },
      },
    },
  })
}
