module.exports = SectionSelect
const View = require('ventnor')
const extend = require('lodash.assign')
const compileJade = require('browjadify-compile')
const join = require('path').join
const optionTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (fullUrlPath, name) {
buf.push("<div class=\"item\">" + (jade.escape((jade_interp = name) == null ? '' : jade_interp)) + " - " + (jade.escape((jade_interp = fullUrlPath) == null ? '' : jade_interp)) + " </div>");}.call(this,"fullUrlPath" in locals_for_with?locals_for_with.fullUrlPath:typeof fullUrlPath!=="undefined"?fullUrlPath:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}

function SectionSelect(serviceLocator, selectedSections) {
  View.apply(this, arguments)
  this.selectedSections = selectedSections || []
  this.initFilter = { _id: { $in: this.selectedSections }, instance: null }
  this.loadFilter = { root: false, instance: null }
  this.selectizeOptions = {}
  this.init()
}

SectionSelect.prototype = Object.create(View.prototype)
SectionSelect.prototype.multiple = false

SectionSelect.prototype.init = function () {
  this.$el = $(this.multiple ? '<select multiple/>' : '<select/>')
  this.$el.addClass('control control--choice')
  this.el = this.$el[0]
  this.$el.attr('placeholder', 'Choose one section')
}

SectionSelect.prototype.initializeSelectize = function () {
  this.serviceLocator.sectionService.find(
    '',
    this.initFilter,
    [],
    {},
    function (err, res) {
      if (err)
        return this.serviceLocator.logger.error(
          err,
          'Cannot find existing sections'
        )
      res.results.forEach(
        function (section) {
          // The item needs to be added to the list
          // of selectize options in order to be selected
          this.el.selectize.addOption(section)
          // Select the added option
          this.el.selectize.addItem(section._id)
        }.bind(this)
      )
      this.el.selectize.on('change', this.updateSelection.bind(this))
    }.bind(this)
  )
}

SectionSelect.prototype.updateSelection = function () {
  this.selectedSections = this.el.selectize.getValue()
  this.emit('change', this.selectedSections)
}

SectionSelect.prototype.load = function (query, cb) {
  this.serviceLocator.sectionService.find(
    query,
    this.loadFilter,
    [],
    {},
    function (err, data) {
      if (err)
        return this.serviceLocator.logger.error(err, 'Cannot find sections')
      cb(data.results)
    }.bind(this)
  )
}

SectionSelect.prototype.render = function () {
  setTimeout(
    function () {
      this.$el.selectize(
        extend(
          {
            delimiter: ',',
            persist: false,
            create: false,
            onInitialize: this.initializeSelectize.bind(this),
            load: this.load.bind(this),
            preload: true,
            valueField: '_id',
            labelField: 'name',
            searchField: 'name',
            render: { option: optionTemplate, item: optionTemplate },
          },
          this.selectizeOptions
        )
      )
    }.bind(this),
    0
  )
  return this
}
