const compileJade = require('browjadify-compile')
const mapFormToObject = require('cf-map-form-to-object')
const join = require('path').join
const formErrorsDelegate =
  require('../../../../../admin/source/js/lib/form-errors-delegate')()
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<form><div class=\"panel\"><div class=\"js-errors-summary\"></div><div id=\"field--text\" class=\"form-row\"><label><span class=\"form-label-text\">Text<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"text\"" + (jade.attr("value", data.text, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--link\" class=\"form-row\"><label><span class=\"form-label-text\">Link<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"link\"" + (jade.attr("value", data.link, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--displayActive\" data-field=\"displayActive\" class=\"form-row form-row-boolean\"><span class=\"form-label-text\"><label for=\"displayActive\">Active</label></span><div class=\"form-field\"><input type=\"checkbox\" name=\"displayActive\" id=\"displayActive\"" + (jade.attr("checked", data.displayActive, true, false)) + " class=\"control control--boolean\"/></div><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>The active item will appear highlighted in the navigation. Only one item can be active at any one time.</p></div></div><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div></div></form>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const ItemModel = require('../models/item-model')
const BaseWidgetView = require('../../../widget/views/form/base')

module.exports = BaseWidgetView.extend({
  events: Object.assign({}, BaseWidgetView.prototype.events, {
    'click .js-cancel-item': 'handleCancel',
    'click .js-save-item': 'handleSave',
  }),

  initialize: function (model, serviceLocator) {
    this.serviceLocator = serviceLocator
    this.model = model || new ItemModel()
    this.render()
  },

  handleCancel: function (e) {
    e.preventDefault()
    this.trigger('cancel')
  },

  handleSave: function (e) {
    e.preventDefault()
    const formData = mapFormToObject(this.$el.find('form'), this.model.schemata)

    this.showErrors = formErrorsDelegate.showErrors
    this.clearErrors = formErrorsDelegate.clearErrors

    this.model.validate(
      formData,
      function (errors) {
        this.model.validationError = errors
        if (this.model.validationError) {
          this.showErrors(this.model.validationError)
        } else {
          this.model.set(formData, { silent: true })
          this.trigger('save', this.model)
        }
      }.bind(this)
    )
  },

  render: function () {
    this.$el
      .empty()
      .append(template({ data: this.model ? this.model.toJSON() : {} }))
  },
})
