import editView from './views/form'
import model from './models/model'
import itemView from './views/item'

const initWidget = () => ({
  formBuilderWidget: ['sectionAdmin', 'formBuilderAdmin', init],
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Form Builder',
    description: 'Display a form from the form builder',
  }

  serviceLocator.widgetFactories.get('section').register('formBuilder', widget)
  serviceLocator.widgetFactories.get('article').register('formBuilder', widget)
  serviceLocator.widgetFactories
    .get('articleBody')
    .register('formBuilder', widget)
  serviceLocator.widgetFactories.get('tabsBody').register('formBuilder', widget)

  done()
}

module.exports = initWidget
