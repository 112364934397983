const schemata = require('@clocklimited/schemata')
const responseSchema = require('../question/response-schema')
const validity = require('validity')
const required = require('@clocklimited/validity-required')
const validateIfPropertyIn = require('validity-validate-if-property-in')
const inputTypesRequiringResponse = require('./input-types')
  .requiresResponse()
  .map((input) => input.input)
const isNotEmpty = (value) => value.length > 0

const nonEmptyValidator =
  validity.createValidatorAllowingFailureMessageOverride(
    validity.booleanToCallback(isNotEmpty),
    'Must have at least one response'
  )

const questionSchema = schemata({
  name: 'Question schema',
  properties: {
    question: {
      type: String,
      validators: [required],
    },
    required: {
      type: Boolean,
    },
    description: {
      type: String,
    },
    inputType: {
      type: String,
      defaultValue: () => 'text',
    },
    responses: {
      type: schemata.Array(responseSchema()),
      validators: [
        validateIfPropertyIn(
          'inputType',
          inputTypesRequiringResponse,
          nonEmptyValidator
        ),
      ],
    },
  },
})

module.exports = function () {
  return questionSchema
}
