const competitions = [
  {
    text: 'All',
    value: 'all',
    squad: '',
    hasStandings: false,
  },
  {
    text: 'Gallagher Premiership Rugby',
    short: 'Gallagher Prem.',
    value: 'gallagher-premiership-rugby',
    squad: 'senior',
    hasStandings: true,
    id: 201,
  },
  {
    text: 'Premiership Rugby Cup',
    short: 'Prem. Cup',
    value: 'premiership-rugby-cup',
    squad: 'senior',
    hasStandings: true,
    id: 326,
  },
  {
    text: 'Champions Cup',
    short: 'Champions Cup',
    value: 'champions-cup',
    squad: 'senior',
    hasStandings: true,
    id: 242,
  },
  {
    text: 'European Rugby Challenge Cup',
    short: 'Challenge Cup',
    value: 'european-rugby-challenge-cup',
    squad: 'senior',
    hasStandings: true,
    id: 243,
  },
  {
    text: "Women's Championship North 1",
    short: 'Championship North 1',
    value: 'womens-championship-north-1',
    squad: 'senior',
    hasStandings: true,
    id: null,
  },
  {
    text: "Premiership Women's Rugby",
    short: 'PWR',
    value: 'premiership-womens-rugby',
    squad: 'senior',
    hasStandings: true,
    id: 340,
  },
  {
    text: 'Allianz Cup',
    short: 'Allianz Cup',
    value: 'allianz-cup',
    squad: 'senior',
    hasStandings: true,
    id: null,
  },
  {
    text: 'A League',
    short: 'A',
    value: 'a-league',
    squad: 'development',
    hasStandings: false,
  },
  {
    text: 'Premiership Rugby U18 Academy League',
    short: 'U18 Prem.',
    value: 'premiership-rugby-u18-academy-league',
    squad: 'junior',
    hasStandings: false,
  },
  {
    text: 'Wheelchair Rugby',
    short: 'Wheelchair',
    value: 'wheelchair-rugby',
    squad: 'wheelchair',
    hasStandings: false,
  },
]

module.exports.all = competitions
module.exports.hasStandings = competitions.filter(
  ({ hasStandings }) => hasStandings
)
module.exports.getSquadNameByCompetition = (name) => {
  const competition = competitions.find(({ value }) => value === name)
  return competition ? competition.squad : ''
}
