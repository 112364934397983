const dropUiDelegate = require('./drop-ui-delegate')
const modal = require('modal')

module.exports = function (view) {
  dropUiDelegate(view)

  view.fileUploader.on('error', displayUploadError)
  view.fileUploader.on('warning', displayUploadWarning)

  function displayUploadWarning(msg) {
    modal({
      title: 'Upload warning',
      content: msg,
      buttons: [ { text: 'Dismiss', className: 'btn' } ],
    })
  }

  function displayUploadError(msg) {
    modal({
      title: 'Upload error',
      content: msg,
      buttons: [ { text: 'Dismiss', className: 'btn' } ],
    })
  }
}
