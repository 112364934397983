module.exports = createLayout
const createUniqueId = require('hat')
const createBaseWidgetSchema = require('../../widget/model/base-widget')
const baseWidget = createBaseWidgetSchema().makeDefault()

function extendBaseWidget(widget) {
  return Object.assign({}, baseWidget, widget)
}

function createLayout() {
  const layout = {
    '0': {
      id: '0',
      attributes: [ 'wide' ],
      cols: [
        {
          id: '3:0',
          order: 1,
          width: 3,
          attributes: [],
          widgetArea: {
            widgets: [
              extendBaseWidget({
                type: 'articleLayoutHeader',
                visible: true,
                essential: true,
                showArticleProperties: [ 'category', 'attributes' ],
                id: createUniqueId(),
              }),
              extendBaseWidget({
                type: 'articleLayoutImages',
                visible: true,
                essential: true,
                id: createUniqueId(),
              }),
              extendBaseWidget({
                type: 'articleLayoutBody',
                visible: true,
                essential: true,
                id: createUniqueId(),
              }),
            ],
          },
        },
      ],
    },
  }

  return {
    name: 'Article Layout',
    description: 'This is the layout that Articles in this section will have',
    isBuiltIn: true,
    type: 'article',
    key: 'article',
    layout: layout,
  }
}
