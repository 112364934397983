module.exports = ArticleService
const CrudService = require('../../../../admin/source/js/lib/crud-service')

function ArticleService() {
  this.name = 'ArticleService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/articles'
}

ArticleService.prototype = Object.create(CrudService.prototype)
