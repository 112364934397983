import editView from './views/form'
import model from './models/model'
import itemView from '../../widget/views/item/edit-base'

const initWidget = () => ({ subNavigationWidget: ['sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Sub Navigation',
    description: 'Display a sub navigation with an optional header',
  }

  serviceLocator.widgetFactories
    .get('section')
    .register('subNavigation', widget)
  serviceLocator.widgetFactories
    .get('tabsBody')
    .register('subNavigation', widget)

  done()
}

module.exports = initWidget
