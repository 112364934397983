const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const validateIfPropertyEquals = require('validity-validate-if-property-equals')

module.exports = () =>
  schemata({
    name: 'Filter',
    properties: {
      filterType: {
        type: String,
        validators: [required],
      },
      competition: {
        type: String,
        validators: [
          validateIfPropertyEquals('filterType', 'standing', required),
          validateIfPropertyEquals('filterType', 'match', required),
        ],
      },
      overrideSeason: {
        type: String,
      },
      squad: {
        type: String,
        validators: [validateIfPropertyEquals('filterType', 'match', required)],
      },
      showCoverage: {
        type: Boolean,
      },
      ...baseSchema.getProperties(),
    },
  })
