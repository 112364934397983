const createListController = require('./controllers/list')
const createFormController = require('./controllers/form')
const VenueService = require('./services/venue')

function initAdmin() {
  return { venueAdmin: [ 'widget', init ] }
}

function init(serviceLocator, cb) {
  serviceLocator.venueService = new VenueService(serviceLocator)

  createListController(serviceLocator)
  createFormController(serviceLocator)
  cb(null)
}

module.exports = initAdmin
