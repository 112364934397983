module.exports = initWidget

function initWidget() {
  return { heroWidget: ['widget', 'listWidget', 'sectionAdmin', init] }
}

function init(serviceLocator, done) {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Hero',
    itemView: require('../list/views/item'),
    description: 'Display list content in main hero block',
  }

  serviceLocator.widgetFactories.get('article').register('hero', widget)
  serviceLocator.widgetFactories.get('section').register('hero', widget)
  serviceLocator.widgetFactories.get('tabsBody').register('hero', widget)

  done()
}
