const required = require('@clocklimited/validity-required')
const isUrl = require('validity-url')
const schemata = require('@clocklimited/schemata')
const imageConfig = require('./image-config.json')
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')
const validateIf = require('../../../lib/validators/validate-if')
const hasWidgets = require('../../../lib/has-widgets')
const validateIfSet = require('validity-validate-if-set')

module.exports = () => {
  const requiredContexts = imageConfig.contexts.map((context) => context.name)
  const requiredCrops = imageConfig.crops.map((crop) => crop.name)
  const schema = schemata({
    name: 'Author schema',
    properties: {
      _id: {
        type: String,
      },
      account: {
        type: String,
        validators: [required],
      },
      name: {
        type: String,
        validators: [required],
      },
      website: {
        type: String,
        validators: [validateIfSet(isUrl)],
      },
      biography: {
        type: String,
      },
      images: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: [
          validateIf(createContextValidator(requiredContexts), hasWidgets),
          validateIf(createCropValidator(requiredCrops), hasWidgets),
        ],
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date(),
      },
    },
  })

  return schema
}
