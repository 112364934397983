module.exports = initWidget

function initWidget() {
  return { ticketHeroWidget: [ 'sectionAdmin', init ] }
}

function init(serviceLocator, done) {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('../../../widget/views/item/edit-base'),
    name: 'Ticket Hero',
    description: 'Display ticket hero for a match',
  }

  serviceLocator.widgetFactories.get('match').register('ticketHero', widget)
  done()
}
