const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const responseSchema = schemata({
  name: 'Response schema',
  properties: {
    response: {
      type: String,
      validators: [required],
    },
  },
})

module.exports = function () {
  return responseSchema
}
