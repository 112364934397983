const FormView = require('./views/form')
const Model = require('./models/model')
const ItemView = require('../../widget/views/item/edit-base')

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    itemView: ItemView,
    name: 'Search',
    description: 'Show search input and results',
  }

  ;[ 'section', 'articleBody', 'article' ].forEach((factory) =>
    serviceLocator.widgetFactories.get(factory).register('search', widget)
  )

  done()
}

const initWidget = () => ({ searchWidget: [ 'widget', 'sectionAdmin', init ] })

module.exports = initWidget
