import Model from './model'
import ItemView from '../../widget/views/item/base'

const initWidget = () => {
  return { relatedWidgets: [ 'sectionAdmin', init ] }
}

const init = (serviceLocator, done) => {
  serviceLocator.widgetFactories.get('article').register('relatedWidgets', {
    model: Model,
    name: 'Related Widgets',
    itemView: ItemView,
    description: 'Placeholder for the articles related widgets',
  })
  done()
}

module.exports = initWidget
