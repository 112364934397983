module.exports={
  "crops": [
    { "name": "Landscape", "aspectRatio": "16:9" },
    { "name": "Square", "aspectRatio": "1:1" }
  ],
  "contexts": [
    { "name": "Hero", "allowMultipleSelection": false },
    { "name": "Thumbnail", "allowMultipleSelection": false }
  ]
}
