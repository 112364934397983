module.exports = SquadSelect

var View = require('ventnor')
var squadsData = require('../../../service/team/squads')

function SquadSelect(
  serviceLocator,
  selectedSquads,
  playable = true,
  isEditable = true
) {
  View.apply(this, arguments)
  this.$el = $('<select multiple/>')
  this.$el.addClass('control control--choice control--multiline')
  this.el = this.$el[0]
  this.$el.attr('placeholder', 'Choose one or more squad')

  this.selectedSquads = selectedSquads
  this.playable = playable
  this.isEditable = isEditable
  this.squads = this.playable ? squadsData.playable : squadsData.all
}

SquadSelect.prototype = Object.create(View.prototype)

SquadSelect.prototype.initializeSelectize = function () {
  this.squads.forEach(
    function (squad) {
      if (this.selectedSquads.includes(squad.value)) {
        // The item needs to be added to the list
        // of selectize options in order to be selected
        this.el.selectize.addOption({ value: squad.value, text: squad.text })
        // Select the added option
        this.el.selectize.addItem(squad.value)
      }
    }.bind(this)
  )

  this.el.selectize.on('change', this.updateSelection.bind(this))
}

SquadSelect.prototype.updateSelection = function () {
  this.selectedSquads = this.el.selectize.getValue()
  this.emit('change', this.selectedSquads)
}

SquadSelect.prototype.load = function (query, cb) {
  cb(
    this.squads.map(function (squad) {
      return { value: squad.value, text: squad.text }
    })
  )
}

SquadSelect.prototype.render = function () {
  setTimeout(
    function () {
      this.$el.selectize({
        delimiter: ',',
        create: false,
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this),
        preload: true,
      })
    }.bind(this),
    0
  )
  return this
}
