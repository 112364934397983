const required = require('@clocklimited/validity-required')
const schemata = require('@clocklimited/schemata')
const imageConfig = require('./image-config.json')
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')
const dateBeforeExpiryValidator = require('validity-date-before-property')(
  'expiryDate'
)

module.exports = () => {
  const requiredContexts = imageConfig.contexts.map((context) => context.name)
  const requiredCrops = imageConfig.crops.map((crop) => crop.name)
  const schema = schemata({
    name: 'Advert schema',
    properties: {
      _id: {
        type: String,
      },
      account: {
        type: String,
        validators: [required],
      },
      name: {
        type: String,
        validators: [required],
      },
      description: {
        type: String,
        validators: [required],
      },
      link: {
        type: String,
        validators: [required],
      },
      target: {
        type: String,
      },
      html: {
        type: String,
      },
      tags: {
        type: String,
      },
      active: {
        type: Boolean,
        defaultValue: true,
      },
      liveDate: {
        type: Date,
        validators: [dateBeforeExpiryValidator],
      },
      expiryDate: {
        type: Date,
      },
      images: {
        type: Object,
        validators: [
          createContextValidator(requiredContexts),
          createCropValidator(requiredCrops),
        ],
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date(),
      },
    },
  })

  return schema
}
