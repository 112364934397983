import editView from './views/form'
import model from './models/model'
import itemView from '../../widget/views/item/edit-base'

const initWidget = () => ({
  matchCentreWidget: [ 'sectionAdmin', 'competitionAdmin', init ],
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Match Centre',
    description:
      'Display information about the last match, next home match, standings and upcoming fixtures',
  }

  serviceLocator.widgetFactories.get('section').register('matchCentre', widget)

  done()
}

module.exports = initWidget
