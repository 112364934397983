const initWidget = () => ({
  juniorTigersClubSignupWidget: [ 'sectionAdmin', init ],
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('../../widget/views/item/edit-base'),
    name: 'Junior Tigers Club Signup',
    description: 'Junior tigers club signup form',
  }

  ;[ 'section', 'article', 'articleBody' ].forEach((factory) =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('juniorTigersClubSignup', widget)
  )

  done()
}

module.exports = initWidget
