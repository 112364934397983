const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')

class ListView extends BaseListView {
  get FilterView() {
    return FilterView
  }

  get ListItemView() {
    return ListItemView
  }

  get name() {
    return { singular: 'Match', plural: 'Matches', resource: 'match' }
  }

  addListItem(model, index) {
    const listItem = super.addListItem(model, index)
    this.listenTo(
      listItem,
      'commentary',
      this.emit.bind(this, 'commentary', model.id)
    )

    return listItem
  }
}

module.exports = ListView
