module.exports = ListItemView
const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, find, format) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('hospitality-package', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("</div>");
if ( allowed('hospitality-package', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/packages/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = find(data.type).name) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = find(data.type).name) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Date:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.date, 'calendar')) ? "" : jade_interp)) + "</dd><dt>Available:</dt><dd>" + (jade.escape(null == (jade_interp = data.available ? 'Yes' : 'No') ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"find" in locals_for_with?locals_for_with.find:typeof find!=="undefined"?find:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const types = require('../../../service/hospitality-package/types')()

function ListItemView() {
  BaseListItemView.apply(this, arguments)
}

ListItemView.prototype = Object.create(BaseListItemView.prototype)

ListItemView.prototype.template = template

ListItemView.prototype.serialize = function () {
  const data = this.model.toJSON()
  return data
}

ListItemView.prototype.render = function () {
  var data = this.serialize()

  this.$el.empty().append(
    template({
      data: data,
      allowed: this.serviceLocator.allowed,
      format: this.serviceLocator.format,
      find: (needle) => types.find((type) => needle === type.id),
    })
  )

  this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
  return this
}
