module.exports = initWidget

function initWidget() {
  return { sectionPromoWidget: [ 'sectionAdmin', init ] }
}

function init(serviceLocator, done) {
  var widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('./views/item'),
    name: 'Section Promo',
    description: 'Section Promo',
  }

  serviceLocator.widgetFactories.get('section').register('sectionPromo', widget)

  done()
}
