const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const metaSchema = require('./meta-schema')

module.exports = function () {
  return schemata({
    name: 'Tag schema',
    properties: {
      _id: { type: String },
      tag: { type: String, validators: { all: [required] } },
      type: { type: String, validators: { all: [required] } },
      meta: { type: schemata.Array(metaSchema()) },
      account: { type: String, validators: { all: [required] } },
      dateCreated: { type: Date, defaultValue: () => new Date() },
    },
  })
}
