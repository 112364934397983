const Model = require('merstone')
const createSchema = require('../../../service/match/schema')

class MatchModel extends Model {
  constructor(serviceLocator, attributes) {
    super(serviceLocator, attributes)

    this.schema = createSchema(serviceLocator)
    this.attributes = this.schema.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })
  }

  validate(cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset(attrs) {
    attrs = this.schema.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }

  isEditable() {
    return this.get('source') === 'cms'
  }
}

module.exports = MatchModel
