const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"grid\"><div class=\"grid__item three-fifths js-selected-sponsors\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Manually Picked Items</h2><div class=\"panel-actions\"><span class=\"label label--large\">Drag in items to populate.</span></div></div><div class=\"panel-content\"><div class=\"js-selected-sponsor-list drop-area\"></div></div></div></div><div class=\"grid__item two-fifths\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Search</h2></div><div class=\"panel-content\"><div class=\"js-sponsor-browser\"></div></div></div></div></div>");;return buf.join("");
}
const BaseView = require('cf-base-view')
const debug = require('../../../../../admin/source/js/lib/debug')(
  'sponsor-picker manual view'
)
const SponsorBrowser = require('../../../../admin/sponsor/views/browser')
const ManualSponsorItem = require('./manual-item')
const modal = require('modal')
const clone = require('lodash.clone')
const async = require('async')

module.exports = BaseView.extend({
  events: {
    'click .js-new-item': 'handleNewItem',
  },
  initialize: function (serviceLocator, model) {
    this.model = model
    this.serviceLocator = serviceLocator
  },
  renderSponsors: function () {
    const toDelete = []
    async.eachSeries(this.model.get('sponsors'), (sponsor, cb) => {
      this.serviceLocator.sponsorService.read(
        sponsor.sponsorId,
        (error, result) => {
          if (error) return
          const view = new ManualSponsorItem({ model: result })
          this.$('.js-selected-sponsor-list').append(view.$el)
          view.render()
          this.listenTo(view, 'removed', this.removeSponsorItem.bind(this))
          cb()
        }
      )
    })

    toDelete.forEach((id) => {
      // Remove any sponsors that have been deleted
      const sponsors = this.model.get('sponsors')
      delete sponsors[id]
      this.model.set(
        'sponsors',
        sponsors.filter((sponsor) => sponsor)
      )
    })
  },
  handleDrop: function (event, ui) {
    const sponsorId = ui.item.attr('data-sponsorId')
    let exists = false
    let sponsors

    debug('sponsor dropped ', sponsorId)

    this.model.get('sponsors').forEach((sponsor) => {
      if (sponsor.sponsorId === sponsorId) exists = true
    })
    if (!exists) {
      sponsors = this.model.get('sponsors')
      sponsors.push({ sponsorId: sponsorId })
      this.model.set('sponsors', sponsors)
      this.serviceLocator.sponsorService.read(sponsorId, (error, found) => {
        if (error) return
        const sponsorItem = new ManualSponsorItem({ model: found })
        this.listenTo(sponsorItem, 'removed', this.removeSponsorItem.bind(this))
        sponsorItem.render()
        ui.item.replaceWith(sponsorItem.$el)
      })
    } else {
      ui.item.remove()
      modal({
        title: 'Duplicate Sponsor',
        content: 'This sponsor is already in the list',
        buttons: [ { text: 'Dismiss', className: 'btn', keyCodes: [ 27 ] } ],
      })
    }
  },

  handleReorder: function () {
    const sponsorIds = []
    this.$el.find('.js-selected-sponsor .list-item').each((index, sponsor) => {
      sponsorIds.push({ sponsorId: $(sponsor).attr('data-sponsorId') })
    })
    this.model.set('sponsors', sponsorIds)
  },

  removeSponsorItem: function (sponsor) {
    debug('Removing Sponsor: ', sponsor)

    if (sponsor._id) {
      sponsor.sponsorId = sponsor._id
    }

    const sponsors = []
    this.model.get('sponsors').forEach(function (item) {
      if (item.sponsorId !== sponsor.sponsorId) {
        sponsors.push(item)
      }
    })
    this.model.set('sponsors', sponsors)
  },

  consolidateSponsors: function () {
    this.handleReorder()
    return this.model.get('sponsors')
  },

  render: function () {
    this.$el.empty().append(template({ data: clone(this.model.attributes) }))
    this.renderSponsors()
    this.sponsorBrowser = new SponsorBrowser(this.serviceLocator).render()
    this.$('.js-sponsor-browser').append(this.sponsorBrowser.$el)

    // Create droppable and add on drop event
    this.$('.js-selected-sponsor-list')
      .sortable({
        handle: '.js-sort-handle',
        cursor: 'move',
        addClasses: false,
        connectWith: '.js-sponsor-drag',
      })
      .off('sortupdate')
      .on(
        'sortupdate',
        function (event, ui) {
          if (!ui.item.hasClass('js-selected-sponsor')) {
            this.handleDrop(event, ui)
          } else {
            this.handleReorder()
          }
        }.bind(this)
      )
    return this
  },
})
