const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"panel panel-styled panel-fixed\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Search<div class=\"form-label-text__right\"><span data-toggle=\"tooltip\" title=\"Use double quotes to search for a phrase and minus to negate a word, e.g.&lt;br /&gt;[ &quot;Fashion Week&quot; ] or [ Fashion -Week ]\" class=\"js-tooltip-trigger label\"><i class=\"icon icon--question\">Help</i></span></div></span><input type=\"text\" name=\"keywords\" placeholder=\"Keywords\" class=\"control control--text form-field\"/></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Order</span><select name=\"sort\" class=\"control control--choice form-field\"><option value=\"-dateCreated\">Newest First</option><option value=\"dateCreated\">Oldest First</option><option value=\"-score\">Relevance</option></select></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");;return buf.join("");
}
const BaseView = require('cf-base-view')

module.exports = BaseView.extend({
  className: 'list-filters',

  events: {
    'submit form': 'handleSubmit',
    'click input[type=reset]': 'handleClear',
    'click .js-filter': 'handleSubmit',
    'click .js-clear': 'handleClear',
    'click .dropdown-menu a': 'handleDropDownAction',
  },

  initialize: function () {
    this.render()
  },

  handleDropDownAction: function (e) {
    e.preventDefault()
    var $target = $(e.target)
    if ($target.hasClass('js-state')) {
      this.$('.js-state-current').text('Current: ' + $target.text())
      this.$('form [name=state]').val($target.attr('data-state'))
    } else if ($target.hasClass('js-sort')) {
      this.$('.js-sort-current').text('Current: ' + $target.text())
      this.$('form [name=sort]').val($target.attr('data-direction'))
    } else if ($target.hasClass('js-type')) {
      this.$('.js-type-current').text('Current: ' + $target.text())
      this.$('form [name=type]').val($target.attr('data-type'))
    }
  },

  handleSubmit: function (e) {
    if (e) e.preventDefault()

    const params = { filter: {}, sort: [] }
    const map = {
      dateCreated: [ 'dateCreated', 'asc' ],
      '-dateCreated': [ 'dateCreated', 'desc' ],
      '-score': [ 'score', 'desc' ],
    }
    const keywords = this.$('form [name=keywords]').val()
    let instance = this.$('form [name=instance]').val()

    params.sort = map[this.$('form [name=sort]').val()]

    if (keywords.length) {
      params.keywords = keywords
    }

    if (instance) {
      instance = this.instanceCollection.get(instance)
      params.filter.instance = instance.id
    }

    this.trigger('filter', params)
  },

  handleClear: function (e) {
    e.preventDefault()
    this.$('form [name=keywords]').val('')
    this.trigger('filter', null)
  },

  render: function () {
    this.$el.empty().append(template)
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
  },
})
