const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const validateInteger = require('validity-integer')
const validateIfSet = require('validity-validate-if-set')

module.exports = () =>
  schemata({
    name: 'Article action list',
    properties: {
      lists: {
        type: Array,
        validators: [required],
      },
      header: { type: String },
      limit: {
        type: Number,
        validators: [validateIfSet(validateInteger)],
      },
      ...baseSchema.getProperties(),
    },
  })
