const View = require('ventnor')

const createSelector = (name, id, displayProperty, service) => {
  return class Selector extends View {
    constructor(serviceLocator, selected) {
      super(serviceLocator)
      this.$el = $(`<select id="${id}" />`)
      this.$el.addClass('control control--choice control--multiline')
      this.el = this.$el[0]
      this.$el.attr('placeholder', `Choose a ${name}`)
      this.selected = selected || []
    }

    initializeSelectize() {
      const pagination = { page: 1, pageSize: 500 }
      this.serviceLocator.formBuilderService.find(
        '',
        { _id: this.selected },
        [],
        pagination,
        (err, res) => {
          if (err) return alert(`Cannot find existing ${name}`)
          res.results.forEach((item) => {
            this.el.selectize.addOption({
              value: item._id,
              text: item[displayProperty],
            })
            this.el.selectize.addItem(item._id)
          })
          this.el.selectize.on('change', this.updateSelection.bind(this))
        }
      )
    }

    updateSelection() {
      this.selected = this.el.selectize.getValue()
      this.emit('change', this.selected)
    }

    load(query, cb) {
      service.find(
        query,
        {},
        [ displayProperty ],
        { pageSize: 10000 },
        (err, res) => {
          if (err) {
            this.serviceLocator.logger.error(err, err.message)
            return this
          }
          cb(
            res.results.map((item) => ({
              value: item._id,
              text: item[displayProperty],
            }))
          )
        }
      )
    }

    render() {
      setTimeout(() => {
        this.$el.selectize({
          create: false,
          onInitialize: this.initializeSelectize.bind(this),
          load: this.load.bind(this),
          preload: true,
          selectOnTab: true,
        })
      }, 0)

      return this
    }
  }
}

module.exports = createSelector
