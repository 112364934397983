module.exports = createSchemata
const schemata = require('@clocklimited/schemata')
const unique = require('lodash.uniq')
const required = require('@clocklimited/validity-required')
const requiredIfSet = require('validity-validate-if-property-set')
const moment = require('moment')
const tagSchema = require('../tag/schema')
const imageConfig = require('./image-config.json')
const dateBeforeExpiryValidator = require('validity-date-before-property')(
  'expiryDate'
)
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')
const createUniqueValidator = require('validity-unique-property')
const validateIf = require('../../../lib/validators/validate-if')
const hasWidgets = require('../../../lib/has-widgets')

// TODO: I think this is a feature of validity already
function customValidityMessage(fn, message) {
  return function (key, name, object, cb) {
    fn(key, name, object, function (error, valid) {
      cb(error, valid ? message : undefined)
    })
  }
}

function createSchemata(find, sectionService) {
  const requiredContexts = ['Thumbnail']
  const requiredCrops = imageConfig.crops.map(function (crop) {
    return crop.name
  })
  const fullUrlUniqueValidator = customValidityMessage(
    createUniqueValidator(find, { keys: ['sections'] }),
    'Slug within this section is already in use'
  )

  return schemata({
    name: 'Article schema',
    properties: {
      _id: { type: String },

      state: {
        type: String,
        options: ['Draft', 'Published', 'Archived', 'Trashed'],
        defaultValue: 'Draft',
        validators: { all: [] },
      },

      headline: {
        type: String,
        validators: { draft: [required], published: [required], archived: [] },
      },

      shortHeadline: {
        type: String,
        validators: { draft: [], published: [required], archived: [] },
      },

      sell: {
        type: String,
        validators: { draft: [], published: [required], archived: [] },
      },

      slug: {
        type: String,
        validators: {
          draft: [],
          published: [required, fullUrlUniqueValidator],
          archived: [],
        },
      },

      sections: {
        type: Array,
        validators: {
          draft: [],
          published: [required, uniqueInstanceValidator],
          archived: [],
        },
      },

      alternatePagination: {
        type: Boolean,
      },

      authorId: {
        type: String,
      },

      matchId: {
        type: String,
      },

      category: {
        type: String,
        validators: {
          draft: [],
          published: [required],
          archived: [],
        },
      },

      body: {
        type: Object,
        defaultValue: function () {
          return { widgets: [] }
        },
        validators: { draft: [], published: [], archived: [] },
      },

      images: {
        type: Object,
        defaultValue: function () {
          return { widgets: [] }
        },
        validators: {
          draft: [
            validateIf(createContextValidator(requiredContexts), hasWidgets),
            validateIf(createCropValidator(requiredCrops), hasWidgets),
          ],
          published: [
            createContextValidator(requiredContexts),
            createCropValidator(requiredCrops),
          ],
          archived: [
            createContextValidator(requiredContexts),
            createCropValidator(requiredCrops),
          ],
        },
      },

      alertMessage: { type: String },
      countdownDate: { type: Date },
      countdownLabel: { type: String },
      sponsors: { type: Array },

      displayDate: {
        type: Date,
        defaultValue: function () {
          return moment().startOf('minute').toDate()
        },

        validators: {
          draft: [],
          published: [
            customValidityMessage(
              requiredIfSet('matchId', required),
              'A display date must be set when an article is assigned to a match'
            ),
          ],
          archived: [],
        },
      },

      liveDate: {
        type: Date,
        validators: {
          draft: [dateBeforeExpiryValidator],
          published: [dateBeforeExpiryValidator],
          archived: [],
        },
      },

      expiryDate: {
        type: Date,
        validators: { draft: [], published: [], archived: [] },
      },

      dateCreated: {
        type: Date,
        defaultValue: () => new Date(),
        validators: { draft: [], published: [], archived: [] },
      },

      tags: { type: schemata.Array(tagSchema()) },

      metaTitle: {
        type: String,
        validators: { draft: [], published: [], archived: [] },
      },

      metaDescription: {
        type: String,
        validators: { draft: [], published: [], archived: [] },
      },

      shareTitle: {
        type: String,
        validators: { draft: [], published: [], archived: [] },
      },

      shareDescription: {
        type: String,
        validators: { draft: [], published: [], archived: [] },
      },

      // ID used by admin to preview
      previewId: {
        type: String,
        defaultValue: function () {
          return Math.round(Math.random() * 100000000000).toString(36)
        },
      },

      relatedWidgets: {
        type: Object,
        defaultValue: function () {
          return { widgets: [] }
        },
        validators: { draft: [], published: [], archived: [] },
      },

      account: {
        type: String,
        validators: {
          draft: [required],
          published: [required],
          archived: [required],
        },
      },

      layout: { type: String, defaultValue: null },
    },
  })

  function uniqueInstanceValidator(key, keyDisplayName, object, cb) {
    // If no section service, assume valid
    if (!sectionService) return cb(null, undefined)

    sectionService.find(
      { _id: { $in: object[key] } },
      function (err, sections) {
        if (err) return cb(err)

        const sectionCount = sections.length
        const uniqueInstanceCount = unique(
          sections.map(function (section) {
            return section.instance
          })
        ).length

        if (sectionCount !== uniqueInstanceCount) {
          return cb(
            null,
            keyDisplayName +
              ' must not contain more than one section from any instance'
          )
        }

        return cb(null, undefined)
      }
    )
  }
}
