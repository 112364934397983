const DateTimePicker = require('anytime')
const moment = require('moment')

module.exports = (dateFormat, model, options = {}) => (el) => {
  const pickerOptions = {
    input: el,
    format: dateFormat,
    initialDate: new Date(),
    ...options,
  }

  el.readOnly = true

  if (el.value) {
    options.initialDate = moment(el.value).toDate()
    el.value = moment(el.value).format(dateFormat)
  }

  const picker = new DateTimePicker(pickerOptions)

  picker.on('change', (value) => {
    if (value) value = moment(value).toDate()
    if (model) model.set(el.name, value)
  })
  picker.render()
  return picker
}
