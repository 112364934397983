import View from 'ventnor'

class PlayerSelect extends View {
  constructor(serviceLocator, position, players, selected, isReadOnly) {
    super(serviceLocator)
    this.isReadOnly = !!isReadOnly
    this.$el = $('<select id="playerId' + position + '" />')
    this.$el.addClass('control control--choice control--multiline')
    this.el = this.$el[0]
    this.$el.attr(
      'placeholder',
      isReadOnly ? 'Choose a player' : 'Choose or enter a player'
    )
    this.selected = selected || null
    this.players = players
    this.position = position
  }

  initializeSelectize() {
    const selectedPlayer = this.players.filter(
      (player) => this.formatSelectValue(player) === this.selected
    )
    const playerList = selectedPlayer.length > 0 ? selectedPlayer : this.players
    playerList.forEach((player) => {
      this.el.selectize.addOption({
        value: this.formatSelectValue(player),
        text: this.formatSelectText(player),
      })
      if (
        selectedPlayer.length > 0 &&
        this.formatSelectValue(selectedPlayer[0]) ===
          this.formatSelectValue(player)
      )
        this.el.selectize.addItem(this.formatSelectValue(player))
    })

    if (!selectedPlayer.length && !this.isReadOnly && this.selected) {
      this.el.selectize.addOption({ value: this.selected, text: this.selected })
      this.el.selectize.addItem(this.selected)
    }

    this.el.selectize.on('change', this.updateSelection.bind(this))
  }

  formatSelectValue(player) {
    return player._id
  }

  formatSelectText(player) {
    const name = `${player.firstName} ${player.lastName}`
    const squad = player.squad
    return `${name}` + (player.squad ? ` (${squad})` : '')
  }

  updateSelection() {
    this.selected = this.el.selectize.getValue()
    this.emit('change', this.selected)
  }

  load(query, cb) {
    cb(
      this.players.map((player) => ({
        value: this.formatSelectValue(player),
        text: this.formatSelectText(player),
      }))
    )
  }

  create(input) {
    return { value: input, text: input }
  }

  render() {
    setTimeout(() => {
      this.$el.selectize({
        persist: false,
        create: this.isReadOnly ? false : this.create.bind(this),
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this),
        preload: true,
        selectOnTab: true,
      })
    }, 0)
    return this
  }
}

module.exports = PlayerSelect
