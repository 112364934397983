const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (description, model, name) {
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\">");
if ( model.get('liveDate') || model.get('expiryDate'))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", "<strong>Live:</strong> " + ( model.get('liveDate') ? model.get('liveDate') : 'Not Set' ) + "<br><strong>Expires:</strong> " + ( model.get('expiryDate') ? model.get('expiryDate') : 'Not Set' ) + "", true, false)) + " class=\"label label--large label--inverse js-tooltip-trigger\"><i class=\"icon icon--clock\"></i></span>");
}
if ( model.get('visible'))
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible&lt;/strong&gt;&lt;br&gt;This widget is visible on the site.\" class=\"label label--large label--standard js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Hidden&lt;/strong&gt;&lt;br&gt;This widget will not appear on the site.\" class=\"label label--large js-tooltip-trigger\"><i class=\"icon icon--draft\"></i></span>");
}
buf.push("</div><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a data-text-expand=\"Expand\" data-text-collapse=\"Collapse\" class=\"js-expander-toggle\">Collapse</a></li><li><a class=\"js-remove\">Delete</a></li></ul></div></div></div><h2><a class=\"js-edit\">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</a></h2></div>");
if ( description)
{
buf.push("<div class=\"list-item-content expander-content\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div></div>");}.call(this,"description" in locals_for_with?locals_for_with.description:typeof description!=="undefined"?description:undefined,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const debug = require('../../../../../admin/source/js/lib/debug')(
  'edit base widget item view'
)
const BaseWidget = require('./base')
const modal = require('modal')
const extend = require('lodash.assign')

module.exports = BaseWidget.extend({
  events: extend({}, BaseWidget.prototype.events, {
    'click .js-edit': 'handleEdit',
  }),
  template: template,
  editView: null,
  handleEdit: function (e, init) {
    init = init || false
    debug('edit', this.model.get('id'))
    if (this.editView !== null) {
      this.factory.editView = this.editView
    }

    // Return early if this widget does
    // not have an edit view e.g. static
    if (!this.factory.editView) return

    const EditView = this.factory.editView
    const editView = new EditView({
      model: this.model,
      serviceLocator: this.options.serviceLocator,
      factoryOptions: this.factory.options,
    })
    this.showModal(editView, null, init)
  },
  showModal: function (view, className, init) {
    view.on('resize', function () {
      debug('child view resized')
      m.centre()
    })

    view.on('cancel', function () {
      debug('widget add cancel')
      if (init) {
        // Remove the widget item view from the dom and remove from model
        this.model.trigger('remove', this.options.model)
      }
      m.close()
    })

    view.on('save', function () {
      // This is here as a quick fix for the following issue: http://bugs.jqueryui.com/ticket/4727
      // Long term we need to find a way to apply this to Ben's modal module.
      $('.cke_button__source').trigger('click')

      debug('widget save')
      m.close()
    })

    var m = modal({
      title: this.factory.name,
      content: view.$el,
      buttons: [],
      className: className || 'wide',
      clickOutsideToClose: false,
      clickOutsideEvent: 'dismiss', // So that it doesn't trigger 'cancel' event
    }).on('cancel', function () {
      view.handleCancel()
    })
  },
})
