const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (undefined, widgets) {
// iterate widgets
;(function(){
  var $$obj = widgets;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var widget = $$obj[$index];

buf.push("<li" + (jade.attr("data-type", widget.get('type'), true, false)) + " class=\"widget-list-item js-widget-list-item draggable\"><i class=\"icon icon--hamburger\"></i>" + (jade.escape((jade_interp = widget.get('name')) == null ? '' : jade_interp)) + "</li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var widget = $$obj[$index];

buf.push("<li" + (jade.attr("data-type", widget.get('type'), true, false)) + " class=\"widget-list-item js-widget-list-item draggable\"><i class=\"icon icon--hamburger\"></i>" + (jade.escape((jade_interp = widget.get('name')) == null ? '' : jade_interp)) + "</li>");
    }

  }
}).call(this);
}.call(this,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined,"widgets" in locals_for_with?locals_for_with.widgets:typeof widgets!=="undefined"?widgets:undefined));;return buf.join("");
}

module.exports = window.Backbone.View.extend({
  initialize: function () {
    if (!this.options.widgetDropArea) {
      throw new Error('Widget list requires a corresponding drop area')
    }
    this.render()
  },
  render: function () {
    this.$el.empty().append(template({ widgets: this.collection.toArray() }))
    this.$('.draggable').draggable({
      connectToSortable: this.options.widgetDropArea,
      addClasses: false,
      helper: 'clone',
      start: function (event, ui) {
        ui.helper.height(ui.helper.height()).width(ui.helper.width()) // drag dimensions
      },
    })
  },
})
