module.exports = SponsorService

const CrudService = require('../../../../admin/source/js/lib/crud-service')

function SponsorService() {
  this.name = 'SponsorService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/sponsors'
}

SponsorService.prototype = Object.create(CrudService.prototype)
