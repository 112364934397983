import editView from './views/form'
import model from './models/model'
import itemView from './views/item'

const initWidget = () => ({ resultsWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    name: 'Results',
    itemView: itemView,
    description: 'Display Results for a given squad and competition',
  }

  serviceLocator.widgetFactories.get('section').register('results', widget)

  done()
}

module.exports = initWidget
