const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/gallery')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const GalleryService = require('./services/gallery')

function initAdmin() {
  return { galleryAdmin: [ init ] }
}

function init(serviceLocator, cb) {
  serviceLocator.galleryService = new GalleryService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'gallery',
    plural: 'gallery',
    path: 'gallery',
    service: serviceLocator.galleryService,
    Model,
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
