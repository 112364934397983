module.exports = createController
const ListView = require('../views/list')
const Collection = require('chale')
const InstanceModel = require('../models/instance')
const Model = require('merstone')
const async = require('async')
const pageSize = 50

function createController(serviceLocator) {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0,
  })
  let currentPage = 1
  let currentParams = {
    keywords: '',
    filter: {},
    sort: [ 'dateCreated', 'desc' ],
  }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an instance is updated, reset the model with its new attributes
  serviceLocator.instanceService.on('update', function (id, attrs) {
    var model = collection.get(id)
    if (model) model.reset(attrs)
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.instanceService.on('create', function () {
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    getInstances(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  serviceLocator.router.route('instances(/)', 'listInstances', function () {
    if (!serviceLocator.allow('instance', 'discover')) return false

    getInstances(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )

    serviceLocator.accountService.cachedFind(
      '',
      {},
      [ 'name' ],
      { pageSize: 1000 },
      function (err, res) {
        if (err) throw err

        var list = new ListView(
          serviceLocator,
          collection,
          paginationModel,
          res.results
        ).render()

        list.displayFilterParams(currentParams)

        list.on('createNew', function () {
          if (!serviceLocator.allow('instance', 'create')) return false
          serviceLocator.router.navigate('instances/form', { trigger: true })
        })

        list.on('edit', function (id) {
          if (!serviceLocator.allow('instance', 'update')) return false
          serviceLocator.router.navigate('instances/' + id + '/form', {
            trigger: true,
          })
        })

        list.on('delete', function (ids) {
          if (!serviceLocator.allow('instance', 'delete')) return false
          async.each(ids, deleteOne, function (err) {
            if (err) return alert(err.message)
          })

          function deleteOne(id, cb) {
            serviceLocator.instanceService.delete(id, function (err) {
              if (err) return cb(err)
              collection.remove(id)
            })
          }
        })

        list.on('filter', function (params) {
          if (!serviceLocator.allow('instance', 'read')) return false
          currentParams = params
          var pagination = { page: currentPage, pageSize: pageSize }
          currentPage = 1
          getInstances(params.keywords, params.filter, params.sort, pagination)
        })

        list.on('loadMore', function () {
          if (!serviceLocator.allow('instance', 'read')) return false
          currentPage += 1
          var pagination = { page: currentPage, pageSize: pageSize }
          appendInstances(
            currentParams.keywords,
            currentParams.filter,
            currentParams.sort,
            pagination
          )
        })

        list.on('showRevisions', function (model) {
          serviceLocator.router.navigate(
            'instances/' + model.id + '/revisions',
            { trigger: true }
          )
        })

        serviceLocator.router.render(list, 'Instances')
      }
    )
  })

  function getInstances(keywords, filter, sort, pagination) {
    serviceLocator.instanceService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err)
          return serviceLocator.logger.error(err, 'Could not load instances')
        collection.reset(
          res.results.map(function (instance) {
            return new InstanceModel(serviceLocator, instance)
          })
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  function appendInstances(keywords, filter, sort, pagination) {
    serviceLocator.instanceService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err) return alert(err.message)
        res.results.forEach(function (instance) {
          collection.add(new InstanceModel(serviceLocator, instance))
        })
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }
}
