const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, title) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><button type=\"button\" class=\"btn js-cancel\">Cancel</button></div><div class=\"control-group\">");
if ( allowed('section', 'reorder'))
{
buf.push("<button type=\"button\" class=\"btn btn--action js-save\">Save</button>");
}
buf.push("</div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><div class=\"notification notification--notice js-order-info\">Drag " + (jade.escape((jade_interp = title.toLowerCase()) == null ? '' : jade_interp)) + " into the desired order then click Save</div><div class=\"dd\"><ol class=\"js-items dd-list\"></ol></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const BaseView = require('cf-base-view')
const ListItemView = require('./reorder-list-item')
const debug = require('../../../../admin/source/js/lib/debug')(
  'section reorder view'
)
const async = require('async')

module.exports = BaseView.extend({
  events: {
    'click .js-save': 'handleSaveOrder',
    'click .js-cancel': 'handleCancelOrder',
  },

  title: 'Sections',

  initialize: function () {
    debug('init view', this)

    this.collection.reset()
    this.render()
    this.listenTo(this.collection, 'add', this.addItem, this)
    this.listenTo(this.collection, 'change', this.updateItem, this)
    this.listenTo(this.collection, 'remove', this.removeItem, this)
  },

  addItem: function (model) {
    debug('add item', this, model)

    // Ignore root items
    if (model.get('root')) {
      return true
    }

    if (this.$('[data-id=' + model.id + ']').length !== 0) {
      // throw new Error('Item already on view')
      return
    }

    let $parent = this.$('.js-items')
    const parentId = model.get('parent')
    const a = $('<ol class="dd-list">')
    const newItem = this.createItemView(model)

    if (parentId) {
      $parent = this.$('.js-items [data-id=' + parentId + ']')
      if ($parent.find('ol').length > 0) {
        $parent = $parent.find('ol')
      } else {
        $parent.append(a)
        $parent = a
      }
    }

    $parent.append(
      $('<li class="dd-item" data-id=' + model.id + '>').append(newItem.$el)
    )
  },

  createItemView: function (model) {
    var newItem = new ListItemView({ model: model })
    this.attachView(newItem)
    return newItem
  },

  updateItem: function (model) {
    debug('update', model)

    // Ignore root items
    if (model.get('root')) {
      return true
    }

    const $current = this.$('[data-id=' + model.id + '] > div')
    const newItem = this.createItemView(model)

    if ($current.length !== 0) {
      $current.replaceWith(newItem.$el)
    } else {
      this.collection.reset()
      this.collection.fetch({ update: true })
    }
  },
  removeItem: function (model) {
    debug('remove', model)

    // Ignore root items
    if (model.get('root')) {
      return true
    }

    // var $current = this.$('[data-id=' + model.id + ']')
    this.$('[data-id=' + model.id + ']').remove()
  },

  handleSaveOrder: function () {
    let orderCounter = 1
    const self = this
    const newOrder = this.$('.dd').nestable('serialize')

    function successCallback() {
      self.trigger('save')
    }

    function processChildren(parent, item, cb) {
      async.each(
        item,
        function (childItem, done) {
          var model = self.collection.get(childItem.id)
          debug('setting ' + childItem.id + ' to parent ' + parent)
          model.save(
            { slug: model.get('slug'), parent: parent, order: orderCounter++ },
            {
              patch: true,
              success: function () {
                if (childItem.children) {
                  processChildren(childItem.id, childItem.children, done)
                } else {
                  done()
                }
              },
            }
          )
        },
        cb
      )
    }

    processChildren(null, newOrder, successCallback)
  },

  handleCancelOrder: function () {
    this.trigger('cancel')
  },

  handleNew: function () {
    this.trigger('new')
  },

  setupNestable: function () {
    this.$('.dd').nestable()
  },

  render: function () {
    this.$el
      .empty()
      .append(
        template({ title: this.title, allowed: window.serviceLocator.allowed })
      )
    this.setupNestable()
    return this
  },
})
