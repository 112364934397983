const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterFormView = require('../../../../widget/views/form/base-form-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div data-field=\"title\" class=\"form-row\"><label><span class=\"form-label-text\">Title<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"title\"" + (jade.attr("value", data.title, true, false)) + " class=\"control--text form-field\"/><div class=\"js-error\"></div></label></div></div></div><div class=\"grid js-body-container\"><div class=\"grid__item three-quarters\"><div id=\"section-body\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Body</h2></div><div class=\"panel-content\"><div class=\"panel js-body\"></div></div></div></div><div class=\"grid__item one-quarter\"><div id=\"sidebar-body-components\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Body Components</h2></div><div class=\"panel-content\"><p>Drag to build up body content</p><ul class=\"widget-list js-widget-list\"></ul></div></div></div></div></form><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}

const WidgetAreaView = require('../../../../widget/views/widget-area')
const WidgetListView = require('../../../../widget/views/widget-list')

class ItemRepeaterFormView extends BaseItemRepeaterFormView {
  constructor(...args) {
    super(...args)
    this.template = template
  }

  render() {
    this.$el.append(this.template({ data: this.model.toJSON() }))
    this.renderWidgetArea()
    return this
  }

  renderWidgetArea() {
    const widgetArea = new WidgetAreaView({
      model: this.model.body,
      serviceLocator: this.serviceLocator,
    })
    const widgetListView = new WidgetListView({
      collection: new window.Backbone.Collection(
        this.serviceLocator.widgetFactories.get('tabsBody').toArray()
      ),
      widgetDropArea: widgetArea.$el.find('.js-widgets'),
    })

    this.$el.find('.js-body').append(widgetArea.$el)
    this.$el.find('.js-widget-list').append(widgetListView.$el)
  }
}

module.exports = ItemRepeaterFormView
