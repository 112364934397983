module.exports = createController
const FormView = require('../views/form')
const Model = require('../models/instance')
const notify = require('../../notification/foreground')

function createController(serviceLocator) {
  // Edit
  serviceLocator.router.route('instances/:id/form', 'editInstance', function (
    id
  ) {
    if (!serviceLocator.allow('instance', 'update')) return false

    serviceLocator.instanceService.read(id, function (err, instance) {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      var form = new FormView(
        serviceLocator,
        new Model(serviceLocator, instance),
        false
      ).render()
      serviceLocator.router.render(form, 'Edit Instance')

      form.on('back', back)

      form.on('save', function () {
        saveExisting(id, form, function (err) {
          if (err) return
          notify('Saved', 'save')
        })
      })

      form.on('saveAndClose', function () {
        saveExisting(id, form, function (err) {
          if (err) return
          notify('Saved', 'save')
          serviceLocator.router.navigate('instances', { trigger: true })
        })
      })
    })
  })

  // Create
  serviceLocator.router.route('instances/form', 'createInstance', function () {
    if (!serviceLocator.allow('instance', 'create')) return false

    const model = new Model(serviceLocator)
    let form

    model.set(model.schema.makeDefault())

    form = new FormView(serviceLocator, model, true).render()

    serviceLocator.router.render(form, 'New Instance')

    form.on('back', back)

    form.on('save', function () {
      saveNew(form, function (err, saved) {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate('instances/' + saved._id + '/form', {
          trigger: true,
        })
      })
    })

    form.on('saveAndClose', function () {
      saveNew(form, function (err) {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate('instances', { trigger: true })
      })
    })
  })

  function saveExisting(id, form, cb) {
    serviceLocator.instanceService.update(id, form.model.toJSON(), function (
      err,
      instance
    ) {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.clearUnsavedChanges()
      form.clearErrors()
      cb(null, instance)
    })
  }

  function saveNew(form, cb) {
    serviceLocator.instanceService.create(form.model.toJSON(), function (
      err,
      instance
    ) {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.clearUnsavedChanges()
      cb(null, instance)
    })
  }

  function back() {
    serviceLocator.router.navigate('instances', { trigger: true })
  }
}
