const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const validateIfPropertyIn = require('validity-validate-if-property-in')
const requireOne = require('validity-require-one')
const validateIfPropertyEquals = require('validity-validate-if-property-equals')
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')
const validateIf = require('../../../lib/validators/validate-if')
const hasWidgets = require('../../../lib/has-widgets')
const types = require('./lib/commentary-types')
const commentaryTypesRequiringTeam = types.commentaryRequiringTeam()
const commentaryRequiringScoreUpdates = types.commentaryRequiringScoreUpdates()
const requiresSocialId = types.requiresSocialId().map(({ type }) => type)
const scoreUpdateCommentaryTypes = commentaryRequiringScoreUpdates.map(
  ({ type }) => type
)
const teamCommentaryTypes = commentaryTypesRequiringTeam.map(({ type }) => type)

const requireHeadingOrText = requireOne(
  ['heading', 'text'],
  "You must enter either 'Heading' or 'Text'"
)

module.exports = () => {
  const schema = schemata({
    name: 'Match commentary schema',
    properties: {
      _id: {
        type: String,
      },
      match: {
        type: String,
        validators: [required],
      },
      heading: {
        type: String,
        validators: [requireHeadingOrText],
      },
      type: {
        type: String,
        validators: [required],
      },
      team: {
        type: String,
        validators: [
          validateIfPropertyIn('type', teamCommentaryTypes, required),
        ],
      },
      text: {
        type: String,
        validators: [requireHeadingOrText],
      },
      homeScore: {
        type: Number,
        validators: [
          validateIfPropertyIn('type', scoreUpdateCommentaryTypes, required),
        ],
      },
      awayScore: {
        type: Number,
        validators: [
          validateIfPropertyIn('type', scoreUpdateCommentaryTypes, required),
        ],
      },
      minute: {
        type: Number,
        validators: [required],
      },
      socialId: {
        name: 'Social ID',
        type: String,
        validators: [validateIfPropertyIn('type', requiresSocialId, required)],
      },
      sendTweet: {
        type: Boolean,
      },
      short: {
        type: String,
        validators: [
          validateIfPropertyEquals('sendPush', true, required),
          validateIfPropertyEquals('sendTweet', true, required),
        ],
      },
      sendPush: {
        type: Boolean,
      },
      images: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: [
          validateIf(createContextValidator(['Background']), hasWidgets),
          validateIf(createCropValidator(['Free']), hasWidgets),
        ],
      },
      dateCreated: {
        type: Date,
        defaultValue: () => new Date(),
      },
    },
  })

  return schema
}
