module.exports = () => [
  {
    value: 200,
    text: 'Full Rugby Camp',
  },
  {
    value: 100,
    text: 'TAG Rugby Camp',
  },
]
