const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('motm', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label>");
}
buf.push("</div><h2><a" + (jade.attr("href", '/motms/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.title) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content\"><dl><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const BaseListItemView = require('../../base/views/list-item')

class ListItemView extends BaseListItemView {
  serialize() {
    const data = this.model.toJSON()
    return data
  }

  render() {
    this.$el.empty().append(
      template({
        data: this.model.toJSON(),
        allowed: this.serviceLocator.allowed,
        resource: this.resource,
        format: this.serviceLocator.format,
      })
    )
    return this
  }
}

module.exports = ListItemView
