module.exports = initAdmin
const MatchCommentaryService = require('./services/commentary')
const createFormController = require('./controllers/form')

function initAdmin() {
  return { commentaryAdmin: [ 'widget', init ] }
}

function init(serviceLocator, done) {
  serviceLocator.matchCommentaryService = new MatchCommentaryService(
    serviceLocator
  )

  createFormController(serviceLocator)
  done()
}
