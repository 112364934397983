import editView from './views/form'
import model from './models/model'
import itemView from './views/item'

const initWidget = () => ({ squadWidget: [ 'widget', 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    name: 'Squad',
    itemView: itemView,
    description: 'Squad',
  }

  serviceLocator.widgetFactories.get('section').register('squad', widget)

  done()
}

module.exports = initWidget
