const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterFormView = require('../../../../widget/views/form/base-form-repeater')
const RichTextInstanceManager = require('../../../../../../admin/source/js/lib/rich-text-editor-instance-manager')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div data-field=\"question\" class=\"form-row\"><label><span class=\"form-label-text\">Question<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"question\"" + (jade.attr("value", data.question, true, false)) + " class=\"control--text form-field\"/><div class=\"js-error\"></div></label></div><div id=\"field--answer\" data-field=\"answer\" class=\"form-row form-row-full-width\"><label><span class=\"vhidden\">Body<abbr title=\"This field is required\">*</abbr></span><textarea name=\"answer\" rows=\"10\" class=\"control control--text control--multiline js-text-editor form-field\">" + (jade.escape(null == (jade_interp = data.answer) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div></div></div></form><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}

class ItemRepeaterFormView extends BaseItemRepeaterFormView {
  constructor(...args) {
    super(...args)
    this.template = template

    this.richTextEditorManager = new RichTextInstanceManager()
    this.on(
      'save',
      this.richTextEditorManager.destroy.bind(this.richTextEditorManager)
    )
    this.on(
      'cancel',
      this.richTextEditorManager.destroy.bind(this.richTextEditorManager)
    )
  }

  renderRichTextEditors() {
    this.$('.js-text-editor').each((index, value) =>
      this.richTextEditorManager.create(value, {
        height: 300,
        startupFocus: true,
      })
    )
  }

  render() {
    this.$el.append(this.template({ data: this.model.toJSON() }))
    this.renderRichTextEditors()
    return this
  }
}

module.exports = ItemRepeaterFormView
