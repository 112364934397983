const View = require('ventnor')

class BaseItemRepeaterItemView extends View {
  constructor(serviceLocator, model) {
    super()
    this.serviceLocator = serviceLocator
    this.model = model
    this.$el.on('click', '.js-edit-item', this.handleEditItem.bind(this))
    this.$el.on('click', '.js-remove-item', this.handleRemoveItem.bind(this))
    this.template = () => {}
  }

  handleEditItem() {
    this.emit('editItem', this.model)
  }

  handleRemoveItem() {
    this.emit('removeItem', this.model)
  }

  render() {
    this.$el.append(
      this.template({
        data: this.model.toJSON(),
        id: this.model.id,
        noImage: this.model.noImage
      })
    )
    return this
  }
}

module.exports = BaseItemRepeaterItemView
