const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed) {
buf.push("<div class=\"page-content\"><div class=\"centering\"><div class=\"grid\"><div class=\"grid__item desktop-one-third\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Content</h2></div><div class=\"panel-content\"><ul>");
if ( allowed('asset', 'create'))
{
buf.push("<li><a href=\"/asset-manager\" data-route=\"asset-manager\">Add Assets</a></li>");
}
buf.push("</ul></div></div><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Accounts and Instances</h2></div><div class=\"panel-content\"><ul>");
if ( allowed('account', 'create'))
{
buf.push("<li><a href=\"/accounts/form\" data-route=\"accounts/form\">Add accounts</a></li>");
}
if ( allowed('instance', 'create'))
{
buf.push("<li><a href=\"/instances/form\" data-route=\"instances/form\">Add instances</a></li>");
}
buf.push("</ul></div></div></div><div class=\"grid__item desktop-one-third\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Additional Content</h2></div><div class=\"panel-content\"><ul>");
if ( allowed('sponsor', 'create'))
{
buf.push("<li><a href=\"/sponsors/form\" data-route=\"sponsors/form\">Add a Sponsor</a></li>");
}
if ( allowed('redirect', 'create'))
{
buf.push("<li><a href=\"/redirects/form\" data-route=\"redirects/form\">Add a Redirect</a></li>");
}
buf.push("</ul></div></div>");
if ( allowed('externalFeed', 'refresh'))
{
buf.push("<div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>External Feeds</h2><p>Refresh the data received from external feeds</p></div><div class=\"panel-content panel-content--padding-bottom\"><button type=\"button\" class=\"btn btn--small btn--block js-feed-fixtures\">Refresh Fixtures</button><button type=\"button\" class=\"btn btn--small btn--block js-feed-teams\">Refresh Teams</button><button type=\"button\" class=\"btn btn--small btn--block js-feed-players\">Refresh Players</button><button type=\"button\" class=\"btn btn--small btn--block js-feed-match-stats\">Refresh Match Stats</button><button type=\"button\" class=\"btn btn--small btn--block js-feed-tables\">Refresh Tables</button><button type=\"button\" class=\"btn btn--small btn--block js-feed-venues\">Refresh Venues</button></div></div>");
}
buf.push("</div><div class=\"grid__item desktop-one-third\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Administrators</h2></div><div class=\"panel-content\">");
if ( allowed('administrator', 'create') || allowed('administrator', 'read') || allowed('role', 'read'))
{
buf.push("<ul>");
if ( allowed('administrator', 'create'))
{
buf.push("<li><a href=\"/administrators/form\" data-route=\"administrators/form\">Add an Administrator</a></li>");
}
if ( allowed('administrator', 'read'))
{
buf.push("<li><a href=\"/administrators\" data-route=\"administrators\">View All Administrators</a></li>");
}
if ( allowed('role', 'read'))
{
buf.push("<li><a href=\"/roles\" data-route=\"roles\">Manage Roles</a></li>");
}
buf.push("</ul>");
}
buf.push("</div></div></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined));;return buf.join("");
}

module.exports = window.Backbone.View.extend({
  events: {
    'click [data-route]': 'handleRoute',
  },
  handleRoute: function (e) {
    // Detect middle click or CMD click to allow <a> to open in new tab
    if (e.which === 2 || e.metaKey) return
    e.preventDefault()
    this.trigger('route', $(e.target).attr('data-route'))
  },
  initialize: function () {
    this.render()
  },
  render: function () {
    this.$el.empty().append(
      template({
        config: window.config,
        allowed: this.options.serviceLocator.allowed,
      })
    )
    return this
  },
})
