module.exports = SquadSelectView
const View = require('ventnor')
const squadsData = require('../../../service/team/squads')
const find = require('lodash.find')

function SquadSelectView(
  serviceLocator,
  value,
  playable = true,
  isEditable = true
) {
  View.apply(this, arguments)
  this.value = value
  this.playable = playable
  this.isEditable = isEditable
  this.squads = this.playable ? squadsData.playable : squadsData.all
  if (this.isEditable) {
    this.$el = $('<select/>')
    this.$el.attr('name', 'squad')
    this.$el.addClass('control control--choice')
    this.$el.on('change', () => {
      this.emit('change', this.$el.val())
    })
    this.notSetLabel = '-- Select a Squad --'
  }
}

SquadSelectView.prototype = Object.create(View.prototype)

SquadSelectView.prototype.render = function () {
  if (this.isEditable) {
    this.$el.append($('<option/>', { text: this.notSetLabel, value: '' }))
    this.populate()
  } else {
    const squad = find(this.squads, { value: this.value })
    this.$el = $('<span/>').html(squad ? squad.text : '')
  }
  return this
}

SquadSelectView.prototype.populate = function () {
  if (this.isEditable) {
    this.squads.forEach(
      function (squad) {
        this.$el.append(
          $('<option/>', {
            text: squad.text,
            value: squad.value,
            selected: this.value === squad.value,
          })
        )
      }.bind(this)
    )
  }
  return this
}
