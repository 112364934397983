const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const extend = require('lodash.assign')
const required = require('@clocklimited/validity-required')
const validateInteger = require('validity-integer')
const validateIfSet = require('validity-validate-if-set')
const validateIfPropertySet = require('validity-validate-if-property-set')
const validateIfPropertyEquals = require('validity-validate-if-property-equals')

module.exports = () =>
  schemata({
    name: 'List',
    properties: {
      lists: { type: Array, validators: { all: [required] } },
      header: { type: String },
      headerLink: { type: String },
      emptyMessage: { type: String },
      dedupe: { type: Boolean, defaultValue: true },
      // The maximum number of items the view should show.
      // null means show as many and possible
      limit: {
        type: Number,
        validators: { all: [validateIfSet(validateInteger)] },
      },
      loadMore: { type: Boolean, defaultValue: false },
      loadMoreMethod: {
        type: String,
        defaultValue: '',
        validators: {
          all: [validateIfPropertyEquals('loadMore', true, required)],
        },
      },
      initialPageSize: {
        type: Number,
        defaultValue: 4,
        validators: {
          all: [validateIfPropertyEquals('loadMore', true, validateInteger)],
        },
      },
      loadMorePageSize: {
        type: Number,
        defaultValue: 8,
        validators: {
          all: [validateIfPropertyEquals('loadMore', true, validateInteger)],
        },
      },
      callToActionText: {
        type: String,
        validators: [validateIfPropertySet('callToActionLink', required)],
      },
      callToActionLink: {
        type: String,
      },
      showArticleProperties: {
        type: Array,
        defaultValue: function () {
          return ['attributes', 'sell']
        },
      },
      ...baseSchema.getProperties(),
    },
  })
