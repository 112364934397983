const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const validateInteger = require('validity-integer')
const validateIfPropertyEquals = require('validity-validate-if-property-equals')
const validateIfSet = require('validity-validate-if-set')
const isUrl = require('validity-url')
const validity = require('validity')
const displayTabsOptions = require('./display-tabs').values

const noDuplicatePlayers = (lineUp) => {
  if (!lineUp) return true
  const playerIds = lineUp.map(({ player }) => player)
  const isDuplicate = playerIds.some(
    (player, index) => playerIds.indexOf(player) !== index
  )
  return !isDuplicate
}

const uniqueValuesValidator =
  validity.createValidatorAllowingFailureMessageOverride(
    validity.booleanToCallback(noDuplicatePlayers),
    'Line up must not contain duplicate players'
  )

module.exports = (serviceLocator) => {
  return schemata({
    name: 'Match schema',
    properties: {
      _id: { type: String },
      competition: {
        type: String,
        validators: [required],
      },
      venue: {
        type: String,
        validators: [validateIfPropertyEquals('source', 'cms', required)],
      },
      season: {
        type: Number,
        validators: [required],
        defaultValue: () =>
          serviceLocator.seasonService &&
          serviceLocator.seasonService.getCurrentYear(),
      },
      dateTime: {
        type: Date,
        validators: [required],
      },
      provisionalStartDate: {
        type: Date,
      },
      provisionalEndDate: {
        type: Date,
      },
      provisionalStartTime: {
        type: String,
      },
      status: {
        type: String,
        validators: [required],
        defaultValue: () => 'Fixture',
      },
      optaFeedLevel: { type: Number },
      homeTeam: {
        type: String,
        validators: [required],
      },
      awayTeam: {
        type: String,
        validators: [required],
      },
      homeScore: {
        type: Number,
      },
      awayScore: {
        type: Number,
      },
      source: {
        type: String,
        defaultValue: () => 'cms',
      },
      squad: {
        type: String,
        validators: [required],
      },
      attendance: {
        type: Number,
        validators: [validateIfSet(validateInteger)],
      },
      coverage: { type: Array, defaultValue: () => [] },
      referee: { type: String },
      sell: { type: String },
      slug: { type: String },
      preMatchSummary: { type: String },
      lineUp: {
        type: Array,
        validators: [uniqueValuesValidator],
        defaultValue: () => [],
      },
      oppositionLineUp: {
        type: Array,
        validators: [uniqueValuesValidator],
        defaultValue: () => [],
      },
      stage: { type: String },
      ticketLink: {
        type: String,
        validators: [validateIfSet(isUrl)],
      },
      showInFixtures: { type: Boolean, default: false },
      showInResults: { type: Boolean, default: false },
      showInMatchdayLive: { type: Boolean, default: false },
      isLive: { type: Boolean },
      lastModifiedDate: { type: Date },
      createdDate: {
        type: Date,
        defaultValue: () => new Date(),
      },
      displayTabs: {
        type: Array,
        options: displayTabsOptions,
      },
    },
  })
}
