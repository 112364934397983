module.exports = HospitalityPackageService
const CrudService = require('../../../../admin/source/js/lib/crud-service')

function HospitalityPackageService() {
  this.name = 'hospitalityPackageService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/hospitality-packages'
}

HospitalityPackageService.prototype = Object.create(CrudService.prototype)
