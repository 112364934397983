module.exports = initWidget

function initWidget() {
  return { htmlWidget: [ 'widget', 'sectionAdmin', init ] }
}

function init(serviceLocator, done) {
  var htmlWidget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'HTML',
    itemView: require('./views/item'),
    description: 'Block of HTML',
  }

  serviceLocator.widgetFactories.get('section').register('html', htmlWidget)
  serviceLocator.widgetFactories.get('article').register('html', htmlWidget)
  serviceLocator.widgetFactories.get('articleBody').register('html', htmlWidget)
  serviceLocator.widgetFactories.get('tabsBody').register('html', htmlWidget)

  done()
}
