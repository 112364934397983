module.exports = SponsorSelect

var View = require('ventnor')

function SponsorSelect(serviceLocator, selectedSponsors) {
  View.apply(this, arguments)
  this.$el = $('<select multiple/>')
  this.$el.addClass('control control--choice control--multiline')
  this.el = this.$el[0]
  this.$el.attr('placeholder', 'Choose one or more sponsors')
  this.selectedSponsors = selectedSponsors || []
}

SponsorSelect.prototype = Object.create(View.prototype)

SponsorSelect.prototype.initializeSelectize = function () {
  this.serviceLocator.sponsorService.find(
    '',
    { _id: { $in: this.selectedSponsors } },
    [],
    {},
    function (err, res) {
      if (err) return alert('Cannot find existing sponsors')
      res.results.forEach(
        function (sponsor) {
          // The item needs to be added to the list
          // of selectize options in order to be selected
          this.el.selectize.addOption({
            value: sponsor._id,
            text: sponsor.name,
          })
          // Select the added option
          this.el.selectize.addItem(sponsor._id)
        }.bind(this)
      )
      this.el.selectize.on('change', this.updateSelection.bind(this))
    }.bind(this)
  )
}

SponsorSelect.prototype.updateSelection = function () {
  this.selectedSponsors = this.el.selectize.getValue()
  this.emit('change', this.selectedSponsors)
}

SponsorSelect.prototype.load = function (query, cb) {
  this.serviceLocator.sponsorService.find(query, {}, [], {}, function (
    err,
    data
  ) {
    if (err) return alert('Cannot load existing sponsors')
    cb(
      data.results.map(function (sponsor) {
        return { value: sponsor._id, text: sponsor.name }
      })
    )
  })
}

SponsorSelect.prototype.render = function () {
  setTimeout(
    function () {
      this.$el.selectize({
        delimiter: ',',
        create: false,
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this),
        preload: true,
      })
    }.bind(this),
    0
  )
  return this
}
