module.exports = ListView
const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')

function ListView(serviceLocator, collection, paginationModel, tagTypes) {
  this.tagTypes = tagTypes
  BaseListView.call(this, serviceLocator, collection, paginationModel)
}

ListView.prototype = Object.create(BaseListView.prototype)

ListView.prototype.name = { singular: 'Tag', plural: 'Tags', resource: 'tag' }

ListView.prototype.FilterView = FilterView
ListView.prototype.ListItemView = ListItemView
