module.exports = ListView
const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')

function ListView(serviceLocator, collection, paginationModel) {
  BaseListView.call(this, serviceLocator, collection, paginationModel)
}

ListView.prototype = Object.create(BaseListView.prototype)

ListView.prototype.name = {
  singular: 'Linked Keyword',
  plural: 'Linked Keywords',
  resource: 'linkedKeyword',
}

ListView.prototype.FilterView = FilterView
ListView.prototype.ListItemView = ListItemView
