module.exports = CompetitionSelectView
const View = require('ventnor')

function CompetitionSelectView(
  serviceLocator,
  value,
  season,
  isEditable = true,
  setAllOption = false
) {
  View.apply(this, arguments)
  this.value = value
  this.season = season
  this.isEditable = isEditable
  this.setAllOption = setAllOption
  this.competitions = []
  if (this.isEditable) {
    this.$el = $('<select/>')
    this.$el.attr('name', 'competition')
    this.$el.addClass('control control--choice')
    this.$el.on(
      'change',
      function () {
        this.emit('change', this.$el.val())
      }.bind(this)
    )
    this.notSetLabel = '-- Select a competition --'
  }
}

CompetitionSelectView.prototype = Object.create(View.prototype)

CompetitionSelectView.prototype.populate = function (season) {
  this.$el.val('').empty()
  this.$el.append($('<option/>', { text: this.notSetLabel, value: '' }))

  if (!season)
    this.competitions = getCompetitionsLatestSeason(this.competitions)

  const allOption = { text: 'All Senior', value: 'all' }

  if (this.setAllOption) this.competitions.unshift(allOption)

  this.competitions.forEach(
    function (competition) {
      if (
        season &&
        competition.season !== parseInt(season) &&
        !competition.text
      )
        return

      const optionText =
        competition.season && competition.name
          ? competition.season + ' - ' + competition.name
          : competition.text
      const optionValue = competition._id || competition.value
      const optionSelected = competition._id || competition.value

      this.$el.append(
        $('<option/>', {
          text: optionText,
          value: optionValue,
          selected: this.value === optionSelected,
        })
      )
    }.bind(this)
  )
  this.$el.trigger('change', '')
  return this
}

CompetitionSelectView.prototype.render = function () {
  if (this.isEditable) {
    this.serviceLocator.competitionService.find(
      '',
      {},
      [ 'name' ],
      { pageSize: 1000 },
      function (err, res) {
        if (err) {
          this.serviceLocator.logger.error(err, err.message)
          return this
        }
        const competitions = res.results.slice(0)
        // sort by season and then competition name
        this.competitions = competitions.sort((a, b) => {
          return b.season - a.season || compareString(a.name, b.name)
        })

        this.populate(this.season)
      }.bind(this)
    )
  } else {
    this.serviceLocator.competitionService.read(
      this.value,
      function (err, competition) {
        if (err) return this.serviceLocator.logger.error(err, err.message)
        this.$el.append(
          $('<span/>').html(
            competition ? competition.season + ' - ' + competition.name : ''
          )
        )
      }.bind(this)
    )
  }
  return this
}

// Competitions are already sorted by latest season first, return a list of competitions unique by there season
const getCompetitionsLatestSeason = (competitions) => {
  const competitionIds = []
  const filteredCompetitions = []
  competitions.forEach((competition) => {
    const competitionId = competition.optaId || competition._id
    if (competitionId && competitionIds.indexOf(competitionId) < 0) {
      competitionIds.push(competitionId)
      filteredCompetitions.push(competition)
    }
  })

  return filteredCompetitions
}

const compareString = (a, b) => {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}
