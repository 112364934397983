import FormView from './views/form'
import Model from './models/model'
import ItemView from '../../widget/views/item/edit-base'

const initWidget = () => ({
  hospitalitySidebarWidget: [ 'widget', 'sectionAdmin', init ],
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    name: 'Hospitality Sidebar',
    itemView: ItemView,
    description: 'Displays the upcoming Hospitality Packages in a sidebar',
  }

  // Register the widget for articles
  serviceLocator.widgetFactories
    .get('article')
    .register('hospitalitySidebar', widget)

  done()
}

module.exports = initWidget
