module.exports={
  "crops": [
    { "name": "Leaderboard", "aspectRatio": "728:90" },
    { "name": "Billboard", "aspectRatio": "97:25" },
    { "name": "MPU", "aspectRatio": "30:25" },
    { "name": "HPU", "aspectRatio": "3:6" },
    { "name": "Mobile Leaderboard", "aspectRatio": "472:74" }
  ],
  "contexts": [{ "name": "Main", "allowMultipleSelection": false }]
}
