import FormView from './views/form'
import Model from './models/model'
import ItemView from '../list/views/item'

const initWidget = () => ({
  nextArticleWidget: [ 'widget', 'listWidget', 'sectionAdmin', init ],
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    name: 'Next Article',
    itemView: ItemView,
    description: 'Displays the next article from this list',
  }

  ;[ 'article' ].forEach((factory) =>
    serviceLocator.widgetFactories.get(factory).register('nextArticle', widget)
  )

  done()
}

module.exports = initWidget
