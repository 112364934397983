module.exports = ListItemView
const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format) {
buf.push("<div class=\"list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-image: url("' + data.previewImageUrlSmall + '");', true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('event', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("</div>");
if ( allowed('event', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/events/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape((jade_interp = data.title) == null ? '' : jade_interp)) + " - " + (jade.escape((jade_interp = format(data.date, 'LLL')) == null ? '' : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2><" + (data.title) + ">- " + (jade.escape((jade_interp = format(data.date, 'LLL')) == null ? '' : jade_interp)) + "</" + (data.title) + "></h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Link:</dt><dd><a" + (jade.attr("href", data.ctaLink, true, false)) + " target=\"_blank\">" + (jade.escape((jade_interp = data.ctaLink) == null ? '' : jade_interp)) + "</a></dd><dd>Venue:</dd><dd>" + (jade.escape(null == (jade_interp = data.venue) ? "" : jade_interp)) + "</dd></dl><dl><dd>Available:</dd><dd>" + (jade.escape(null == (jade_interp = data.available ? 'Yes' : 'No') ? "" : jade_interp)) + "</dd><dt>Shards:</dt><dd>" + (jade.escape(null == (jade_interp = data.displayShards ? 'Yes' : 'No') ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const createImageUrlBuilder = require('cf-image-url-builder')
const config = window.config

function ListItemView() {
  BaseListItemView.apply(this, arguments)
}

ListItemView.prototype = Object.create(BaseListItemView.prototype)

ListItemView.prototype.template = template

ListItemView.prototype.serialize = function () {
  const images = this.model.get('images').widgets
  const data = this.model.toJSON()
  const drUrl = config.darkroom.url
  const drKey = config.darkroom.salt
  const urlBuilder = createImageUrlBuilder(
    drUrl,
    drKey,
    this.model.get('images').widgets
  )

  if (
    images &&
    images.length &&
    urlBuilder.getImage('Thumbnail') &&
    urlBuilder.getImage('Thumbnail').crop('Square')
  ) {
    data.previewImageUrlSmall = urlBuilder
      .getImage('Thumbnail')
      .crop('Square')
      .constrain(150)
      .url()
    data.previewImageUrlLarge = urlBuilder
      .getImage('Thumbnail')
      .crop('Square')
      .constrain(400)
      .url()
  } else {
    data.previewImageUrlSmall = '/assets/img/content/no-photo-medium.png'
    data.previewImageUrlLarge = '/assets/img/content/no-photo-large.png'
  }

  data.hasSection = typeof data.__fullUrlPath !== 'undefined'

  return data
}

ListItemView.prototype.render = function () {
  var data = this.serialize()

  this.$el.empty().append(
    template({
      data: data,
      allowed: this.serviceLocator.allowed,
      format: this.serviceLocator.format,
    })
  )

  this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
  return this
}
