const modal = require('modal')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<div class=\"list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"btn-group\"><a class=\"pull-right btn js-remove\"><i class=\"icon icon--cross--dark\"></i></a></div></div><h2><a class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.text) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content\"><dl><dt>Link:</dt><dd>" + (jade.escape(null == (jade_interp = data.link) ? "" : jade_interp)) + "</dd><dt>Active:</dt><dd>" + (jade.escape(null == (jade_interp = data.displayActive ? 'Yes' : 'No') ? "" : jade_interp)) + "</dd></dl></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const ItemFormView = require('../views/item-form')
const BaseWidgetView = require('../../../widget/views/form/base')

module.exports = BaseWidgetView.extend({
  events: Object.assign({}, BaseWidgetView.prototype.events, {
    'click .js-edit': 'handleEdit',
    'click .js-remove': 'handleRemove',
  }),

  initialize: function (model, serviceLocator) {
    this.model = model
    this.serviceLocator = serviceLocator
    this.render()
  },

  handleEdit: function (e) {
    e.preventDefault()
    const itemFormView = new ItemFormView(this.model, this.serviceLocator)
    this.modal = modal({
      title: 'Edit Item',
      className: 'wide',
      buttons: [],
      content: itemFormView.$el,
      clickOutsideToClose: false,
    })
    itemFormView.on(
      'save',
      function () {
        this.modal.close()
        this.trigger('re-render')
      }.bind(this)
    )
    itemFormView.on(
      'cancel',
      function () {
        this.modal.close()
      }.bind(this)
    )
  },

  handleRemove: function (e) {
    e.preventDefault()
    modal({
      content: 'Are you sure you want to delete this item?',
      buttons: [
        { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [ 27 ] },
        { text: 'Delete', event: 'confirm', className: 'btn btn--action' },
      ],
    }).on(
      'confirm',
      function () {
        this.trigger('remove', this.model.id)
      }.bind(this)
    )
  },

  render: function () {
    const data = this.model.toJSON()
    this.$el.empty().append(template({ data: data }))
    this.$el.addClass('js-selected-item list-item')
    this.$el.data('id', this.model.id)
  },
})
