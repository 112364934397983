const schemata = require('@clocklimited/schemata')
const validityRequired = require('@clocklimited/validity-required')

module.exports = function () {
  return schemata({
    name: 'SeasonStat',
    properties: {
      season: {
        type: String,
        validators: [validityRequired],
      },
      tries: {
        type: Number,
        validators: [validityRequired],
        defaultValue: 0,
      },
      played: {
        type: Number,
        validators: [validityRequired],
        defaultValue: 0,
      },
      conversions: {
        type: Number,
        validators: [validityRequired],
        defaultValue: 0,
      },
      dropGoals: {
        type: Number,
        validators: [validityRequired],
        defaultValue: 0,
      },
      penaltyKicks: {
        type: Number,
        validators: [validityRequired],
        defaultValue: 0,
      },
      yellowCards: {
        type: Number,
        validators: [validityRequired],
        defaultValue: 0,
      },
      redCards: {
        type: Number,
        validators: [validityRequired],
        defaultValue: 0,
      },
      points: {
        type: Number,
        validators: [validityRequired],
        defaultValue: 0,
      },
    },
  })
}
