import editView from './views/form'
import model from './models/model'
import itemView from '../../../widget/views/item/edit-base'

const initWidget = () => ({ formGuideWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Form Guide',
    description: 'Display form guide for match teams',
  }

  serviceLocator.widgetFactories.get('match').register('formGuide', widget)
  done()
}

module.exports = initWidget
