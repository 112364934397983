const Model = require('./models/model')
const Form = require('./views/form')
const ItemView = require('../../widget/views/item/edit-base')
const initWidget = () => ({ articleSearchWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: Form,
    itemView: ItemView,
    model: Model,
    name: 'Article Search',
    description: 'Article Search Inputs',
  }

  serviceLocator.widgetFactories
    .get('section')
    .register('articleSearch', widget)
  done()
}

module.exports = initWidget
