const mapFormToObject = require('cf-map-form-to-object')
const debug = require('../../../../../admin/source/js/lib/debug')(
  'widget form view'
)
const formErrorsDelegate =
  require('../../../../../admin/source/js/lib/form-errors-delegate')()
const formCancelDelegate = require('cf-form-cancel-delegate')
const widgetExpander = require('../../lib/widget-expander-toggle')(debug)
const RichTextInstanceManager = require('../../../../../admin/source/js/lib/rich-text-editor-instance-manager')
const clone = require('lodash.clonedeep')
const omit = require('lodash.omit')
const schemata = require('@clocklimited/schemata')
const dateTimePickers = require('../../../../../admin/source/js/lib/init-date-time-pickers')

module.exports = window.Backbone.View.extend({
  events: {
    'click .js-save': 'handleSave',
    'click .js-cancel': 'handleCancel',
    'click .js-expander-toggle': 'handleExpanderToggle',
    submit: function (e) {
      e.preventDefault()
    },
    'click .js-clear-date': 'handleClearDate',
  },
  handleClearDate: function (e) {
    $(e.currentTarget).closest('label').find('input').val('')
  },

  initialize: function () {
    this.initialModel = clone(this.model.toJSON())
    debug('setting initial model for cancel', this.initialModel)

    this.extraProperties = this.extraProperties || {}

    this.on(
      'cancel',
      function () {
        this.model.set(this.initialModel)
      }.bind(this)
    )

    this.dateTimePickers = []
    this.initDateTimePickers = dateTimePickers(
      window.config.locale.longDateFormat.LLLL,
      this.model
    )
    var removeTimePickers = function () {
      this.dateTimePickers.forEach((picker) => picker.destroy())
    }.bind(this)
    this.on('save', removeTimePickers)
    this.on('cancel', removeTimePickers)

    // The CK Editor instances need to be created and destroyed
    // when the form view is created and destroyed
    this.richTextEditorManager = new RichTextInstanceManager()
    this.on(
      'save',
      this.richTextEditorManager.destroy.bind(this.richTextEditorManager)
    )
    this.on(
      'cancel',
      this.richTextEditorManager.destroy.bind(this.richTextEditorManager)
    )

    this.render()
  },
  handleSave: function () {
    const model = this.model
    const view = this
    const schemaWithoutDates = schemata({
      name: 'schemaWithoutDates',
      properties: omit(
        model.schemata.getProperties(),
        'liveDate',
        'expiryDate'
      ),
    })

    const formData = this.addCustomFormData(
      mapFormToObject(this.$el.find('form'), schemaWithoutDates)
    )

    this.debug('handle save', formData)

    view.showErrors = view.showErrors || formErrorsDelegate.showErrors
    view.clearErrors = view.clearErrors || formErrorsDelegate.clearErrors

    model.validate(
      formData,
      function (errors) {
        model.validationError = errors
        if (model.validationError) {
          this.debug('validation failed', model.validationError, formData)
          view.showErrors(model.validationError)
        } else {
          // Silently updating then triggering a change event to
          // re-render
          // This is needed for widgets that only have image area
          // views, without this line the image updates do not get
          // re-rendered
          // Without the initial silent update, some views get
          // rendered twice which is not supported
          model.set(formData, { silent: true })
          model.trigger('change')
          this.trigger('save', model)
        }
      }.bind(this)
    )
  },

  // Override this function to add any additional form data to the model such as lists
  addCustomFormData: function (formData) {
    return formData
  },

  handleExpanderToggle: widgetExpander.toggleExpander,

  handleCancel: function () {
    formCancelDelegate(debug).apply(this)
  },
  render: function () {
    this.$el.empty().append(
      this.template(
        Object.assign(
          {
            className: 'base',
            data: this.model.toJSON(),
            format: this.options.serviceLocator.format,
          },
          this.extraProperties
        )
      )
    )

    this.dateTimePickers = this.$el
      .find('.js-date-time-picker')
      .toArray()
      .map(this.initDateTimePickers)
    this.trigger('afterRender')

    return this
  },
})
