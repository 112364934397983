module.exports = initWidget

function initWidget() {
  return { articleLayoutArticleHeaderWidget: [ 'widget', 'sectionAdmin', init ] }
}

function init(serviceLocator, done) {
  serviceLocator.widgetFactories
    .get('article')
    .register('articleLayoutHeader', {
      model: require('./models/model'),
      name: 'Article Header',
      itemView: require('./views/item'),
      editView: require('./views/form'),
      description: 'Placeholder for the article header',
    })

  done()
}
