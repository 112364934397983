import FormView from './views/form'
import ItemView from './views/item'
import Model from './models/model'

const initWidget = () => ({ listWidget: ['sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    itemView: ItemView,
    name: 'List',
    description: 'List',
  }

  ;['article', 'section', 'tabsBody'].forEach((factory) =>
    serviceLocator.widgetFactories.get(factory).register('list', widget)
  )

  done()
}

module.exports = initWidget
