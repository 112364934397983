const ListModel = require('../../list/models/model')
const baseSchema = require('../../list/models/schema')()
const schemata = require('@clocklimited/schemata')

const listModelSchema = schemata({
  name: 'Grid schema',
  properties: Object.assign({}, baseSchema.getProperties(), {
    width: {
      type: Number,
      defaultValue: null,
    },
  }),
})

module.exports = ListModel.extend({
  schemata: listModelSchema,
  type: 'grid',
  displayName: 'Grid',
  defaults() {
    return listModelSchema.makeDefault({ type: this.type || 'grid' })
  },
})
