module.exports={
  "crops": [
    { "name": "Square", "aspectRatio": "1:1" },
    { "name": "Portrait", "aspectRatio": "2:3" },
    { "name": "Landscape", "aspectRatio": "16:9" }
  ],
  "contexts": [
    { "name": "Main", "allowMultipleSelection": false },
    { "name": "Bio", "allowMultipleSelection": false },
    { "name": "Line Up", "allowMultipleSelection": false },
    { "name": "Gallery", "allowMultipleSelection": true }
  ]
}
