const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowQuestionDescription, allowReorder, data, inputTypes, responseRequired, showDelete, showInputType, showRequired, undefined) {
buf.push("<div class=\"panel panel-styled\"><div class=\"panel-header\">");
if ( allowReorder)
{
buf.push("<div class=\"panel-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div>");
}
buf.push("<h2 class=\"counter-question\">Question</h2><div class=\"panel-actions\"><input type=\"hidden\" name=\"type\"" + (jade.attr("value", data.type, true, false)) + "/>");
if ( showDelete)
{
buf.push("<a class=\"btn btn--small js-question-delete\">Delete Question</a>");
}
buf.push("</div></div><div class=\"panel-content\"><div id=\"field--questions-question\" data-field=\"question\" class=\"form-row\"><label><span class=\"form-label-text\">Question<abbr title=\"This field is required\">*</abbr></span><textarea type=\"text\" name=\"question\" rows=\"2\" class=\"control control--text control--multiline form-field\">" + (jade.escape(null == (jade_interp = data.question) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div>");
if ( allowQuestionDescription)
{
buf.push("<div id=\"field--questions-description\" data-field=\"description\" class=\"form-row\"><label><span class=\"form-label-text\">Description</span><textarea type=\"text\" name=\"description\" rows=\"2\" class=\"control control--text control--multiline form-field\">" + (jade.escape(null == (jade_interp = data.description) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Further information relating to the question, to be shown below the questions input.</p></div></div>");
}
if ( showInputType)
{
buf.push("<div id=\"field--questions-inputType\" data-field=\"inputType\" class=\"form-row\"><label><span class=\"form-label-text\">Input Type<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field\"><select name=\"inputType\" class=\"control control--choice form-field\">");
// iterate inputTypes
;(function(){
  var $$obj = inputTypes;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var input = $$obj[$index];

buf.push("<option" + (jade.attr("value", input.input, true, false)) + (jade.attr("selected", data.inputType === input.input, true, false)) + ">" + (jade.escape((jade_interp = input.name) == null ? '' : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var input = $$obj[$index];

buf.push("<option" + (jade.attr("value", input.input, true, false)) + (jade.attr("selected", data.inputType === input.input, true, false)) + ">" + (jade.escape((jade_interp = input.name) == null ? '' : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></div></label><div class=\"js-error\"></div></div>");
}
if ( showRequired)
{
buf.push("<div id=\"field--questions-required\" data-field=\"required\" class=\"form-row form-row-boolean\"><label><span class=\"form-label-text\">Required</span><div class=\"form-field\"><input type=\"checkbox\" name=\"required\"" + (jade.attr("checked", data.required, true, false)) + " class=\"control control--boolean\"/></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>If checked, this question must be answered.</p></div></div>");
}
buf.push("<hr/><h4>Responses</h4><div data-field=\"responses\"><div class=\"js-error\"></div></div><form class=\"js-response-input counter-answer-reset\">");
if ( responseRequired)
{
buf.push("<p class=\"js-no-responses\">Must have at least one response.</p>");
}
else
{
buf.push("<p class=\"js-no-responses\">Responses are not required for text or date inputs.</p>");
}
buf.push("</form><div class=\"form-row form-row-actions\"><a" + (jade.attr("disabled", (responseRequired ? null : 'disabled'), true, false)) + " class=\"btn btn--action js-response-add\">Add Response</a></div></div></div>");}.call(this,"allowQuestionDescription" in locals_for_with?locals_for_with.allowQuestionDescription:typeof allowQuestionDescription!=="undefined"?allowQuestionDescription:undefined,"allowReorder" in locals_for_with?locals_for_with.allowReorder:typeof allowReorder!=="undefined"?allowReorder:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"inputTypes" in locals_for_with?locals_for_with.inputTypes:typeof inputTypes!=="undefined"?inputTypes:undefined,"responseRequired" in locals_for_with?locals_for_with.responseRequired:typeof responseRequired!=="undefined"?responseRequired:undefined,"showDelete" in locals_for_with?locals_for_with.showDelete:typeof showDelete!=="undefined"?showDelete:undefined,"showInputType" in locals_for_with?locals_for_with.showInputType:typeof showInputType!=="undefined"?showInputType:undefined,"showRequired" in locals_for_with?locals_for_with.showRequired:typeof showRequired!=="undefined"?showRequired:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const ResponseModel = require('../models/response')
const ResponseView = require('./response')
const debug = require('../../../../admin/source/js/lib/debug')('question view')
const modal = require('modal')
const inputTypes = require('../../../service/form-builder/input-types').all()
const formErrorTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (error, undefined) {
if ( typeof error === 'object')
{
buf.push("<div class=\"form-copy form-error\"><ul>");
// iterate error
;(function(){
  var $$obj = error;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var err = $$obj[key];

buf.push("<li>" + (jade.escape(null == (jade_interp = err) ? "" : jade_interp)) + "</li>");
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var err = $$obj[key];

buf.push("<li>" + (jade.escape(null == (jade_interp = err) ? "" : jade_interp)) + "</li>");
    }

  }
}).call(this);

buf.push("</ul></div>");
}
else
{
buf.push("<div class=\"form-row-description form-row-error-text\">" + (jade.escape(null == (jade_interp = error) ? "" : jade_interp)) + "</div>");
}}.call(this,"error" in locals_for_with?locals_for_with.error:typeof error!=="undefined"?error:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class QuestionFormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    this.showInputType = true
    this.showRequired = true
    this.showDelete = true
    this.allowQuestionDescription = false
    this.allowReorder = true
    this.responseViews = []
    this.$el.addClass('js-question')
    this.$el.data('item', this.model)
    this.$el.on('click', '.js-response-add', this.handleResponseAdd.bind(this))
    this.$el.on('click', '.js-question-delete', this.handleDelete.bind(this))
    this.$el.on(
      'change',
      'select[name=inputType]',
      this.updateResponseButton.bind(this)
    )
    this.on('afterAppend', () => {
      this.$el.find('input[type=text]:first').focus()
    })
  }

  handleDelete() {
    modal({
      content: 'Are you sure you want to delete this question?',
      buttons: [
        { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [ 27 ] },
        { text: 'Delete', event: 'confirm', className: 'btn btn--error' },
      ],
    }).on('confirm', this.deleteQuestion.bind(this))
  }

  deleteQuestion() {
    this.trigger('remove', this.model.cid)
    this.remove()
  }

  showErrors(errors) {
    if (errors.question) {
      this.$('[data-field=question] .js-error').append(
        formErrorTemplate({ error: errors.question })
      )
    }

    if (errors.responses && typeof errors.responses === 'object') {
      Object.keys(errors.responses).forEach((key) => {
        this.responseViews[key].view.showErrors(errors.responses[key])
      })
    }

    if (errors.responses && typeof errors.responses === 'string') {
      this.$('[data-field=responses] .js-error').append(
        formErrorTemplate({ error: errors.responses })
      )
    }
  }

  toggleNoResponseText() {
    this.$('.js-response-input').find('.js-no-responses').toggle()
  }

  // Responses are not required for text, textarea and date inputs
  updateResponseButton() {
    const inputTypeValue = this.$('select[name=inputType]').val()
    const input = inputTypes.find((input) => input.input === inputTypeValue)

    if (input.requiresResponse) {
      this.$('.js-response-add').attr('disabled', false)
    } else {
      this.$('.js-response-add').attr('disabled', true)
    }

    this.updateResponseText(input.requiresResponse)
  }

  updateResponseText(responseRequired) {
    const responseText = responseRequired
      ? 'Must have at least one response.'
      : 'Responses are not required for text or date inputs.'
    this.$('.js-no-responses').text(responseText)
  }

  handleResponseAdd(response) {
    debug('add response input')
    if (this.type === 'free-text') this.setType('radio')
    const $responsesElement = this.$('.js-response-input')
    let model = response
    if (this.model.responses.models.length === 0) {
      this.toggleNoResponseText()
    }

    if (response.target) {
      model = new ResponseModel()
      this.model.responses.add(model)
    }

    const responseView = new ResponseView(this.serviceLocator, model)

    responseView.on('remove', (id) => {
      this.model.responses.remove(id)
      const i = this.responseViews.findIndex((view) => view.cid === id)
      this.responseViews.splice(i, 1)
      if (this.model.responses.models.length === 0) {
        this.toggleNoResponseText()
      }
    })

    this.responseViews.push({ cid: model.cid, view: responseView })

    $responsesElement.append(responseView.render().$el)
  }

  render() {
    debug('render')
    const questionInput = inputTypes.find(
      (input) => input.input === this.model.get('inputType')
    )
    this.$el.append(
      template({
        data: this.model.toJSON(),
        questionIndex: this.model.index,
        inputTypes: inputTypes,
        responseRequired: questionInput
          ? questionInput.requiresResponse
          : false,
        showInputType: this.showInputType,
        showRequired: this.showRequired,
        showDelete: this.showDelete,
        allowReorder: this.allowReorder,
      })
    )

    this.model.responses.models.forEach((response) => {
      this.handleResponseAdd(response)
    }, this)

    if (this.model.responses.models.length > 0)
      this.$('.js-no-responses').hide()

    return this
  }
}

module.exports = QuestionFormView
