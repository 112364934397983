const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const isUrl = require('validity-url')
const imageConfig = require('./image-config.json')
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')

module.exports = function () {
  const requiredContexts = imageConfig.contexts.map((context) => context.name)
  const requiredCrops = imageConfig.crops.map((crop) => crop.name)

  return schemata({
    name: 'Sponsor schema',
    properties: {
      _id: {
        type: String,
      },
      account: {
        type: String,
        validators: [required],
      },
      name: {
        type: String,
        validators: [required],
      },
      description: {
        type: String,
      },
      link: {
        type: String,
        validators: [required, isUrl],
      },
      playerSponsor: {
        type: Boolean,
        defaultValue: false,
      },
      images: {
        type: Object,
        validators: [
          createContextValidator(requiredContexts),
          createCropValidator(requiredCrops),
        ],
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date(),
      },
    },
  })
}
