import editView from '../../../widgets/article-layout/header/views/form'
import itemView from '../../../widgets/article-layout/header/views/item'
import model from './models/model'

const initWidget = () => ({ postMatchArticlesWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Post-match Articles',
    description: 'Display post-match articles',
  }

  serviceLocator.widgetFactories
    .get('match')
    .register('postMatchArticles', widget)
  done()
}

module.exports = initWidget
