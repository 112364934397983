module.exports = OptaPlayerService
const CrudService = require('../../../../admin/source/js/lib/crud-service')

function OptaPlayerService() {
  this.name = 'OptaPlayerService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/opta-players'
}

OptaPlayerService.prototype = Object.create(CrudService.prototype)
