module.exports = ListView
const BaseListView = require('../../base/views/list')

function ListView() {
  BaseListView.apply(this, arguments)
}

ListView.prototype = Object.create(BaseListView.prototype)

ListView.prototype.addListItem = function (model) {
  var listItem = BaseListView.prototype.addListItem.apply(this, arguments)
  this.listenTo(
    listItem,
    'showRevisions',
    this.emit.bind(this, 'showRevisions', model)
  )
  return listItem
}
