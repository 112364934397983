const schemata = require('@clocklimited/schemata')
const dateBeforePropertyValidator = require('validity-date-before-property')()

module.exports = function () {
  return schemata({
    name: 'Base widget schema',
    properties: {
      type: {
        type: String,
      },
      className: {
        type: String,
      },
      visible: {
        type: Boolean,
        defaultValue: true,
      },
      liveDate: {
        type: Date,
        validators: {
          all: [dateBeforePropertyValidator],
        },
      },
      expiryDate: {
        type: Date,
      },
      tiers: {
        type: Array,
      },
      displayOptions: {
        type: Array,
        defaultValue: () => ['desktop', 'tablet', 'mobile'],
        validators: [
          (key, keyDisplayName, object, cb) =>
            cb(
              null,
              object[key].length < 1
                ? 'At least one display option must be selected'
                : null
            ),
        ],
      },
      essential: {
        type: Boolean,
        defaultValue: true,
      },
    },
  })
}
