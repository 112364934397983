module.exports = ListFilterView
const compileJade = require('browjadify-compile')
const View = require('ventnor')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"panel panel-styled panel-fixed\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Order</span><select name=\"sort\" class=\"control control--choice form-field\"><option value=\"tag\">Keyword Name</option><option value=\"-dateCreated\">Newest First</option><option value=\"dateCreated\">Oldest First</option></select></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");;return buf.join("");
}

function ListFilterView(serviceLocator, listView) {
  View.apply(this, arguments)
  this.listView = listView
  this.$el.addClass('list-filters')
  this.$el.on('submit', 'form', this.handleSubmit.bind(this))
  this.$el.on('click', 'input[type=reset]', this.handleClear.bind(this))
}

ListFilterView.prototype = Object.create(View.prototype)

// Compatibility with backbone admin
ListFilterView.prototype.trigger = View.prototype.emit

ListFilterView.prototype.updateDisplay = function (params) {
  var backMap = {
    'dateCreated,asc': 'dateCreated',
    'dateCreated,desc': '-dateCreated',
    'score,desc': '-score',
  }

  if (Array.isArray(params.sort)) {
    this.$el
      .find('[name=sort] option[value=' + backMap[params.sort.join(',')] + ']')
      .attr('selected', true)
  }
}

ListFilterView.prototype.handleSubmit = function (e) {
  if (e) e.preventDefault()

  const params = { filter: {}, sort: [] }
  const map = {
    dateCreated: [ 'dateCreated', 'asc' ],
    '-dateCreated': [ 'dateCreated', 'desc' ],
    '-score': [ 'score', 'desc' ],
  }
  params.sort = map[this.$el.find('form [name=sort]').val()]

  this.emit('filter', params)
}

ListFilterView.prototype.handleClear = function (e) {
  e.preventDefault()
  this.$el.find('form [name=sort]').val('-dateCreated')
  this.$el.find('form [name=type] [value=""]').attr('selected', true)
  this.handleSubmit()
}

ListFilterView.prototype.render = function () {
  this.$el.empty().append(template({ types: this.listView.tagTypes }))
  this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
  return this
}
