import FormView from './views/form'
import Model from './models/model'
import ItemView from '../list/views/item'

const initWidget = () => ({
  gridWidget: ['widget', 'listWidget', 'sectionAdmin', init],
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    name: 'Grid',
    itemView: ItemView,
    description: 'Displays content in a grid',
  }

  ;['article', 'section', 'articleBody', 'tabsBody'].forEach((factory) =>
    serviceLocator.widgetFactories.get(factory).register('grid', widget)
  )

  done()
}

module.exports = initWidget
