module.exports = initWidget

function initWidget() {
  return { quoteWidget: [ 'widget', 'sectionAdmin', init ] }
}

function init(serviceLocator, done) {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('./views/item'),
    name: 'Quote',
    description: 'Display a quote within an article body',
  }

  serviceLocator.widgetFactories.get('articleBody').register('quote', widget)

  done()
}
