const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const competitionConfig =
  require('../../../../service/competition/competitions').all
const squads = require('../../../../service/team/squads').playable

module.exports = () =>
  schemata({
    name: 'Results',
    properties: {
      squad: {
        type: Array,
        options: squads,
        validators: [required],
      },
      competition: {
        type: String,
        options: competitionConfig,
        validators: [required],
      },
      infoHtml: {
        type: String,
      },
      ...baseSchema.getProperties(),
    },
  })
