module.exports = initWidget

function initWidget() {
  return { sponsorPicker: [ 'widget', 'sectionAdmin', 'sponsorAdmin', init ] }
}

function init(serviceLocator, done) {
  var widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Sponsor Selector',
    itemView: require('./views/item'),
    description:
      'Displays a selection of random Sponsors. Either most recently added or manually chosen.',
    options: {
      crops: [ { name: 'Free', aspectRatio: '*' } ],
      contexts: [ { name: 'Main', allowMultipleSelection: false } ],
    },
  }

  serviceLocator.widgetFactories
    .get('section')
    .register('sponsorPicker', widget)
  done()
}
