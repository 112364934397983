module.exports = createAcl

function createAcl(acl) {
  function allowed(targets, resource, action) {
    var target, i

    if (!Array.isArray(targets)) targets = [ targets ]

    for (i = 0; i < targets.length; i++) {
      target = targets[i]

      // Allow wildcard resource. This allows you to create a target with
      //    grant('root', '*', '*')
      // Who will always have access
      if (acl['*'].actions['*'].indexOf(target) !== -1) return true
      if (targetAllowed(target, resource, action)) return true
    }

    return false
  }

  function targetAllowed(target, resource, action) {
    return (
      acl[resource] &&
      acl[resource].actions[action] &&
      (acl[resource].actions[action].indexOf(target) !== -1 ||
        (acl[resource].actions['*'] &&
          acl[resource].actions['*'].indexOf(target) !== -1))
    )
  }

  return { allowed: allowed, acl: acl }
}
