const View = require('ventnor')

class AuthorSelect extends View {
  constructor(serviceLocator, selected) {
    super(serviceLocator)
    this.$el = $('<select id="authorId" />')
    this.$el.addClass('control control--choice control--multiline')
    this.el = this.$el[0]
    this.$el.attr('placeholder', 'Choose an author')
    this.selected = selected || []
  }

  initializeSelectize() {
    this.serviceLocator.authorService.find(
      '',
      { _id: this.selected },
      [],
      {},
      (err, res) => {
        if (err) return alert('Cannot find existing authors')
        res.results.forEach((author) => {
          // The item needs to be added to the list
          // of selectize options in order to be selected
          this.el.selectize.addOption({ value: author._id, text: author.name })
          // Select the added option
          this.el.selectize.addItem(author._id)
        })
        this.el.selectize.on('change', this.updateSelection.bind(this))
      }
    )
  }

  updateSelection() {
    this.selected = this.el.selectize.getValue()
    this.emit('change', this.selected)
  }

  load(query, cb) {
    this.serviceLocator.authorService.find(query, {}, [], {}, (err, data) => {
      if (err) return alert('Cannot load existing authors')
      cb(
        data.results.map((author) => ({ value: author._id, text: author.name }))
      )
    })
  }

  render() {
    setTimeout(() => {
      this.$el.selectize({
        delimiter: ',',
        create: false,
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this),
        preload: true,
      })
    }, 0)
    return this
  }
}

module.exports = AuthorSelect
