// WARNING!
// This file is generated by pliers. Do not manually
// edit its contents. Your changes WILL be overwritten!

module.exports = initComponents

const componentLoader = require('component-loader')
let components = [
  require('../../../components/admin/account/init.js')
, require('../../../components/admin/administrator/init.js')
, require('../../../components/admin/advert/init.js')
, require('../../../components/admin/api-error/init.js')
, require('../../../components/admin/article-types/standard-article/init.js')
, require('../../../components/admin/article/init.js')
, require('../../../components/admin/asset/init.js')
, require('../../../components/admin/author/init.js')
, require('../../../components/admin/competition/init.js')
, require('../../../components/admin/event/init.js')
, require('../../../components/admin/external-feed/init.js')
, require('../../../components/admin/form-builder/init.js')
, require('../../../components/admin/gallery/init.js')
, require('../../../components/admin/hospitality-package/init.js')
, require('../../../components/admin/index/init.js')
, require('../../../components/admin/instance/init.js')
, require('../../../components/admin/layout/init.js')
, require('../../../components/admin/linked-keywords/init.js')
, require('../../../components/admin/list/init.js')
, require('../../../components/admin/match-commentary/init.js')
, require('../../../components/admin/match/init.js')
, require('../../../components/admin/menu-bar/init.js')
, require('../../../components/admin/motm/init.js')
, require('../../../components/admin/player/init.js')
, require('../../../components/admin/poll/init.js')
, require('../../../components/admin/redirect/init.js')
, require('../../../components/admin/revision/init.js')
, require('../../../components/admin/role/init.js')
, require('../../../components/admin/rugby-camp/init.js')
, require('../../../components/admin/season/init.js')
, require('../../../components/admin/section/init.js')
, require('../../../components/admin/sponsor/init.js')
, require('../../../components/admin/style-guide/init.js')
, require('../../../components/admin/tag/init.js')
, require('../../../components/admin/team/init.js')
, require('../../../components/admin/venue/init.js')
, require('../../../components/admin/widget/init.js')
, require('../../../components/admin/widgets/advert/init.js')
, require('../../../components/admin/widgets/article-action-list/init.js')
, require('../../../components/admin/widgets/article-layout/body/init.js')
, require('../../../components/admin/widgets/article-layout/header/init.js')
, require('../../../components/admin/widgets/article-layout/images/init.js')
, require('../../../components/admin/widgets/article-layout/related-content/init.js')
, require('../../../components/admin/widgets/article-search/init.js')
, require('../../../components/admin/widgets/calendar/init.js')
, require('../../../components/admin/widgets/cover-grid/init.js')
, require('../../../components/admin/widgets/enquiry/init.js')
, require('../../../components/admin/widgets/events/init.js')
, require('../../../components/admin/widgets/filter/init.js')
, require('../../../components/admin/widgets/fixtures-and-results/init.js')
, require('../../../components/admin/widgets/form-builder/init.js')
, require('../../../components/admin/widgets/frequently-asked/init.js')
, require('../../../components/admin/widgets/full-standings/init.js')
, require('../../../components/admin/widgets/grid/init.js')
, require('../../../components/admin/widgets/heritage/init.js')
, require('../../../components/admin/widgets/hero/init.js')
, require('../../../components/admin/widgets/hospitality-sidebar/init.js')
, require('../../../components/admin/widgets/html/init.js')
, require('../../../components/admin/widgets/inline-image/init.js')
, require('../../../components/admin/widgets/junior-tigers-club-signup/init.js')
, require('../../../components/admin/widgets/list/init.js')
, require('../../../components/admin/widgets/match-centre/init.js')
, require('../../../components/admin/widgets/match-layout/commentary/init.js')
, require('../../../components/admin/widgets/match-layout/form-guide/init.js')
, require('../../../components/admin/widgets/match-layout/head-to-head-table/init.js')
, require('../../../components/admin/widgets/match-layout/jumbotron/init.js')
, require('../../../components/admin/widgets/match-layout/line-up/init.js')
, require('../../../components/admin/widgets/match-layout/match-details/init.js')
, require('../../../components/admin/widgets/match-layout/match-menu-and-score/init.js')
, require('../../../components/admin/widgets/match-layout/match-stats/init.js')
, require('../../../components/admin/widgets/match-layout/player-stats/init.js')
, require('../../../components/admin/widgets/match-layout/post-match-articles/init.js')
, require('../../../components/admin/widgets/match-layout/pre-match-articles/init.js')
, require('../../../components/admin/widgets/match-layout/pre-match-summary/init.js')
, require('../../../components/admin/widgets/match-layout/sell/init.js')
, require('../../../components/admin/widgets/match-layout/ticket-hero/init.js')
, require('../../../components/admin/widgets/maxi-grid/init.js')
, require('../../../components/admin/widgets/new-stripes/init.js')
, require('../../../components/admin/widgets/next-article/init.js')
, require('../../../components/admin/widgets/player-layout/bio/init.js')
, require('../../../components/admin/widgets/poll/init.js')
, require('../../../components/admin/widgets/pop-promotion/init.js')
, require('../../../components/admin/widgets/quote/init.js')
, require('../../../components/admin/widgets/related-articles/init.js')
, require('../../../components/admin/widgets/related-widgets/init.js')
, require('../../../components/admin/widgets/results/init.js')
, require('../../../components/admin/widgets/rugby-camp-finder/init.js')
, require('../../../components/admin/widgets/search/init.js')
, require('../../../components/admin/widgets/section-promo/init.js')
, require('../../../components/admin/widgets/share/init.js')
, require('../../../components/admin/widgets/sponsor-picker/init.js')
, require('../../../components/admin/widgets/squad/init.js')
, require('../../../components/admin/widgets/store/init.js')
, require('../../../components/admin/widgets/sub-navigation/init.js')
, require('../../../components/admin/widgets/tabs/init.js')
, require('../../../components/admin/widgets/text/init.js')
, require('../../../components/admin/widgets/video/init.js')
]

function initComponents(serviceLocator) {
  componentLoader(
    components,
    function (initFn) {
      return initFn.bind(null, serviceLocator)
    },
    function (error) {
      if (error) throw error
      serviceLocator.componentLoader.emit('complete')
    }
  )
}
