const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../../service/widget/model/base-widget')()
const validateDelegate =
  require('../../../../../../admin/source/js/lib/validate-delegate')()
const displayButtons =
  require('../../../../../service/ticket-hero/display-buttons').values

const schema = schemata({
  name: 'Ticket hero',
  properties: {
    displayButtons: {
      type: Array,
      options: displayButtons,
    },
    ...baseSchema.getProperties(),
  },
})

module.exports = BaseModel.extend({
  schemata: schema,

  defaults() {
    return schema.makeDefault({ type: this.type || 'ticketHero' })
  },

  validate: validateDelegate,
})
