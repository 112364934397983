const CrudService = require('../../../../admin/source/js/lib/crud-service')

class AccountService extends CrudService {
  get name() {
    return 'AccountService'
  }
  get urlRoot() {
    return '/accounts'
  }
}

module.exports = AccountService
