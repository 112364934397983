const BaseModel = require('cf-base-model')
const schemata = require('./schema')()
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

module.exports = BaseModel.extend({
  schemata: schemata,

  type: 'articleActionList',

  displayName: 'Article Action List',

  defaults() {
    return schemata.makeDefault({ type: this.type || 'articleActionList' })
  },

  validate: validateDelegate,
})
