const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/motm')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const MotmService = require('./services/motm')

const initAdmin = () => ({ motmAdmin: [ init ] })

const init = (serviceLocator, cb) => {
  serviceLocator.motmService = new MotmService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'motm',
    plural: 'motms',
    path: 'motms',
    service: serviceLocator.motmService,
    Model,
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
