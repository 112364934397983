module.exports = EventModel
const Model = require('merstone')
const createSchema = require('../../../service/event/schema')
const ImageAreaModel = require('../../asset/models/image-area')

function EventModel(serviceLocator, attributes) {
  Model.apply(this, arguments)
  this.schema = createSchema()
  this.attributes = this.schema.cast(attributes || {})

  this.hook(
    'preSet',
    function (attrs, cb) {
      cb(null, this.schema.cast(attrs))
    }.bind(this)
  )

  this.images = new ImageAreaModel(this.get('images'))

  this.images.on(
    'add remove change',
    function () {
      this.set('images', this.images.toJSON())
    }.bind(this)
  )
}

EventModel.prototype = Object.create(Model.prototype)

EventModel.prototype.validate = function (cb) {
  this.schema.validate(this.attributes, cb)
}

EventModel.prototype.reset = function (attrs) {
  attrs = this.schema.cast(attrs || {})
  Model.prototype.reset.call(this, attrs)
}
