const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/advert')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const AdvertService = require('./services/advert')

function initAdmin() {
  return { advertAdmin: [init] }
}

function init(serviceLocator, cb) {
  serviceLocator.advertService = new AdvertService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'advert',
    plural: 'adverts',
    path: 'adverts',
    service: serviceLocator.advertService,
    Model,
    currentParams: {
      keywords: '',
      filter: {},
      sort: ['createdDate', 'desc'],
    },
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
