const sectionSchema = require('./schema')
const schemata = require('@clocklimited/schemata')
const pick = require('lodash.pick')

module.exports = function () {
  return schemata({
    name: 'Lite schema',
    properties: pick(
      sectionSchema().getProperties(),
      '_id',
      'name',
      'slug',
      'className',
      'displayInNav',
      'displayInSecondaryNav',
      'visible',
      'liveDate',
      'expiryDate',
      'parent',
      'fullUrlPath',
      'root'
    ),
  })
}
