module.exports = BrowserView
const View = require('ventnor')
const extend = require('lodash.assign')
const Pagination = require('../../base/views/pagination')
const Collection = require('chale')
const Model = require('merstone')
const SponsorModel = require('../models/sponsor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"js-filters\"></div><div class=\"list-container\"></div><div class=\"list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div>");;return buf.join("");
}
const ListItemView = require('./browser-list-item')
const ListFilterView = require('./list-filter')

function BrowserView(serviceLocator) {
  View.apply(this, arguments)

  extend(this, Pagination.prototype)
  Pagination.call(
    this,
    new Model(serviceLocator, { totalItems: 0, showing: 0 })
  )

  this.collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  this.currentPage = 1
  this.pageSize = 25
  this.currentParams = {
    keywords: '',
    filter: {},
    sort: [ 'dateCreated', 'desc' ],
  }

  this.listenTo(this.collection, 'add', this.addListItem.bind(this))
  this.listenTo(this.collection, 'remove', this.removeListItem.bind(this))
  this.listenTo(this.collection, 'reset', this.resetListItems.bind(this))

  this.$el.on('click', '.js-more', this.loadMore.bind(this))

  this.createFilterView()

  this.getSponsors(
    this.currentParams.keywords,
    this.currentParams.filter,
    this.currentParams.sort,
    { page: this.currentPage, pageSize: this.pageSize }
  )
}

BrowserView.prototype = Object.create(View.prototype)

BrowserView.prototype.createFilterView = function () {
  this.filters = new ListFilterView(this.serviceLocator, this, 'condensed')
  this.filters.on('filter', this.filter.bind(this))
  this.attachView(this.filters)
}

BrowserView.prototype.addListItem = function (model) {
  var listItem = new ListItemView(this.serviceLocator, model)
  this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
  this.attachView(listItem)
  this.$el.find('.js-items').append(listItem.render().$el)
}

BrowserView.prototype.resetListItems = function (models, previousModels) {
  previousModels.forEach(this.removeListItem.bind(this))
  models.forEach(this.addListItem.bind(this))
}

BrowserView.prototype.removeListItem = function (model) {
  var view = this.getViewByModelId(model.id)
  if (!view) return
  view.remove()
}

BrowserView.prototype.render = function () {
  this.$el.append(template())
  this.$el.find('.js-filters').append(this.filters.render().$el)
  this.updatePagination()
  return this
}

BrowserView.prototype.getSponsors = function (
  keywords,
  filter,
  sort,
  pagination
) {
  this.serviceLocator.sponsorService.find(
    keywords,
    filter,
    sort,
    pagination,
    function (err, res) {
      if (err) return alert(err.message)
      this.collection.reset(
        res.results.map(
          function (sponsor) {
            return new SponsorModel(this.serviceLocator, sponsor)
          }.bind(this)
        )
      )
      this.updatePaginationModel(res.totalItems, this.collection.models.length)
    }.bind(this)
  )
}

BrowserView.prototype.updatePaginationModel = function (
  total,
  collectionTotal
) {
  this.paginationModel.set('totalItems', total)
  this.paginationModel.set('showing', collectionTotal)
}

BrowserView.prototype.appendSponsors = function (
  keywords,
  filter,
  sort,
  pagination
) {
  this.serviceLocator.sponsorService.find(
    keywords,
    filter,
    sort,
    pagination,
    function (err, res) {
      if (err) return alert(err.message)
      res.results.forEach(
        function (sponsor) {
          this.collection.add(new SponsorModel(this.serviceLocator, sponsor))
        }.bind(this)
      )
      this.updatePaginationModel(res.totalItems, this.collection.models.length)
    }.bind(this)
  )
}

BrowserView.prototype.filter = function (params) {
  this.currentParams = params
  var pagination = { page: this.currentPage, pageSize: this.pageSize }
  this.currentPage = 1
  this.getSponsors(params.keywords, params.filter, params.sort, pagination)
}

BrowserView.prototype.loadMore = function () {
  this.currentPage += 1
  var pagination = { page: this.currentPage, pageSize: this.pageSize }
  this.appendSponsors(
    this.currentParams.keywords,
    this.currentParams.filter,
    this.currentParams.sort,
    pagination
  )
}
