module.exports = initWidget

function initWidget() {
  return { lineUpWidget: [ 'sectionAdmin', init ] }
}

function init(serviceLocator, done) {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('../../../widget/views/item/edit-base'),
    name: 'Line-up',
    description: 'Display match team line-ups',
  }

  serviceLocator.widgetFactories.get('match').register('lineUp', widget)
  done()
}
