module.exports = FormView
const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (isEditable, title, venue) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form>");
if ( !isEditable)
{
buf.push("<div class=\"notification notification--notice\"><p>This venue is maintained via the opta feed and only some fields will be editable.</p></div>");
}
buf.push("<div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">Name<abbr title=\"This field is required\">*</abbr></span>");
if ( isEditable)
{
buf.push("<input type=\"text\" name=\"name\"" + (jade.attr("value", venue.name, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/>");
}
else
{
buf.push("<span class=\"control form-field\">" + (jade.escape(null == (jade_interp = venue.name) ? "" : jade_interp)) + "</span>");
}
buf.push("</label><div class=\"js-error\"></div></div><div id=\"field--capacity\" data-field=\"capacity\" class=\"form-row\"><label><span class=\"form-label-text\">Capacity</span>");
if ( isEditable)
{
buf.push("<input type=\"number\" capacity=\"capacity\"" + (jade.attr("value", venue.capacity, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/>");
}
else
{
buf.push("<span class=\"control form-field\">" + (jade.escape(null == (jade_interp = venue.capacity) ? "" : jade_interp)) + "</span>");
}
buf.push("</label><div class=\"js-error\"></div></div></div></div><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Location</h2></div><div class=\"panel-content\"><div id=\"field--city\" data-field=\"city\" class=\"form-row\"><label><span class=\"form-label-text\">City</span>");
if ( isEditable)
{
buf.push("<input type=\"text\" name=\"city\"" + (jade.attr("value", venue.city, true, false)) + " class=\"control control--text form-field\"/>");
}
else
{
buf.push("<span class=\"control form-field\">" + (jade.escape(null == (jade_interp = venue.city) ? "" : jade_interp)) + "</span>");
}
buf.push("</label><div class=\"js-error\"></div></div><div id=\"field--street\" data-field=\"street\" class=\"form-row\"><label><span class=\"form-label-text\">Street</span>");
if ( isEditable)
{
buf.push("<input type=\"text\" name=\"street\"" + (jade.attr("value", venue.street, true, false)) + " class=\"control control--text form-field\"/>");
}
else
{
buf.push("<span class=\"control form-field\">" + (jade.escape(null == (jade_interp = venue.street) ? "" : jade_interp)) + "</span>");
}
buf.push("</label><div class=\"js-error\"></div></div><div id=\"field--country\" data-field=\"country\" class=\"form-row\"><label><span class=\"form-label-text\">Country</span>");
if ( isEditable)
{
buf.push("<input type=\"text\" name=\"country\"" + (jade.attr("value", venue.country, true, false)) + " class=\"control control--text form-field\"/>");
}
else
{
buf.push("<span class=\"control form-field\">" + (jade.escape(null == (jade_interp = venue.country) ? "" : jade_interp)) + "</span>");
}
buf.push("</label><div class=\"js-error\"></div></div><div id=\"field--longitude\" data-field=\"longitude\" class=\"form-row\"><label><span class=\"form-label-text\">Longitude</span>");
if ( isEditable)
{
buf.push("<input type=\"number\" name=\"longitude\"" + (jade.attr("value", venue.longitude, true, false)) + " class=\"control control--text form-field\"/>");
}
else
{
buf.push("<span class=\"control form-field\">" + (jade.escape(null == (jade_interp = venue.longitude) ? "" : jade_interp)) + "</span>");
}
buf.push("</label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>E.g. -1.13283</p></div></div><div id=\"field--latitude\" data-field=\"latitude\" class=\"form-row\"><label><span class=\"form-label-text\">Latitude</span>");
if ( isEditable)
{
buf.push("<input type=\"number\" name=\"latitude\"" + (jade.attr("value", venue.latitude, true, false)) + " class=\"control control--text form-field\"/>");
}
else
{
buf.push("<span class=\"control form-field\">" + (jade.escape(null == (jade_interp = venue.latitude) ? "" : jade_interp)) + "</span>");
}
buf.push("</label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>E.g. 52.6242</p></div></div></div></div></form></div></div>");}.call(this,"isEditable" in locals_for_with?locals_for_with.isEditable:typeof isEditable!=="undefined"?isEditable:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"venue" in locals_for_with?locals_for_with.venue:typeof venue!=="undefined"?venue:undefined));;return buf.join("");
}
const slug = require('slugg')

function FormView(serviceLocator, model, account, isNew) {
  BaseFormView.call(this, serviceLocator, model, isNew)

  this.$el.on('change', '[name=name]', this.generateSlug.bind(this))

  this.on(
    'afterAppend',
    function () {
      this.$el.find('input[type=text]:first').focus()
    }.bind(this)
  )
}

FormView.prototype = Object.create(BaseFormView.prototype)

FormView.prototype.generateSlug = function () {
  const name = this.$el.find('[name=name]').val()
  const $slug = this.$el.find('[name=slug]')
  if (!$slug.val()) $slug.val(slug(name)).change()
}

FormView.prototype.render = function () {
  // Render the template
  this.$el.append(
    template({
      title: this.isNew ? 'New Venue' : 'Edit Venue',
      venue: this.model.toJSON(),
      format: this.serviceLocator.format,
      isEditable: this.model.isEditable(),
    })
  )

  // Render the toolbar
  this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

  return this
}
