const baseSchema = require('../../../../../service/widget/model/base-widget')()
const articleSchema = require('./schema')()
const schemata = require('@clocklimited/schemata')
const validateDelegate =
  require('../../../../../../admin/source/js/lib/validate-delegate')()

const widgetSchema = schemata({
  name: 'Article layout schema',
  properties: Object.assign(
    {},
    baseSchema.getProperties(),
    articleSchema.getProperties()
  ),
})

module.exports = window.Backbone.Model.extend({
  schemata: widgetSchema,
  defaults() {
    return widgetSchema.makeDefault({ type: 'articleLayoutHeader' })
  },
  validate: validateDelegate,
})
