import View from 'ventnor'
import moment from 'moment'
import getMatchDateTime from '../../../service/match/lib/get-match-date-time'

class MatchSelect extends View {
  constructor(serviceLocator, selected, options) {
    super(serviceLocator)
    this.$el = $('<select id="matchId" />')
    this.$el.addClass('control control--choice control--multiline')
    this.el = this.$el[0]
    this.$el.attr('placeholder', 'Choose a match')
    this.selected = selected || []
    this.options = options || {}
  }

  initializeSelectize() {
    const pagination = { page: 1, pageSize: 500 }
    this.serviceLocator.matchService.find(
      '',
      { _id: this.selected },
      [],
      pagination,
      (err, res) => {
        if (err) return alert('Cannot find existing matches')
        res.results.forEach((match) => {
          // The item needs to be added to the list
          // of selectize options in order to be selected
          this.el.selectize.addOption({
            value: match._id,
            text: this.formatSelectText(match),
            date: getMatchDateTime(match),
          })
          // Select the added option
          this.el.selectize.addItem(match._id)
        })
        this.el.selectize.on('change', this.updateSelection.bind(this))
      }
    )
  }

  formatSelectText(match) {
    const homeTeam = match.__homeTeam && match.__homeTeam.name || 'TBC'
    const awayTeam = match.__awayTeam && match.__awayTeam.name || 'TBC'
    const dateFormat = this.serviceLocator.config.locale.longDateFormat.LLL
    const matchDate = moment(getMatchDateTime(match)).format(dateFormat)
    const competition = match.__competition && match.__competition.name ? match.__competition.name : 'NONE'
    return `${matchDate} - ${homeTeam} vs ${awayTeam} (${competition})`
  }

  updateSelection() {
    this.selected = this.el.selectize.getValue()
    this.emit('change', this.selected)
  }

  load(query, cb) {
    const pagination = { page: 1, pageSize: 1000 }

    this.serviceLocator.matchService.find(
      query,
      {},
      [],
      pagination,
      (err, data) => {
        if (err) return alert('Cannot load existing matches')
        let results = data.results

        if (this.options.hideMatchesWithoutOpponent) {
          results = results.filter(
            (match) => match.__homeTeam && match.__awayTeam
          )
        }

        if (this.options.hideMatchesWithoutCompetition) {
          results = results.filter((match) => match.__competition)
        }

        cb(
          results.map((match) => ({
            value: match._id,
            text: this.formatSelectText(match),
            date: getMatchDateTime(match),
          }))
        )
      }
    )
  }

  render() {
    setTimeout(() => {
      this.$el.selectize({
        create: false,
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this),
        sortField: [ { field: 'date', direction: 'desc' } ],
        preload: true,
        selectOnTab: true,
      })
    }, 0)
    return this
  }
}

module.exports = MatchSelect
