const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')

module.exports = function () {
  return schemata({
    name: 'Role schema',
    properties: {
      _id: { type: String },
      name: { type: String, validators: { all: [required] } },
      grants: { type: Object, validators: { all: [required] } },
      created: { type: Date, defaultValue: () => new Date() },
    },
  })
}
