module.exports = SeasonSelectView
const View = require('ventnor')
const find = require('lodash.find')

function SeasonSelectView(serviceLocator, value, isEditable = true) {
  View.apply(this, arguments)
  this.isEditable = isEditable
  this.seasons = this.serviceLocator.seasonService.getSeasons(true)
  this.value = value
  if (this.isEditable) {
    this.$el = $('<select/>')
    this.$el.attr('name', 'season')
    this.$el.addClass('control control--choice')
    this.$el.on(
      'change',
      function () {
        this.emit('change', this.$el.val())
      }.bind(this)
    )
    this.notSetLabel = '-- Select a Season --'
  }
}

SeasonSelectView.prototype = Object.create(View.prototype)

SeasonSelectView.prototype.render = function () {
  if (this.isEditable) {
    this.$el.append($('<option/>', { text: this.notSetLabel, value: '' }))
    this.populate()
  } else {
    const season = find(this.seasons, { year: parseInt(this.value) })
    this.$el = $('<span/>').html(season ? season.text : '')
  }
  return this
}

SeasonSelectView.prototype.populate = function () {
  if (this.isEditable) {
    this.seasons.forEach(
      function (season) {
        this.$el.append(
          $('<option/>', {
            text: season.text,
            value: season.year,
            selected: +this.value === +season.year,
          })
        )
      }.bind(this)
    )
  }
  return this
}
