const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')

module.exports = () =>
  schemata({
    name: 'Quote',
    properties: {
      attributed: {
        type: String,
      },
      content: {
        type: String,
        validators: [required],
      },
      position: {
        type: String,
        validators: [required],
      },
      ...baseSchema.getProperties(),
    },
  })
