module.exports = SectionService
const CrudService = require('../../../../admin/source/js/lib/crud-service')

function SectionService() {
  this.name = 'SectionService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/sections'
}

SectionService.prototype = Object.create(CrudService.prototype)

SectionService.prototype.find = function (
  keywords,
  filter,
  sort,
  pagination,
  projection,
  cb
) {
  var query = {
    keywords: keywords,
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
    pagination: JSON.stringify(pagination),
    projection: JSON.stringify(projection),
  }
  if (cb === undefined) cb = projection
  this.authedRequest(
    'GET',
    this.urlRoot,
    query,
    function (err, res, body) {
      if (err) return cb(err)
      if (res.statusCode >= 300)
        return this.handleError(res.statusCode, body, cb)
      cb(null, body)
    }.bind(this)
  )
}
