import editView from './views/form'
import model from './models/model'
import itemView from '../../../widget/views/item/edit-base'

const initWidget = () => ({ jumbotronWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Jumbotron',
    description: 'Display a live match score and timeline of events',
  }

  serviceLocator.widgetFactories.get('match').register('jumbotron', widget)
  done()
}

module.exports = initWidget
