module.exports = createController
const FormView = require('../views/form')
const Model = require('../models/linked-keyword')
const notify = require('../../notification/foreground')

function createController(serviceLocator) {
  // Edit
  serviceLocator.router.route(
    'linked-keywords/:id/form',
    'editLinkedKeyword',
    function (id) {
      if (!serviceLocator.allow('linkedKeyword', 'update')) return false

      serviceLocator.linkedKeywordService.read(id, function (
        err,
        linkedKeyword
      ) {
        if (err) return serviceLocator.router.trigger('notFound', err.message)

        var form = new FormView(
          serviceLocator,
          new Model(serviceLocator, linkedKeyword),
          false
        ).render()
        serviceLocator.router.render(form, 'Edit Keyword')

        form.on('back', back)

        form.on('save', function () {
          saveExisting(id, form, function (err) {
            if (err) return
            notify('Saved', 'save')
          })
        })

        form.on('saveAndClose', function () {
          saveExisting(id, form, function (err) {
            if (err) return
            notify('Saved', 'save')
            serviceLocator.router.navigate('linked-keywords', { trigger: true })
          })
        })
      })
    }
  )

  // Create
  serviceLocator.router.route(
    'linked-keywords/form',
    'createLinkedKeyword',
    function () {
      if (!serviceLocator.allow('linkedKeyword', 'create')) return false

      const model = new Model(serviceLocator)
      const form = new FormView(serviceLocator, model, true).render()

      model.set(model.schema.makeDefault())

      serviceLocator.router.render(form, 'New Keyword List')

      form.on('back', back)

      form.on('save', function () {
        saveNew(form, function (err, saved) {
          if (err) return
          notify('Saved', 'save')
          serviceLocator.router.navigate(
            'linked-keywords/' + saved._id + '/form',
            { trigger: true }
          )
        })
      })

      form.on('saveAndClose', function () {
        saveNew(form, function (err) {
          if (err) return
          notify('Saved', 'save')
          serviceLocator.router.navigate('linked-keywords', { trigger: true })
        })
      })
    }
  )

  function saveExisting(id, form, cb) {
    serviceLocator.linkedKeywordService.update(
      id,
      form.model.toJSON(),
      function (err, tag) {
        if (err) {
          form.showErrors(err.errors)
          return cb(err)
        }
        form.clearUnsavedChanges()
        form.clearErrors()
        cb(null, tag)
      }
    )
  }

  function saveNew(form, cb) {
    serviceLocator.linkedKeywordService.create(form.model.toJSON(), function (
      err,
      tag
    ) {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.clearUnsavedChanges()
      cb(null, tag)
    })
  }

  function back() {
    serviceLocator.router.navigate('linked-keywords', { trigger: true })
  }
}
