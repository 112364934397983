const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('match', 'delete'))
{
if ( data.isLive)
{
buf.push("<span class=\"label label--large label--notice\">Live</span>");
}
buf.push("<button type=\"button\" class=\"btn btn--small js-commentary\">Live Match Details</button><label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("</div>");
if ( allowed('match', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/matches/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape((jade_interp = data.__homeTeam.name) == null ? '' : jade_interp)) + " vs " + (jade.escape((jade_interp = data.__awayTeam.name) == null ? '' : jade_interp)) + " - " + (jade.escape((jade_interp = format(data.dateTime, 'LLL')) == null ? '' : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2><" + (data.__homeTeam.name) + ">vs " + (jade.escape((jade_interp = data.__awayTeam.name) == null ? '' : jade_interp)) + " - " + (jade.escape((jade_interp = format(data.dateTime, 'LLL')) == null ? '' : jade_interp)) + "</" + (data.__homeTeam.name) + "></h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Status:</dt><dd>" + (jade.escape(null == (jade_interp = data.status) ? "" : jade_interp)) + "</dd>");
if ( data.__competition)
{
buf.push("<dt>Competition:</dt><dd>" + (jade.escape(null == (jade_interp = data.__competition.name) ? "" : jade_interp)) + "</dd>");
}
if ( data.__venue)
{
buf.push("<dt>Venue:</dt><dd>" + (jade.escape(null == (jade_interp = data.__venue.name) ? "" : jade_interp)) + "</dd>");
}
buf.push("<dt>Squad:</dt><dd>" + (jade.escape(null == (jade_interp = data.squad) ? "" : jade_interp)) + "</dd><dt>Source:</dt><dd>" + (jade.escape(null == (jade_interp = data.source) ? "" : jade_interp)) + "</dd></dl></div>");
if ( data.slug && data._showSlug)
{
buf.push("<div class=\"list-item-content\"><dl><dt>Slug:</dt><dd><a" + (jade.attr("href", data._url, true, false)) + " target=\"_blank\">" + (jade.escape((jade_interp = data.slug) == null ? '' : jade_interp)) + "</a></dd></dl></div>");
}
buf.push("</div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}

class ListItemView extends BaseListItemView {
  constructor(...args) {
    super(...args)
    this.$el.on('click', '.js-commentary', this.redirectToCommentary.bind(this))
  }
  render() {
    const data = this.model.toJSON()
    const tbcTeamId = this.serviceLocator.config.tbcTeamId
    data._url = this.serviceLocator.config.url + data.slug
    data._showSlug =
      data.slug && data.homeTeam !== tbcTeamId && data.awayTeam !== tbcTeamId

    if (!data.__homeTeam) {
      data.__homeTeam = { name: 'TBC' }
    }

    if (!data.__awayTeam) {
      data.__awayTeam = { name: 'TBC' }
    }

    this.$el.empty().append(
      template({
        data,
        allowed: this.serviceLocator.allowed,
        format: this.serviceLocator.format,
      })
    )

    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }

  redirectToCommentary() {
    this.emit('commentary')
  }

  get template() {
    return template
  }
}

module.exports = ListItemView
