const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()
const schema = schemata({
  name: 'Sub nav item model schema',
  properties: {
    text: {
      type: String,
      validators: {
        all: [required],
      },
    },
    link: {
      type: String,
      validators: {
        all: [required],
      },
    },
    displayActive: {
      type: Boolean,
    },
  },
})

module.exports = BaseModel.extend({
  schemata: schema,
  defaults() {
    return schema.makeDefault()
  },
  validate: validateDelegate,
})
