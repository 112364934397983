const CrudService = require('../../../../admin/source/js/lib/crud-service')

class PollService extends CrudService {
  constructor(...args) {
    super(...args)
    this.name = 'pollService'
    this.urlRoot = '/polls'
  }
}

module.exports = PollService
