const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, limitRequired) {
buf.push("<div class=\"grid\"><div class=\"grid__item three-fifths js-selected-items\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Manually Picked Content</h2><div class=\"panel-actions\"><span class=\"label label--large\">Drag in items to populate the list.</span></div></div><div class=\"panel-content\">");
if ( limitRequired)
{
buf.push("<div id=\"field--limit\" class=\"form-row\"><label><span class=\"form-label-text\">Limit<abbr title=\"This field is required\">*</abbr></span><input type=\"number\" name=\"limit\"" + (jade.attr("value", data.limit, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>The maximum amount of content that should be pulled into this list.</p></div></div>");
}
buf.push("<div class=\"form-row\"><label><input type=\"Submit\" value=\"New Item\" class=\"btn js-new-item\"/></label></div><div class=\"js-selected-item-list drop-area\"></div></div></div></div><div class=\"grid__item two-fifths\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Content Search</h2></div><div class=\"panel-content\"><div class=\"js-item-browser\"></div></div></div></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"limitRequired" in locals_for_with?locals_for_with.limitRequired:typeof limitRequired!=="undefined"?limitRequired:undefined));;return buf.join("");
}
const ArticleBrowser = require('../../article/views/browser')
const Collection = require('chale')
const ArticleModel = require('../../article/models/article')
const ListItemModel = require('../models/item')
const CustomItemModel = require('../models/custom-item')
const ManualItem = require('./manual-item')
const modal = require('modal')
const async = require('async')
const CustomItemFormView = require('./custom-item-form')

const loadItem = (view, toDelete) => (item, cb) => {
  const overridesModel = new ArticleModel(view.serviceLocator, item.overrides)
  if (item.isCustom) {
    const contentModel = new CustomItemModel(
      view.serviceLocator,
      item.properties
    )
    return cb(
      null,
      new ListItemModel(view.serviceLocator, item, contentModel, overridesModel)
    )
  }

  view.serviceLocator.articleService.read(item.itemId, (err, attrs) => {
    if (err) {
      toDelete.push(item.itemId)
      return cb(null)
    }
    const contentModel = new ArticleModel(view.serviceLocator, attrs)
    cb(
      null,
      new ListItemModel(view.serviceLocator, item, contentModel, overridesModel)
    )
  })
}

class ManualFormView extends View {
  constructor(serviceLocator, model, limitRequired, ...args) {
    super(serviceLocator, model, limitRequired, ...args)
    this.model = model

    this.limitRequired = limitRequired !== false

    this.itemBrowser = new ArticleBrowser(serviceLocator)

    this.manualItems = new Collection(serviceLocator, [], [ 'remove' ])
    this.loadManualItems()

    this.$el.on('change', this.applyChangeToModel.bind(this))

    this.listenTo(this.manualItems, 'model:remove', this.removeItem.bind(this))
  }

  applyChangeToModel(e) {
    if (this.model.schema.getProperties().hasOwnProperty(e.target.name)) {
      this.model.set(e.target.name, e.target.value)
    }
  }

  render() {
    this.$el.empty().append(
      template({
        data: this.model.toJSON(),
        limitRequired: this.limitRequired,
      })
    )
    this.renderManualItems()

    this.$el.find('.js-item-browser').append(this.itemBrowser.render().$el)

    // Create droppable and add on drop event
    this.$el
      .find('.js-selected-item-list')
      .sortable({
        handle: '.js-sort-handle',
        cursor: 'move',
        addClasses: false,
        connectWith: '.js-item-drag',
      })
      .off('sortupdate')
      .on('sortupdate', (event, ui) => {
        if (!ui.item.hasClass('js-selected-item')) this.handleDrop(event, ui)
        var order = []
        this.$el.find('.js-selected-item').map(function (index, el) {
          order.push($(el).data('item').get('itemId'))
        })
        this.model.setOrder(order)
      })
    this.$el.find('.js-new-item').on('click', (e) => {
      e.preventDefault()
      const customItemModel = new CustomItemModel(this.serviceLocator)
      const view = new CustomItemFormView(this.serviceLocator, customItemModel)
      const customItemModal = modal({
        title: 'New Custom Item',
        className: 'wide',
        buttons: [],
        content: view.render().$el,
        clickOutsideToClose: false,
      })
      view.on('error', () => customItemModal.centre())
      view.on('save', () => {
        const items = this.model.get('items') || []
        const newItem = {
          itemId: customItemModel.id,
          isCustom: true,
          properties: customItemModel.toJSON(),
        }
        items.push(newItem)
        this.model.set('items', items)
        const model = new ListItemModel(
          this.serviceLocator,
          newItem,
          customItemModel
        )
        this.manualItems.add(model)
        this.addItem(model)
        customItemModal.close()
      })
      view.on('cancel', () => customItemModal.close())
    })
    return this
  }

  editCustomItem(model) {
    const view = new CustomItemFormView(this.serviceLocator, model.contentModel)
    const customItemModal = modal({
      title: 'Edit Custom Item',
      className: 'wide',
      content: view.render().$el,
      buttons: [],
    })
    view.on('save', () => {
      const items = this.model.get('items')
      const item = items.find((item) => item.itemId === model.get('itemId'))
      item.properties = model.contentModel.toJSON()
      this.model.set('items', items)
      this.$el.find('.js-selected-item-list').html('')
      this.renderManualItems()
      customItemModal.close()
    })
    view.on('cancel', () => customItemModal.close())
  }

  handleDrop(event, ui) {
    /* eslint-disable one-var */
    const itemId = ui.item.attr('data-itemId')
    const exists = this.manualItems.models.some(
      (model) => model.get('itemId') === itemId
    )

    if (exists) {
      ui.item.remove()
      modal({
        title: 'Duplicate item',
        content: 'This item is already in the list',
        buttons: [ { text: 'Dismiss', className: 'btn', keyCodes: [ 27 ] } ],
      })
      return
    }

    const items = this.model.get('items') || []
    const newItem = { itemId: itemId, isCustom: false }
    items.push(newItem)
    this.model.set('items', items)

    const contentModel = this.itemBrowser.collection.get(itemId)
    const overridesModel = new ArticleModel(this.serviceLocator, {})
    const manualListItemModel = new ListItemModel(
      this.serviceLocator,
      newItem,
      contentModel,
      overridesModel
    )

    this.addItem(manualListItemModel, ui.item)
    this.manualItems.add(manualListItemModel)
  }

  loadManualItems() {
    const items = this.model.get('items')
    // Keep track of any missing items
    const toDelete = []

    if (!Array.isArray(items)) return

    async.map(items, loadItem(this, toDelete), (err, items) => {
      if (err) return alert(err.message)

      // Remove any items that couldn't be found
      const remainingItems = this.model
        .get('items')
        .filter((item) => toDelete.indexOf(item.itemId) === -1)
      this.model.set('items', remainingItems)
      this.manualItems.reset(items.filter((item) => !!item))
    })
  }

  renderManualItems() {
    this.manualItems.models.forEach(this.addItem.bind(this))
    this.manualItems.on('reset', this.renderManualItems.bind(this))
  }

  addItem(model, placeholder) {
    if (typeof placeholder === 'number') placeholder = null
    var view = new ManualItem(this.serviceLocator, model)
    if (placeholder) {
      placeholder.replaceWith(view.render().$el)
    } else {
      this.$el.find('.js-selected-item-list').append(view.render().$el)
    }
    view.$el.find('.js-edit').on('click', () => this.editCustomItem(model))
    this.attachView(view)
  }

  removeItem(model) {
    this.model.set(
      'items',
      this.model
        .get('items')
        .filter((item) => item.itemId !== model.get('itemId'))
    )
    this.manualItems.remove(model.cid)
  }
}

module.exports = ManualFormView
