module.exports = initWidget

function initWidget() {
  return { preMatchSummaryWidget: [ 'sectionAdmin', init ] }
}

function init(serviceLocator, done) {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('../../../widget/views/item/edit-base'),
    name: 'Pre-match Summary',
    description: 'Display pre-match summary',
  }

  serviceLocator.widgetFactories
    .get('match')
    .register('preMatchSummary', widget)
  done()
}
