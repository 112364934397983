module.exports = Toolbar
const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (canPreview, section) {
buf.push("<div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\"><button type=\"button\" class=\"btn js-btn-back\">Back to Layouts</button></div></div><div class=\"control-group\"><div class=\"btn-group\"><button type=\"button\" class=\"btn js-btn-expand-all\">Expand All</button><button type=\"button\" class=\"btn js-btn-collapse-all\">Collapse All</button></div></div><div class=\"control-group\"><div class=\"btn-group\"><button type=\"button\" class=\"btn js-btn-save\">Save</button><a data-toggle=\"dropdown\" href=\"#\" class=\"btn dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a href=\"#\" class=\"js-btn-save-and-close\">Save and Close</a></li>");
if ( canPreview)
{
buf.push("<li><a href=\"#\" class=\"js-btn-save-and-preview\">Save and " + (jade.escape((jade_interp = section.visible ? 'View' : 'Preview') == null ? '' : jade_interp)) + "</a></li>");
}
buf.push("</ul></div></div></div>");}.call(this,"canPreview" in locals_for_with?locals_for_with.canPreview:typeof canPreview!=="undefined"?canPreview:undefined,"section" in locals_for_with?locals_for_with.section:typeof section!=="undefined"?section:undefined));;return buf.join("");
}
const modal = require('modal')
const equal = require('lodash.isequal')

function Toolbar(serviceLocator, layoutDescriptor, layoutView) {
  View.apply(this, arguments)
  this.layoutDescriptor = layoutDescriptor
  this.layoutView = layoutView
  this.$el.addClass('toolbar')

  this.$el.on('click', '.js-btn-back', this.confirmBack.bind(this))
  this.$el.on('click', '.js-btn-save', this.emit.bind(this, 'save'))

  this.$el.on(
    'click',
    '.js-btn-save-and-close',
    this.emit.bind(this, 'saveAndClose')
  )
  this.$el.on(
    'click',
    '.js-btn-save-and-preview',
    this.emit.bind(this, 'saveAndPreview')
  )

  this.$el.on(
    'click',
    '.js-btn-collapse-all',
    this.emit.bind(this, 'collapseAll')
  )
  this.$el.on('click', '.js-btn-expand-all', this.emit.bind(this, 'expandAll'))
}

Toolbar.prototype = Object.create(View.prototype)

Toolbar.prototype.render = function () {
  this.$el.empty().append(
    template({
      section: this.layoutDescriptor.parent,
      allowed: this.serviceLocator.allowed,
      canPreview: this.layoutDescriptor.type === 'section',
    })
  )
  return this
}

Toolbar.prototype.confirmDraft = function () {
  modal({
    title: 'Revert to Draft',
    content: 'This article will be removed from public view. Are you sure?',
    buttons: [
      { text: 'Remain Published', event: 'cancel', className: 'btn' },
      {
        text: 'Revert to Draft',
        event: 'confirm',
        className: 'btn btn--warning',
      },
    ],
  }).on('confirm', this.emit.bind(this, 'draft'))
}

Toolbar.prototype.confirmBack = function () {
  if (equal(this.layoutView.initialModel, this.layoutView.layout.toJSON()))
    return this.emit('back')
  modal({
    title: 'You have unsaved changes',
    content: 'Would you like to continue editing, or discard these changes?',
    buttons: [
      {
        text: 'Continue editing',
        event: 'continue',
        className: 'btn btn--success',
        keyCodes: [ 27 ],
      },
      { text: 'Discard changes', event: 'discard', className: 'btn' },
    ],
  }).on('discard', this.emit.bind(this, 'back'))
}

Toolbar.prototype.showSaveNotification = function () {
  const $el = this.$el.find('.js-btn-save').addClass('disabled')
  const previousText = $el.text()
  $el.text('Saved ✔︎')
  setTimeout(function () {
    $el.removeClass('disabled').text(previousText)
  }, 3000)
}
