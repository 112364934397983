module.exports = initWidget

function initWidget() {
  return { articleLayoutBodyWidget: [ 'widget', 'sectionAdmin', init ] }
}

function init(serviceLocator, done) {
  serviceLocator.widgetFactories.get('article').register('articleLayoutBody', {
    model: require('./models/model'),
    name: 'Article Body',
    description: 'Placeholder for article body widgets',
  })

  done()
}
