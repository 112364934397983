import editView from './views/form'
import model from './models/model'
import itemView from '../../widget/views/item/edit-base'

const initWidget = () => ({ newStripesWidget: ['sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'New Stripes',
    description: 'New Stripes',
  }

  serviceLocator.widgetFactories.get('section').register('newStripes', widget)
  done()
}

module.exports = initWidget
