const BaseModel = require('cf-base-model')
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()
const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const validateInteger = require('validity-integer')
const validateIfSet = require('validity-validate-if-set')

const schema = schemata({
  name: 'Video',
  properties: {
    videoId: { type: String, validators: { all: [required] } },
    provider: { type: String, validators: { all: [required] } },
    header: { type: String },
    width: { type: String, validators: { all: [required] } },
    position: { type: String, validators: { all: [required] } },
    layout: {
      type: String,
      validators: { all: [required] },
      defaultValue: 'Carousel',
    },
    limit: { type: Number, validators: [validateIfSet(validateInteger)] },
    ...baseSchema.getProperties(),
  },
})

module.exports = BaseModel.extend({
  schemata: schema,

  type: 'video',

  defaults() {
    return schema.makeDefault({ type: this.type || 'video' })
  },

  validate: validateDelegate,

  initialize: function () {},
})
