const compileJade = require('browjadify-compile')
const mapFormToObject = require('cf-map-form-to-object')
const modal = require('modal')
const join = require('path').join
const formErrorsDelegate =
  require('../../../../../admin/source/js/lib/form-errors-delegate')()
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<form><div class=\"panel\"><div class=\"js-errors-summary\"></div><div id=\"field--name\" class=\"form-row\"><label><span class=\"form-label-text\">Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"name\"" + (jade.attr("value", data.name, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--price\" class=\"form-row\"><label><span class=\"form-label-text\">Price<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"price\"" + (jade.attr("value", data.price, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>E.g. £2.99 or This is now £2.99</p></div></div><div id=\"field--link\" class=\"form-row\"><label><span class=\"form-label-text\">Link<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"link\"" + (jade.attr("value", data.link, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Image</h2></div><div class=\"panel-content\"><div class=\"js-image-area\"></div><div class=\"form-row form-row-actions\"><input type=\"submit\" value=\"Choose Image\" class=\"btn btn-success js-choose-image\"/></div></div></div><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div></div></form>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const ItemModel = require('../models/item-model')
const AssetCollection = require('../../../asset/collections/asset')
const AssetPickerView = require('../../../asset/views/picker')
const getImageFactory = require('../../../asset/lib/image-factory')()
const WidgetAreaView = require('../../../widget/views/widget-area')
const crops = [{ name: 'Square', aspectRatio: '1:1' }]

module.exports = window.Backbone.View.extend({
  events: {
    'click .js-cancel-item': 'handleCancel',
    'click .js-save-item': 'handleSave',
    'click .js-choose-image': 'handleChooseImage',
  },

  initialize: function (model, serviceLocator) {
    this.serviceLocator = serviceLocator
    this.model = model || new ItemModel()
    this.render()
  },

  handleChooseImage: function (e) {
    e.preventDefault()
    const assets = new AssetCollection()
    const assetPicker = new AssetPickerView({
      collection: assets,
      type: 'image',
      serviceLocator: this.serviceLocator,
    })

    assets.getByType('image')
    modal({
      title: 'Image',
      className: 'modal-asset-view wide',
      content: assetPicker.$el,
      buttons: [
        { text: 'Cancel', event: 'Cancel', keyCodes: [27], className: 'btn' },
        {
          text: 'Add Selected Image',
          event: 'add',
          className: 'btn btn--action',
          keyCodes: [13],
        },
      ],
    })
      .on('add', this.addImage.bind(this, assetPicker.selectedCollection))
      .on('close', assetPicker.remove.bind(assetPicker))
  },

  addImage: function (images) {
    const factory = getImageFactory('image')
    const Model = factory.model
    images.map(function (model) {
      var image = new Model(model.attributes)
      this.model.images.add(image)
      image.setDefaultCrops(crops)
      return image
    }, this)
  },

  renderImageSelector: function () {
    var imageView = new WidgetAreaView({
      model: this.model.images,
      receiveDrops: false,
      serviceLocator: this.options.serviceLocator,
      widgetItemOptions: { crops: crops, contexts: [{ name: 'Main' }] },
    })

    this.$('.js-image-area').append(imageView.$el)
  },
  handleCancel: function (e) {
    e.preventDefault()
    this.trigger('cancel')
  },

  handleSave: function (e) {
    e.preventDefault()
    const formData = mapFormToObject(this.$el.find('form'), this.model.schemata)
    formData.image = this.model.images.toJSON()

    this.showErrors = formErrorsDelegate.showErrors
    this.clearErrors = formErrorsDelegate.clearErrors

    this.model.validate(
      formData,
      function (errors) {
        this.model.validationError = errors
        if (this.model.validationError) {
          this.showErrors(this.model.validationError)
        } else {
          this.model.set(formData, { silent: true })
          this.trigger('save', this.model)
        }
      }.bind(this)
    )
  },

  render: function () {
    this.$el
      .empty()
      .append(template({ data: this.model ? this.model.toJSON() : {} }))
    this.renderImageSelector()
  },
})
