const initWidget = () => ({ bioWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    model: require('./models/model'),
    name: 'Bio',
    description: 'Display biographical information about a player',
  }

  serviceLocator.widgetFactories.get('player').register('bio', widget)
  done()
}

module.exports = initWidget
