import PlayerSelector from './player-selector'

class FreeTextPlayerSelect extends PlayerSelector {
  formatSelectValue(player) {
    return `${player.firstName} ${player.lastName}`
  }

  formatSelectText(player) {
    return `${player.firstName} ${player.lastName}`
  }
}

module.exports = FreeTextPlayerSelect
