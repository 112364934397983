const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')

module.exports = () => {
  return schemata({
    name: 'Motm schema',
    properties: {
      _id: {
        type: String,
      },
      title: {
        type: String,
        validators: [required],
      },
      match: {
        type: String,
        validators: [required],
      },
      startDate: {
        type: Date,
        validators: [required],
      },
      endDate: {
        type: Date,
        validators: [required],
      },
      voteOpenDate: {
        type: Date,
        validators: [required],
      },
      voteCloseDate: {
        type: Date,
        validators: [required],
      },
      ctaText: {
        type: String,
        validators: [required],
      },
      description: {
        type: String,
      },
      competitionTitle: {
        type: String,
      },
      competitionDescription: {
        type: String,
      },
      competitionUrl: {
        type: String,
      },
      images: {
        type: Object,
      },
      dateCreated: {
        type: Date,
        defaultValue: () => new Date(),
      },
      account: {
        type: String,
        validators: [required],
      },
    },
  })
}
