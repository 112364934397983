const matchOptions = [
  {
    text: 'Home',
    value: 'home',
  },
  {
    text: 'Home and Away',
    value: 'homeAndAway',
  },
]

module.exports = matchOptions
