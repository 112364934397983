import FormView from '../list/views/form'
import Model from './models/model'
import ItemView from '../list/views/item'

const initWidget = () => ({
  coverGridWidget: [ 'widget', 'listWidget', 'sectionAdmin', init ],
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    name: 'Cover Grid',
    itemView: ItemView,
    description: 'Displays content in a grid with a cover image background',
  }

  ;[ 'article', 'section' ].forEach((factory) =>
    serviceLocator.widgetFactories.get(factory).register('coverGrid', widget)
  )

  done()
}

module.exports = initWidget
