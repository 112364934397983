const Model = require('merstone')
const createSchema = require('../../../service/form-builder/schema')
const Collection = require('chale')
const QuestionModel = require('../../question/models/question')

class FormBuilderModel extends Model {
  constructor(serviceLocator, attributes) {
    super(serviceLocator, attributes)

    this.schema = createSchema()
    this.attributes = this.schema.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })

    this.questions = new Collection()

    this.questions.on('add', () =>
      this.set('questions', this.questions.toJSON())
    )

    this.questions.on('model:change', () => {
      this.set('questions', this.questions.toJSON())
    })

    this.questions.on('remove', () => {
      this.set('questions', this.questions.toJSON())
    })

    this.questions.on('reorder', (questions) => {
      this.set(
        'questions',
        questions.map((q) => q.toJSON())
      )
    })

    if (!this.has('questions')) this.set('questions', [])

    this.get('questions').forEach((question) => {
      this.questions.add(new QuestionModel({}, question))
    })
  }

  validate(cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset(attrs) {
    attrs = this.schema.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }

  has(key) {
    return !!this.get(key)
  }
}

module.exports = FormBuilderModel
