const schemata = require('@clocklimited/schemata')

module.exports = function () {
  return schemata({
    name: 'List item schema',
    properties: {
      itemId: {
        type: String,
        tag: ['manual'],
      },
      isCustom: {
        type: Boolean,
        defaultvalue: false,
      },
      overrides: {
        type: Object,
        defaultvalue: () => ({}),
      },
      properties: {
        type: Object,
        defaultvalue: () => ({}),
      },
    },
  })
}
