const channelOptions = [
  {
    text: 'TNT Sport',
    value: 'tnt-sport',
    isChannel: true,
  },
  {
    text: 'Sky Sports',
    value: 'sky-sports',
    isChannel: true,
  },
  {
    text: 'PRTV Live',
    value: 'prtv-live',
    isChannel: true
  },
  {
    text: 'ITV',
    value: 'itv',
    isChannel: true
  }
]

module.exports.all = channelOptions
