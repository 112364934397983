module.exports = initWidget

function initWidget() {
  return { inlineImageWidget: ['widget', 'sectionAdmin', init] }
}

function init(serviceLocator, done) {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Inline Image',
    itemView: require('./views/item'),
    description: 'Display an image within an article body',
  }

  serviceLocator.widgetFactories
    .get('articleBody')
    .register('inlineImage', widget)
  serviceLocator.widgetFactories.get('section').register('inlineImage', widget)
  serviceLocator.widgetFactories.get('article').register('inlineImage', widget)
  serviceLocator.widgetFactories.get('tabsBody').register('inlineImage', widget)

  done()
}
