module.exports = TagService
const CrudService = require('../../../../admin/source/js/lib/crud-service')

function TagService() {
  this.name = 'TagService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/tags'
}

TagService.prototype = Object.create(CrudService.prototype)
