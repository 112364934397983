module.exports = createSchema
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../service/widget/model/base-widget')()
const assetSchemata = require('../../../service/asset/schema')()

function createSchema() {
  var imageSchema = { selectedContexts: { type: Array } }
  var schema = schemata({
    name: 'Asset schema',
    properties: Object.assign(
      {},
      baseSchema.getProperties(),
      assetSchemata.getProperties(),
      imageSchema
    ),
  })
  return schema
}
