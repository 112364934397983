module.exports = createSchema
const schemata = require('@clocklimited/schemata')

function createSchema() {
  return schemata({
    name: 'Article layout header schema',
    properties: {
      showArticleProperties: {
        type: Array,
        defaultValue: function () {
          return ['category', 'attributes']
        },
      },
    },
  })
}
