const async = require('async')

module.exports = getPreviewUrlComponents

function getPreviewUrlComponents(serviceLocator, instanceId, cb) {
  async.waterfall(
    [
      function (cb) {
        if (!instanceId) {
          return serviceLocator.instanceService.find(
            '',
            {},
            [ 'name' ],
            { pageSize: 1 },
            function (err, instances) {
              cb(err, { instance: instances.results[0] })
            }
          )
        }
        serviceLocator.instanceService.read(instanceId, function (
          err,
          instance
        ) {
          if (err) return cb(err)
          return cb(null, { instance: instance })
        })
      },
      function (obj, cb) {
        serviceLocator.accountService.read(obj.instance.account, function (
          err,
          account
        ) {
          if (err) return cb(err)
          obj.account = account
          return cb(null, obj)
        })
      },
    ],
    cb
  )
}
