module.exports = ListView
const BaseListItemView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')
const join = require('path').join
const compileJade = require('browjadify-compile')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\">");
if ( allowed('hospitality-package', 'delete'))
{
buf.push("<button class=\"btn js-delete\">Delete Selected</button>");
}
buf.push("</div>");
if ( allowed('hospitality-package', 'create'))
{
buf.push("<button class=\"btn js-create-day\">Create Day Hospitality Packages</button>");
}
if ( allowed('hospitality-package', 'create'))
{
buf.push("<button class=\"btn btn--action js-new\">New Hospitality Package</button>");
}
buf.push("</div></div><div class=\"centering\"><header class=\"page-header\"><h1>Hospitality Packages</h1></header><div class=\"grid grid--reverse\"><div class=\"grid__item one-quarter\"><div class=\"js-filters\"></div></div><div class=\"grid__item three-quarters\"><div class=\"list-container\"><div class=\"list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined));;return buf.join("");
}

function ListView() {
  BaseListItemView.apply(this, arguments)
}

ListView.prototype = Object.create(BaseListItemView.prototype)

ListView.prototype.name = {
  singular: 'Hospitality Package',
  plural: 'Hospitality Packages',
  resource: 'hospitality-package',
}
ListView.prototype.template = template
ListView.prototype.FilterView = FilterView
ListView.prototype.ListItemView = ListItemView
