const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const email = require('validity-email')

module.exports = () =>
  schemata({
    name: 'Filter',
    properties: {
      header: {
        type: String,
        validators: [required],
      },
      emailAddress: {
        type: String,
        validators: [required, email],
      },
      infoHtml: {
        type: String,
      },
      showFixtures: {
        type: Boolean,
      },
      ...baseSchema.getProperties(),
    },
  })
