module.exports = PlayerService
const CrudService = require('../../../../admin/source/js/lib/crud-service')

function PlayerService() {
  this.name = 'PlayerService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/players'
}

PlayerService.prototype = Object.create(CrudService.prototype)
