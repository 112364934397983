const baseItemView = require('../views/item/base')

module.exports = function () {
  var factories = {}

  function register(type, factory) {
    if (factories[type]) {
      throw new Error("Widget '" + type + "' already registered")
    }
    if (!factory.name) {
      throw new Error('name must be defined')
    }
    // Not all widgets have an editView
    // if ((factory.model == EditBaseWidget) && (!factory.editView)) {
    //   throw new Error('editView must extend Backbone.View')
    // }
    if (!factory.model) {
      throw new Error('model must extend Backbone.Model')
    }
    // Use default item view is none is passed
    if (!factory.itemView) {
      factory.itemView = baseItemView
    }
    factory.type = type
    factories[type] = factory
  }

  function get(type) {
    if (!factories[type]) {
      throw new Error("No type '" + type + "' registered")
    }
    return factories[type]
  }

  function list() {
    return factories
  }

  function toArray() {
    return Object.keys(factories).map(function (key) {
      return factories[key]
    })
  }

  get.register = register
  get.list = list
  get.toArray = toArray

  return get
}
