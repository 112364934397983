const initWidget = () => {
  return { fixturesAndResultsWidget: [ 'sectionAdmin', init ] }
}

const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Fixtures and Results',
    itemView: require('./views/item'),
    description:
      'Display Fixtures and Results for a given squad and competition',
  }

  serviceLocator.widgetFactories
    .get('section')
    .register('fixturesAndResults', widget)

  done()
}

module.exports = initWidget
