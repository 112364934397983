module.exports = ListItemView
const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, getSquadNameById, undefined) {
buf.push("<div class=\"list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-image: url("' + data.previewImageUrlSmall + '");', true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('player', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("</div>");
if ( allowed('player', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/players/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape((jade_interp = data.firstName) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = data.lastName) == null ? '' : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2><" + (data.firstName) + ">" + (jade.escape((jade_interp = data.lastName) == null ? '' : jade_interp)) + "</" + (data.firstName) + "></h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Team:</dt><dd>");
// iterate data.squad
;(function(){
  var $$obj = data.squad;
  if ('number' == typeof $$obj.length) {

    for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
      var val = $$obj[i];

buf.push(jade.escape(null == (jade_interp = getSquadNameById(data.squad[i])) ? "" : jade_interp));
if ( (i < data.squad.length - 1))
{
buf.push(jade.escape(null == (jade_interp = ', ') ? "" : jade_interp));
}
    }

  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;      var val = $$obj[i];

buf.push(jade.escape(null == (jade_interp = getSquadNameById(data.squad[i])) ? "" : jade_interp));
if ( (i < data.squad.length - 1))
{
buf.push(jade.escape(null == (jade_interp = ', ') ? "" : jade_interp));
}
    }

  }
}).call(this);

buf.push("</dd><dt>Position:</dt><dd>" + (jade.escape(null == (jade_interp = data.position) ? "" : jade_interp)) + "</dd><dt>Shirt Number:</dt><dd>" + (jade.escape(null == (jade_interp = data.shirtNumber || 'None') ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"getSquadNameById" in locals_for_with?locals_for_with.getSquadNameById:typeof getSquadNameById!=="undefined"?getSquadNameById:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const createImageUrlBuilder = require('cf-image-url-builder')
const getSquadNameById = require('../../../service/team/squads')
  .getSquadNameById
const config = window.config

function ListItemView() {
  BaseListItemView.apply(this, arguments)
}

ListItemView.prototype = Object.create(BaseListItemView.prototype)

ListItemView.prototype.template = template

ListItemView.prototype.serialize = function () {
  const images = this.model.get('images').widgets
  const data = this.model.toJSON()
  const drUrl = config.darkroom.url
  const drKey = config.darkroom.salt
  const urlBuilder = createImageUrlBuilder(
    drUrl,
    drKey,
    this.model.get('images').widgets
  )

  if (
    images &&
    images.length &&
    urlBuilder.getImage('Main') &&
    urlBuilder.getImage('Main').crop('Square')
  ) {
    data.previewImageUrlSmall = urlBuilder
      .getImage('Main')
      .crop('Square')
      .constrain(150)
      .url()
    data.previewImageUrlLarge = urlBuilder
      .getImage('Main')
      .crop('Square')
      .constrain(400)
      .url()
  } else {
    data.previewImageUrlSmall = '/assets/img/content/no-photo-medium.png'
    data.previewImageUrlLarge = '/assets/img/content/no-photo-large.png'
  }

  data.hasSection = typeof data.__fullUrlPath !== 'undefined'

  return data
}

ListItemView.prototype.render = function () {
  var data = this.serialize()

  this.$el.empty().append(
    template({
      data: data,
      allowed: this.serviceLocator.allowed,
      format: this.serviceLocator.format,
      getSquadNameById,
    })
  )

  this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
  return this
}
