const schemata = require('@clocklimited/schemata')
const isURL = require('validity-url')
const required = require('@clocklimited/validity-required')
const imageConfig = require('./image-config.json')
const hasWidgets = require('../../../lib/has-widgets')
const createRegexValidator = require('validity-regex-match')
const validateIfSet = require('validity-validate-if-set')
const validateIf = require('../../../lib/validators/validate-if')
const validateIfPropertyIn = require('validity-validate-if-property-in')
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')
const customValidityMessage = require('./lib/validity-message-customiser')
const isRelativeLink = customValidityMessage(
  createRegexValidator(new RegExp('^[^/]*/[^/].*$', 'i')),
  'Link is required and must be formatted as a relative url e.g /fixtures'
)
const validateIfPropertySet = require('validity-validate-if-property-set')

const isRelativeOrAbsolute = (key, name, object, cb) => {
  isRelativeLink(key, name, object, (error, relative) => {
    isURL(key, name, object, (err, absolute) => {
      cb(
        err || error,
        relative && absolute
          ? 'Please ensure this is a relative or absolute url'
          : undefined
      )
    })
  })
}

module.exports = function () {
  const requiredContexts = imageConfig.contexts.map((context) => context.name)
  const requiredCrops = imageConfig.crops.map((crop) => crop.name)

  const schema = schemata({
    name: 'Navigation item schema',
    properties: {
      title: {
        type: String,
        validators: [required],
      },
      type: {
        type: String,
        defaultValue: 'section',
        validators: [required],
      },
      section: {
        type: String,
        validators: [validateIfPropertyIn('type', ['section'], required)],
      },
      url: {
        type: String,
        validators: [
          validateIfPropertyIn(
            'type',
            ['internalLink', 'externalLink'],
            required
          ),
          validateIfPropertyIn('type', ['externalLink'], isURL),
          validateIfPropertyIn('type', ['internalLink'], isRelativeLink),
        ],
      },
      ctaLabel: {
        type: String,
        validators: [validateIfPropertySet('ctaLink', required)],
      },
      ctaLink: {
        type: String,
        validators: [
          validateIfPropertySet('ctaLabel', required),
          validateIfSet(isRelativeOrAbsolute),
        ],
      },
      images: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: [
          validateIf(createContextValidator(requiredContexts), hasWidgets),
          validateIf(createCropValidator(requiredCrops), hasWidgets),
        ],
      },
    },
  })

  return schema
}
