module.exports = initWidget

function initWidget() {
  return { textWidget: ['widget', 'sectionAdmin', init] }
}

function init(serviceLocator, done) {
  var textWidget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Text',
    itemView: require('./views/item'),
    description: 'Rich Text Block',
  }

  serviceLocator.widgetFactories.get('section').register('text', textWidget)
  serviceLocator.widgetFactories.get('article').register('text', textWidget)
  serviceLocator.widgetFactories.get('articleBody').register('text', textWidget)
  serviceLocator.widgetFactories.get('tabsBody').register('text', textWidget)

  done()
}
