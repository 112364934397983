module.exports = initAdmin
const createFormController = require('./controllers/form')
const createListController = require('./controllers/list')
const InstanceService = require('./services/instance')
const createRevisionController = require('../revision/controllers/controller')

function initAdmin() {
  return { instanceAdmin: init }
}

function init(serviceLocator, done) {
  serviceLocator.instanceService = new InstanceService(serviceLocator)
  createFormController(serviceLocator)
  createListController(serviceLocator)
  createRevisionController(
    serviceLocator,
    serviceLocator.instanceService,
    'instance',
    'instances/:id/revisions',
    'instances',
    'name'
  )
  done()
}
