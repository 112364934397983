const View = require('ventnor')
const compileJade = require('browjadify-compile')
const async = require('async')
const join = require('path').join
const lineUpContainerTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["lineUpPanel"] = jade_interp = function(type, label, hiddenByDefault){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div id=\"section-lineUp\"" + (jade.attr("data-field", type + 'LineUp', true, false)) + (jade.cls(['panel','panel-styled','js-line-up-panel',hiddenByDefault ? 'hidden' : ''], [null,null,null,true])) + "><div class=\"panel-header panel-header--justified\"><h2>" + (jade.escape(null == (jade_interp = label + ' Line Up') ? "" : jade_interp)) + "</h2><div class=\"control-group\"><button type=\"button\"" + (jade.attr("data-type", type, true, false)) + " class=\"btn js-clear-lineup\">Clear Line Up</button></div></div><div class=\"panel-content\"><div" + (jade.attr("id", 'field--' + type + 'lineUp', true, false)) + " class=\"form-row is-hidden\"><div class=\"js-error\"></div><div" + (jade.cls(['js-' + type + '-line-up'], [true])) + "></div></div><button type=\"button\"" + (jade.attr("data-type", type, true, false)) + " class=\"btn js-add-player\">Add Player</button><p></p></div></div>");
};
jade_mixins["lineUpPanel"]('primaryClub', 'Tigers', false);
jade_mixins["lineUpPanel"]('opposition', 'Opposition', true);;return buf.join("");
}
const lineUpItemTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (hasPlayed, index, type) {
buf.push("<div" + (jade.attr("id", 'field--playerId-' + index, true, false)) + (jade.attr("data-field", 'playerId-' + index, true, false)) + " class=\"form-row\"><span class=\"form-label-text\"><label>" + (jade.escape((jade_interp = index) == null ? '' : jade_interp)) + "</label></span><div" + (jade.cls(['form-field','js-' + type + '-has-played-' + index], [null,true])) + "><input type=\"checkbox\"" + (jade.attr("name", type + '-has-played-' + index, true, false)) + (jade.attr("checked", hasPlayed, true, false)) + "/>Played in match?</div><div" + (jade.cls(['form-field','js-' + type + '-player-' + index], [null,true])) + "></div></div>");}.call(this,"hasPlayed" in locals_for_with?locals_for_with.hasPlayed:typeof hasPlayed!=="undefined"?hasPlayed:undefined,"index" in locals_for_with?locals_for_with.index:typeof index!=="undefined"?index:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined));;return buf.join("");
}
const PlayerSelector = require('../../player/views/player-selector')
const FreeTextPlayerSelector = require('../../player/views/free-text-player-selector')

class LineUpView extends View {
  constructor(serviceLocator, model) {
    super(serviceLocator)
    this.model = model
    this.defaultNumPlayers = 23
    this.currentOppositionTeam = null
    this.lineUpCount = { primaryClub: 1, opposition: 1 }
    this.lineUpPlayers = { primaryClub: [], opposition: [] }
    this.playerSelectors = {
      primaryClub: PlayerSelector,
      opposition: FreeTextPlayerSelector,
    }
    this.propertyNames = {
      primaryClub: 'lineUp',
      opposition: 'oppositionLineUp',
    }
  }

  render(oppositionId) {
    this.$el.append(lineUpContainerTemplate())

    this.setTeamPlayers(oppositionId, (error) => {
      if (error)
        return this.serviceLocator.logger.error(error, 'Could not load players')
      this.renderLineUp('primaryClub')
      this.renderLineUp('opposition')
    })

    this.$el
      .find('.js-clear-lineup')
      .on('click', this.handleClearLineUp.bind(this))
    this.$el
      .find('.js-add-player')
      .on('click', this.handleAddNewPlayer.bind(this))

    return this
  }

  showOppositionPanel() {
    this.$el.find('.js-line-up-panel').removeClass('hidden')
  }

  handleClearLineUp(e) {
    e.preventDefault()
    const lineUpType = $(e.currentTarget).data('type')
    this.clearLineUp(lineUpType)
  }

  renderLineUp(type) {
    this.lineUpCount[type] = 1
    this.$el.find(`.js-${type}-line-up`).empty()
    const currentLineUp = this.model.get(this.propertyNames[type]) || []
    const lastItem = currentLineUp[currentLineUp.length - 1]
    const lastPosition = lastItem ? lastItem.position : 0
    const numPlayerSlots =
      lastPosition > this.defaultNumPlayers
        ? lastPosition
        : this.defaultNumPlayers

    for (let i = 1; i <= numPlayerSlots; i++) {
      const player = currentLineUp.find(({ position }) => position === i)
      this.addPlayer(player, type)
    }
  }

  addPlayer(player = {}, type) {
    const index = this.lineUpCount[type]++
    const players = this.lineUpPlayers[type]
    this.$el.find(`.js-${type}-line-up`).append(
      lineUpItemTemplate({
        index,
        type,
        hasPlayed: player.hasPlayed,
      })
    )
    const playerSelectView = new this.playerSelectors[type](
      this.serviceLocator,
      index,
      players,
      player.player,
      type === 'primaryClub'
    )
    const playerItem = this.$el.find(`[data-field="playerId-${index}"]`)
    const selectField = playerItem.find(`.js-${type}-player-${index}`)
    const hasPlayedField = playerItem.find(
      `.js-${type}-has-played-${index} input`
    )

    selectField.append(playerSelectView.render().$el)
    hasPlayedField.on('change', (e) => {
      const hasPlayed = $(e.target).is(':checked')
      const propertyName = this.propertyNames[type]
      const lineUp = this.model.get(propertyName)
      const player = lineUp.find(
        ({ position }) => position === playerSelectView.position
      )

      if (!player) return

      this.model.set(
        propertyName,
        this.updateLineUp(
          this.model.get(propertyName),
          Object.assign({}, player, { hasPlayed })
        )
      )
    })
    playerSelectView.on('change', (player) => {
      const data = {
        player,
        position: playerSelectView.position,
        hasPlayed: false,
      }
      const propertyName = this.propertyNames[type]
      this.model.set(
        propertyName,
        this.updateLineUp(this.model.get(propertyName), data)
      )
      hasPlayedField.prop('checked', false)
    })
  }

  handleAddNewPlayer(e) {
    e.preventDefault()
    const $clickedEl = $(e.currentTarget)
    const type = $clickedEl.data('type')
    this.addPlayer({}, type)
  }

  clearLineUp(type) {
    this.model.set(this.propertyNames[type], [])
    this.renderLineUp(type)
  }

  setTeamPlayers(oppositionTeamId, cb) {
    this.currentOppositionTeam = oppositionTeamId
    const sort = [ 'lastName', 'asc' ]
    const pagination = { page: 1, pageSize: 5000 }
    const tasks = {
      primaryClub: this.serviceLocator.playerService.find.bind(
        this.serviceLocator.playerService,
        '',
        {},
        sort,
        pagination
      ),
      opposition: this.serviceLocator.optaPlayerService.find.bind(
        this.serviceLocator.optaPlayerService,
        '',
        { team: oppositionTeamId },
        sort,
        pagination
      ),
    }
    async.parallel(tasks, (error, results) => {
      if (error && cb) return cb(error)
      this.lineUpPlayers.primaryClub = results.primaryClub.results
      this.lineUpPlayers.opposition = results.opposition.results
      if (cb) cb()
    })
  }

  updateLineUp(lineUp, data) {
    if (!lineUp) lineUp = []
    const positionIndex = lineUp.findIndex(
      (player) => player.position === data.position
    )

    if (positionIndex > -1) {
      // position is already taken, replace player in this position with new player
      lineUp[positionIndex] = data
    } else {
      lineUp = [ ...lineUp, data ]
    }

    return lineUp
  }
}

module.exports = LineUpView
