const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const createUniqueValidator = require('validity-unique-property')
const parseUrl = require('url').parse
const urlOrPathValidator = require('./lib/urlOrPathValidator')
const urlPathValidator = require('./lib/urlPathValidator')
const validateIfSet = require('validity-validate-if-set')

module.exports = function (serviceLocator, save) {
  function findLoop(redirect, cb) {
    const conditions = [
      { redirectUrl: redirect.path },
      { redirectUrl: serviceLocator.config.url + redirect.path },
      { path: redirect.redirectUrl },
    ]
    const urlParts = parseUrl(redirect.redirectUrl)

    if (urlParts.hostname === serviceLocator.config.hostname) {
      conditions.push({ path: urlParts.pathname })
    }

    save.count({ $or: conditions }, cb)
  }

  function noLoopValidator(key, keyDisplayName, obj, cb) {
    findLoop(obj, function (err, count) {
      var message = null

      if (err) return cb(err)

      if (count !== 0) {
        message =
          obj.path +
          ' to ' +
          obj.redirectUrl +
          ' may cause an infinite redirection loop'
      }
      cb(null, message)
    })
  }

  function notEqualRedirectValidator(key, keyDisplayName, obj, cb) {
    const pathUrl = serviceLocator.config.url + obj.path
    let message = null

    if (obj.redirectUrl === pathUrl) {
      message = 'The path can not equal the redirect URL'

      return cb(null, message)
    }

    cb()
  }

  return schemata({
    name: 'Redirect schema',
    properties: {
      _id: { type: String },
      path: {
        type: String,
        validators: {
          all: [
            required,
            validateIfSet(urlPathValidator),
            notEqualRedirectValidator,
            createUniqueValidator(save.findOne),
          ],
        },
      },
      redirectUrl: {
        type: String,
        name: 'Redirect URL',
        validators: {
          all: [
            required,
            validateIfSet(urlOrPathValidator),
            validateIfSet(noLoopValidator),
          ],
        },
      },
      createdDate: { type: Date, defaultValue: () => new Date() },
      instance: { type: Array },
      account: { type: String, validators: { all: [required] } },
    },
  })
}
