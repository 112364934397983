const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('./views/item'),
    name: 'Tabs',
    description: 'Tabs widget',
  }

  serviceLocator.widgetFactories.get('section').register('tabs', widget)
  serviceLocator.widgetFactories.get('article').register('tabs', widget)
  serviceLocator.widgetFactories.get('articleBody').register('tabs', widget)

  done()
}

module.exports = () => ({
  tabsWidget: ['widget', 'sectionAdmin', init],
})
