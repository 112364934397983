const createListController = require('./controllers/list')
const createFormController = require('./controllers/form')
const CompetitionService = require('./services/competition')

function initAdmin() {
  return { competitionAdmin: [ 'widget', init ] }
}

function init(serviceLocator, cb) {
  serviceLocator.competitionService = new CompetitionService(serviceLocator)

  createListController(serviceLocator)
  createFormController(serviceLocator)
  cb(null)
}

module.exports = initAdmin
