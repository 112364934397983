const createSignedApiUrl = require('../../../../admin/source/js/lib/create-signed-api-url')

module.exports = (serviceLocator) => {
  const updateButton = (className, status) => {
    if (status === 'OK') {
      $(className).addClass('btn--success')
      $(className).prop('disabled', true)
    } else {
      $(className).addClass('btn--error')
    }
  }

  $(document).on('click', '.js-feed-fixtures', () => {
    makeRequest(serviceLocator, '/feed/fixtures', (status) => {
      updateButton('.js-feed-fixtures', status)
    })
  })

  $(document).on('click', '.js-feed-competitions', () => {
    makeRequest(serviceLocator, '/feed/competitions', (status) => {
      updateButton('.js-feed-competitions', status)
    })
  })

  $(document).on('click', '.js-feed-teams', () => {
    makeRequest(serviceLocator, '/feed/teams', (status) => {
      updateButton('.js-feed-teams', status)
    })
  })

  $(document).on('click', '.js-feed-players', () => {
    makeRequest(serviceLocator, '/feed/players', (status) => {
      updateButton('.js-feed-players', status)
    })
  })

  $(document).on('click', '.js-feed-match-stats', () => {
    makeRequest(serviceLocator, '/feed/match-stats', (status) => {
      updateButton('.js-feed-match-stats', status)
    })
  })

  $(document).on('click', '.js-feed-tables', () => {
    makeRequest(serviceLocator, '/feed/tables', (status) => {
      updateButton('.js-feed-tables', status)
    })
  })

  $(document).on('click', '.js-feed-venues', () => {
    makeRequest(serviceLocator, '/feed/venues', (status) => {
      updateButton('.js-feed-venues', status)
    })
  })
}

function makeRequest(serviceLocator, url, cb) {
  const signedApiUrl = createSignedApiUrl(
    url,
    window.localStorage.apiKey,
    window.localStorage.apiId,
    serviceLocator.config.apiUrl
  )

  window.Backbone.ajax({
    url: signedApiUrl,
    type: 'GET',
    success: cb,
  })
}
