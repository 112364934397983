module.exports = () => [
  {
    id: '1880-club',
    name: '1880 Club',
    link: '/hospitality/1880-club',
  },
  {
    id: 'executive-boxes',
    name: 'Executive Boxes',
    link: '/hospitality/executive-boxes',
  },
  {
    id: 'the-clubhouse',
    name: 'The Clubhouse',
    link: '/hospitality/the-clubhouse',
  },
  {
    id: 'the-try-line-club',
    name: 'The Try Line Club',
    link: '/hospitality/the-try-line-club',
  },
  {
    id: 'big-match-sponsor',
    name: 'Big Match Sponsor',
    link: '/hospitality/big-match-sponsor',
  },
  {
    id: 'directors-experience',
    name: 'Directors Experience',
    link: '/hospitality/directors-experience',
  },
  {
    id: 'players-table',
    name: 'Players Table',
    link: '/hospitality/players-table',
  },
  {
    id: 'match-ball-experience',
    name: 'Match Ball Experience',
    link: '/hospitality/match-ball-experience',
  },
  {
    id: 'man-of-the-match',
    name: 'Man of the Match',
    link: '/hospitality/man-of-the-match',
  },
  {
    id: '1st-try-scorer',
    name: '1st Try Scorer',
    link: '/hospitality/first-try-scorer',
  },
  {
    id: 'vip',
    name: 'VIP Hospitality',
    link: '/hospitality/vip-hospitality',
  },
  {
    id: 'special-occasion-experience',
    name: 'Special Occasion Experience',
    link: '/hospitality/special-occasion-experience',
  },
  {
    id: 'burleighs-gin-experience',
    name: 'Burleigh’s Gin Experience',
    link: '/hospitality/burleighs-gin-experience',
  },
  {
    id: 'green-carpet',
    name: 'The Green Carpet',
    link: '/hospitality/the-green-carpet',
  },
  {
    id: 'champions-club',
    name: 'Champions Club',
    link: '/hospitality/champions-club',
  },
  {
    id: 'the-chairmans-lounge',
    name: 'The Pay iO Chairman’s Lounge',
    link: '/hospitality/the-chairmans-lounge'
  },
  {
    id: 'the-players-lounge',
    name: 'The Players Lounge',
    link: '/hospitality/the-players-lounge'
  },
  {
    id: 'dine-with-directors',
    name: 'Dine with Directors',
    link: '/hospitality/dine-with-directors'
  }
]
