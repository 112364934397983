const BaseListItemView = require('../../base/views/list-item')
const modal = require('modal')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, typeLabel) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\"><div class=\"btn-group\"><button type=\"button\" class=\"btn btn-small js-remove\">Remove</button></div></div>");
if ( data.heading)
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.heading) ? "" : jade_interp)) + "</h2>");
}
if ( data.text)
{
buf.push("<div>" + (null == (jade_interp = data.text) ? "" : jade_interp) + "</div>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Type:</dt><dd>" + (jade.escape(null == (jade_interp = typeLabel) ? "" : jade_interp)) + "</dd><dt>Minute:</dt><dd>" + (jade.escape(null == (jade_interp = data.minute) ? "" : jade_interp)) + "</dd>");
if ( data.team)
{
buf.push("<dt>Team:</dt><dd>" + (jade.escape(null == (jade_interp = data.team) ? "" : jade_interp)) + "</dd>");
}
if ( data.homeScore || data.awayScore)
{
buf.push("<dt>Score:</dt><dd>" + (jade.escape((jade_interp = data.homeScore || 'Unchanged') == null ? '' : jade_interp)) + " - " + (jade.escape((jade_interp = data.awayScore || 'Unchanged') == null ? '' : jade_interp)) + "</dd>");
}
buf.push("</dl></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"typeLabel" in locals_for_with?locals_for_with.typeLabel:typeof typeLabel!=="undefined"?typeLabel:undefined));;return buf.join("");
}
const commentaryTypes = require('../../../service/match-commentary/lib/commentary-types')

class CommentaryItemView extends BaseListItemView {
  constructor(...args) {
    super(...args)

    this.$el.on('click', '.js-remove', this.removeItem.bind(this))
  }

  render() {
    const data = this.model.toJSON()
    const typeLabel = commentaryTypes.getCommentaryByType(data.type).text
    this.$el.empty().append(
      template({
        data,
        typeLabel,
      })
    )
    return this
  }

  get template() {
    return template
  }

  removeItem() {
    modal({
      title: 'Delete',
      content: 'Are you sure you want to delete this item?',
      buttons: [
        { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [ 27 ] },
        { text: 'Delete', event: 'confirm', className: 'btn btn--error' },
      ],
    }).on('confirm', this.handleDelete.bind(this))
  }

  handleDelete() {
    this.emit('delete')
  }
}

module.exports = CommentaryItemView
