module.exports = TypeSelectView
const View = require('ventnor')
const types = require('../../../service/hospitality-package/types')()

function TypeSelectView(serviceLocator, value) {
  View.apply(this, arguments)
  this.value = value
  this.types = types
  this.$el = $('<select/>')
  this.$el.attr('name', 'type')
  this.$el.addClass('control control--choice')
  this.$el.on(
    'change',
    function () {
      this.emit('change', this.$el.val())
    }.bind(this)
  )
  this.notSetLabel = '-- Select a Type --'
}

TypeSelectView.prototype = Object.create(View.prototype)

TypeSelectView.prototype.render = function () {
  this.$el.append($('<option/>', { text: this.notSetLabel, value: '' }))
  this.populate()
  return this
}

TypeSelectView.prototype.populate = function () {
  this.types.forEach(
    function (type) {
      this.$el.append(
        $('<option/>', {
          text: type.name,
          value: type.id,
          selected: this.value === type.id,
        })
      )
    }.bind(this)
  )
  return this
}
