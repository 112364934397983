const View = require('./views/no-access')
const debug = require('../../lib/debug')('not-found routes')

module.exports = function (serviceLocator) {
  serviceLocator.router.on('noAccess', function (message) {
    this.render(new View({ message: message }))
  })

  serviceLocator.router.route('/no-access', 'noAccess', function () {
    debug('No Access view route triggered')
    this.render(new View())
  })
}
