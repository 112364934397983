const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('./views/item'),
    name: 'Frequently Asked',
    description: 'FAQ widget'
  }

  serviceLocator.widgetFactories
    .get('section')
    .register('frequentlyAsked', widget)
  serviceLocator.widgetFactories
    .get('article')
    .register('frequentlyAsked', widget)
  serviceLocator.widgetFactories
    .get('articleBody')
    .register('frequentlyAsked', widget)
  serviceLocator.widgetFactories
    .get('tabsBody')
    .register('frequentlyAsked', widget)

  done()
}

module.exports = () => ({
  frequentlyAskedWidget: [ 'widget', 'sectionAdmin', init ]
})
