module.exports = function (from = 2011) {
  const getCurrentYear = () => {
    let currentYear = new Date().getFullYear()
    // Return the next season after 18th June each year
    if (new Date() >= new Date(`${currentYear}-06-18`)) currentYear++
    return currentYear
  }

  const getSeasons = (includeFuture = false) => {
    let seasons = []
    let to = getCurrentYear()

    if (includeFuture) to++

    for (let year = to; from <= year; year--) {
      seasons.push(year)
    }

    const formattedSeasons = formatSeasons(seasons)
    return formattedSeasons
  }

  const getSeason = (year) => {
    let seasons = getSeasons(true)
    return seasons.find((season) => season.year === year)
  }

  const getCurrentSeason = () => getSeason(getCurrentYear())

  const formatSeasons = (seasons) =>
    seasons.map((season) => ({
      year: season,
      text: `${season - 1} / ${season}`,
      value: season,
    }))

  return {
    getSeasons,
    getSeason,
    getCurrentSeason,
    getCurrentYear,
    formatSeasons,
  }
}
