module.exports = TeamSelectView
const View = require('ventnor')

function TeamSelectView(serviceLocator, name, value, isEditable = true) {
  View.apply(this, arguments)
  this.value = value
  this.isEditable = isEditable
  if (this.isEditable) {
    this.$el = $('<select/>')
    this.$el.attr('name', name)
    this.$el.addClass('control control--choice')
    this.$el.on(
      'change',
      function () {
        this.emit('change', this.$el.val())
      }.bind(this)
    )
    this.notSetLabel = '-- Select a Team --'
  }
}

TeamSelectView.prototype = Object.create(View.prototype)

TeamSelectView.prototype.render = function () {
  if (this.isEditable) {
    this.$el.append($('<option/>', { text: this.notSetLabel, value: '' }))
  }
  return this
}

TeamSelectView.prototype.populate = function () {
  if (this.isEditable) {
    const pagination = { page: 1, pageSize: 500 }
    this.serviceLocator.teamService.find(
      '',
      {},
      [ 'name' ],
      pagination,
      function (err, res) {
        if (err) return this.serviceLocator.logger.error(err, err.message)
        res.results.forEach(
          function (team) {
            this.$el.append(
              $('<option/>', {
                text: `${team.name} - ${team.squad}`,
                value: team._id,
                selected: this.value === team._id,
              })
            )
          }.bind(this)
        )
      }.bind(this)
    )
  } else {
    this.serviceLocator.teamService.read(
      this.value,
      function (err, team) {
        if (err) return this.serviceLocator.logger.error(err, err.message)
        this.$el.append($('<span/>').html(team ? team.name : ''))
      }.bind(this)
    )
  }
  return this
}
