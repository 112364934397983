const BaseModel = require('cf-base-model')

const schemata = require('../../../service/player/season-stat-schema')()

module.exports = BaseModel.extend({
  schemata,
  schema: schemata,
  defaults() {
    return schemata.makeDefault()
  },
  validate(cb) {
    this.schema.validate(this.attributes, (ignoreErr, errors) => {
      if (!errors) return cb(errors)
      cb(Object.keys(errors).length > 0 ? errors : undefined)
    })
  }
})
