const displayTabs = [
  {
    value: 'groupDays',
    option: 'Group Days',
  },
  {
    value: 'hospitality',
    option: 'Hospitality',
  },
  {
    value: 'matchBreaks',
    option: 'Match Breaks',
  },
  {
    value: 'europeanTravel',
    option: 'European Travel',
  },
  {
    value: 'buyTickets',
    option: 'Buy Tickets',
  },
  {
    value: 'awayTravel',
    option: 'Away Travel',
  },
]

module.exports.all = displayTabs
module.exports.values = displayTabs.map((button) => button.value)
