module.exports = createController
const ListView = require('../views/list')
const Collection = require('chale')
const HospitalityPackageModel = require('../models/hospitality-package')
const Model = require('merstone')
const async = require('async')
const pageSize = 50

function createController(serviceLocator) {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0,
  })
  let currentPage = 1
  let currentParams = {
    keywords: '',
    filter: {},
    sort: [ 'dateCreated', 'desc' ],
  }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an package is updated, reset the model with its new attributes
  serviceLocator.hospitalityPackageService.on('update', function (id, attrs) {
    var model = collection.get(id)
    if (model) model.reset(attrs)
    serviceLocator.hospitalityPackageService.cachedFind.clear()
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.hospitalityPackageService.on('create', function () {
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    serviceLocator.hospitalityPackageService.cachedFind.clear()
    getHospitalityPackages(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  serviceLocator.router.route(
    'hospitality-packages(/)',
    'listpackages',
    function () {
      if (!serviceLocator.allow('hospitality-package', 'discover')) return false

      getHospitalityPackages(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )

      var list = new ListView(
        serviceLocator,
        collection,
        paginationModel
      ).render()

      list.displayFilterParams(currentParams)

      list.on('createNew', function () {
        if (!serviceLocator.allow('hospitality-package', 'create')) return false
        serviceLocator.router.navigate('hospitality-packages/form', {
          trigger: true,
        })
      })

      list.on('edit', function (id) {
        if (!serviceLocator.allow('hospitality-package', 'update')) return false
        serviceLocator.router.navigate('hospitality-packages/' + id + '/form', {
          trigger: true,
        })
      })

      list.on('delete', function (ids) {
        if (!serviceLocator.allow('hospitality-package', 'delete')) return false
        async.each(ids, deleteOne, function (err) {
          if (err) return alert(err.message)
        })

        function deleteOne(id, cb) {
          serviceLocator.hospitalityPackageService.delete(id, function (err) {
            if (err) return cb(err)
            collection.remove(id)
          })
        }
      })

      list.on('filter', function (params) {
        currentParams = params
        var pagination = { page: currentPage, pageSize: pageSize }
        currentPage = 1
        getHospitalityPackages(
          params.keywords,
          params.filter,
          params.sort,
          pagination
        )
      })

      list.on('loadMore', function () {
        currentPage += 1
        var pagination = { page: currentPage, pageSize: pageSize }
        appendpackages(
          currentParams.keywords,
          currentParams.filter,
          currentParams.sort,
          pagination
        )
      })

      serviceLocator.router.render(list, 'hospitality-packages')
    }
  )

  function getHospitalityPackages(keywords, filter, sort, pagination) {
    serviceLocator.hospitalityPackageService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err)
          return serviceLocator.logger.error(
            err,
            'Could not load hospitality packages'
          )
        collection.reset(
          res.results.map(
            (packageInstance) =>
              new HospitalityPackageModel(serviceLocator, packageInstance)
          )
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  function appendpackages(keywords, filter, sort, pagination) {
    serviceLocator.hospitalityPackageService.find(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err) return alert(err.message)
        res.results.forEach(function (packageInstance) {
          collection.add(
            new HospitalityPackageModel(serviceLocator, packageInstance)
          )
        })
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }
}
