module.exports = fixedSidebar

/*
 * Fixed sidebar. Detects whether a fixed sidebar
 * has got too long to remain fixed, and changes its
 * class to enable styles to keep it viewable.
 */
function fixedSidebar(view, $sidebar) {
  if (!$sidebar || !$sidebar.length) return

  function isSidebarFixed() {
    const sidebarHeight = $sidebar.height()
    const sidebarOffset = $sidebar.offset().top
    const windowHeight = $(window).height()

    if (sidebarHeight + sidebarOffset > windowHeight) {
      $sidebar.addClass('panel-unfixed')
    } else {
      $sidebar.removeClass('panel-unfixed')
    }
  }

  $(window).on('resize', isSidebarFixed)

  view.on('remove', function () {
    $(window).off('resize', isSidebarFixed)
  })

  isSidebarFixed()
}
