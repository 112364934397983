module.exports = FormView
const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (isEditable, isNew, team, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form>");
if ( !isEditable)
{
buf.push("<div class=\"notification notification--notice\"><p>This team is maintained via the opta feed and only some fields will be editable.</p></div>");
}
buf.push("<div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\">");
if ( isNew)
{
buf.push("<div id=\"field--_id\" data-field=\"_id\" class=\"form-row js-id-row\"><label><span class=\"form-label-text\">ID<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"_id\"" + (jade.attr("value", team._id, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--source\" data-field=\"source\" class=\"form-row\"><label><span class=\"form-label-text\">Source<abbr title=\"This field is required\">*</abbr></span><select name=\"source\" class=\"control control--choice form-field js-source-select\"><option value=\"opta\"" + (jade.attr("selected", team.source === 'opta', true, false)) + ">Opta</option><option value=\"cms\"" + (jade.attr("selected", team.source === 'cms', true, false)) + ">CMS</option></select></label><div class=\"js-error\"></div></div>");
}
buf.push("<div id=\"field--squad\" data-field=\"squad\" class=\"form-row js-squad\"><label><span class=\"form-label-text\">Squad<abbr title=\"This field is required\">*</abbr></span><div class=\"js-squads form-field\"></div></label><div class=\"js-error\"></div></div><div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">Name<abbr title=\"This field is required\">*</abbr></span>");
if ( isEditable)
{
buf.push("<input type=\"text\" name=\"name\"" + (jade.attr("value", team.name, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/>");
}
else
{
buf.push("<span class=\"control form-field\">" + (jade.escape(null == (jade_interp = team.name) ? "" : jade_interp)) + "</span>");
}
buf.push("</label><div class=\"js-error\"></div></div><div id=\"field--displayName\" data-field=\"displayName\" class=\"form-row\"><label><span class=\"form-label-text\">Display Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"displayName\"" + (jade.attr("value", team.displayName, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--nickname\" data-field=\"nickname\" class=\"form-row\"><label><span class=\"form-label-text\">Nickname<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"nickname\"" + (jade.attr("value", team.nickname, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Example: Tigers</p></div></div><div id=\"field--abbreviation\" data-field=\"abbreviation\" class=\"form-row\"><label><span class=\"form-label-text\">Name Abbreviation<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"abbreviation\"" + (jade.attr("value", team.abbreviation, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Example: LEI. WARNING: Changing this value will impact match links!</p></div></div><div id=\"field--venue\" data-field=\"venue\" class=\"form-row\"><label><span class=\"form-label-text\">Venue</span><div class=\"js-venues form-field\"></div></label><div class=\"js-error\"></div></div><div id=\"field--coachFirstName\" data-field=\"coachFirstName\" class=\"form-row\"><label><span class=\"form-label-text\">Coach First Name</span>");
if ( isEditable)
{
buf.push("<input type=\"text\" name=\"coachFirstName\"" + (jade.attr("value", team.coachFirstName, true, false)) + " class=\"control control--text form-field\"/>");
}
else
{
buf.push("<span class=\"control form-field\">" + (jade.escape(null == (jade_interp = team.coachFirstName) ? "" : jade_interp)) + "</span>");
}
buf.push("</label><div class=\"js-error\"></div></div><div id=\"field--coachLastName\" data-field=\"coachLastName\" class=\"form-row\"><label><span class=\"form-label-text\">Coach Last Name</span>");
if ( isEditable)
{
buf.push("<input type=\"text\" name=\"coachLastName\"" + (jade.attr("value", team.coachLastName, true, false)) + " class=\"control control--text form-field\"/>");
}
else
{
buf.push("<span class=\"control form-field\">" + (jade.escape(null == (jade_interp = team.coachLastName) ? "" : jade_interp)) + "</span>");
}
buf.push("</label><div class=\"js-error\"></div></div><div id=\"field--crestId\" data-field=\"crestId\" class=\"form-row\"><label><span class=\"form-label-text\">Crest ID</span><input type=\"text\" name=\"crestId\"" + (jade.attr("value", team.crestId, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div></div></div></form></div></div>");}.call(this,"isEditable" in locals_for_with?locals_for_with.isEditable:typeof isEditable!=="undefined"?isEditable:undefined,"isNew" in locals_for_with?locals_for_with.isNew:typeof isNew!=="undefined"?isNew:undefined,"team" in locals_for_with?locals_for_with.team:typeof team!=="undefined"?team:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const SquadSelector = require('../../squad/views/squad-selector')
const VenueSelector = require('../../venue/views/venue-selector')

function FormView(serviceLocator, model, isNew) {
  BaseFormView.call(this, serviceLocator, model, isNew)
  this.squadSelectView = new SquadSelector(
    serviceLocator,
    this.model.get('squad'),
    true,
    this.model.isEditable()
  )
  this.on(
    'afterAppend',
    function () {
      this.$el.find('input[type=text]:first').focus()
    }.bind(this)
  )
  this.$el.on('change', '.js-source-select', this.handleSourceChange.bind(this))
}

FormView.prototype = Object.create(BaseFormView.prototype)

FormView.prototype.handleSourceChange = function (e) {
  this.model.set('source', e.target.value)
  this.showSourceFields()
}

FormView.prototype.render = function () {
  // Render the template
  this.$el.append(
    template({
      title: this.isNew ? 'New Team' : 'Edit Team',
      team: this.model.toJSON(),
      format: this.serviceLocator.format,
      isEditable: this.model.isEditable(),
      isNew: this.isNew,
    })
  )

  // Render the toolbar
  this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))
  this.renderVenueSelect()
  this.squadSelectView.render().$el.appendTo(this.$el.find('.js-squads'))
  this.showSourceFields()

  return this
}

FormView.prototype.showSourceFields = function () {
  const source = this.model.get('source')
  if (this.isNew && source === 'cms') {
    this.$el.find('.js-id-row').hide()
    this.$el.find('.js-opta-id-row').hide()
  } else if (this.isNew && source === 'opta') {
    this.$el.find('.js-id-row').show()
    this.$el.find('.js-opta-id-row').show()
  }
}

FormView.prototype.renderVenueSelect = function () {
  const VenueSelectView = new VenueSelector(
    this.serviceLocator,
    this.model.get('venue')
  )
  this.$el.find('.js-venues').append(VenueSelectView.render().$el)
  VenueSelectView.on('change', (venueId) => {
    this.model.set('venue', venueId)
  })
  this.attachView(VenueSelectView)
}
