module.exports = ListView
const BaseListItemView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')

function ListView() {
  BaseListItemView.apply(this, arguments)
}

ListView.prototype = Object.create(BaseListItemView.prototype)

ListView.prototype.name = {
  singular: 'Team',
  plural: 'Teams',
  resource: 'team',
}

ListView.prototype.FilterView = FilterView
ListView.prototype.ListItemView = ListItemView
