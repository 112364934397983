module.exports = ListItemView
const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data) {
buf.push("<div class=\"list-item list-item--condensed\"><div class=\"list-item-header\"><div class=\"list-item-actions\"><span data-toggle=\"tooltip\"" + (jade.attr("title", 'Tag Type: <strong>' + (data.type) + '</strong>', true, false)) + " class=\"label label--large js-tooltip-trigger\"><i" + (jade.cls(['icon','icon--tag-' + data.type.toLowerCase().replace(' ', '-')], [null,true])) + "></i></span>");
if ( allowed('tag', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label>");
}
buf.push("</div><h2>");
if ( allowed('tag', 'update'))
{
buf.push("<a" + (jade.attr("href", 'tags/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.tag) ? "" : jade_interp)) + "</a>");
}
else
{
buf.push("<span>" + (jade.escape(null == (jade_interp = data.tag) ? "" : jade_interp)) + "</span>");
}
buf.push("</h2></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}

function ListItemView() {
  BaseListItemView.apply(this, arguments)
  this.$el.addClass('one-third')
}

ListItemView.prototype = Object.create(BaseListItemView.prototype)

ListItemView.prototype.template = template
