module.exports = CrudService

const Emitter = require('events')
const extend = require('lodash.assign')
let uberMemoize = require('uber-memoize')
let createAuthedRequester = require('../../../../admin/source/js/lib/authed-request')

function CrudService(serviceLocator, options) {
  Emitter.call(this)
  this.authedRequest = createAuthedRequester(serviceLocator.config.apiUrl).bind(
    this
  )
  this.options = extend({ ttl: 1000 * 60 * 5 }, options)
  this.serviceLocator = serviceLocator
  const cacheId = this.name
  const memoize = uberMemoize(cacheId + '-find', this.serviceLocator.cache)
  this.cachedFind = memoize(this.find.bind(this), this.options.ttl)
  this._setupCachePurging()
}

CrudService.prototype = Object.create(Emitter.prototype)

CrudService.prototype.create = function (obj, cb) {
  this.authedRequest(
    'POST',
    this.urlRoot,
    obj,
    function (err, res, body) {
      if (err) return cb(err)
      if (res.statusCode >= 300)
        return this.handleError(res.statusCode, body, cb)
      this.emit('create', body)
      cb(null, body)
    }.bind(this)
  )
}

CrudService.prototype.read = function (id, cb) {
  this.authedRequest('GET', this.urlRoot + '/' + id, null, (err, res, body) => {
    if (err) return cb(err)
    if (res.statusCode >= 300) return this.handleError(res.statusCode, body, cb)
    cb(null, body)
  })
}

CrudService.prototype.find = function (keywords, filter, sort, pagination, cb) {
  var query = {
    keywords: keywords,
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
    pagination: JSON.stringify(pagination),
  }
  this.authedRequest(
    'GET',
    this.urlRoot,
    query,
    function (err, res, body) {
      if (err) return cb(err)
      if (res.statusCode >= 300)
        return this.handleError(res.statusCode, body, cb)
      cb(null, body)
    }.bind(this)
  )
}

CrudService.prototype.update = function (id, obj, cb) {
  this.authedRequest(
    'PUT',
    this.urlRoot + '/' + id,
    obj,
    function (err, res, body) {
      if (err) return cb(err)
      if (res.statusCode >= 300)
        return this.handleError(res.statusCode, body, cb)
      this.emit('update', id, body)
      cb(null, body)
    }.bind(this)
  )
}

CrudService.prototype.partialUpdate = function (id, data, cb) {
  this.authedRequest(
    'PATCH',
    this.urlRoot + '/' + id,
    data,
    function (err, res, body) {
      if (err) return cb(err)
      if (res.statusCode >= 300)
        return this.handleError(res.statusCode, body, cb)
      this.emit('partialUpdate', id, body)
      cb(null, body)
    }.bind(this)
  )
}

CrudService.prototype.delete = function (id, cb) {
  this.authedRequest(
    'DELETE',
    this.urlRoot + '/' + id,
    null,
    function (err, res, body) {
      if (err) return cb(err)
      if (res.statusCode >= 300)
        return this.handleError(res.statusCode, body, cb)
      this.emit('delete', id)
      cb(null)
    }.bind(this)
  )
}

CrudService.prototype._setupCachePurging = function () {
  ;[ 'create', 'update', 'partialUpdate', 'delete' ].forEach(
    function (e) {
      this.on(
        e,
        function () {
          this.cachedFind.clear()
        }.bind(this)
      )
    }.bind(this)
  )
}

CrudService.prototype.getError = getError

function getError(body) {
  if (body.errors) {
    var error = new Error('Validation Error')
    error.errors = body.errors
    return error
  }
  return new Error(body.error || body.status || body || 'Unknown Error')
}

CrudService.prototype.handleError = function (statusCode, body, cb) {
  if (statusCode === 401)
    return this.serviceLocator.router.trigger('noAccess', body)
  return cb(getError(body))
}
