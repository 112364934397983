const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const urlOrPathValidator = require('../redirect/lib/urlOrPathValidator')
const length = require('validity-length')
const keywordSchema = require('./keyword-schema')
const validateIfSet = require('validity-validate-if-set')

module.exports = function () {
  return schemata({
    name: 'Linked keyword schema',
    properties: {
      _id: { type: String },
      name: {
        type: String,
        validators: {
          all: [required],
        },
      },
      keywords: {
        type: schemata.Array(keywordSchema()),
        validators: { all: [length(1)] },
      },
      link: {
        type: String,
        validators: { all: [required, validateIfSet(urlOrPathValidator)] },
      },
      active: { type: Boolean, defaultValue: false },
      dateCreated: { type: Date, defaultValue: () => new Date() },
    },
  })
}
