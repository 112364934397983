const createSignature = require('cf-signature')
const url = require('url')

const createSignedApiUrl = (uri, apiKey, apiId, apiUrl) => {
  const date = Date.now()
  const hash = createSignature(apiKey, 'GET', '', date, uri)
  const urlParts = url.parse(uri, true)
  Object.assign({}, urlParts.query, {
    authorization: apiId + ':' + hash,
    'x-cf-date': date,
  })

  return apiUrl + url.format(urlParts)
}

module.exports = createSignedApiUrl
