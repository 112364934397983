const BaseFormView = require('../../base/views/form')
const RichTextEditorInstanceManager = require('../../../../admin/source/js/lib/rich-text-editor-instance-manager')
const dateTimePickers = require('../../../../admin/source/js/lib/init-date-time-pickers')
const debounce = require('debounce')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (rugbyCamp, rugbyCampTypes, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");
jade_mixins["dateField"] = jade_interp = function(property, label, description, value){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", `field--${property}`, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)) + "<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field\"><span class=\"datepicker\"><input type=\"text\"" + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " class=\"control control--text form-field js-date-time-picker\"/></span></div></label><div class=\"js-error\"></div>");
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};
jade_mixins["selectField"] = jade_interp = function(property, label, description, value, options, required){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", `field--${property}`, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><select" + (jade.attr("name", property, true, false)) + " class=\"control control--choice form-field\"><option value=\"\">-- Please select --</option>");
// iterate options
;(function(){
  var $$obj = options;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var option = $$obj[$index];

buf.push("<option" + (jade.attr("value", option.value, true, false)) + (jade.attr("selected", option.value === value, true, false)) + ">" + (jade.escape(null == (jade_interp = option.text) ? "" : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var option = $$obj[$index];

buf.push("<option" + (jade.attr("value", option.value, true, false)) + (jade.attr("selected", option.value === value, true, false)) + ">" + (jade.escape(null == (jade_interp = option.text) ? "" : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></label><div class=\"js-error\"></div>");
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};
buf.push("<div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\">");
jade_mixins["inputField"]('location', 'Location', 'Where the camp is held', rugbyCamp.location, true, 'text');
jade_mixins["dateField"]('startDate', 'Start Date', 'First day of the camp', rugbyCamp.startDate, true);
jade_mixins["dateField"]('endDate', 'End Date', 'Last day of the camp', rugbyCamp.endDate, true);
jade_mixins["selectField"]('type', 'Type', '', rugbyCamp.type, rugbyCampTypes, true);
jade_mixins["inputField"]('ageRange', 'Age Range', 'i.e. 5 to 8 years, Over 10s etc.', rugbyCamp.ageRange, true, 'text');
jade_mixins["inputField"]('days', 'Duration', 'Number of days of the camp', rugbyCamp.days, true, 'text');
jade_mixins["inputField"]('cost', 'Cost', 'Total cost of the camp in £', rugbyCamp.cost, true, 'text');
jade_mixins["inputField"]('bookingLink', 'Booking Link', 'Link to buy a place on this camp', rugbyCamp.bookingLink, false, 'text');
jade_mixins["inputField"]('addressLine1', 'Address Line 1', '', rugbyCamp.addressLine1, true, 'text');
jade_mixins["inputField"]('addressLine2', 'Address Line 2', '', rugbyCamp.addressLine2, false, 'text');
jade_mixins["inputField"]('addressLine3', 'Address Line 3', '', rugbyCamp.addressLine3, false, 'text');
jade_mixins["inputField"]('town', 'Town', '', rugbyCamp.town, true, 'text');
jade_mixins["inputField"]('county', 'County', '', rugbyCamp.county, true, 'text');
jade_mixins["inputField"]('postcode', 'Postcode', 'Lat/long will be looked up once you\'ve entered a postcode', rugbyCamp.postcode, true, 'text');
buf.push("<p class=\"js-loading hidden\">Searching for coordinates...</p>");
jade_mixins["inputField"]('latitude', 'Latitude', '', rugbyCamp.latitude, false, 'number');
jade_mixins["inputField"]('longitude', 'Longitude', '', rugbyCamp.longitude, false, 'number');
buf.push("</div></div></form></div></div>");}.call(this,"rugbyCamp" in locals_for_with?locals_for_with.rugbyCamp:typeof rugbyCamp!=="undefined"?rugbyCamp:undefined,"rugbyCampTypes" in locals_for_with?locals_for_with.rugbyCampTypes:typeof rugbyCampTypes!=="undefined"?rugbyCampTypes:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const geocode = debounce(require('../lib/geocoder'), 800)
const types = require('../../../service/rugby-camp/types')()

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    const { account } = args
    this.account = account
    this.dateTimePickers = []
    this.richTextEditorInstanceManager = new RichTextEditorInstanceManager()
    this.initDateTimePickers = dateTimePickers(
      window.config.locale.longDateFormat.LLLL,
      this.model
    )
    this.on('remove', () => {
      this.richTextEditorInstanceManager.destroy()
      this.dateTimePickers.forEach((picker) => picker.destroy())
    })
    this.postcode = this.model.get('postcode')
    this.on('afterAppend', () =>
      this.$el.find('input[type=text]:first').focus()
    )
  }

  handlePostcodeChange() {
    const formPostcode = this.$el.find('input[name=postcode]').val()
    if (formPostcode.length < 3) return true
    const currentPostcode = this.postcode || ''

    if (currentPostcode !== formPostcode) {
      this.$el.find('.js-loading').removeClass('hidden')
      geocode(formPostcode, (coords) => {
        // TODO: Hide loading spinner on lat long
        if (coords) {
          this.$el.find('input[name=latitude]').val(coords.latitude)
          this.$el.find('input[name=longitude]').val(coords.longitude)
          this.postcode = formPostcode
          this.model.set('latitude', coords.latitude)
          this.model.set('longitude', coords.longitude)
        } else {
          this.$el.find('input[name=latitude]').val('')
          this.$el.find('input[name=longitude]').val('')
          this.model.set('latitude', '')
          this.model.set('longitude', '')
        }

        this.$el.find('.js-loading').addClass('hidden')
      })
    }
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New Rugby Camp' : 'Edit Rugby Camp',
        rugbyCamp: this.model.toJSON(),
        account: this.account,
        format: this.serviceLocator.format,
        rugbyCampTypes: types,
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))
    this.on('afterAppend', () => this.renderRichTextEditors())
    this.$el
      .find('.js-postcode')
      .on('keyup', this.handlePostcodeChange.bind(this))
    this.dateTimePickers = this.$el
      .find('.js-date-time-picker')
      .toArray()
      .map(this.initDateTimePickers)
    return this
  }

  renderRichTextEditors() {
    this.$el
      .find('.js-text-editor')
      .each((i, el) =>
        this.richTextEditorInstanceManager.create(el, { height: 300 })
      )
  }
}

module.exports = FormView
