module.exports = createGetRevisions
const Model = require('merstone')

function createGetRevisions(serviceLocator, collection, paginationModel) {
  function getRevisions(entityId, type) {
    serviceLocator.revisionService.listRevisionsForEntity(
      entityId,
      type,
      function (err, res) {
        if (err)
          return serviceLocator.logger.error(err, 'Could not load revisions')
        collection.reset(
          res.map(function (revision) {
            return new Model(serviceLocator, revision)
          })
        )
        paginationModel.set('totalItems', collection.models.length)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  return getRevisions
}
