const compileJade = require('browjadify-compile')
const debug = require('../../../../admin/source/js/lib/debug')(
  'upload drop ui delegate'
)
const join = require('path').join
const dropOverlayTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"drop-overlay\"></div>");;return buf.join("");
}

module.exports = function (view) {
  const $dropOverlay = $(dropOverlayTemplate())
  let dropOverlayTimeout = null

  $('html').one('dragenter', onDragenter)
  view.on('remove', removeDropUi)

  /*
   * Handle the start of a drag and drop action.
   * Gives the user feedback that the thing they
   * are dragging can be dropped.
   */
  function onDragenter() {
    debug('Appending drop overlay')
    $('body').append($dropOverlay)
    $('html').one('drop', onDrop)
    $('html').on('dragover', onDragover)
  }

  /*
   * Handle a drop. Removes the drop indicator.
   */
  function onDrop() {
    debug('Drop! Removing drop overlay')
    $dropOverlay.remove()
    $('html').one('dragenter', onDragenter)
    $('html').off('dragover', onDragover)
  }

  /*
   * The dragover gets called every few ms. If there is a
   * substantial delay between two it probably means the users
   * pointer has left the bounds of the browser, so set a short-ish
   * timeout to remove the drop indicator.
   */
  function onDragover() {
    // debug('Mouse re-entered window. Removing drop overlay')
    clearTimeout(dropOverlayTimeout)
    dropOverlayTimeout = setTimeout(function () {
      $dropOverlay.remove()
      $('html').off('drop', onDrop)
      $('html').one('dragenter', onDragenter)
    }, 100)
  }

  /*
   * Remove the drop UI behviour
   */
  function removeDropUi() {
    $('html').off('dragenter', onDragenter)
    $('html').off('drop', onDrop)
    $('html').off('dragover', onDragover)
  }
}
