module.exports = NavigationSectionField
const join = require('path').join
const View = require('ventnor')
const InstanceSectionSelect = require('../../../section/views/instance-section-select')
const compileJade = require('browjadify-compile')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div id=\"field--section\" data-field=\"section\" class=\"form-row js-section-select-form-row\"><span class=\"form-label-text\">Section</span><div class=\"js-instance-sections form-field\"></div><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Choose the section that this navigation item will link to</p></div></div>");;return buf.join("");
}

function NavigationSectionField (serviceLocator, item, instance) {
  View.call(this, serviceLocator)
  this.item = item
  this.instance = instance
}

NavigationSectionField.prototype = Object.create(View.prototype)

NavigationSectionField.prototype.render = function () {
  this.$el.append(template())

  this.serviceLocator.sectionService.find('', { instance: this.instance._id }, [], {}, function (error, res) {
    if (error) return this.serviceLocator.logger.error('Cannot find sections', error)
    const sectionIds = []
    res.results.forEach(function (section) {
      sectionIds.push(section._id)
    })

    const instanceSectionSelect = new InstanceSectionSelect(this.serviceLocator, this.item.section, sectionIds)
    this.$el.find('.js-instance-sections').append(instanceSectionSelect.render().$el)
    instanceSectionSelect.on('change', function (section) { this.emit('change', section) }.bind(this))
    this.attachView(instanceSectionSelect)
  }.bind(this))

  return this
}
