const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')

module.exports = function () {
  return schemata({
    name: 'Keyword schema',
    properties: {
      keyword: {
        type: String,
        validators: [required],
      },
    },
  })
}
