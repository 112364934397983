module.exports = FormView
const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (competition, isEditable, isNew, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form>");
if ( !isEditable)
{
buf.push("<div class=\"notification notification--notice\"><p>This competition is maintained via the opta feed and only some fields will be editable.</p></div>");
}
buf.push("<div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\">");
if ( isNew)
{
buf.push("<div id=\"field--_id\" data-field=\"_id\" class=\"form-row js-id-row\"><label><span class=\"form-label-text\">ID<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"_id\"" + (jade.attr("value", competition._id, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>This must be in the format of 'optaId-season' E.g. 201-2018</p></div></div><div id=\"field--optaId\" data-field=\"optaId\" class=\"form-row js-opta-id-row\"><label><span class=\"form-label-text\">Opta ID</span><input type=\"text\" name=\"optaId\"" + (jade.attr("value", competition.optaId, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Competition ID provided by Opta</p></div></div><div id=\"field--source\" data-field=\"source\" class=\"form-row\"><label><span class=\"form-label-text\">Source<abbr title=\"This field is required\">*</abbr></span><select name=\"source\" class=\"control control--choice form-field js-source-select\"><option value=\"opta\"" + (jade.attr("selected", competition.source === 'opta', true, false)) + ">Opta</option><option value=\"cms\"" + (jade.attr("selected", competition.source === 'cms', true, false)) + ">CMS</option></select></label><div class=\"js-error\"></div></div>");
}
buf.push("<div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">Name<abbr title=\"This field is required\">*</abbr></span>");
if ( isEditable)
{
buf.push("<input type=\"text\" name=\"name\"" + (jade.attr("value", competition.name, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/>");
}
else
{
buf.push("<span class=\"control form-field\">" + (jade.escape(null == (jade_interp = competition.name) ? "" : jade_interp)) + "</span>");
}
buf.push("</label><div class=\"js-error\"></div></div><div id=\"field--slug\" data-field=\"slug\" class=\"form-row\"><label><span class=\"form-label-text\">Slug<abbr title=\"This field is required\">*</abbr></span>");
if ( isEditable)
{
buf.push("<input type=\"text\" name=\"slug\"" + (jade.attr("value", competition.slug, true, false)) + " class=\"control control--text form-field\"/>");
}
else
{
buf.push("<span class=\"control form-field\">" + (jade.escape(null == (jade_interp = competition.slug) ? "" : jade_interp)) + "</span>");
}
buf.push("</label><div class=\"js-error\"></div></div><div id=\"field--season\" data-field=\"season\" class=\"form-row js-season\"><label><span class=\"form-label-text\">Season<abbr title=\"This field is required\">*</abbr></span><div class=\"js-seasons form-field\"></div></label><div class=\"js-error\"></div></div><div id=\"field--squad\" data-field=\"squad\" class=\"form-row js-squad\"><label><span class=\"form-label-text\">Squad<abbr title=\"This field is required\">*</abbr></span><div class=\"js-squads form-field\"></div></label><div class=\"js-error\"></div></div></div></div></form></div></div>");}.call(this,"competition" in locals_for_with?locals_for_with.competition:typeof competition!=="undefined"?competition:undefined,"isEditable" in locals_for_with?locals_for_with.isEditable:typeof isEditable!=="undefined"?isEditable:undefined,"isNew" in locals_for_with?locals_for_with.isNew:typeof isNew!=="undefined"?isNew:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const slug = require('slugg')
const SeasonSelector = require('../../season/views/season-selector')
const SquadSelector = require('../../squad/views/squad-selector')

function FormView(serviceLocator, model, isNew) {
  BaseFormView.call(this, serviceLocator, model, isNew)

  this.$el.on('change', '[name=name]', this.generateSlug.bind(this))
  this.$el.on('change', '.js-source-select', this.handleSourceChange.bind(this))
  this.seasonSelectView = new SeasonSelector(
    serviceLocator,
    this.model.get('season'),
    this.model.isEditable()
  )
  this.squadSelectView = new SquadSelector(
    serviceLocator,
    this.model.get('squad'),
    true,
    this.model.isEditable()
  )

  this.on(
    'afterAppend',
    function () {
      this.$el.find('input[type=text]:first').focus()
    }.bind(this)
  )
}

FormView.prototype = Object.create(BaseFormView.prototype)

FormView.prototype.generateSlug = function () {
  const name = this.$el.find('[name=name]').val()
  const $slug = this.$el.find('[name=slug]')
  if (!$slug.val()) $slug.val(slug(name)).change()
}

FormView.prototype.handleSourceChange = function (e) {
  this.model.set('source', e.target.value)
  this.showSourceFields()
}

FormView.prototype.showSourceFields = function () {
  const source = this.model.get('source')
  if (this.isNew && source === 'cms') {
    this.$el.find('.js-id-row').hide()
    this.$el.find('.js-opta-id-row').hide()
  } else if (this.isNew && source === 'opta') {
    this.$el.find('.js-id-row').show()
    this.$el.find('.js-opta-id-row').show()
  }
}

FormView.prototype.render = function () {
  // Render the template
  this.$el.append(
    template({
      title: this.isNew ? 'New Competition' : 'Edit Competition',
      competition: this.model.toJSON(),
      format: this.serviceLocator.format,
      isEditable: this.model.isEditable(),
      isNew: this.isNew,
    })
  )

  // Render the toolbar
  this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))
  this.seasonSelectView.render().$el.appendTo(this.$el.find('.js-seasons'))
  this.squadSelectView.render().$el.appendTo(this.$el.find('.js-squads'))
  this.showSourceFields()

  return this
}
