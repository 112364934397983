const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format, resource) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed(resource, 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label>");
}
if ( allowed(resource, 'download'))
{
buf.push("<div class=\"btn-group\"><a class=\"btn btn--small js-download-csv\">Download Responses</a></div>");
}
buf.push("</div><h2><a" + (jade.attr("href", '/' + (resource) + 's/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.title) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content\"><dl><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"resource" in locals_for_with?locals_for_with.resource:typeof resource!=="undefined"?resource:undefined));;return buf.join("");
}
const signUri = require('cf-signature/uri')
const BaseListItemView = require('../../base/views/list-item')

class ListItemView extends BaseListItemView {
  constructor(...args) {
    super(...args)
    this.$el.on('click', '.js-images-add', (e) => this.toggleSelect(e))
    this.$el.on('click', '.js-download-csv', (e) => this.downloadCsv())
    this.baseExportUrl = '/form-builders/'
  }
  serialize() {
    const data = this.model.toJSON()
    return data
  }

  downloadCsv() {
    const apiKey = window.localStorage.apiKey
    const id = window.localStorage.apiId
    const uri = this.baseExportUrl + this.model.get('_id') + '/responses'
    window.location = window.config.apiUrl + signUri(uri, apiKey, id)
  }

  render() {
    this.$el.empty().append(
      template({
        data: this.model.toJSON(),
        allowed: this.serviceLocator.allowed,
        resource: this.resource,
        format: this.serviceLocator.format,
      })
    )
    return this
  }
}

module.exports = ListItemView
