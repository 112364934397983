const editView = require('./views/form')
const model = require('./models/model')
const itemView = require('../../../widget/views/item/edit-base')

const initWidget = () => {
  return { matchStatsWidget: [ 'sectionAdmin', init ] }
}

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Match Stats',
    description: 'Display statistics for a match',
  }

  serviceLocator.widgetFactories.get('match').register('matchStats', widget)
  done()
}

module.exports = initWidget
