module.exports = InstanceSelect
const View = require('ventnor')

function InstanceSelect(serviceLocator, selectedInstances) {
  View.apply(this, arguments)
  this.$el = $('<select multiple/>')
  this.$el.addClass('control control--choice')
  this.el = this.$el[0]
  this.$el.attr('placeholder', 'Choose one or more instances')
  this.selectedInstances = selectedInstances || []
}

InstanceSelect.prototype = Object.create(View.prototype)

InstanceSelect.prototype.initializeSelectize = function () {
  if (!this.selectedInstances.length) {
    return this.el.selectize.on('change', this.updateSelection.bind(this))
  }
  var filter = { _id: { $in: this.selectedInstances } }
  this.serviceLocator.instanceService.find(
    '',
    filter,
    [],
    {},
    function (err, res) {
      if (err)
        return this.serviceLocator.logger.error(
          err,
          'Cannot find existing instances'
        )
      res.results.forEach(
        function (instance) {
          // The item needs to be added to the list
          // of selectize options in order to be selected
          this.el.selectize.addOption({
            value: instance._id,
            text: instance.name,
          })
          // Select the added option
          this.el.selectize.addItem(instance._id)
        }.bind(this)
      )
      this.el.selectize.on('change', this.updateSelection.bind(this))
    }.bind(this)
  )
}

InstanceSelect.prototype.updateSelection = function () {
  this.selectedInstances = this.el.selectize.getValue()
  this.emit('change', this.selectedInstances)
}

InstanceSelect.prototype.load = function (query, cb) {
  this.serviceLocator.instanceService.find(
    query,
    {},
    [],
    {},
    function (err, data) {
      if (err)
        return this.serviceLocator.logger.error(err, 'Cannot find instances')
      cb(
        data.results.map(function (instance) {
          return { value: instance._id, text: instance.name }
        })
      )
    }.bind(this)
  )
}

InstanceSelect.prototype.render = function () {
  setTimeout(
    function () {
      this.$el.selectize({
        delimiter: ',',
        persist: false,
        create: false,
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this),
        preload: true,
      })
    }.bind(this),
    0
  )
  return this
}
