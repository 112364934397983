const createListController = require('./controllers/list')
const createFormController = require('./controllers/form')
const TeamService = require('./services/team')

function initAdmin() {
  return { teamAdmin: [ 'widget', init ] }
}

function init(serviceLocator, cb) {
  serviceLocator.teamService = new TeamService(serviceLocator)

  createListController(serviceLocator)
  createFormController(serviceLocator)
  cb(null)
}

module.exports = initAdmin
