const BaseModel = require('cf-base-model')
const schemata = require('../../../service/asset/schema')()
const bytes = require('bytes')
const clone = require('lodash.clonedeep')
const config = window.config
const createDarkroomUrlBuilder = require('@clocklimited/darkroom-url-builder')

module.exports = BaseModel.extend({
  idAttribute: '_id',
  initialize: function () {
    BaseModel.prototype.initialize.call(this)
    this.urlBuilder = createDarkroomUrlBuilder(
      config.darkroom.url,
      config.darkroom.salt
    )
  },

  schemata: schemata,

  defaults() {
    return { dateCreated: new Date(), binaryUri: null }
  },

  toJSON: function () {
    return schemata.stripUnknownProperties(clone(this.attributes))
  },

  checkSize: function (size, cb) {
    const thresholds = schemata.getProperties().size.thresholds
    let error = null

    // Validate for on set with a size attribute
    if (size > bytes(thresholds.error)) {
      error = new Error(
        'File size exceeds the maximum size of ' + thresholds.error
      )
      error.level = 'error'
    } else if (size > bytes(thresholds.warning)) {
      error = new Error('File size is large, this may take a while…')
      error.level = 'warning'
    }

    cb(error)
  },

  buildInfoUrl: function () {
    return this.urlBuilder().resource(this.get('binaryUri')).info()
  },

  buildPreviewUrl: function (width, height) {
    var img = this.urlBuilder()
      .resource(this.get('binaryUri'))
      .width(width || 500)
      .mode('cover')

    if (height) img.height(height)

    return img.filename(this.get('name')).url()
  },

  buildOriginalUrl: function () {
    return this.urlBuilder()
      .resource(this.get('binaryUri'))
      .filename(this.get('name'))
      .url()
  },
})
