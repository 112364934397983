module.exports = ListFilterView
const compileJade = require('browjadify-compile')
const View = require('ventnor')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (instances, undefined) {
buf.push("<div class=\"panel panel-styled panel-fixed\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Instance</span><select name=\"instance\" class=\"control control--choice form-field\">");
if ( instances.length >= 2)
{
buf.push("<option value=\"\">All Instances</option><option value=\"_global\">-- Global Sections --</option>");
}
// iterate instances
;(function(){
  var $$obj = instances;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var instance = $$obj[$index];

buf.push("<option" + (jade.attr("value", instance._id, true, false)) + ">" + (jade.escape(null == (jade_interp = instance.name) ? "" : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var instance = $$obj[$index];

buf.push("<option" + (jade.attr("value", instance._id, true, false)) + ">" + (jade.escape(null == (jade_interp = instance.name) ? "" : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");}.call(this,"instances" in locals_for_with?locals_for_with.instances:typeof instances!=="undefined"?instances:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

function ListFilterView(serviceLocator, listView) {
  View.apply(this, arguments)
  this.instances = listView.instances
  this.$el.addClass('list-filters')
  this.$el.on('submit', 'form', this.handleSubmit.bind(this))
  this.$el.on('click', 'input[type=reset]', this.handleClear.bind(this))
  this.$el.on('change', '[name=instance]', this.handleSubmit.bind(this))
}

ListFilterView.prototype = Object.create(View.prototype)

// Compatibility with backbone admin
ListFilterView.prototype.trigger = View.prototype.emit

ListFilterView.prototype.updateDisplay = function (params) {
  if (params.filter.instance === null) {
    this.$el
      .find('[name=instance] option[value=_global]')
      .attr('selected', true)
  }

  if (params.filter.instance) {
    this.$el
      .find('[name=instance] option[value=' + params.filter.instance + ']')
      .attr('selected', true)
  }
}

ListFilterView.prototype.handleSubmit = function (e) {
  if (e) e.preventDefault()

  const params = { filter: {}, sort: [ 'fullUrlPath', 'asc' ] }
  const instance = this.$el.find('form [name=instance]').val()
  if (instance) {
    if (instance === '_global') {
      params.filter.instance = null
    } else {
      params.filter.instance = instance
    }
  }

  this.emit('filter', params)
}

ListFilterView.prototype.handleClear = function (e) {
  e.preventDefault()
  this.$el.find('form [name=instance]').val('')
  this.handleSubmit()
}

ListFilterView.prototype.render = function () {
  this.$el.empty().append(template({ instances: this.instances }))
  this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
  return this
}
