module.exports = ListItemView
const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format) {
buf.push("<div class=\"list-item list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( data.active)
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Active&lt;/strong&gt;&lt;br&gt;This keyword collection will be linked within articles.\" class=\"label label--large label--standard js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Inactive&lt;/strong&gt;&lt;br&gt;This keyword collection will not be linked within articles.\" class=\"label label--large js-tooltip-trigger\"><i class=\"icon icon--draft\"></i></span>");
}
if ( allowed('linkedKeyord', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label>");
}
buf.push("</div>");
if ( allowed('linkedKeyword', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", 'linked-keywords/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd><dt>Link:</dt><dd>" + (jade.escape(null == (jade_interp = data.link) ? "" : jade_interp)) + "</dd><dt>Active:</dt><dd>" + (jade.escape(null == (jade_interp = data.active) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}

function ListItemView() {
  BaseListItemView.apply(this, arguments)
}

ListItemView.prototype = Object.create(BaseListItemView.prototype)

ListItemView.prototype.template = template
