const Model = require('./models/model')

const init = (serviceLocator, done) => {
  const widget = {
    model: Model,
    name: 'Share',
    description: 'Display share buttons',
  }

  ;[ 'match', 'article', 'articleBody' ].map((layout) =>
    serviceLocator.widgetFactories.get(layout).register('share', widget)
  )

  done()
}

const initWidget = () => ({ shareWidget: [ 'sectionAdmin', init ] })

module.exports = initWidget
