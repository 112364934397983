const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<div data-field=\"keyword\" class=\"form-row\"><label class=\"form-label-text counter-answer\">Keyword</label><div class=\"form-field\"><div class=\"control-addon\"><input type=\"text\" name=\"keyword\"" + (jade.attr("value", data.keyword, true, false)) + " class=\"control control--text control-addon__base\"/><div class=\"control-addon__affix control-addon__affix--suffix\"><button type=\"button\" class=\"btn btn--large js-keyword-delete\"><i class=\"icon icon--cross--dark\">Delete</i></button></div></div><div class=\"js-error\"></div></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const BaseFormView = require('../../base/views/form')

class KeywordFormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    this.$el.on('click', '.js-keyword-delete', this.handleDelete.bind(this))
  }

  handleDelete() {
    this.trigger('remove', this.model.cid)
    this.remove()
  }

  render() {
    this.$el.empty().append(
      template({
        data: this.model.toJSON(),
      })
    )

    return this
  }
}

module.exports = KeywordFormView
