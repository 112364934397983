const Model = require('./models/model')
const initWidget = () => ({ relatedContentWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  serviceLocator.widgetFactories.get('article').register('relatedContent', {
    model: Model,
    name: 'Related Content',
    description: 'Lists content related to the article in view.',
  })
  done()
}

module.exports = initWidget
