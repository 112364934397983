const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const regexMatch = require('validity-regex-match')
const createUniqueValidator = require('validity-unique-property')
const validateIfSet = require('validity-validate-if-set')

module.exports = function (findOne) {
  return schemata({
    name: 'Account schema',
    properties: {
      _id: { type: String },
      name: { type: String, validators: [required] },
      logo: { type: String },
      domain: {
        type: String,
        validators: {
          all:
            // TODO remove duplicate call to validate wrapper,
            // make module support arrays of validators
            [
              validateIfSet(required),
              validateIfSet(
                regexMatch(
                  new RegExp(
                    '^((?=[^.]{1,63}(?:\\.|$))(?![^.]*-(?:\\.|$))' +
                      '[0-9a-z][0-9a-z-]*)(\\.(?=[^.]{1,63}(?:\\.|$))(?![^.]*-(?:\\.|$))' +
                      '[0-9a-z][0-9a-z-]*)+$'
                  ),
                  'Must be a valid domain name'
                )
              ),
              createUniqueValidator(findOne),
            ],
        },
      },
      primaryInstance: { type: String },
      categories: { type: Array, validators: [required] },
      supportEmailAddress: { type: String, validators: [required] },
      addThisId: { type: String },
      facebookId: { type: String },
      twitterId: { type: String },
      googlePlusId: { type: String },
      pinterestId: { type: String },
      instagramId: { type: String },
      googleAnalyticsPropertyId: { type: String },
      footerHtml: { type: String },
      dateCreated: { type: Date, defaultValue: () => new Date() },
    },
  })
}
