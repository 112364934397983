const MatchService = require('./services/match')
const createWidgetFactory = require('../widget/lib/base-factory')
const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/match')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')

const initAdmin = () => ({ matchAdmin: [ 'widget', init ] })

const init = (serviceLocator, cb) => {
  serviceLocator.matchService = new MatchService(serviceLocator)

  const widgetFactory = createWidgetFactory()
  serviceLocator.widgetFactories.register('match', widgetFactory)

  // Entity services get all the widgets from sectionw widget factory
  const sectionWidgetFactory = serviceLocator.widgetFactories.get('section')
  serviceLocator.componentLoader.on('complete', () => {
    Object.keys(sectionWidgetFactory.list()).forEach((widget) =>
      widgetFactory.register(widget, sectionWidgetFactory(widget))
    )
  })

  const currentParams = { keywords: '', filter: {}, sort: [ 'dateTime', 'desc' ] }

  const controllerOptions = {
    serviceLocator,
    singular: 'match',
    plural: 'matches',
    path: 'matches',
    service: serviceLocator.matchService,
    Model,
    currentParams,
  }

  const customListViewInitiator = (list, router) => {
    list.on('commentary', (id) => {
      if (!serviceLocator.allow('match', 'update')) return false
      serviceLocator.router.navigate(`matches/${id}/commentary`, {
        trigger: true,
      })
    })
  }

  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(
    Object.assign({}, controllerOptions, { ListView, customListViewInitiator })
  )
  cb(null)
}

module.exports = initAdmin
