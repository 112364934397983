module.exports = NavigationItemModel

const Model = require('merstone')
const createSchema = require('../../../service/instance/navigation-item-schema')
const ImageAreaModel = require('../../asset/models/image-area')

function NavigationItemModel (serviceLocator, attributes) {
  this.schema = createSchema()
  attributes = Object.assign(this.schema.makeDefault(), attributes)
  attributes = this.schema.cast(attributes || {})
  Model.apply(this, arguments)

  this.hook('preSet', function (attrs, cb) {
    cb(null, this.schema.cast(attrs))
  }.bind(this))

  this.images = new ImageAreaModel(this.get('images')).bind(this)

  this.images.on('add remove change', () =>
    this.set('images', this.images.toJSON())
  ).bind(this)
}

NavigationItemModel.prototype = Object.create(Model.prototype)

NavigationItemModel.prototype.validate = function (cb) {
  this.schema.validate(this.attributes, cb)
}

NavigationItemModel.prototype.reset = function (attrs) {
  attrs = this.schema.cast(attrs || {})
  Model.prototype.reset.call(this, attrs)
}
