module.exports = initAdmin
const createFormController = require('./controllers/form')
const createListController = require('./controllers/list')
const LinkedKeywordService = require('./services/linked-keyword')

function initAdmin() {
  return { linkedKeyword: init }
}

function init(serviceLocator, done) {
  serviceLocator.linkedKeywordService = new LinkedKeywordService(serviceLocator)
  createFormController(serviceLocator)
  createListController(serviceLocator)
  done()
}
