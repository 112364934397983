const PollListView = require('../../question/views/list')
const PollListItemView = require('./list-item')

class ListView extends PollListView {
  get name() {
    return { singular: 'Poll', plural: 'Polls', resource: 'poll' }
  }

  get ListItemView() {
    return PollListItemView
  }
}

module.exports = ListView
