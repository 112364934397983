module.exports = initAdmin
const createListController = require('./controllers/list')
const createFormController = require('./controllers/form')
const SponsorService = require('./services/sponsor')

function initAdmin() {
  return { sponsorAdmin: [ init ] }
}

function init(serviceLocator, cb) {
  serviceLocator.sponsorService = new SponsorService(serviceLocator)
  createListController(serviceLocator)
  createFormController(serviceLocator)

  cb(null)
}
