import editView from './views/form'
import model from './models/model'
import itemView from '../../widget/views/item/edit-base'

const initWidget = () => ({ heritageWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Heritage',
    description: 'Heritage',
  }

  serviceLocator.widgetFactories.get('section').register('heritage', widget)
  done()
}

module.exports = initWidget
