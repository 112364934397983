const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const extend = require('lodash.assign')
const required = require('@clocklimited/validity-required')
const createContextValidator = require('validity-cf-image-context-selection')

module.exports = () =>
  schemata({
    name: 'Inline image',
    properties: {
      images: {
        type: Object,
        defaultValue: function () {
          return {}
        },
        validators: { all: [createContextValidator(['Inline'])] },
      },
      title: { type: String, validators: { all: [required] } },
      link: { type: String },
      externalLink: { type: Boolean },
      alternatePagination: { type: Boolean },
      caption: { type: String },
      selectedCrop: { type: String, validators: { all: [required] } },
      width: { type: String, validators: { all: [required] } },
      position: { type: String, validators: { all: [required] } },
      showOriginalWhenClicked: { type: Boolean },
      displayShards: { type: Boolean },
      ...baseSchema.getProperties(),
    },
  })
