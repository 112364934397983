const urlValidity = require('validity-url-optional-tlds')()
const urlPathValidator = require('./urlPathValidator')

function urlOrPathValidator(key, keyDisplayName, object, cb) {
  if (object[key].substr(0, 4) === 'http') {
    return urlValidity(key, keyDisplayName, object, cb)
  }
  return urlPathValidator(key, keyDisplayName, object, cb)
}

module.exports = urlOrPathValidator
