const BaseModel = require('cf-base-model')
const schemata = require('schemata')
const required = require('@clocklimited/validity-required')

const WidgetAreaModel = require('../../../../widget/models/widget-area')

const schema = schemata({
  name: 'Tabs Item',
  properties: {
    title: {
      type: String,
      validators: { all: [required] },
    },
    body: {
      type: Object,
      defaultValue: function () {
        return { widgets: [] }
      },
      validators: { draft: [], published: [], archived: [] },
    },
  },
})

module.exports = BaseModel.extend({
  schema,

  defaults() {
    return schema.makeDefault()
  },

  initialize(item, serviceLocator) {
    this.body = new WidgetAreaModel(this.get('body'), {
      abstractWidgetFactory: serviceLocator.widgetFactories.get('tabsBody'),
    })

    this.body.on(
      'add remove change',
      function () {
        this.set('body', this.body.toJSON())
      }.bind(this)
    )
  },

  validate(cb) {
    this.schema.validate(this.attributes, (ignoreErr, errors) =>
      cb(Object.keys(errors).length > 0 ? errors : undefined)
    )
  },
})
