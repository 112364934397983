module.exports = ListItemView
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<div class=\"list-item list-item-minimal\"><div class=\"list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><h4>" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</h4></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const View = require('ventnor')

function ListItemView(serviceLocator, model) {
  View.apply(this, arguments)
  this.model = model
  this.$el.attr('data-SponsorId', this.model.id)
}

ListItemView.prototype = Object.create(View.prototype)

ListItemView.prototype.render = function () {
  this.$el.empty().append(template({ data: this.model.toJSON() }))
  this.$el.draggable({
    addClasses: false,
    helper: 'clone',
    appendTo: 'body',
    zIndex: 10000,
    connectToSortable: '.js-selected-sponsor-list',
    start: function (event, ui) {
      ui.helper.height(30).width(300)
    },
  })
  return this
}
