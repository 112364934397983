const BaseModel = require('cf-base-model')
const schemata = require('./schema')()
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const config = window.config
const createImageUrlBuilder = require('cf-image-url-builder')
const ImageAreaModel = require('../../../asset/models/image-area')

module.exports = BaseModel.extend({
  schemata: schemata,
  type: 'sectionPromo',
  defaults() {
    return schemata.makeDefault({ type: this.type || 'sectionPromo' })
  },
  validate: validateDelegate,
  initialize: function () {
    this.images = new ImageAreaModel(this.get('images'))
  },
  getPreviewImageUrl: function () {
    const images = this.images.widgets.map(function (widget) {
      return widget.toJSON()
    })
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, images)
    const image = urlBuilder.getImage('Main')
    if (!image) return null
    return image.crop('Landscape').constrain(300).url()
  },
})
