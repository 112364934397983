const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()

const schema = schemata({
  name: 'Related articles schema',
  properties: Object.assign({}, baseSchema.getProperties(), {
    articles: {
      type: Array,
      defaultValue: () => [],
    },
  }),
})

module.exports = BaseModel.extend({
  idAttribute: 'id',

  schemata: schema,

  type: 'relatedArticles',

  defaults() {
    return schema.makeDefault({ type: this.type || 'relatedArticles' })
  },

  validate: validateDelegate,
})
