module.exports = function (debug) {
  var expander = {
    handleExpandAll: function () {
      debug('handle expand all')
      const button = this.$('.js-expander-toggle')
      const expander = this.$('.js-expander')
      button.text(button.data('text-collapse'))
      expander.removeClass('expander-collapsed')
      expander.addClass('expander-expanded')
      $(window).resize()
    },

    handleCollapseAll: function () {
      debug('handle collapse all')
      const button = this.$('.js-expander-toggle')
      const expander = this.$('.js-expander')
      button.text(button.data('text-expand'))
      expander.removeClass('expander-expanded')
      expander.addClass('expander-collapsed')
      $(window).resize()
    },

    toggleExpander: function (e) {
      const button = $(e.target)
      const expander = button.closest('.js-expander')

      if (expander.hasClass('expander-collapsed')) {
        debug('handle expander expand')
        button.text(button.data('text-collapse'))
        expander.removeClass('expander-collapsed')
        expander.addClass('expander-expanded')
      } else {
        debug('handle expander collapse')
        button.text(button.data('text-expand'))
        expander.removeClass('expander-expanded')
        expander.addClass('expander-collapsed')
      }
      $(window).resize()
    },
  }

  return expander
}
