const BaseModel = require('cf-base-model')
const debug = require('../../../../admin/source/js/lib/debug')(
  'widget image model'
)
const config = window.config
const imageHelper = require('../lib/image-crop-helper')
const createDarkroomUrlBuilder = require('@clocklimited/darkroom-url-builder')
const schemata = require('../schema/image')()
const clone = require('lodash.clonedeep')
const find = require('lodash.find')

module.exports = BaseModel.extend({
  schemata: schemata,

  initialize: function () {
    BaseModel.prototype.initialize.call(this)
    this.urlBuilder = createDarkroomUrlBuilder(
      config.darkroom.url,
      config.darkroom.salt
    )
  },

  defaults() {
    return schemata.makeDefault({ type: 'image' })
  },

  toJSON: BaseModel.prototype.toJSON,

  setDefaultCrops: function (crops, noRequest) {
    this.trigger('retrievingCrops')

    debug('setting up default crops', crops)

    const defaultCrops = this.calculateBestFitDefaultCrops(crops)
    const updatedCrops = defaultCrops
    const currentCrops = this.get('crops')

    if (Array.isArray(currentCrops)) {
      defaultCrops.forEach(function (crop) {
        var found = find(currentCrops, { aspectRatio: crop.aspectRatio })
        if (!found) currentCrops.push(crop)
      })
    }

    this.set({ crops: updatedCrops }, { silent: true })

    debug('making request for default crops', defaultCrops)

    if (!noRequest) this.retrieveCrops(this.getCropRequestData(defaultCrops))
  },

  setCrops: function () {
    this.trigger('retrievingCrops')
    this.retrieveCrops(this.getCropRequestData(this.get('crops')))
    debug('updating crops', this.get('crops'))
  },

  retrieveCrops: function (cropData) {
    const url = config.darkroom.url + '/crop'
    const data = { src: this.get('binaryUri'), crops: cropData }

    $.ajax({
      type: 'POST',
      url: url,
      data: JSON.stringify(data),
      headers: { 'x-darkroom-key': config.darkroom.key },
      success: this.cropsReceived,
      context: this,
      error: function () {
        this.trigger('cropsFailed')
        debug('crop retrieval failed')
      },
      dataType: 'json',
      contentType: 'application/json',
    })
  },

  cropsReceived: function (crops) {
    debug('Retrieved crops', crops)

    var numberOfCropsActual = 0

    Object.keys(crops).forEach(
      function (key) {
        var newCrop = crops[key]
        this.get('crops').forEach(
          function (crop) {
            if (this.makeImageKey(crop) === key) {
              debug('found image')
              crop.src = newCrop
              numberOfCropsActual += 1
            } else {
              debug('comparing', this.makeImageKey(crop), 'to', key)
            }
          }.bind(this)
        )
      }.bind(this)
    )

    // This clears the processing images overlay.
    this.trigger('change')

    // In the rare event we can't match our local crops with those made by darkroom.
    if (this.get('crops').length !== numberOfCropsActual) {
      this.trigger('cropsFailed')
    }

    // notify interface of crop retrieval.
    debug('crop retrieval received')
  },

  getCropRequestData: function (data) {
    return data.map(function (crop) {
      const coordinates = crop.coordinates
      const result = {
        x1: coordinates.x1,
        x2: coordinates.x2,
        y1: coordinates.y1,
        y2: coordinates.y2,
        w: coordinates.w,
        h: coordinates.h,
      }
      return result
    })
  },

  calculateBestFitDefaultCrops: function (crops) {
    return crops.map(function (crop) {
      crop = clone(crop)
      const width = this.get('width')
      const height = this.get('height')
      const aspectRatio = imageHelper.parseRatioToFloat(crop.aspectRatio)
      const bestFitData = imageHelper.calculateBestFit(
        width,
        height,
        aspectRatio
      )
      debug('Calculating default crop for ' + crop.name, width + 'x' + height)
      crop.coordinates = {
        x1: 0,
        y1: 0,
        x2: bestFitData[0],
        y2: bestFitData[1],
        w: bestFitData[0],
        h: bestFitData[1],
      }

      return crop
    }, this)
  },

  makeImageKey: function (crop) {
    var item = crop.coordinates
    return [
      item.x1,
      item.x2,
      item.y1,
      item.y2,
      item.w,
      item.h,
      this.get('binaryUri'),
    ].join(':')
  },

  buildInfoUrl: function () {
    return this.urlBuilder().resource(this.get('binaryUri')).info()
  },

  buildPreviewUrl: function (width, height) {
    var img = this.urlBuilder()
      .resource(this.get('binaryUri'))
      .width(width || 500)
      .mode('cover')

    if (height) img.height(height)

    return img.filename(this.get('name')).url()
  },

  buildOriginalUrl: function () {
    return this.urlBuilder()
      .resource(this.get('binaryUri'))
      .filename(this.get('name'))
      .url()
  },
})
