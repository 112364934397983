module.exports = function () {
  return function (attributes, cb) {
    this.schemata.validate(this.schemata.cast(attributes), function (
      ignoreErr,
      errors
    ) {
      return cb(Object.keys(errors).length > 0 ? errors : undefined)
    })
  }
}
