import editView from './views/form'
import model from './models/model'
import itemView from '../../widget/views/item/edit-base'

const initWidget = () => ({
  eventsWidget: [ 'sectionAdmin', 'eventAdmin', init ],
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Events',
    description: 'Display upcoming events and a filter',
  }

  serviceLocator.widgetFactories.get('section').register('events', widget)

  done()
}

module.exports = initWidget
