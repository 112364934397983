import FormView from './views/form'
import Model from './models/model'
import ItemView from './views/item'

const initWidget = () => {
  return { relatedArticles: [ 'widget', 'articleAdmin', init ] }
}

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    name: 'Related Articles',
    itemView: ItemView,
    description: 'Show related articles',
  }
  serviceLocator.widgetFactories
    .get('relatedArticles')
    .register('relatedArticles', widget)

  done()
}

module.exports = initWidget
