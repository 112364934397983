module.exports = ListModel
const Model = require('merstone')
const createSchema = require('../../../service/list/schema')

function ListModel(serviceLocator, attributes) {
  Model.apply(this, arguments)
  this.schema = createSchema()
  this.attributes = this.schema.cast(attributes || {})

  this.hook(
    'preSet',
    function (attrs, cb) {
      cb(null, this.schema.cast(attrs))
    }.bind(this)
  )
}

ListModel.prototype = Object.create(Model.prototype)

ListModel.prototype.validate = function (cb) {
  this.schema.validate(this.attributes, cb)
}

ListModel.prototype.reset = function (attrs) {
  attrs = this.schema.cast(attrs || {})
  Model.prototype.reset.call(this, attrs)
}

ListModel.prototype.setOrder = function (ids) {
  const oldOrder = this.get('items') || []
  let newOrder = []
  if (!oldOrder) throw new Error('This list has no items')
  ids.forEach(function (id) {
    newOrder = newOrder.concat(
      oldOrder.filter(function (item) {
        return item.itemId === id
      })
    )
  })
  if (newOrder.length !== oldOrder.length)
    throw new Error('Not all items were sorted')
  this.set('items', newOrder)
}
