const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const createImageUrlBuilder = require('cf-image-url-builder')
const join = require('path')

const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format) {
buf.push("<div class=\"list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-image: url("' + data.previewImageUrlSmall + '");', true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('advert', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("</div>");
if ( allowed('advert', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/adverts/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.description || '???') ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.description) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Unit Name:</dt><dd>" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</dd><dt>Active:</dt><dd>" + (jade.escape(null == (jade_interp = data.active ? 'Yes' : 'No') ? "" : jade_interp)) + "</dd><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.createdDate, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const config = window.config

class ListItemView extends BaseListItemView {
  serialize() {
    const images = this.model.get('images').widgets
    const data = this.model.toJSON()
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(
      drUrl,
      drKey,
      this.model.get('images').widgets
    )

    if (
      images &&
      images.length &&
      urlBuilder.getImage('Main') &&
      urlBuilder.getImage('Main').crop('MPU')
    ) {
      data.previewImageUrlSmall = urlBuilder
        .getImage('Main')
        .crop('MPU')
        .constrain(150)
        .url()
      data.previewImageUrlLarge = urlBuilder
        .getImage('Main')
        .crop('MPU')
        .constrain(400)
        .url()
    } else {
      data.previewImageUrlSmall = '/assets/img/content/no-photo-medium.png'
      data.previewImageUrlLarge = '/assets/img/content/no-photo-large.png'
    }

    data.hasSection = typeof data.__fullUrlPath !== 'undefined'

    return data
  }

  render() {
    const data = this.serialize()

    this.$el.empty().append(
      template({
        data,
        allowed: this.serviceLocator.allowed,
        format: this.serviceLocator.format,
      })
    )

    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }

  get template() {
    return template
  }
}

module.exports = ListItemView
