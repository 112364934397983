module.exports = ListItemView
const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, dropdownActions, format) {
jade_mixins["stateMap"] = jade_interp = function(schedule, visibility){
var block = (this && this.block), attributes = (this && this.attributes) || {};
// iterate [schedule, visibility]
;(function(){
  var $$obj = [schedule, visibility];
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var indicator = $$obj[$index];

if ( (indicator))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", '<strong>' + (indicator.title) + '</strong><br>' + (indicator.description) + '', true, false)) + (jade.cls(['label','label--large','js-tooltip-trigger',indicator.labelClass], [null,null,null,true])) + "><i" + (jade.cls(['icon',indicator.iconClass], [null,true])) + "></i></span>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var indicator = $$obj[$index];

if ( (indicator))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", '<strong>' + (indicator.title) + '</strong><br>' + (indicator.description) + '', true, false)) + (jade.cls(['label','label--large','js-tooltip-trigger',indicator.labelClass], [null,null,null,true])) + "><i" + (jade.cls(['icon',indicator.iconClass], [null,true])) + "></i></span>");
}
    }

  }
}).call(this);

};
buf.push("<div class=\"list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-image: url("' + data.previewImageUrlSmall + '");', true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
jade_mixins["stateMap"](data.schedule, data.visibility);
if ( allowed('article', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("<div class=\"btn-group\">");
if ( data.hasSection)
{
buf.push("<button type=\"button\" class=\"btn btn--small js-preview\">Preview</button>");
}
else
{
buf.push("<div data-container=\"body\" data-toggle=\"tooltip\" title=\"Can’t be previewed without a section assigned\" class=\"tooltip__disabled-wrapper js-tooltip-trigger\"><button type=\"button\" class=\"btn btn--small is-disabled\">Preview</button></div>");
}
if ( dropdownActions.length > 0)
{
buf.push("<a data-toggle=\"dropdown\" href=\"#\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\">");
if ( dropdownActions.indexOf('duplicate') > -1)
{
buf.push("<li><a class=\"js-duplicate\">Duplicate</a></li>");
}
if ( dropdownActions.indexOf('datePreview') > -1)
{
buf.push("<li><a class=\"js-date-preview\">Date Preview</a></li>");
}
buf.push("<li><a class=\"js-show-revisions\">Show Revisions</a></li></ul>");
}
buf.push("</div></div>");
if ( allowed('article', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/articles/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.headline) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.headline) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd><dt>Live:</dt><dd>" + (jade.escape(null == (jade_interp = data.liveDate ? format(data.liveDate, 'calendar') : 'Always') ? "" : jade_interp)) + "</dd><dt>Expires:</dt><dd>" + (jade.escape(null == (jade_interp = data.expiryDate ? format(data.expiryDate, 'calendar') : 'Never') ? "" : jade_interp)) + "</dd></dl>");
if ( data.hasSection)
{
buf.push("<div><a" + (jade.attr("href", data.publicUrlPath, true, false)) + " target=\"_blank\">" + (jade.escape(null == (jade_interp = data.publicUrlPath) ? "" : jade_interp)) + "</a></div>");
}
buf.push("</div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"dropdownActions" in locals_for_with?locals_for_with.dropdownActions:typeof dropdownActions!=="undefined"?dropdownActions:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const stateMap = require('../state-map')
const createImageUrlBuilder = require('cf-image-url-builder')
const config = window.config

function ListItemView() {
  BaseListItemView.apply(this, arguments)
  this.$el.on('click', '.js-preview', this.emit.bind(this, 'preview'))
  this.$el.on(
    'click',
    '.js-date-preview',
    this.emit.bind(this, 'datePreview', this.model)
  )
  this.$el.on('click', '.js-duplicate', this.emit.bind(this, 'duplicate'))
  this.$el.on(
    'click',
    '.js-show-revisions',
    this.emit.bind(this, 'showRevisions')
  )
}

ListItemView.prototype = Object.create(BaseListItemView.prototype)

ListItemView.prototype.template = template

ListItemView.prototype.serialize = function () {
  const images = this.model.get('images').widgets
  const data = this.model.toJSON()
  const drUrl = config.darkroom.url
  const drKey = config.darkroom.salt
  const urlBuilder = createImageUrlBuilder(
    drUrl,
    drKey,
    this.model.get('images').widgets
  )
  const getVisibilityState = stateMap.visibility.get(
    this.model.getVisibilityState()
  )
  const getScheduleState = stateMap.schedule.get(this.model.getScheduleState())

  if (
    images &&
    images.length &&
    urlBuilder.getImage('Thumbnail') &&
    urlBuilder.getImage('Thumbnail').crop('Square')
  ) {
    data.previewImageUrlSmall = urlBuilder
      .getImage('Thumbnail')
      .crop('Square')
      .constrain(150)
      .url()
    data.previewImageUrlLarge = urlBuilder
      .getImage('Thumbnail')
      .crop('Square')
      .constrain(400)
      .url()
  } else {
    data.previewImageUrlSmall = '/assets/img/content/no-photo-medium.png'
    data.previewImageUrlLarge = '/assets/img/content/no-photo-large.png'
  }

  data.schedule = getScheduleState(
    this.model.get('liveDate'),
    this.model.get('expiryDate')
  )
  data.visibility = getVisibilityState()

  data.hasSection = typeof data.__fullUrlPath !== 'undefined'

  // Add include path
  data.publicUrlPath = config.url + data.__fullUrlPath

  return data
}

ListItemView.prototype.render = function () {
  const dropdownActions = []
  const data = this.serialize()

  if (this.serviceLocator.allowed('article', 'duplicate')) {
    dropdownActions.push('duplicate')
  }

  if (data.hasSection) {
    dropdownActions.push('datePreview')
  }

  this.$el.empty().append(
    template({
      data: data,
      allowed: this.serviceLocator.allowed,
      dropdownActions: dropdownActions,
      format: this.serviceLocator.format,
    })
  )

  this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
  return this
}
