const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const isEmail = require('validity-email')
const crypto = require('crypto')
const moment = require('moment')

module.exports = function (save, config) {
  function generateSalt() {
    const saltInt = '' + process.pid + '' + Math.random()
    const shaSum = crypto.createHash('sha1').update(saltInt)
    const saltHash = shaSum.digest('hex')
    return saltHash
  }

  function duplicateEmailValidator(key, errorProperty, object, callback) {
    save.findOne(
      {
        emailAddress: new RegExp(['^', object.emailAddress, '$'].join(''), 'i'),
      },
      function (error, found) {
        if (error) {
          return callback(error)
        }
        callback(
          null,
          found && found._id.toString() !== object._id
            ? object.emailAddress + ' already in use'
            : undefined
        )
      }
    )
  }

  return schemata({
    name: 'Administrator schema',
    properties: {
      _id: { type: String, tag: ['update'] },
      firstName: {
        type: String,
        validators: { all: [required] },
        tag: ['update'],
      },
      lastName: {
        type: String,
        validators: { all: [required] },
        tag: ['update'],
      },
      emailAddress: {
        type: String,
        validators: { all: [required, isEmail, duplicateEmailValidator] },
        tag: ['update'],
      },
      password: { type: String, validators: { all: [] } },
      passwordSalt: { type: String, defaultValue: generateSalt },
      passwordResetDate: {
        type: Date,
        defaultValue: function () {
          var numDaysBetweenPasswordResets =
            config.passwordResetPolicy.numDaysBetweenReset
          return moment().add(numDaysBetweenPasswordResets, 'days').toDate()
        },
      },
      previousPasswords: {
        type: Array,
        defaultValue: function () {
          return []
        },
      },
      key: { type: String },
      keyExpiry: { type: Date },
      twoFaEnabled: { type: Boolean, defaultValue: true, tag: ['update'] },
      twoFaKey: { type: String, tag: ['update'] },
      twoFaChallengeDates: {
        type: Object,
        tag: ['update'],
        defaultValue: function () {
          return {}
        },
      },
      roles: { type: Array, tag: ['update'] },
      account: { type: String, tag: ['update'] },
      failedLoginAttempts: { type: Number, defaultValue: 0 },
      nextAllowedLoginAttemptDate: { type: Date },
      instances: {
        type: Array,
        defaultValue: function () {
          return []
        },
        tag: ['update'],
      },
      createdDate: {
        type: Date,
        validators: { all: [] },
        defaultValue: () => new Date(),
      },
    },
  })
}
