const ArticleHeaderModel = require('../../../article-layout/header/models/model')
const validateDelegate = require('../../../../../../admin/source/js/lib/validate-delegate')()

module.exports = ArticleHeaderModel.extend({
  type: 'postMatchArticles',
  displayName: 'Post-match Articles',
  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'postMatchArticles' })
  },

  validate: validateDelegate,
})
