const ListView = require('../views/list')
const FormView = require('../views/form')
const RedirectCollection = require('../collections/redirect')
const RedirectModel = require('../models/redirect')
const debug = require('../../../../admin/source/js/lib/debug')('redirect')

module.exports = function (serviceLocator) {
  const router = serviceLocator.router
  const collection = new RedirectCollection()
  const displayName = { singular: 'Redirect', plural: 'Redirects' }

  router.route('redirects(/)', 'listRedirects', function () {
    debug('redirect url list view route triggered')
    if (!serviceLocator.allow('redirect', 'read')) {
      return false
    }

    var listView = new ListView({
      collection: collection,
      serviceLocator: serviceLocator,
      displayName: displayName,
    })

    listView.on(
      'new',
      router.navigate.bind(router, 'redirects/form', { trigger: true })
    )

    listView.on('edit', function (model) {
      router.navigate('redirects/' + model.id + '/form', { trigger: true })
    })

    router.render(listView, displayName.plural)
    collection.load()
  })

  function bindSaveAndCancel(view) {
    view.on('save cancel', function () {
      router.navigate('redirects', { trigger: true })
    })
    return view
  }

  router.route('redirects/form(/)', 'newRedirect', function () {
    if (!serviceLocator.allow('redirect', 'create')) {
      return false
    }
    debug('redirect create view route triggered')
    const model = new RedirectModel({}, { collection: collection })
    const view = new FormView({
      model: model,
      title: displayName.singular,
      serviceLocator: serviceLocator,
    })

    router.render(bindSaveAndCancel(view), 'New ' + displayName.singular)
  })

  router.route('redirects/:id/form(/)', 'editRedirect', function (id) {
    if (!serviceLocator.allow('redirect', 'update')) {
      return false
    }
    debug('redirect edit view route triggered')

    collection.retrieve(id, function (err, model) {
      if (err) {
        router.trigger('notFound', err.message)
        return
      }
      debug('Loading form view', model)
      var view = new FormView({
        model: model,
        title: displayName.singular,
        serviceLocator: serviceLocator,
      })

      router.render(bindSaveAndCancel(view), 'Edit ' + displayName.singular)
    })
  })
}
