const View = require('./views/not-found')
const debug = require('../../lib/debug')('not-found routes')

module.exports = function (serviceLocator) {
  serviceLocator.router.on('notFound', function (message) {
    this.render(new View({ message: message }))
  })

  serviceLocator.router.route(/(.*)/, 'notFound', function () {
    debug('not found view route triggered')
    this.render(new View())
  })
}
