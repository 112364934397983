module.exports = ListView
const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, formTitle, serviceLocator) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\">");
if ( serviceLocator.allowed('section', 'reorder'))
{
buf.push("<button type=\"button\" class=\"btn js-edit-order\">Edit Order</button>");
}
buf.push("</div><div class=\"control-group\"><button style=\"display: none\" type=\"button\" class=\"btn js-cancel\">Cancel</button>");
if ( allowed('section', 'reorder'))
{
buf.push("<button style=\"display: none\" type=\"button\" class=\"btn js-save\">Save</button>");
}
if ( serviceLocator.allowed('section', 'create'))
{
buf.push("<button type=\"button\" class=\"btn btn--action js-new\">New Section</button>");
}
buf.push("</div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = formTitle) ? "" : jade_interp)) + "</h1></header><div class=\"grid\"><div class=\"grid__item\"><div class=\"list-container\"><div style=\"display: none\" class=\"notification notification--notice js-order-info\">Drag sections into the desired order then click Save</div><div class=\"js-controls\"></div><div data-layout=\"detail\" class=\"grid list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"formTitle" in locals_for_with?locals_for_with.formTitle:typeof formTitle!=="undefined"?formTitle:undefined,"serviceLocator" in locals_for_with?locals_for_with.serviceLocator:typeof serviceLocator!=="undefined"?serviceLocator:undefined));;return buf.join("");
}

function ListView(
  serviceLocator,
  collection,
  paginationModel,
  instances,
  accounts
) {
  this.instances = instances
  this.accounts = accounts

  BaseListView.apply(this, arguments)
  this.$el.on('click', '.js-edit-order', this.emit.bind(this, 'reorder'))
}

ListView.prototype = Object.create(BaseListView.prototype)

ListView.prototype.name = {
  singular: 'Section',
  plural: 'Sections',
  resource: 'section',
}

ListView.prototype.FilterView = FilterView
ListView.prototype.ListItemView = ListItemView
ListView.prototype.template = template

ListView.prototype.addListItem = function (model) {
  var listItem = new this.ListItemView(
    this.serviceLocator,
    model,
    this.instances,
    this.accounts
  )
  this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
  this.listenTo(
    listItem,
    'manageLayouts',
    this.emit.bind(this, 'manageLayouts', model.id)
  )
  this.listenTo(listItem, 'preview', this.emit.bind(this, 'preview', model))
  this.listenTo(
    listItem,
    'datePreview',
    this.emit.bind(this, 'datePreview', model)
  )
  this.listenTo(listItem, 'delete', this.emit.bind(this, 'delete', model.id))
  this.attachView(listItem)
  this.$el.find('.js-items').append(listItem.render().$el)
}

ListView.prototype.render = function () {
  BaseListView.prototype.render.call(this)
  return this
}
