module.exports = ListItemView
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
jade_mixins["stateMap"] = jade_interp = function(schedule, visibility){
var block = (this && this.block), attributes = (this && this.attributes) || {};
// iterate [schedule, visibility]
;(function(){
  var $$obj = [schedule, visibility];
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var indicator = $$obj[$index];

if ( (indicator))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", '<strong>' + (indicator.title) + '</strong><br>' + (indicator.description) + '', true, false)) + (jade.cls(['label','label--large','js-tooltip-trigger',indicator.labelClass], [null,null,null,true])) + "><i" + (jade.cls(['icon',indicator.iconClass], [null,true])) + "></i></span>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var indicator = $$obj[$index];

if ( (indicator))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", '<strong>' + (indicator.title) + '</strong><br>' + (indicator.description) + '', true, false)) + (jade.cls(['label','label--large','js-tooltip-trigger',indicator.labelClass], [null,null,null,true])) + "><i" + (jade.cls(['icon',indicator.iconClass], [null,true])) + "></i></span>");
}
    }

  }
}).call(this);

};
buf.push("<div class=\"list-item list-item-minimal\"><div class=\"list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\">");
jade_mixins["stateMap"](data.schedule, data.visibility);
buf.push("</div><h4>" + (jade.escape(null == (jade_interp = data.headline) ? "" : jade_interp)) + "</h4></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const stateMap = require('../state-map')
const createImageUrlBuilder = require('cf-image-url-builder')
const View = require('ventnor')
const config = window.config

function ListItemView(serviceLocator, model) {
  View.apply(this, arguments)
  this.model = model
  this.$el.attr('data-itemId', this.model.id)
}

ListItemView.prototype = Object.create(View.prototype)

ListItemView.prototype.serialize = function () {
  const images = this.model.get('images').widgets
  const data = this.model.toJSON()
  const drUrl = config.darkroom.url
  const drKey = config.darkroom.salt
  const urlBuilder = createImageUrlBuilder(
    drUrl,
    drKey,
    this.model.get('images').widgets
  )
  const getVisibilityState = stateMap.visibility.get(
    this.model.getVisibilityState()
  )
  const getScheduleState = stateMap.schedule.get(this.model.getScheduleState())

  if (
    images &&
    images.length &&
    urlBuilder.getImage('Thumbnail') &&
    urlBuilder.getImage('Thumbnail').crop('Square')
  ) {
    data.previewImageUrlSmall = urlBuilder
      .getImage('Thumbnail')
      .crop('Square')
      .constrain(150)
      .url()
    data.previewImageUrlLarge = urlBuilder
      .getImage('Thumbnail')
      .crop('Square')
      .constrain(400)
      .url()
  } else {
    data.previewImageUrlSmall = '/assets/img/content/no-photo-medium.png'
    data.previewImageUrlLarge = '/assets/img/content/no-photo-large.png'
  }

  data.schedule = getScheduleState(
    this.model.get('liveDate'),
    this.model.get('expiryDate')
  )
  data.visibility = getVisibilityState()

  data.hasSection = typeof data.__fullUrlPath !== 'undefined'

  // Add include path
  data.publicUrlPath = config.url + data.__fullUrlPath

  return data
}

ListItemView.prototype.render = function () {
  this.$el.empty().append(template({ data: this.serialize() }))
  this.$el.draggable({
    addClasses: false,
    helper: 'clone',
    appendTo: 'body',
    zIndex: 10000,
    connectToSortable: '.js-selected-item-list',
    start: function (event, ui) {
      ui.helper.height(30).width(300)
    },
  })
  return this
}
