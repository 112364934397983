const baseSchema = require('../../../../service/widget/model/base-widget')()
const required = require('@clocklimited/validity-required')
const schemata = require('@clocklimited/schemata')
const createContextValidator = require('validity-cf-image-context-selection')
const competitionConfig =
  require('../../../../service/competition/competitions').all
const squads = require('../../../../service/team/squads').playable
const heroImageDisplayButtons =
  require('../../../../service/ticket-hero/display-buttons').values

module.exports = () =>
  schemata({
    name: 'Fixtures and results',
    properties: {
      groupDayImages: {
        type: Object,
        defaultValue: () => ({}),
        validators: { all: [createContextValidator(['Main'])] },
      },
      awayTravelImages: {
        type: Object,
        defaultValue: () => ({}),
      },
      squad: {
        type: Array,
        options: squads,
        validators: [required],
      },
      groupDayTitle: {
        type: String,
        validators: [required],
      },
      groupDayBody: {
        type: String,
        validators: [required],
      },
      awayTravelTitle: {
        type: String,
      },
      awayTravelBody: {
        type: String,
      },
      competition: {
        type: String,
        options: competitionConfig,
        validators: [required],
      },
      infoHtml: {
        type: String,
      },
      heroImageDisplayButtons: {
        type: Array,
        options: heroImageDisplayButtons,
      },
      nextMatch: {
        type: String,
        validators: [required],
        defaultValue: () => 'home',
      },
      ...baseSchema.getProperties(),
    },
  })
