module.exports = ListItemView
const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format, logoUrl) {
buf.push("<div class=\"list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-color:#' + data.accentBackground + ';', true, false)) + " class=\"image image-detail\"><img" + (jade.attr("src", logoUrl, true, false)) + (jade.attr("alt", '' + (data.name) + ' Logo', true, false)) + " class=\"image-logo\"/></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('instance', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label>");
}
buf.push("<div class=\"btn-group\"><a data-toggle=\"dropdown\" href=\"#\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-show-revisions\">Show Revisions</a></li></ul></div></div>");
if ( allowed('instance', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", 'instances/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dd><a" + (jade.attr("href", data._url, true, false)) + " target=\"_blank\">" + (jade.escape(null == (jade_interp = data._hostname) ? "" : jade_interp)) + "</a></dd>");
if ( data.externalId)
{
buf.push("<dt>External ID:</dt><dd>" + (jade.escape(null == (jade_interp = data.externalId) ? "" : jade_interp)) + "</dd>");
}
buf.push("<dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"logoUrl" in locals_for_with?locals_for_with.logoUrl:typeof logoUrl!=="undefined"?logoUrl:undefined));;return buf.join("");
}
const createDarkroomUrlBuilder = require('@clocklimited/darkroom-url-builder')
const config = window.config

function ListItemView(serviceLocator, model, accounts) {
  this.accounts = accounts
  BaseListItemView.apply(this, arguments)
  this.$el.on(
    'click',
    '.js-show-revisions',
    this.emit.bind(this, 'showRevisions')
  )
}

ListItemView.prototype = Object.create(BaseListItemView.prototype)

ListItemView.prototype.template = template

ListItemView.prototype.render = function () {
  const data = this.model.toJSON()
  const urlBuilder = createDarkroomUrlBuilder(
    config.darkroom.url,
    config.darkroom.salt
  )
  const url = urlBuilder()
    .resource(data.logo)
    .width(120)
    .filename('preview.jpg')
    .url()
  const account = this.accounts
    .filter(
      function (account) {
        return account._id === this.model.get('account')
      }.bind(this)
    )
    .pop()

  data._url = this.serviceLocator.instanceService.createUrl(data, account)
  data._hostname = this.serviceLocator.instanceService.createHost(data, account)
  this.$el.empty().append(
    this.template({
      data: data,
      allowed: this.serviceLocator.allowed,
      format: this.serviceLocator.format,
      logoUrl: url,
    })
  )

  this.$el.find('.js-tooltip-trigger').tooltip({ html: true })

  return this
}
