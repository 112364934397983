module.exports = SectionModel
const Model = require('merstone')
const createSchema = require('../../../service/section/schema')

function SectionModel(serviceLocator, attributes) {
  Model.apply(this, arguments)
  this.schema = createSchema()
  this.attributes = this.schema.cast(attributes || {})

  this.hook(
    'preSet',
    function (attrs, cb) {
      cb(null, this.schema.cast(attrs))
    }.bind(this)
  )
}

SectionModel.prototype = Object.create(Model.prototype)

SectionModel.prototype.validate = function (cb) {
  this.schema.validate(this.attributes, cb)
}

SectionModel.prototype.reset = function (attrs) {
  attrs = this.schema.cast(attrs || {})
  Model.prototype.reset.call(this, attrs)
}
