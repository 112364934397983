module.exports = ListView
const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')

function ListView(serviceLocator, collection, paginationModel, accounts) {
  this.accounts = accounts
  BaseListView.apply(this, arguments)
}

ListView.prototype = Object.create(BaseListView.prototype)

ListView.prototype.addListItem = function (model) {
  var listItem = new this.ListItemView(
    this.serviceLocator,
    model,
    this.accounts
  )
  this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
  this.listenTo(
    listItem,
    'showRevisions',
    this.emit.bind(this, 'showRevisions', model)
  )
  this.attachView(listItem)
  this.$el.find('.js-items').append(listItem.render().$el)
}

ListView.prototype.name = {
  singular: 'Instance',
  plural: 'Instances',
  resource: 'instance',
}

ListView.prototype.FilterView = FilterView
ListView.prototype.ListItemView = ListItemView
