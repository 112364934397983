const schemata = require('schemata')
const length = require('validity-length')
const baseSchema = require('../../../../service/widget/model/base-widget')()

module.exports = () =>
  schemata({
    name: 'Tabs',
    properties: {
      items: {
        type: Array,
        validators: { all: [ length(1) ] },
        defaultValue: () => [],
      },
      ...baseSchema.getProperties()
    }
  })
