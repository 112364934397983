module.exports = ArticleModel
const Model = require('merstone')
const createSchema = require('../../../service/article/schema')
const WidgetAreaModel = require('../../widget/models/widget-area')
const ImageAreaModel = require('../../asset/models/image-area')

function ArticleModel(serviceLocator, attributes) {
  Model.apply(this, arguments)
  this.schema = createSchema()
  this.attributes = this.schema.cast(attributes || {})

  this.hook(
    'preSet',
    function (attrs, cb) {
      cb(null, this.schema.cast(attrs))
    }.bind(this)
  )

  this.body = new WidgetAreaModel(this.get('body'), {
    abstractWidgetFactory: serviceLocator.widgetFactories.get('articleBody'),
  })

  this.relatedWidgets = new WidgetAreaModel(this.get('relatedWidgets'), {
    abstractWidgetFactory: serviceLocator.widgetFactories.get(
      'relatedArticles'
    ),
  })

  this.images = new ImageAreaModel(this.get('images'))

  this.images.on(
    'add remove change',
    function () {
      this.set('images', this.images.toJSON())
    }.bind(this)
  )

  this.body.on(
    'add remove change',
    function () {
      this.set('body', this.body.toJSON())
    }.bind(this)
  )

  this.relatedWidgets.on(
    'add remove change',
    function () {
      this.set('relatedWidgets', this.relatedWidgets.toJSON())
    }.bind(this)
  )
}

ArticleModel.prototype = Object.create(Model.prototype)

ArticleModel.prototype.makeDefault = function (cb) {
  var serviceLocator = this.serviceLocator

  serviceLocator.accountService.read(
    serviceLocator.session.account,
    function (err, account) {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      this.set(this.schema.makeDefault())
      cb(null, this, account)
    }.bind(this)
  )
}

ArticleModel.prototype.validate = function (cb) {
  this.schema.validate(this.attributes, cb)
}

ArticleModel.prototype.reset = function (attrs) {
  attrs = this.schema.cast(attrs || {})
  Model.prototype.reset.call(this, attrs)
}

ArticleModel.prototype.isInDate = function () {
  var now = new Date()
  // No schedule settings
  if (!this.get('liveDate') && !this.get('expiryDate')) return true
  // Live date in the past, no expiry date
  if (now - this.get('liveDate') > 0 && !this.get('expiryDate')) return true
  // Live date in the past, expiry date in the future
  if (now - this.get('liveDate') >= 0 && this.get('expiryDate') - now > 0)
    return true
  // No live date, expiry date in the future
  if (!this.get('liveDate') && this.get('expiryDate') - now > 0) return true
  // Otherwise not in date
  return false
}

ArticleModel.prototype.getVisibilityState = function () {
  switch (this.get('state')) {
    case 'Draft':
      return 'draft'
    case 'Archived':
      return 'archived'
    case 'Published':
      if (this.isInDate()) {
        return 'published'
      } else {
        return 'publishedHidden'
      }
  }
}

ArticleModel.prototype.getScheduleState = function () {
  /* eslint complexity: [ 2, 14 ] */

  const now = new Date()
  const threeDays = 3 * 24 * 60 * 60 * 1000

  // No schedule info
  if (!this.get('liveDate') && !this.get('expiryDate')) return 'notScheduled'

  // expiryDate in past
  if (this.get('expiryDate') && now - this.get('expiryDate') > 0)
    return 'expired'

  //
  // From here on in, expiryDate is either not set or in future:
  //

  // liveDate in future, expiryDate not set or in future
  if (
    this.get('liveDate') - now > 0 &&
    (!this.get('expiryDate') || this.get('liveDate') - now > 0)
  ) {
    if (this.get('state') !== 'Published') {
      // Draft, Archived states
      if (this.get('liveDate') - now < threeDays)
        return 'scheduledLiveSoonNotPublished'
      // Published
      return 'scheduledFuture'
    }

    return 'scheduledFuture'
  }

  // liveDate in past, expiryDate not set or in future
  if (now - this.get('liveDate') > 0) {
    if (this.get('state') !== 'Published') {
      // Not published
      return 'scheduledLiveNowNotPublished'
    } else if (!this.get('expiryDate')) {
      // Published, no expiryDate
      return 'scheduledInDateWillNotExpire'
    } else {
      // Published, expiryDate in future
      // Different state for expiring soon (less than 3 days)
      return this.get('expiryDate') - now > threeDays
        ? 'scheduledInDateWillExpire'
        : 'scheduledInDateExpiringSoon'
    }
  }
}
