const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const createContextValidator = require('validity-cf-image-context-selection')

module.exports = () =>
  schemata({
    name: 'Pop promotion schema',
    properties: {
      images: {
        type: Object,
        defaultValue: function () {
          return {}
        },
        validators: {
          all: [createContextValidator(['Background', 'Promotion'])],
        },
      },
      callToActionText: {
        type: String,
        validators: { all: [required] },
      },
      callToActionLink: {
        type: String,
        validators: { all: [required] },
      },
      dismissText: {
        type: String,
        validators: { all: [required] },
      },
      trackingCode: {
        type: String,
        validators: { all: [required] },
      },
      ...baseSchema.getProperties(),
    },
  })
