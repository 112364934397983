const moment = require('moment')
const schedule = require('regg')()
const visibility = require('regg')()

schedule

  .register('notScheduled', function () {
    return null
  })

  .register('expired', function (liveDate, expiryDate) {
    var description = 'Expired ' + moment(expiryDate).fromNow()
    return {
      labelClass: 'label-scheduled label--inverse',
      iconClass: 'icon--clock',
      title: 'Expired',
      description: description,
    }
  })

  .register('scheduledFuture', function (liveDate) {
    var description = 'Goes live ' + moment(liveDate).fromNow()
    return {
      labelClass: 'label-scheduled label--notice',
      iconClass: 'icon--clock',
      title: 'Scheduled – Future',
      description: description,
    }
  })

  .register('scheduledInDateWillExpire', function (liveDate, expiryDate) {
    var description = 'Expires ' + moment(expiryDate).fromNow()
    return {
      labelClass: 'label-scheduled label--success',
      iconClass: 'icon--clock',
      title: 'Scheduled – In date',
      description: description,
    }
  })

  .register('scheduledInDateWillNotExpire', function (liveDate) {
    var description = 'Will not expire<br>Live ' + moment(liveDate).fromNow()
    return {
      labelClass: 'label-scheduled label--success',
      iconClass: 'icon--clock',
      title: 'Scheduled – In date',
      description: description,
    }
  })

  .register('scheduledInDateExpiringSoon', function (liveDate, expiryDate) {
    var description = 'Expires ' + moment(expiryDate).fromNow()
    return {
      labelClass: 'label-scheduled label--warning',
      iconClass: 'icon--clock',
      title: 'Scheduled – Ending Soon',
      description: description,
    }
  })

  .register('scheduledLiveSoonNotPublished', function (liveDate) {
    var description =
      'Goes live ' +
      moment(liveDate).fromNow() +
      ' but item is not yet published.'
    return {
      labelClass: 'label-scheduled label--error',
      iconClass: 'icon--clock',
      title: 'Scheduled – Unpublished Content',
      description: description,
    }
  })

  .register('scheduledLiveNowNotPublished', function (liveDate) {
    var description
    if (!liveDate) {
      description =
        'Went live at time of writing, but item is not yet published.'
    } else {
      description =
        'Went live ' +
        moment(liveDate).fromNow() +
        ', but item is not yet published.'
    }
    return {
      labelClass: 'label-scheduled label--error',
      iconClass: 'icon--clock-important',
      title: 'Scheduled – Unpublished Content',
      description: description,
    }
  })

visibility

  .register('draft', function () {
    return {
      labelClass: 'label-status',
      iconClass: 'icon--draft',
      title: 'Draft',
      description:
        'This content is not published.<br>It is not visible on the site',
    }
  })

  .register('published', function () {
    return {
      labelClass: 'label-status label--standard',
      iconClass: 'icon--published',
      title: 'Published',
      description: 'This content is visible on the site.',
    }
  })

  .register('publishedHidden', function () {
    return {
      labelClass: 'label-status label--inverse',
      iconClass: 'icon--published',
      title: 'Published – Hidden',
      description:
        'Published but not visible on the site due to schedule settings.',
    }
  })

  .register('archived', function () {
    return {
      labelClass: 'label-status label--inverse',
      iconClass: 'icon--archived',
      title: 'Archived',
      description:
        'This content is visible on the site. Will not appear in unfiltered CMS searches',
    }
  })

module.exports = { visibility: visibility, schedule: schedule }
