const QuestionFormView = require('../../question/views/question')

class PollQuestionFormView extends QuestionFormView {
  constructor(...args) {
    super(...args)
    this.model.set('inputType', 'radio')
    this.model.set('required', true)
    this.allowQuestionDescription = true
    this.showInputType = false
    this.showRequired = false
    this.showDelete = false
    this.allowReorder = false
  }
}

module.exports = PollQuestionFormView
