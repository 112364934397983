const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, roles, title, twoFaEnabled, undefined) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"user-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>User Details</h2></div><div class=\"panel-content\"><div id=\"field--firstName\" data-field=\"firstName\" class=\"form-row\"><label><span class=\"form-label-text\">First Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"firstName\" maxlength=\"200\"" + (jade.attr("value", data.firstName, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--lastName\" data-field=\"lastName\" class=\"form-row\"><label><span class=\"form-label-text\">Last Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"lastName\" maxlength=\"200\"" + (jade.attr("value", data.lastName, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--emailAddress\" data-field=\"emailAddress\" class=\"form-row\"><label><span class=\"form-label-text\">Email Address<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"emailAddress\" maxlength=\"200\"" + (jade.attr("value", data.emailAddress, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div>");
if ( twoFaEnabled  )
{
buf.push("<div id=\"field--twoFaEnabled\" data-field=\"twoFaEnabled\" class=\"form-row form-row-boolean\"><label><span class=\"form-label-text\">Two Factor Authentication</span><div class=\"form-field\"><input type=\"checkbox\" name=\"twoFaEnabled\"" + (jade.attr("checked", data.twoFaEnabled, true, false)) + " class=\"control control--boolean\"/><span>Should this user require two factor authentication when logging in?</span></div></label><div class=\"js-error\"></div></div>");
}
buf.push("</div></div><div id=\"access-level\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Access Level</h2></div><div class=\"panel-content\"><div id=\"field--account\" data-field=\"account\" class=\"form-row js-account\"><label><span class=\"form-label-text\">Account</span><div class=\"js-accounts form-field\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Please leave blank if the user needs to access all accounts.</p></div></div><div id=\"field--roles\" data-field=\"roles\" class=\"form-row form-row-boolean\"><span class=\"form-label-text\">Roles</span><div class=\"form-field\"><ul>");
// iterate roles
;(function(){
  var $$obj = roles;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var role = $$obj[$index];

buf.push("<li><label>");
if ( typeof data.roles === 'undefined' || data.roles.indexOf(role.name) === -1)
{
buf.push("<input type=\"checkbox\" name=\"roles\"" + (jade.attr("value", role.name, true, false)) + " class=\"control control--boolean\"/><span>" + (jade.escape(null == (jade_interp = role.name) ? "" : jade_interp)) + "</span>");
}
else
{
buf.push("<input type=\"checkbox\" name=\"roles\"" + (jade.attr("value", role.name, true, false)) + " checked=\"checked\" class=\"control control--boolean\"/><span>" + (jade.escape(null == (jade_interp = role.name) ? "" : jade_interp)) + "</span>");
}
buf.push("</label></li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var role = $$obj[$index];

buf.push("<li><label>");
if ( typeof data.roles === 'undefined' || data.roles.indexOf(role.name) === -1)
{
buf.push("<input type=\"checkbox\" name=\"roles\"" + (jade.attr("value", role.name, true, false)) + " class=\"control control--boolean\"/><span>" + (jade.escape(null == (jade_interp = role.name) ? "" : jade_interp)) + "</span>");
}
else
{
buf.push("<input type=\"checkbox\" name=\"roles\"" + (jade.attr("value", role.name, true, false)) + " checked=\"checked\" class=\"control control--boolean\"/><span>" + (jade.escape(null == (jade_interp = role.name) ? "" : jade_interp)) + "</span>");
}
buf.push("</label></li>");
    }

  }
}).call(this);

buf.push("</ul></div></div></div></div></form></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"roles" in locals_for_with?locals_for_with.roles:typeof roles!=="undefined"?roles:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"twoFaEnabled" in locals_for_with?locals_for_with.twoFaEnabled:typeof twoFaEnabled!=="undefined"?twoFaEnabled:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const AccountSelect = require('../../account/views/account-select')

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    this.roles = this.model.get('adminRoles')
    this.accountSelectView = new AccountSelect(
      this.serviceLocator,
      this.model.get('account')
    )
    this.accountSelectView.notSetLabel = '-- All accounts --'
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New Administrator' : 'Edit Administrator',
        data: this.model.toJSON(),
        roles: this.roles,
        twoFaEnabled: this.serviceLocator.config.twoFa.enabled,
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    this.accountSelectView
      .populate()
      .render()
      .$el.appendTo(this.$el.find('.js-accounts'))

    return this
  }
}

module.exports = FormView
