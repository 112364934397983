module.exports = createController
const ListView = require('../views/list')
const Collection = require('chale')
const PlayerModel = require('../models/player')
const Model = require('merstone')
const async = require('async')
const pageSize = 50

function createController(serviceLocator) {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0,
  })
  let currentPage = 1
  let currentParams = {
    keywords: '',
    filter: {},
    sort: [ 'dateCreated', 'desc' ],
  }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an player is updated, reset the model with its new attributes
  serviceLocator.playerService.on('update', function (id, attrs) {
    var model = collection.get(id)
    if (model) model.reset(attrs)
    serviceLocator.playerService.cachedFind.clear()
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.playerService.on('create', function () {
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    serviceLocator.playerService.cachedFind.clear()
    getPlayers(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  serviceLocator.router.route('players(/)', 'listPlayers', function () {
    if (!serviceLocator.allow('player', 'discover')) return false

    getPlayers(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )

    var list = new ListView(
      serviceLocator,
      collection,
      paginationModel
    ).render()

    list.displayFilterParams(currentParams)

    list.on('createNew', function () {
      if (!serviceLocator.allow('player', 'create')) return false
      serviceLocator.router.navigate('players/form', { trigger: true })
    })

    list.on('edit', function (id) {
      if (!serviceLocator.allow('player', 'update')) return false
      serviceLocator.router.navigate('players/' + id + '/form', {
        trigger: true,
      })
    })

    list.on('delete', function (ids) {
      if (!serviceLocator.allow('player', 'delete')) return false
      async.each(ids, deleteOne, function (err) {
        if (err) return alert(err.message)
      })

      function deleteOne(id, cb) {
        serviceLocator.playerService.delete(id, function (err) {
          if (err) return cb(err)
          collection.remove(id)
        })
      }
    })

    list.on('filter', function (params) {
      currentParams = params
      var pagination = { page: currentPage, pageSize: pageSize }
      currentPage = 1
      getPlayers(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', function () {
      currentPage += 1
      var pagination = { page: currentPage, pageSize: pageSize }
      appendPlayers(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    serviceLocator.router.render(list, 'Players')
  })

  function getPlayers(keywords, filter, sort, pagination) {
    serviceLocator.playerService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err)
          return serviceLocator.logger.error(err, 'Could not load players')
        collection.reset(
          res.results.map(function (player) {
            return new PlayerModel(serviceLocator, player)
          })
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  function appendPlayers(keywords, filter, sort, pagination) {
    serviceLocator.playerService.find(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err) return alert(err.message)
        res.results.forEach(function (player) {
          collection.add(new PlayerModel(serviceLocator, player))
        })
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }
}
