import FormView from './views/form'
import Model from './models/model'
import ItemView from '../../widget/views/item/edit-base'

const initWidget = () => ({ calendarWidget: [ 'widget', 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    name: 'Calendar',
    itemView: ItemView,
    description: 'Displays a Calendar of matches and events',
  }

  ;[ 'article', 'section' ].forEach((factory) =>
    serviceLocator.widgetFactories.get(factory).register('calendar', widget)
  )

  done()
}

module.exports = initWidget
