module.exports = SectionSelect
const View = require('./section-select')

function SectionSelect(serviceLocator, selectedSections) {
  View.apply(this, arguments)
  this.selectedSections = selectedSections || []
  this.initFilter = { _id: { $in: this.selectedSections }, instance: null }
  this.loadFilter = { root: false, instance: null }
}

SectionSelect.prototype = Object.create(View.prototype)
