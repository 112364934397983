module.exports = FormBuilderService
const CrudService = require('../../../../admin/source/js/lib/crud-service')

function FormBuilderService() {
  this.name = 'formBuilderService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/form-builders'
}

FormBuilderService.prototype = Object.create(CrudService.prototype)
