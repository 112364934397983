import FormView from './views/form'
import ItemView from './views/item'
import Model from './models/model'

const initWidget = () => ({
  articleActionListWidget: [ 'widget', 'listWidget', 'sectionAdmin', init ],
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    name: 'Article Action List',
    itemView: ItemView,
    description: 'Displays articles in a action list',
  }

  ;[ 'article', 'section' ].forEach((factory) =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('articleActionList', widget)
  )

  done()
}

module.exports = initWidget
