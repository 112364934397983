module.exports = createSchema

const createBaseSchema = require('../../../../service/widget/model/base-widget')
const validity = require('validity')
const schemata = require('@clocklimited/schemata')

const isNotEmpty = (value) => value.length > 0
const activeIsSetOnce = (value) =>
  value.filter((item) => item.displayActive).length === 1

const nonEmptyValidator =
  validity.createValidatorAllowingFailureMessageOverride(
    validity.booleanToCallback(isNotEmpty),
    'Atleast one item is required'
  )

const singleActiveValidator =
  validity.createValidatorAllowingFailureMessageOverride(
    validity.booleanToCallback(activeIsSetOnce),
    'There must always be only one active item'
  )

function createSchema() {
  const baseSchema = createBaseSchema()
  const schema = {
    header: {
      type: String,
    },
    items: {
      type: Array,
      validators: [nonEmptyValidator, singleActiveValidator],
    },
  }
  const widgetSchema = schemata({
    name: 'Sub-navigation schema',
    properties: Object.assign({}, baseSchema.getProperties(), schema),
  })
  return widgetSchema
}
