module.exports = initAdmin

const createListController = require('./controllers/list')
const createFormController = require('./controllers/form')
const HospitalityPackageService = require('./services/hospitality-package')

function initAdmin() {
  return { hospitalityPackageAdmin: init }
}

function init(serviceLocator, done) {
  serviceLocator.hospitalityPackageService = new HospitalityPackageService(
    serviceLocator
  )
  createListController(serviceLocator)
  createFormController(serviceLocator)
  done()
}
