module.exports = FormView
const View = require('ventnor')
const formErrorsDelegate = require('../../../../admin/source/js/lib/form-errors-delegate')()
const isEqual = require('lodash.isequal')
const ToolbarView = require('../../toolbar/views/form-toolbar')

function FormView(serviceLocator, model, isNew) {
  View.apply(this, arguments)
  this.model = model
  this.clearUnsavedChanges()
  this.setupToolbar()
  this.isNew = !!isNew
  this.$el.on('change', this.applyChangeToModel.bind(this))
  this.$el.on('submit', 'form', this.submit.bind(this))
}

FormView.prototype = Object.create(View.prototype)

// Compatibility with backbone admin
FormView.prototype.trigger = View.prototype.emit

// Compatibility with the form errors delegate
FormView.prototype.$ = function (selector) {
  return this.$el.find(selector)
}

FormView.prototype.applyChangeToModel = function (e) {
  if (this.model.schema.getProperties().hasOwnProperty(e.target.name)) {
    if (
      e.target.type === 'checkbox' &&
      this.model.schema.getProperties()[e.target.name].type === Boolean
    ) {
      this.model.set(e.target.name, e.target.checked)
    } else if (
      e.target.type === 'checkbox' &&
      this.model.schema.getProperties()[e.target.name].type === Array
    ) {
      var values = this.$el
        .find('[name=' + e.target.name + ']')
        .toArray()
        .filter(function (el) {
          return el.checked
        })
        .map(function (el) {
          return el.value
        })
      this.model.set(e.target.name, values)
    } else {
      this.model.set(e.target.name, e.target.value)
    }
  }
}

FormView.prototype.showErrors = formErrorsDelegate.showErrors
FormView.prototype.clearErrors = formErrorsDelegate.clearErrors

FormView.prototype.clearUnsavedChanges = function () {
  this.initialModel = this.model.toJSON()
}

FormView.prototype.hasUnsavedChanges = function () {
  return !isEqual(this.initialModel, this.model.toJSON())
}

FormView.prototype.setupToolbar = function () {
  this.toolbar = new ToolbarView(this.serviceLocator, this.model, this)
  this.toolbar.on('back', this.emit.bind(this, 'back'))
  this.toolbar.on('save', this.emit.bind(this, 'save'))
  this.toolbar.on('saveAndClose', this.emit.bind(this, 'saveAndClose'))
}

FormView.prototype.template = function () {
  return ''
}
FormView.prototype.render = function () {}

FormView.prototype.submit = function (e) {
  e.preventDefault()
  this.emit('save')
}

FormView.prototype.close = function () {
  this.emit('close')
}
