const BaseListItemView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')

class ListView extends BaseListItemView {
  get FilterView() {
    return FilterView
  }

  get ListItemView() {
    return ListItemView
  }

  get name() {
    return {
      singular: 'Rugby Camp',
      plural: 'Rugby Camps',
      resource: 'rugby-camp',
    }
  }
}

module.exports = ListView
