module.exports={
  "crops": [
    { "name": "Letterbox", "aspectRatio": "2:1" },
    { "name": "Landscape", "aspectRatio": "16:9" },
    { "name": "Square", "aspectRatio": "1:1" },
    { "name": "Portrait", "aspectRatio": "2:3" }
  ],
  "contexts": [
    { "name": "Hero", "allowMultipleSelection": true },
    { "name": "Thumbnail", "allowMultipleSelection": false }
  ]
}
