module.exports = initAdmin

const service = require('../../service/season/service')

function initAdmin() {
  return { seasonAdmin: [ init ] }
}

function init(serviceLocator, done) {
  serviceLocator.seasonService = service()
  done()
}
