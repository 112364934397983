const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowAdditionalQuestions, data, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");
jade_mixins["question-form"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<form><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--title\" data-field=\"title\" class=\"form-row\"><label><span class=\"form-label-text\">Title<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"title\"" + (jade.attr("value", data.title, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--closeDate\" data-field=\"closeDate\" class=\"form-row\"><label><span class=\"form-label-text\">Close Date</span><input type=\"text\" name=\"closeDate\"" + (jade.attr("value", data.closeDate, true, false)) + " class=\"control control--text js-date-time-picker form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--ctaText\" data-field=\"ctaText\" class=\"form-row\"><label><span class=\"form-label-text\">Call to Action Text<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"ctaText\"" + (jade.attr("value", data.ctaText, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div>");
if ( block)
{
block && block();
}
buf.push("<div id=\"field--description\" data-field=\"description\" class=\"form-row form-row-full-width\"><label><span class=\"vhidden\">description</span><textarea name=\"description\" rows=\"5\" class=\"control control--text control--multiline js-text-editor form-field\">" + (jade.escape(null == (jade_interp = data.description) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div></div></div><div id=\"section-thanksMessage\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Thank You Message</h2></div><div class=\"panel-content\"><div id=\"field--thanksMessage\" data-field=\"thanksMessage\" class=\"form-row form-row-full-width\"><label><span class=\"vhidden\">thanksMessage</span><textarea name=\"thanksMessage\" rows=\"5\" class=\"control control--text control--multiline js-text-editor form-field\">" + (jade.escape(null == (jade_interp = data.thanksMessage) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div></div></div></form>");
};
jade_mixins["question-form"].call({
block: function(){
buf.push("<div id=\"field--email\" data-field=\"email\" class=\"form-row\"><label><span class=\"form-label-text\">Email</span><input type=\"text\" name=\"email\"" + (jade.attr("value", data.email, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div>");
}
});
buf.push("<div id=\"section-questions\" class=\"counter-question-reset js-questions\"></div>");
if ( allowAdditionalQuestions)
{
buf.push("<a class=\"btn js-question-add\">Add Question</a>");
}
buf.push("</form></div></div>");}.call(this,"allowAdditionalQuestions" in locals_for_with?locals_for_with.allowAdditionalQuestions:typeof allowAdditionalQuestions!=="undefined"?allowAdditionalQuestions:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const QuestionFormView = require('../../question/views/question')
const QuestionModel = require('../../question/models/question')
const RichTextEditorInstanceManager = require('../../../../admin/source/js/lib/rich-text-editor-instance-manager')
const formErrorsDelegate = require('../../../../admin/source/js/lib/form-errors-delegate')()
const dateTimePickers = require('../../../../admin/source/js/lib/init-date-time-pickers')

class FormBuilderFormView extends BaseFormView {
  constructor(...args) {
    super(...args)

    this.title = 'Form Builder'
    this.QuestionFormView = QuestionFormView
    this.allowAdditionalQuestions = true
    this.allowQuestionDescription = true
    this.allowReorder = true

    this.questionViews = []

    this.$el.on('click', '.js-question-add', this.handleAddQuestion.bind(this))
    this.on('remove', () => {
      this.removeQuestion.bind(this)
    })

    this.model.questions.on('add', this.addQuestion.bind(this))
    this.model.questions.on('remove', this.removeQuestion.bind(this))
    this.dateTimePickers = []
    this.richTextEditorInstanceManager = new RichTextEditorInstanceManager()
    this.initDateTimePickers = dateTimePickers(
      window.config.locale.longDateFormat.LLLL,
      this.model
    )
    this.on('remove', () => {
      this.richTextEditorInstanceManager.destroy()
      this.dateTimePickers.forEach((picker) => picker.destroy())
    })

    this.on('afterAppend', () => {
      this.$el.find('input[type=text]:first').focus()
    })
  }

  handleAddQuestion() {
    const question = new QuestionModel(this.serviceLocator)
    question.index = this.model.questions.models.length
    this.model.questions.add(question)
  }

  showErrors(errors) {
    formErrorsDelegate.showErrors.call(this, errors)

    if (errors.questions && typeof errors.questions === 'object') {
      Object.keys(errors.questions).forEach((key) => {
        this.questionViews[key].view.showErrors(errors.questions[key])
      })
    }
  }

  addQuestion(model) {
    const view = new this.QuestionFormView(
      this.serviceLocator,
      model,
      this.account,
      this.isNew,
      this.allowQuestionDescription
    )

    this.questionViews.push({ cid: model.cid, view: view })
    view.on('remove', (id) => {
      this.model.questions.remove(id)
    })

    this.$el.find('.js-questions').append(view.render().$el)
  }

  initReorderableQuestions() {
    this.$el
      .find('.js-questions')
      .sortable({
        handle: '.js-sort-handle',
        cursor: 'move',
        addClasses: false,
        connectWith: '.js-item-drag',
      })
      .off('sortupdate')
      .on(
        'sortupdate',
        function (event, ui) {
          var order = []
          this.$el.find('.js-question').map(function (index, el) {
            order.push($(el).data('item'))
          })
          this.model.questions.emit('reorder', order)
        }.bind(this)
      )

    this.$el.find('.js-questions').addClass('drop-area')
  }

  removeQuestion(id) {
    const i = this.questionViews.findIndex((view) => view.cid === id)
    this.questionViews.splice(i, 1)
  }

  renderRichTextEditors() {
    this.$el
      .find('.js-text-editor')
      .each((i, el) =>
        this.richTextEditorInstanceManager.create(el, { height: 200 })
      )
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New ' + this.title : 'Edit ' + this.title,
        data: this.model.toJSON(),
        account: this.account,
        format: this.serviceLocator.format,
        allowAdditionalQuestions: this.allowAdditionalQuestions,
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    this.on('afterAppend', () => this.renderRichTextEditors())
    this.dateTimePickers = this.$el
      .find('.js-date-time-picker')
      .toArray()
      .map(this.initDateTimePickers)
    this.model.questions.models.forEach((question, i) => {
      question.index = i
      this.addQuestion(question)
    })

    if (this.allowReorder) this.initReorderableQuestions()

    return this
  }
}

module.exports = FormBuilderFormView
