module.exports = () => ({ popPromotionWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('./views/item'),
    name: 'Pop Promotion',
    description: 'Show a pop-up promotion on a page',
  }

  ;[ 'section', 'article' ].forEach((factory) =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('pop-promotion', widget)
  )

  done()
}
