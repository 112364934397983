const join = require('path').join
const compileJade = require('browjadify-compile')
const formErrorsTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (errors, undefined) {
jade_mixins["displayError"] = jade_interp = function(key, error){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if ( typeof error === 'object')
{
// iterate error
;(function(){
  var $$obj = error;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var err = $$obj[key];

jade_mixins["displayError"](key, err);
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var err = $$obj[key];

jade_mixins["displayError"](key, err);
    }

  }
}).call(this);

}
else
{
buf.push("<li><a" + (jade.attr("href", ('#field--' + key), true, false)) + ">" + (jade.escape(null == (jade_interp = error) ? "" : jade_interp)) + "</a></li>");
}
};
buf.push("<div class=\"form-copy form-error\"><strong>Oops! Something isn’t right.</strong><p>Please check and try again.</p><ul>");
// iterate errors
;(function(){
  var $$obj = errors;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var error = $$obj[key];

jade_mixins["displayError"](key, error);
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var error = $$obj[key];

jade_mixins["displayError"](key, error);
    }

  }
}).call(this);

buf.push("</ul></div>");}.call(this,"errors" in locals_for_with?locals_for_with.errors:typeof errors!=="undefined"?errors:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const formErrorTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (error, undefined) {
if ( typeof error === 'object')
{
buf.push("<div class=\"form-copy form-error\"><ul>");
// iterate error
;(function(){
  var $$obj = error;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var err = $$obj[key];

buf.push("<li>" + (jade.escape(null == (jade_interp = err) ? "" : jade_interp)) + "</li>");
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var err = $$obj[key];

buf.push("<li>" + (jade.escape(null == (jade_interp = err) ? "" : jade_interp)) + "</li>");
    }

  }
}).call(this);

buf.push("</ul></div>");
}
else
{
buf.push("<div class=\"form-row-description form-row-error-text\">" + (jade.escape(null == (jade_interp = error) ? "" : jade_interp)) + "</div>");
}}.call(this,"error" in locals_for_with?locals_for_with.error:typeof error!=="undefined"?error:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

function showErrors(errors) {
  this.clearErrors()

  const $errors = formErrorsTemplate({ errors: errors })
  const firstError = Object.keys(errors)[0]
  Object.keys(errors).forEach((key) => {
    this.$('[data-field=' + key + '] .js-error').append(
      formErrorTemplate({ error: errors[key] })
    )
  })
  this.$('.js-errors-summary').append($errors)
  // Set the focus to the first error
  this.$(':input[name=' + firstError + ']').focus()
}

function clearErrors() {
  this.$('.js-errors-summary, .js-error').empty()
}

module.exports = function () {
  return {
    showErrors: showErrors,
    clearErrors: clearErrors,
  }
}
