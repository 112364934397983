module.exports = FormView
const join = require('path').join
const View = require('ventnor')
const modal = require('modal')
const hat = require('hat')
const diff = require('lodash.difference')
const pluck = require('lodash.pluck')
const findIndex = require('lodash.findindex')
const rack = hat.rack()
const compileJade = require('browjadify-compile')
const EditItemView = require('./navigation-item')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"js-errors-summary\"></div><div class=\"dd\"><ol class=\"js-navigation-items dd-list\"></ol></div><button type=\"button\" class=\"btn btn--success js-add\">New Item</button><div class=\"modal-controls\"><button type=\"button\" class=\"btn js-close\">Close</button></div>");;return buf.join("");
}
const itemTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (item) {
buf.push("<div class=\"dd-handle dd-handle--inline\"><div class=\"dd-handle-icon\"></div></div><div class=\"dd-item-content\"><div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\"><div class=\"btn-group\"><button type=\"button\" class=\"btn btn--small js-edit\">Edit</button><button type=\"button\" class=\"btn btn--small js-delete\">Delete</button></div></div><h2>" + (jade.escape(null == (jade_interp = item.title) ? "" : jade_interp)) + "</h2></div><div class=\"list-item-content\"><dl>");
if ( item.type)
{
buf.push("<dt>Type:</dt><dd>" + (jade.escape(null == (jade_interp = item.type.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase() })) ? "" : jade_interp)) + "</dd>");
}
if ( item.ctaLabel)
{
buf.push("<dt>Call to action text:</dt><dd>" + (jade.escape(null == (jade_interp = item.ctaLabel) ? "" : jade_interp)) + "</dd>");
}
buf.push("</dl></div></div></div>");}.call(this,"item" in locals_for_with?locals_for_with.item:typeof item!=="undefined"?item:undefined));;return buf.join("");
}

function FormView (options) {
  View.apply(this, options.serviceLocator)
  this.navigation = options.navigation || []
  this.instance = options.instance
  this.serviceLocator = options.serviceLocator
  this.$el.on('click', '.js-add', this.handleAdd.bind(this))
  this.$el.on('click', '.js-edit', this.handleEdit.bind(this))
  this.$el.on('click', '.js-delete', this.handleDelete.bind(this))
  this.$el.on('click', '.js-save', this.save.bind(this))
  this.$el.on('change', this.save.bind(this))
  this.$el.on('click', '.js-close', this.emit.bind(this, 'close'))
}

FormView.prototype = Object.create(View.prototype)

FormView.prototype.renderNavigationItems = function () {
  var $navigationItems = this.$el.find('.js-navigation-items')
  $navigationItems.empty()
  this.navigation.forEach(function (item) {
    this.addItem(item)
  }.bind(this))
}

FormView.prototype.handleAdd = function (e) {
  e.preventDefault()
  const options = {
    serviceLocator: this.serviceLocator,
    instance: this.instance,
    isNew: true
  }
  const editItemView = new EditItemView(options).render()
  const m = modal({
    title: 'New Item',
    className: 'modal-view wide',
    content: editItemView.$el,
    buttons: []
  })
  editItemView.on('save', item => {
    m.close()
    this.navigation.push(item)
    this.addItem(item)
  })
  editItemView.on('close', () => {
    m.close()
    editItemView.remove.bind(editItemView)
  })
}

FormView.prototype.handleEdit = function (e) {
  e.preventDefault()
  const $item = $(e.currentTarget).closest('.dd-item')
  const id = $item.data('id')
  const currentItem = this.navigation.find(item => item.id === id)
  const options = {
    serviceLocator: this.serviceLocator,
    instance: this.instance,
    isNew: false,
    item: currentItem
  }
  const editItemView = new EditItemView(options).render()
  const m = modal({
    title: 'Edit Item',
    className: 'modal-view wide',
    content: editItemView.$el,
    buttons: []
  })
  editItemView.on('save', item => {
    m.close()
    Object.assign(currentItem, item)
    this.render()
  })
  editItemView.on('close', () => {
    m.close()
    editItemView.remove.bind(editItemView)
  })
}

FormView.prototype.handleDelete = function (e) {
  e.preventDefault()
  const $item = $(e.currentTarget).closest('.dd-item')
  const id = $item.data('id')
  const item = this.navigation.find(item => item.id === id)

  modal(
    {
      content: 'Are you sure you want to delete "' + item.title + '"?',
      buttons: [ {
        text: 'Cancel',
        event: 'cancel',
        keyCodes: [ 27 ],
        className: 'btn'
      }, {
        text: 'Delete',
        event: 'confirm',
        className: 'btn btn--error',
        keyCodes: [ 13 ]
      } ]
    })
    .on('confirm', () => {
      $item.remove()
      const currentItems = this.$el.find('.dd').nestable('serialize')
      const currentIds = []

      const getCurrentIds = items => {
        items.forEach(function (item) {
          currentIds.push(item.id)
          if (item.children) getCurrentIds(item.children)
        })
      }

      getCurrentIds(currentItems)

      const idsToRemove = diff(pluck(this.navigation, 'id'), currentIds)
      idsToRemove.forEach(id => {
        const index = findIndex(this.navigation, item => item.id === id)
        this.navigation.splice(index, 1)
      })
    })
}

FormView.prototype.addItem = function (item) {
  if (!item.id) item.id = rack()
  let $parent = this.$el.find('.js-navigation-items')
  const parentId = item.parent

  if (parentId) {
    $parent = this.$el.find('.js-navigation-items [data-id=' + parentId + ']')
    if ($parent.children('ol').length > 0) {
      $parent = $parent.children('ol')
    } else {
      var ddList = $('<ol class="dd-list">')
      $parent.append(ddList)
      $parent = ddList
    }
  }
  $parent.append($('<li class="dd-item" data-id=' + item.id + '>')
    .append(itemTemplate({ item: item })))
}

FormView.prototype.render = function () {
  this.$el.empty()
  this.$el.append(template())
  this.renderNavigationItems()
  this.$el.find('.dd').nestable({ maxDepth: 2, collapseBtnHTML: '', expandBtnHTML: '' })

  return this
}

FormView.prototype.save = function () {
  let orderCounter = 1
  const newOrder = this.$el.find('.dd').nestable('serialize')

  const processChildren = (parent, orderItems) => {
    orderItems.forEach(childItem => {
      const item = this.navigation.find(item => item.id === childItem.id)
      item.parent = parent
      item.order = orderCounter++
      if (childItem.children) {
        processChildren.call(this, childItem.id, childItem.children)
      }
    })
  }

  processChildren.call(this, null, newOrder)
  this.navigation.sort((a, b) => {
    if (a.order > b.order) {
      return 1
    } else if (b.order > a.order) {
      return -1
    } else {
      return 0
    }
  })
}

FormView.prototype.getNavigation = function () {
  return this.navigation
}
