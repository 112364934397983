const debug = require('../../../../admin/source/js/lib/debug')(
  'widget area model'
)
const BaseModel = require('cf-base-model')
const generateId = require('hat')
// Private internal add. Make sure you bind this
function addToArea(model, at) {
  debug('adding widget', model)
  model.widgetArea = this
  if (typeof at === 'number') {
    ;[].splice.apply(this.widgets, [ at, 0 ].concat(model))
  } else {
    this.widgets.push(model)
  }
  this.widgetsIndex[model.id] = model
  this.observeWidgetEvents(model)

  // Create a global lookup between widget areas
  if (this.options && this.options.relatedWidgets) {
    this.options.relatedWidgets[model.cid] = model
  }
}

function getByCid(cid) {
  debug('getByCid', this.options)
  return (
    this.options &&
    this.options.relatedWidgets &&
    this.options.relatedWidgets[cid]
  )
}

module.exports = BaseModel.extend({
  initialize: function (attributes, options) {
    debug('init', attributes, options)

    if (!options || !options.abstractWidgetFactory) {
      throw new Error("You must provide an 'abstractWidgetFactory' in options")
    }

    this.abstractWidgetFactory = options && options.abstractWidgetFactory

    this.options = options || {}

    // If relatedWidget isn't passed make just for local reference
    if (typeof this.options.relatedWidgets === 'undefined') {
      this.options.relatedWidgets = {}
    }

    this.widgets = []
    this.widgetsIndex = {}

    if (!Array.isArray(this.get('widgets'))) {
      throw new Error('widgets as an {} when a [] was expected')
    }

    this.get('widgets').forEach(
      function (widget) {
        const factory = this.abstractWidgetFactory(
          widget.type || widget.get('type')
        )
        const Model = factory.model

        addToArea.call(this, new Model(widget))
      }.bind(this)
    )

    this.set('widgets', this.widgets)
  },

  defaults() {
    return {
      widgets: [],
    }
  },

  toJSON: function () {
    var attributes = { widgets: [] }
    this.get('widgets').forEach(function (value) {
      attributes.widgets.push(value.toJSON())
    })
    return attributes
  },

  observeWidgetEvents: function (widget) {
    debug('observe widget events')
    widget.on('remove', this.remove.bind(this))
    widget.on(
      'change',
      function () {
        this.trigger('change')
      }.bind(this)
    )
  },

  add: function (widget, options) {
    options = options || {}
    debug('add', widget)

    var id = generateId()
    // A collision is mega unlikely, but still possible, so…
    while (Object.keys(this.widgetsIndex).indexOf(id) !== -1) id = generateId()

    widget.set({ id: id })

    addToArea.call(this, widget, options.at)

    this.set('widgets', this.widgets)
    this.trigger('change')
    this.trigger('add', this, widget, options)
    return widget
  },

  // How all the widgets of given type
  getType: function (type) {
    return this.widgets.filter(function (widget) {
      return type === widget.get('type')
    })
  },

  /**
   *  Removes either the widget provided or an index to a widget
   */
  remove: function (widget) {
    debug('remove', widget)
    var id = widget
    // Allow objects or IDs to be passed
    if (widget.get) {
      id = widget.get('id')
    }

    if (!this.widgetsIndex[id]) {
      throw new Error("Widget with ID '" + id + "' not found")
    }
    delete this.widgetsIndex[id]
    this.widgets = this.widgets.filter(function (widget) {
      return widget.id !== id
    })
    this.set({ widgets: this.widgets })
    this.trigger('change')
    this.trigger('remove', this, widget)
  },

  setOrder: function (order) {
    debug('setting order', order)
    var newOrderWidget = []

    order.forEach(function (cid) {
      newOrderWidget.push(getByCid.call(this, cid))
    }, this)

    this.widgets = newOrderWidget
    this.set({ widgets: this.widgets })
    this.trigger('change reorder')
  },

  getWidget: function (id) {
    return this.widgetsIndex[id]
  },
})
