const CrudService = require('../../../../admin/source/js/lib/crud-service')

class GalleryService extends CrudService {
  get name() {
    return 'GalleryService'
  }
  get urlRoot() {
    return '/gallery'
  }
}

module.exports = GalleryService
