const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const isEmail = require('validity-email')

module.exports = () =>
  schemata({
    name: 'Gallery schema',
    properties: {
      _id: {
        type: String,
      },
      account: {
        type: String,
      },
      name: {
        type: String,
        validators: [required],
      },
      emailAddress: {
        type: String,
        validators: [required, isEmail],
      },
      message: {
        type: String,
      },
      images: {
        type: Object,
        validators: [required],
      },
      dateSubmitted: {
        type: Date,
        defaultValue: () => new Date(),
      },
      destination: {
        type: String,
        validatiors: [required],
        defaultValue: () => 'tiger-hunt',
      },
    },
  })
