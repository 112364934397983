module.exports = createController
const ListView = require('../views/list')
const Collection = require('chale')
const CompetitionModel = require('../models/competition')
const Model = require('merstone')
const async = require('async')
const pageSize = 50

function createController(serviceLocator) {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0,
  })
  let currentPage = 1
  let currentParams = {
    keywords: '',
    filter: {},
    sort: [ 'dateCreated', 'desc' ],
  }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an competition is updated, reset the model with its new attributes
  serviceLocator.competitionService.on('update', function (id, attrs) {
    var model = collection.get(id)
    if (model) model.reset(attrs)
    serviceLocator.competitionService.cachedFind.clear()
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.competitionService.on('create', function () {
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    serviceLocator.competitionService.cachedFind.clear()
    getCompetitions(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  serviceLocator.router.route(
    'competitions(/)',
    'listCompetitions',
    function () {
      if (!serviceLocator.allow('competition', 'discover')) return false

      getCompetitions(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )

      var list = new ListView(
        serviceLocator,
        collection,
        paginationModel
      ).render()

      list.displayFilterParams(currentParams)

      list.on('createNew', function () {
        if (!serviceLocator.allow('competition', 'create')) return false
        serviceLocator.router.navigate('competitions/form', { trigger: true })
      })

      list.on('edit', function (id) {
        if (!serviceLocator.allow('competition', 'update')) return false
        serviceLocator.router.navigate('competitions/' + id + '/form', {
          trigger: true,
        })
      })

      list.on('delete', function (ids) {
        if (!serviceLocator.allow('competition', 'delete')) return false
        async.each(ids, deleteOne, function (err) {
          if (err) return alert(err.message)
        })

        function deleteOne(id, cb) {
          serviceLocator.competitionService.delete(id, function (err) {
            if (err) return cb(err)
            collection.remove(id)
          })
        }
      })

      list.on('filter', function (params) {
        currentParams = params
        var pagination = { page: currentPage, pageSize: pageSize }
        currentPage = 1
        getCompetitions(params.keywords, params.filter, params.sort, pagination)
      })

      list.on('loadMore', function () {
        currentPage += 1
        var pagination = { page: currentPage, pageSize: pageSize }
        appendCompetitions(
          currentParams.keywords,
          currentParams.filter,
          currentParams.sort,
          pagination
        )
      })

      serviceLocator.router.render(list, 'Competitions')
    }
  )

  function getCompetitions(keywords, filter, sort, pagination) {
    serviceLocator.competitionService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err)
          return serviceLocator.logger.error(err, 'Could not load competitions')
        collection.reset(
          res.results.map(function (competition) {
            return new CompetitionModel(serviceLocator, competition)
          })
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  function appendCompetitions(keywords, filter, sort, pagination) {
    serviceLocator.competitionService.find(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err) return alert(err.message)
        res.results.forEach(function (competition) {
          collection.add(new CompetitionModel(serviceLocator, competition))
        })
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }
}
