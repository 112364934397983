module.exports = createController
const ListView = require('../views/list')
const Collection = require('chale')
const LinkedKeywordModel = require('../models/linked-keyword')
const Model = require('merstone')
const async = require('async')
const pageSize = 50

function createController(serviceLocator) {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0,
  })
  let currentPage = 1
  let currentParams = {
    keywords: '',
    filter: {},
    sort: [ 'dateCreated', 'desc' ],
  }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an linked keyword is updated, reset the model with its new attributes
  serviceLocator.linkedKeywordService.on('update', function (id, attrs) {
    var model = collection.get(id)
    if (model) model.reset(attrs)
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.linkedKeywordService.on('create', function () {
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    getLinkedKeywords(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  serviceLocator.router.route(
    'linked-keywords(/)',
    'listLinkedKeywords',
    function () {
      if (!serviceLocator.allow('linkedKeyword', 'discover')) return false

      getLinkedKeywords(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )

      var list = new ListView(
        serviceLocator,
        collection,
        paginationModel
      ).render()

      list.displayFilterParams(currentParams)

      list.on('createNew', function () {
        if (!serviceLocator.allow('linkedKeyword', 'create')) return false
        serviceLocator.router.navigate('linked-keywords/form', {
          trigger: true,
        })
      })

      list.on('edit', function (id) {
        if (!serviceLocator.allow('linkedKeyword', 'update')) return false
        serviceLocator.router.navigate('linked-keywords/' + id + '/form', {
          trigger: true,
        })
      })

      list.on('delete', function (ids) {
        if (!serviceLocator.allow('linkedKeyword', 'delete')) return false
        async.each(ids, deleteOne, function (err) {
          if (err) return alert(err.message)
        })

        function deleteOne(id, cb) {
          serviceLocator.linkedKeywordService.delete(id, function (err) {
            if (err) return cb(err)
            collection.remove(id)
          })
        }
      })

      list.on('filter', function (params) {
        currentParams = params
        var pagination = { page: currentPage, pageSize: pageSize }
        currentPage = 1
        getLinkedKeywords(
          params.keywords,
          params.filter,
          params.sort,
          pagination
        )
      })

      list.on('loadMore', function () {
        currentPage += 1
        var pagination = { page: currentPage, pageSize: pageSize }
        appendLinkedKeywords(
          currentParams.keywords,
          currentParams.filter,
          currentParams.sort,
          pagination
        )
      })

      serviceLocator.router.render(list, 'Linked Keywords')
    }
  )

  function getLinkedKeywords(keywords, filter, sort, pagination) {
    serviceLocator.linkedKeywordService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err)
          return serviceLocator.logger.error(
            err,
            'Could not load linked keywords'
          )
        collection.reset(
          res.results.map(function (linkedKeyword) {
            return new LinkedKeywordModel(serviceLocator, linkedKeyword)
          })
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  function appendLinkedKeywords(keywords, filter, sort, pagination) {
    serviceLocator.linkedKeywordService.find(
      keywords,
      filter,
      sort,
      pagination,
      function (err, res) {
        if (err) return alert(err.message)
        res.results.forEach(function (linkedKeyword) {
          collection.add(new LinkedKeywordModel(serviceLocator, linkedKeyword))
        })
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }
}
