const BaseModel = require('cf-base-model')
const schemata = require('../../../service/redirect/schema')({}, {})

module.exports = BaseModel.extend({
  idAttribute: '_id',
  schemata: schemata,
  defaults() {
    return schemata.makeDefault()
  },
})
