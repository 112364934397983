module.exports = VenueService
const CrudService = require('../../../../admin/source/js/lib/crud-service')

function VenueService() {
  this.name = 'VenueService'
  CrudService.apply(this, arguments)
  this.urlRoot = '/venues'
}

VenueService.prototype = Object.create(CrudService.prototype)
