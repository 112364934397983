import editView from './views/form'
import model from './models/model'
import itemView from './views/item'

const initWidget = () => ({ videoWidget: ['widget', 'sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Video',
    description: 'Displays a video from a third party provider',
  }

  ;['section', 'articleBody', 'article', 'tabsBody'].forEach((factory) =>
    serviceLocator.widgetFactories.get(factory).register('video', widget)
  )

  done()
}

module.exports = initWidget
