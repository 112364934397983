const FormView = require('../views/form')
const notify = require('../../notification/foreground')
const previewArticle = require('../lib/article-preview')

module.exports = function createArticleFormViewController(serviceLocator) {
  return function showArticleForm(article, account, isNew) {
    var form = new FormView(serviceLocator, article, account, isNew).render()
    serviceLocator.router.render(form, isNew ? 'New Article' : 'Edit Article')

    function save(icon, state, cb) {
      const previousState = form.model.get('state') || 'Draft'
      const service = serviceLocator.articleService
      let saveFn
      let id

      if (state) form.model.set('state', state)

      if (isNew && !form.model.get('_id')) {
        saveFn = service.create.bind(service, form.model.toJSON())
      } else {
        id = article.id || form.model.get('_id')
        saveFn = service.update.bind(service, id, form.model.toJSON())
      }

      afterSave(saveFn, function (err) {
        if (err) return form.model.set('state', previousState)
        notify(state || 'Saved', icon)
        if (typeof cb === 'function') cb(null, isNew)
      })
    }

    function afterSave(saveFn, cb) {
      saveFn(function (err, data) {
        if (err) {
          form.showErrors(err.errors)
          return cb(err)
        }
        form.model.reset(data)
        form.clearUnsavedChanges()
        form.clearErrors()
        cb(null, form.model)
      })
    }

    function preview() {
      previewArticle(serviceLocator, article)
        .on('window:open', function (w) {
          w.document.write('Saving, please wait...')
        })
        .on('error:noSection', form.showMissingSectionError.bind(form))
    }

    form.on(
      'publish',
      save.bind(null, 'published', 'Published', form.close.bind(form))
    )
    form.on('draft', save.bind(null, 'draft', 'Draft', form.close.bind(form)))
    form.on('saveAndClose', save.bind(null, 'save', '', form.close.bind(form)))

    form.on('save', function () {
      save('save', null, function (ignoreErr, isNew) {
        form.emit('saveComplete', isNew)
      })
    })

    form.on('saveAndPreview', function () {
      save('save', null, preview)
    })

    return form
  }
}
