import editView from './views/form'
import itemView from '../../../widget/views/item/edit-base'
import model from './models/model'

const initWidget = () => ({ playerMatchStatsWidget: [ 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Player Match Stats',
    description: 'Display team players stats for a match',
  }

  serviceLocator.widgetFactories
    .get('match')
    .register('playerMatchStats', widget)
  done()
}

module.exports = initWidget
