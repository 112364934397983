const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/list')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const createRevisionController = require('../revision/controllers/controller')
const ListService = require('./services/list')

function initAdmin() {
  return { listAdmin: [ init ] }
}

function init(serviceLocator, cb) {
  serviceLocator.listService = new ListService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'list',
    plural: 'lists',
    path: 'lists',
    service: serviceLocator.listService,
    Model,
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  createRevisionController(
    serviceLocator,
    serviceLocator.listService,
    'list',
    'lists/:id/revisions',
    'lists',
    'name'
  )
  cb(null)
}

module.exports = initAdmin
