module.exports = initWidget

function initWidget() {
  return { sellWidget: [ 'sectionAdmin', init ] }
}

function init(serviceLocator, done) {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('../../../widget/views/item/edit-base'),
    name: 'Sell',
    description: 'Display match sell',
  }

  serviceLocator.widgetFactories.get('match').register('sell', widget)
  done()
}
