module.exports = createController
const Model = require('../models/article')
const createArticleFormViewController = require('./form-view-controller')
const async = require('async')

function createController(serviceLocator) {
  var initializeArticleFormView = createArticleFormViewController(
    serviceLocator
  )

  // Create
  serviceLocator.router.route('articles/form', 'createArticle', function () {
    if (!serviceLocator.allow('article', 'create')) return false

    const accountId = serviceLocator.session.account
    const model = new Model(serviceLocator)

    if (!accountId)
      return alert(
        'Session must be associated with an account id to create/edit articles'
      )

    model.makeDefault(function (err, articleModel, account) {
      if (err) return serviceLocator.router.trigger('notFound', err.message)
      setupNavigation(initializeArticleFormView(model, account, true))
    })
  })

  // Duplicate
  serviceLocator.router.route(
    'articles/:id/duplicate',
    'duplicateArticle',
    function (id) {
      if (!serviceLocator.allow('article', 'update')) return false

      var accountId = serviceLocator.session.account
      if (!accountId)
        return alert(
          'Session must be associated with an account id to create/edit articles'
        )

      async.parallel(
        {
          article: serviceLocator.articleService.read.bind(
            serviceLocator.articleService,
            id
          ),
          account: serviceLocator.accountService.read.bind(
            serviceLocator.accountService,
            accountId
          ),
        },
        function (err, data) {
          if (err) return serviceLocator.router.trigger('notFound', err.message)
          const article = new Model(serviceLocator)
          const articleData = data.article
          // Clear ID so it's new
          delete articleData._id
          // Clear slug as duplicate is not allowed
          delete articleData.slug
          // Clear previewId so a new one is generated
          delete articleData.previewId
          // Delete dateCreated so ordering is logical
          delete articleData.dateCreated
          article.set(article.schema.makeDefault(articleData))
          setupNavigation(
            initializeArticleFormView(article, data.account, true)
          )
        }
      )
    }
  )

  // Edit
  serviceLocator.router.route('articles/:id/form', 'editArticle', function (
    id
  ) {
    if (!serviceLocator.allow('article', 'update')) return false

    var accountId = serviceLocator.session.account
    if (!accountId)
      return alert(
        'Session must be associated with an account id to create/edit articles'
      )

    async.parallel(
      {
        article: serviceLocator.articleService.read.bind(
          serviceLocator.articleService,
          id
        ),
        account: serviceLocator.accountService.read.bind(
          serviceLocator.accountService,
          accountId
        ),
      },
      function (err, data) {
        if (err) return serviceLocator.router.trigger('notFound', err.message)
        var model = new Model(serviceLocator, data.article)
        setupNavigation(initializeArticleFormView(model, data.account, false))
      }
    )
  })

  function setupNavigation(view) {
    view.on('close', function () {
      serviceLocator.router.navigate('articles', { trigger: true })
    })

    view.on('saveComplete', function (isNew) {
      if (isNew)
        serviceLocator.router.navigate('articles/' + view.model.id + '/form', {
          trigger: true,
        })
    })

    view.on('back', function () {
      serviceLocator.router.navigate('articles', { trigger: true })
    })
  }
}
