const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const isNumber = require('validity-number')
const validateIfSet = require('validity-validate-if-set')

module.exports = function (serviceLocator) {
  return schemata({
    name: 'Venue schema',
    properties: {
      _id: { type: String },
      season: {
        type: Number,
        defaultValue: () => serviceLocator.seasonService.getCurrentYear(),
      },
      name: { type: String, validators: [required] },
      city: { type: String },
      street: { type: String },
      country: { type: String },
      capacity: { type: Number, validators: [validateIfSet(isNumber)] },
      latitude: { type: Number, validators: [validateIfSet(isNumber)] },
      longitude: { type: Number, validators: [validateIfSet(isNumber)] },
      source: { type: String, defaultValue: () => 'cms' },
      lastModifiedDate: { type: Date },
      createdDate: {
        type: Date,
        defaultValue: () => new Date(),
      },
    },
  })
}
