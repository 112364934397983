const async = require('async')
const EventEmitter = require('events').EventEmitter

module.exports = function preview(serviceLocator, article) {
  const eventEmitter = new EventEmitter()
  const w = window.open('')

  process.nextTick(function () {
    eventEmitter.emit('window:open', w)

    if (!article.get('__fullUrlPath')) {
      w.close()
      return eventEmitter.emit('error:noSection')
    }

    async.waterfall(
      [
        function (cb) {
          serviceLocator.sectionService.read(
            article.get('sections')[0],
            function (err, section) {
              if (err) return cb(err)
              return cb(null, { section: section })
            }
          )
        },
        function (obj, cb) {
          if (obj.section.instance) {
            serviceLocator.instanceService.read(obj.section.instance, function (
              err,
              instance
            ) {
              if (err) return cb(err)
              obj.instance = instance
              return cb(null, obj)
            })
          } else {
            serviceLocator.instanceService.find(
              '',
              {},
              [],
              { pageSize: 1, page: 1 },
              function (err, res) {
                if (err) return cb(err)
                obj.instance = res.results[0]
                return cb(null, obj)
              }
            )
          }
        },
        function (obj, cb) {
          serviceLocator.instanceService.getUrl(obj.instance, cb)
        },
      ],
      function (err, url) {
        if (err) {
          w.close()
          eventEmitter.emit('error')
          return serviceLocator.logger.error(err, 'Could not preview article')
        }

        url +=
          article.get('__fullUrlPath') +
          '?previewId=' +
          article.get('previewId')
        w.location = url
        eventEmitter.emit('window:set', w, url)
      }
    )
  })

  return eventEmitter
}
