module.exports = SectionSelect
const View = require('./section-select')

function SectionSelect(serviceLocator, selectedSections) {
  View.apply(this, arguments)
  this.selectedSections = selectedSections || []
  if (!Array.isArray(this.selectedSections)) {
    this.selectedSections = [ this.selectedSections ]
  }
  this.initFilter = {
    _id: { $in: this.selectedSections },
    instance: { $ne: null },
  }
  this.loadFilter = { root: false, instance: { $ne: null } }
}

SectionSelect.prototype = Object.create(View.prototype)
