const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (sectionName) {
buf.push("<form><div class=\"js-errors-summary\"><div class=\"form-row form-row-output\"><span class=\"form-label-text\">Duplicating from</span><div class=\"form-field\">" + (jade.escape(null == (jade_interp = sectionName) ? "" : jade_interp)) + "</div></div><div data-field=\"toSection\" class=\"form-row\"><span class=\"form-label-text\">Duplicating to</span><div class=\"form-field form-field-multi-select\"><ul class=\"js-to-sections\"></ul><label><span class=\"form-label-text vhidden\">Choose A Section</span><select class=\"control control--choice js-to-section-dropdown\"></select></label></div><div class=\"js-error\"></div></div><div data-field=\"duplicateRecursively\" class=\"form-row form-row-boolean\"><label><span class=\"form-label-text\">Apply to Child Sections</span><div class=\"form-field\"><input type=\"checkbox\" name=\"duplicateRecursively\" class=\"control control--boolean\"/></div><div class=\"js-error\"></div></label></div><div data-field=\"duplicateSectionLayout\" class=\"form-row form-row-boolean\"><label><span class=\"form-label-text\">Duplicate Section Layout</span><div class=\"form-field\"><input type=\"checkbox\" checked=\"checked\" name=\"duplicateSectionLayout\" class=\"control control--boolean\"/></div><div class=\"js-error\"></div></label></div><div data-field=\"duplicateArticleLayout\" class=\"form-row form-row-boolean\"><label><span class=\"form-label-text\">Duplicate Article Layout</span><div class=\"form-field\"><input type=\"checkbox\" name=\"duplicateArticleLayout\" class=\"control control--boolean\"/></div><div class=\"js-error\"></div></label></div></div></form>");}.call(this,"sectionName" in locals_for_with?locals_for_with.sectionName:typeof sectionName!=="undefined"?sectionName:undefined));;return buf.join("");
}
const debug = require('../../../../admin/source/js/lib/debug')(
  'section duplicate layout'
)
const SectionCollection = require('../collections/lite-section')
const sectionOptionTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (name, selected, value) {
buf.push("<option" + (jade.attr("value", value, true, false)) + (jade.attr("selected", selected, true, false)) + ">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</option>");}.call(this,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"selected" in locals_for_with?locals_for_with.selected:typeof selected!=="undefined"?selected:undefined,"value" in locals_for_with?locals_for_with.value:typeof value!=="undefined"?value:undefined));;return buf.join("");
}
const relatedSectionTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model) {
buf.push("<li" + (jade.attr("js-section-id", model.id, true, false)) + (jade.attr("js-section-name", model.get('fullUrlPath'), true, false)) + " class=\"control-addon js-related-section\"><span class=\"control-addon__base control-placeholder\">" + (jade.escape(null == (jade_interp = model.get('fullUrlPath')) ? "" : jade_interp)) + "</span><div class=\"control-addon__affix control-addon__affix--suffix\"><button type=\"button\" class=\"btn btn--large js-delete-section\"><i class=\"icon icon--cross--dark\">Reset</i></button></div></li>");}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined));;return buf.join("");
}

module.exports = window.Backbone.View.extend({
  events: {
    'change .js-to-section-dropdown': 'handleAddToSection',
    'change input[type=checkbox]': 'handleCheckboxChange',
    'click .js-delete-section': 'handleDeleteToSection',
  },

  sectionCollection: null,
  section: null,
  toSections: [],

  initialize: function (params) {
    this.section = params.section
    this.render()

    this.populateSections()
  },

  populateSections: function () {
    // Populate the section drop down
    this.sectionCollection = new SectionCollection()

    var currentSection = this.section

    this.sectionCollection.on(
      'add',
      function (section) {
        var renderOptions = {
          value: section.id,
          name: section.get('fullUrlPath'),
          order: section.get('order'),
          selected: currentSection === section.id,
        }

        debug(
          'Adding to section dropdown values',
          currentSection,
          renderOptions
        )

        // Add to the dropdown
        renderOptions.selected = false
        this.$('.js-to-section-dropdown').append(
          sectionOptionTemplate(renderOptions)
        )
      },
      this
    )
    this.$('.js-to-section-dropdown').append(
      '<option value="", disabled, selected>Choose Sections</option>'
    )
    this.$('select[name=section]').empty()
    this.sectionCollection.load()
  },

  handleCheckboxChange: function (e) {
    debug('handle checkbox change')

    this.model.set(e.target.name, e.target.checked)
  },

  handleAddToSection: function () {
    debug('handle add to section')
    const $dropdown = $('.js-to-section-dropdown')
    const $ul = $('.js-to-sections')
    const id = $dropdown.find('option:selected').val()
    if (id) {
      $ul.append(
        relatedSectionTemplate({ model: this.sectionCollection.get(id) })
      )
      $dropdown.find('option[value=' + id + ']').remove()
    }

    if ($dropdown.find('option').length === 1) {
      $('.js-add-to-section').attr('disabled', 'disabled')
    } else {
      $('.js-add-to-section').removeAttr('disabled')
    }

    this.model.addSection(id)
  },

  handleDeleteToSection: function (e) {
    debug('handle delete to section')
    const $liItem = $($(e.target).closest('li'))
    const $dropdown = $('.js-to-section-dropdown')
    const id = $liItem.attr('js-section-id')
    const name = $liItem.attr('js-section-name')

    this.model.removeSection(id)

    $liItem.remove()
    $dropdown.append('<option value=' + id + '>' + name + '</option>')
    $('.js-add-to-section').removeAttr('disabled')
    return false
  },

  render: function () {
    this.$el.empty().append(template({ sectionName: this.section.get('name') }))
    return this
  },
})
