const getMatchDateTime = require('../../../service/match/lib/get-match-date-time').default
const FormView = require('../views/form')
const Model = require('../models/commentary')
const fecha = require('fecha')

const createController = (serviceLocator) => {
  serviceLocator.router.route(
    'matches/:id/commentary',
    'editCommentary',
    (id) => {
      if (!serviceLocator.allow('commentary', 'update')) return false
      serviceLocator.matchService.read(id, (error, match) => {
        if (error)
          return serviceLocator.router.trigger('notFound', error.message)
        const ids = [match.homeTeam, match.awayTeam]

        serviceLocator.teamService.find(
          '',
          { _id: { $in: ids } },
          [],
          {},
          (error, teams) => {
            if (error)
              return serviceLocator.logger.error(
                error,
                'Unable to embellish teams'
              )

            const attributes = {
              match: id,
              matchDate: fecha.format(
                new Date(getMatchDateTime(match)),
                'D MMM YYYY HH:mm'
              ),
              homeTeam: teams.results.find(
                (team) => team._id === match.homeTeam
              ),
              awayTeam: teams.results.find(
                (team) => team._id === match.awayTeam
              ),
              matchSlug: serviceLocator.config.url + match.slug,
              isLive: match.isLive,
              status: match.status,
            }

            const form = new FormView(
              serviceLocator,
              new Model(serviceLocator, attributes),
              false
            ).render()
            serviceLocator.router.render(form, 'Edit Commentary')

            form.on('back', back)
            form.on('toggleLiveStatus', function (e) {
              serviceLocator.matchService.emit(
                'toggleLiveStatus',
                id,
                e.currentTarget.checked,
                (error) => {
                  if (error) {
                    serviceLocator.logger.error(
                      error,
                      'Unable to toggle match status'
                    )
                    return form.emit(
                      'toggleLive:completed',
                      e.currentTarget.checked,
                      error
                    )
                  }
                  form.emit('toggleLive:completed', e.currentTarget.checked)
                }
              )
            })

            form.on('toggleMatchStatus', function (status) {
              serviceLocator.matchService.emit(
                'toggleMatchStatus',
                id,
                status,
                (error) => {
                  if (error) {
                    serviceLocator.logger.error(
                      error,
                      'Unable to toggle match status'
                    )
                    return form.emit('toggleStatus:completed', status, error)
                  }
                  form.emit('toggleStatus:completed', status)
                }
              )
            })
          }
        )
      })
    }
  )

  const back = () => {
    serviceLocator.router.navigate('matches', { trigger: true })
  }
}

module.exports = createController
