const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const createContextValidator = require('validity-cf-image-context-selection')

module.exports = () =>
  schemata({
    name: 'Section promo',
    properties: {
      images: {
        type: Object,
        defaultValue: function () {
          return {}
        },
        validators: { all: [createContextValidator(['Main'])] },
      },
      title: {
        type: String,
        validators: { all: [required] },
      },
      subtitle: {
        type: String,
        validators: { all: [required] },
      },
      sell: {
        type: String,
        validators: { all: [required] },
      },
      callToActionText: {
        type: String,
        validators: { all: [required] },
      },
      callToActionLink: {
        type: String,
        validators: { all: [required] },
      },
      displayShards: { type: Boolean },
      ...baseSchema.getProperties(),
    },
  })
