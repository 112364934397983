const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('@clocklimited/validity-required')

const schema = schemata({
  name: 'Form builder',
  properties: {
    formBuilder: {
      type: String,
      validators: [required],
    },
    ...baseSchema.getProperties(),
  },
})

module.exports = BaseModel.extend({
  schemata: schema,
  type: 'formBuilder',
  defaults() {
    return schema.makeDefault({ type: this.type || 'formBuilder' })
  },

  validate: validateDelegate,
})
