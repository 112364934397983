const debug = require('../../../../admin/source/js/lib/debug')(
  'image area model'
)
const WidgetAreaModel = require('../../widget/models/widget-area')
const abstractWidgetFactory = require('../lib/image-factory')()

module.exports = WidgetAreaModel.extend({
  initialize: function () {
    this.debug = debug
    WidgetAreaModel.prototype.initialize.call(
      this,
      {},
      {
        abstractWidgetFactory: abstractWidgetFactory,
        serviceLocator: window.serviceLocator,
      }
    )
  },
})
