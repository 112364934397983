//
// GLOBAL LOADING
// ==============
// Appends a loading bar to the global 'toolbar' when required.
// This delay will allow ajax to return without showing the loader

module.exports = function (target, loadingDelay, debug) {
  debug('setting up global loading bar')

  let concurrentRequests = 0
  let finished = false
  let classAdded = false

  $(document).ajaxComplete(function () {
    concurrentRequests = concurrentRequests - 1

    if (concurrentRequests > 0) {
      return
    }

    debug('hiding global loading bar')

    var $toolbar = $(target)

    if ($toolbar.find('.global-loading').length > 0) {
      $toolbar.find('.global-loading').removeClass('currently-loading')

      finished = true
      if (!classAdded) {
        $toolbar.find('.global-loading').remove()
      } else {
        $('.global-loading').on(
          'transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd',
          function () {
            debug('hiding global loading bar')
            $toolbar.find('.global-loading').remove()
          }
        )
      }
    }
  })

  $(document).ajaxSend(function () {
    concurrentRequests += 1

    var $toolbar = $(target)

    if ($toolbar.find('.global-loading-content').length > 0) {
      return
    }
    finished = false
    classAdded = false

    debug('appending global loading bar')

    /* eslint-disable one-var */
    var $loaderContent = $('<span />')
        .addClass('global-loading-content')
        .text('Loading'),
      $loader = $('<div />').addClass('global-loading').append($loaderContent)

    $toolbar.append($loader)

    window.setTimeout(function () {
      debug('showing global loading bar')
      if (!finished) {
        classAdded = true
        $loader.addClass('currently-loading')
      }
    }, loadingDelay)
  })
}
