const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterItemView = require('../../../widget/views/item/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, id, parseInt) {
var formattedSeason = `${parseInt(data.season) - 1} / ${data.season}`
buf.push("<div" + (jade.attr("data-id", id, true, false)) + " class=\"js-item list-item\"><div class=\"list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-edit-item\">Edit</a><a class=\"js-remove-item\">Remove</a></li></ul></div></div><h2><a class=\"js-edit-item\">" + (jade.escape(null == (jade_interp = formattedSeason) ? "" : jade_interp)) + "</a></h2></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined,"parseInt" in locals_for_with?locals_for_with.parseInt:typeof parseInt!=="undefined"?parseInt:undefined));;return buf.join("");
}

class SeasonStatsRepeaterItemView extends BaseItemRepeaterItemView {
  constructor(serviceLocator, model, ...args) {
    super(serviceLocator, model, ...args)
    this.template = template
  }
}

module.exports = SeasonStatsRepeaterItemView
