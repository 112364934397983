module.exports = StatusSelectView
const View = require('ventnor')
const statusData = require('../../../service/match/match-statuses').all
const find = require('lodash.find')

function StatusSelectView(
  serviceLocator,
  value,
  playable = true,
  isEditable = true
) {
  View.apply(this, arguments)
  this.value = value
  this.playable = playable
  this.isEditable = isEditable
  this.statuses = statusData
  if (this.isEditable) {
    this.$el = $('<select/>')
    this.$el.attr('name', 'status')
    this.$el.addClass('control control--choice')
    this.$el.on(
      'change',
      function () {
        this.emit('change', this.$el.val())
      }.bind(this)
    )
    this.notSetLabel = '-- Select a Status --'
  }
}

StatusSelectView.prototype = Object.create(View.prototype)

StatusSelectView.prototype.render = function () {
  if (this.isEditable) {
    this.$el.append($('<option/>', { text: this.notSetLabel, value: '' }))
    this.populate()
  } else {
    const status = find(this.statuses, { value: this.value })
    this.$el = $('<span/>').html(status ? status.text : '')
  }
  return this
}

StatusSelectView.prototype.populate = function () {
  if (this.isEditable) {
    this.statuses.forEach(
      function (status) {
        this.$el.append(
          $('<option/>', {
            text: status.text,
            value: status.value,
            selected: this.value === status.value,
          })
        )
      }.bind(this)
    )
  }
  return this
}
