module.exports = function () {
  // Use this to configure ck-editor
  return {
    toolbarGroups: [
      { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
      { name: 'editing', groups: [ 'find', 'selection' ] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'forms' },
      { name: 'tools' },
      { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
      { name: 'others' },
      '/',
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
      { name: 'paragraph', groups: [ 'list', 'blocks', 'align' ] },
      { name: 'styles' },
      { name: 'colors' },
    ],

    removeButtons: 'Underline,Subscript,Superscript',
    format_tags: 'h2;h3',
    stylesSet: 'custom_styles',
    removeDialogTabs: 'image:advanced;link:advanced',
    disableNativeSpellChecker: false,
    height: 500,
    scayt_sLang: 'en_GB',
    allowedContent: true,
  }
}
