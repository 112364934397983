const required = require('@clocklimited/validity-required')
const schemata = require('@clocklimited/schemata')
const dateBeforePropertyValidator = require('validity-date-before-property')()
const validateIfPropertyEquals = require('validity-validate-if-property-equals')
const createDefaultSectionLayout = require('./lib/default-section-layout')
const createDefaultArticleLayout = require('./lib/default-article-layout')

module.exports = function () {
  return schemata({
    name: 'Section schema',
    properties: {
      _id: { type: String, tag: ['root'] },
      // Is this section the root section of the site. If so then slug is not editable
      root: { type: Boolean, defaultValue: false, tag: ['root'] },
      systemType: { type: String, defaultValue: null, tag: ['root'] },
      instance: { type: String, validators: {}, tag: ['root'] },
      name: { type: String, validators: { all: [required] }, tag: ['root'] },
      slug: {
        type: String,
        validators: {
          all: [validateIfPropertyEquals('root', false, required)],
        },
      },
      className: { type: String, tag: ['root'] },
      classNameSectionOnly: { type: String, tag: ['root'] },
      fullUrlPath: { type: String },
      parent: { type: String, tag: ['root'] },
      order: { type: Number, tag: ['root'] },
      layouts: {
        type: Object,
        tag: ['root'],
        defaultValue: () => ({
          section: createDefaultSectionLayout(),
          article: createDefaultArticleLayout(),
        }),
      },
      visible: {
        type: Boolean,
        tag: ['root'],
        defaultValue: false,
        validators: { all: [required] },
      },
      displayInNav: { type: Boolean, defaultValue: false },
      isAgeRestricted: { type: Boolean, defaultValue: false },
      displayInSecondaryNav: { type: Boolean, defaultValue: false },
      liveDate: {
        type: Date,
        tag: ['root'],
        validators: { all: [dateBeforePropertyValidator] },
      },
      expiryDate: { type: Date, tag: ['root'] },
      metaTitle: { type: String },
      metaDescription: { type: String },
      shareTitle: { type: String },
      shareDescription: { type: String },
      previewId: {
        type: String,
        tag: ['root'],
        defaultValue: function () {
          // TODO: Ensure no clash - Serby
          return Math.round(Math.random() * 100000000000).toString(36)
        },
      },
      advertTag: { type: String, tag: ['root'] },
      showAdverts: { type: Boolean, defaultValue: true, tag: ['root'] },
      account: { type: String, validators: { all: [required] }, tag: ['root'] },
      created: { type: Date, tag: ['root'] },
    },
  })
}
