const initWidget = () => ({ filterWidget: [ 'widget', 'sectionAdmin', init ] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('./views/item'),
    name: 'Filter',
    description:
      'Display a filter for events, matches or competition standings',
  }

  serviceLocator.widgetFactories.get('section').register('filter', widget)

  done()
}

module.exports = initWidget
